// Rôle requis : USER_TIMESHEET
// Ce hook permet à l'utilisateur de récupérer la liste de ses timesheets.
// Seuls les utilisateurs ayant le rôle USER_TIMESHEET peuvent accéder à cette requête.

import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const GET_TIMESHEETS = gql`
  query ConsultantsTimesheets($consultantUuid: ID!,$sliceNumber: Int!) {
    consultantTimesheets(timesheetsRequest: { consultantUuid: $consultantUuid, sliceNumber: $sliceNumber }) {
      timesheets {
        uuid
        clientName
        year
        month
        status
        daysWorked
        daysVacation
        daysIllness
        daysTraining
      }
      hasNext
    }
  }
`;

export const useConsultantTimesheets = (sliceNumber) => {
    const { user } = useAuth();
    const { id: consultantUuid } = useParams();
    if (!consultantUuid) return;

    const { loading, error, data, refetch } = useQuery(GET_TIMESHEETS, {
        variables: {consultantUuid, sliceNumber },
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
        
    });

    useEffect(() => {
        refetch()
    }, [sliceNumber])

    return { loading, error, data: data?.timesheets, refetch };
};
