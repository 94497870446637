import { useTranslation } from "react-i18next";

const skillsMapLang = {
  en: "labelEn",
  es: "labelEs",
  fr: "labelFr",
  it: "labelIt",
  nl: "labelNl",
};
export const useGetSkillLabel = () => {
      const { i18n } = useTranslation("global");
      const currentLanguageCode = i18n.language;
      const fieldLangToPrint = skillsMapLang[currentLanguageCode];
    
    return fieldLangToPrint;

}