import React, { useState } from "react";

import { Label } from "@/components/atoms/texts/label";

import useCVTab from '../../../../../hooks/global-hooks/useCVTab';
import RenderCvContent from './RenderCvContent';
import { useEffect } from 'react';
// import { useProspect } from '@/services/admin-services/useProspect';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import { Outlet } from 'react-router-dom';
import { transformFakeDataCV } from './transformFakeDataCV';
import { useProspectCvs } from '@/services/admin-services/useProspectCvs';
import { useProspect } from "@/services/admin-services/query/useProspect";
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { Button } from "@/components/molecules/buttons/button";
export default function CVTab({ isForProspect = false }) {
    const [CVData, setCVData] = useState();
    const { CVData: fakeData, error } = useCVTab();
    const { loading, error: errorUseProspect, data, refetch: refetchCVData } = useProspect();
    const { loading: loadingTemplates, error: errorTemplates, data: templates, refetch: refetchTemplates } = useProspectCvs();

    const [isDoingAction, setIsDoingAction] = useState(false);


    // console.log('CVTab() CVData : ', CVData)
    useEffect(() => {
        if (isForProspect) {
            data && setCVData(data)
        } else {
            console.log("isForProspect is false", fakeData)
            fakeData && setCVData(fakeData);
            // Exécution de la fonction avec les fake data
            const transformedCVData = fakeData && transformFakeDataCV(fakeData);
            setCVData(transformedCVData);
        }
        console.log("CVTab() CVData =", data, fakeData, CVData);
        console.log("CVTab() error =", error, isForProspect, { errorUseProspect }, loading);

    }, [fakeData, data, isDoingAction]);
    useEffect(() => {
        if (isDoingAction) {
            refetchCVData();
            setIsDoingAction(false);
        }
    }, [isDoingAction]);





    if (isForProspect && loading) {
        return (
            <span className='loader' />
        );

    }
    if (loadingTemplates) {
        return (
            <span className='loader' />
        );

    }
    if (error) {
        return (

            <PrintErrorMessage error={error} />

        );

    }
    if (isForProspect == true && (errorUseProspect || errorTemplates)) {
        return (

            <PrintErrorMessage error={errorUseProspect || errorTemplates} />

        );

    }
    if ((!CVData)) {
        return (<PrintErrorMessage label="error.fromServer.unknownError" />)

    }
    return (
        <div
            className={`flex flex-col gap-thirdDegree ${!isForProspect && `py-paddingTopAndBottom px-paddingLeftAndRight`
                } `}
        >
            {/* {console.log("CVTab, CVData = ", CVData)} */}
            <RenderCvContent
                CVData={CVData}
                isMain
                isForProspect={isForProspect}
                templateData={templates}
                isDoingAction={isDoingAction}
                setIsDoingAction={setIsDoingAction}
            />
        </div>
    );

}
