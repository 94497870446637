/**
 * @component AddManuallyProspect
 *
 * The `AddManuallyProspect` component provides a user interface for manually adding a prospect. It handles the display of different sections based on user interactions, including creating or updating a consultant and managing CV-related actions. The component integrates with other components to manage the addition process and handle various states.
 *
 * Key Features:
 * - Supports adding a prospect by editing consultant details or creating/updating a CV.
 * - Manages different states and interactions, including toggling between the consultant edit view and CV management view.
 * - Provides functionality to handle user actions such as saving drafts, clicking new, or editing.
 * - Integrates with parent components to update and refetch data as needed.
 *
 * Props:
 * - @param {Function} setIsOpen - Function to set the state of whether the panel is open or closed.
 * - @param {Function} refetch - Function to refetch data or update the state after an action.
 * - @param {Function} setIsDoingAction - Function to set the state indicating if an action is being performed.
 *
 * State:
 * - @variable {Boolean} isNewClicked - Boolean to track if the "new" button was clicked.
 * - @variable {Boolean} openCvSection - Boolean to control the visibility of the CV section.
 * - @variable {Boolean} draftIsClicked - Boolean to track if the "draft" button was clicked.
 * - @variable {Boolean} isEditing - Boolean to indicate if the user is in editing mode.
 * - @variable {Boolean} showFourthStep - Boolean to control the visibility of the fourth step.
 * - @variable {Object} user - Object representing the user details for editing or CV creation.
 *
 * Usage:
 * This component is used to provide a manual prospect addition process where users can either edit consultant details or manage CVs. It toggles between different views based on user interactions and integrates with other components for a complete prospect management workflow.
 *
 * Example:
 * <AddManuallyProspect
 *   setIsOpen={setPanelOpen}
 *   refetch={handleRefetch}
 *   setIsDoingAction={setDoingAction}
 * />
 *
 * Dependencies:
 * - `EditConsultant` for editing consultant details.
 * - `CreateAndUpdateCV` for creating or updating CVs.
 * - `useState` from React for managing component state.
 *
 * Where it is used:
 * -  AddProspectsPanel
 * 
 */


import React from 'react'
import EditConsultant from '../prospectConsultant/createConsultant/EditConsultant'
import CreateAndUpdateCV from '../prospectConsultant/CreateAndUpdateCV'
import { useState } from 'react';
export default function AddManuallyProspect({ setIsOpen, refetch, setIsDoingAction }) {

    const [isNewClicked, setNewClicked] = useState(false);

    const [openCvSection, setOpenCvSection] = useState(false);
    const [draftIsClicked, setDraftIsClicked] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [showFourthStep, setShowFourthStep] = useState(false);
    const [user, setUser] = useState({})
    return (
        <div>
            {!openCvSection && (
                <EditConsultant
                    openCvSection={setOpenCvSection}
                    setEditing={setEditing}
                    setIsOpen={setIsOpen}
                    // user={userCreated}
                    setUser={setUser}
                    showFourthStep={showFourthStep}
                    setShowFourthStep={setShowFourthStep}
                    refetchAllData={refetch}
                    setIsDoingAction={setIsDoingAction}
                />
            )}

            {openCvSection && (
                <CreateAndUpdateCV
                    openCvSection={setOpenCvSection}
                    setDraftClicked={setDraftIsClicked}
                    setNewIsClicked={setNewClicked}
                    shoulGetSectionsData={false}
                    setShowFourthStep={setShowFourthStep}
                    user={user}
                    setIsDoingAction={setIsDoingAction}
                //refetch={refetch}
                // setUser={setUserCreated}
                />
            )}
        </div>
    );
}
