/* eslint-disable react/prop-types */
/**
 * @component PrintCertification
 *
 * The `PrintCertification` component displays details of a certification and provides functionality for editing it. It shows the certification details in a collapsible format and includes an option to toggle between viewing and editing the certification.
 *
 * Key Features:
 * - Displays certification details such as title, organization, date obtained, and description.
 * - Collapsible view to show or hide details.
 * - Edit functionality to update the certification details.
 * - Integration with the `AddCertification` component for handling editing operations.
 *
 * Props:
 * - @param {Object} certification - The certification object containing details to be displayed and edited.
 * - @param {Function} setEditingCertification - Function to set the editing state for the certification.
 * - @param {Boolean} editingCertification - Flag indicating if the component is in edit mode.
 * - @param {Object} user - User object needed for API interactions.
 * - @param {Function} setIsDoingAction - Function to set the action state (e.g., loading state).
 * - @param {Function} setFormIsValid - Function to set the form validity state.
 * - @param {Function} setServerResponseIserror - Function to handle server response errors.
 * - @param {Function} setErrors - Function to set error messages.
 *
 * Local State:
 * - `showDetails`: Boolean to manage the visibility of the certification details.
 * - `showForm`: Boolean to control the visibility of the `AddCertification` form.
 *
 * Usage:
 * - This component is used to display and edit certification details.
 * - It provides a collapsible view to toggle the visibility of certification details.
 * - It integrates with the `AddCertification` component for editing purposes.
 *
 * Example:
 * ```jsx
 * <PrintCertification
 *   certification={currentCertification}
 *   setEditingCertification={setIsEditingCertification}
 *   editingCertification={isEditingCertification}
 *   user={currentUser}
 *   setIsDoingAction={setIsDoingAction}
 *   setFormIsValid={setFormIsValid}
 *   setServerResponseIserror={setServerResponseIserror}
 *   setErrors={setErrors}
 * />
 * ```
 *
 * Dependencies:
 * - @component Label: Component for displaying text labels.
 * - @component AddCertification: Component for adding or editing certifications.
 * - @mui/icons-material: Icons for expand/collapse and edit actions.
 *
 * Functions:
 * - `handleEdit()`: Toggles the form visibility for editing the certification.
 *
 * @example
 * const certification = {
 *   title: 'Certification Title',
 *   organization: 'Certification Organization',
 *   dateObtained: '2024-01-01',
 *   description: 'Certification Description',
 * };
 *
 * <PrintCertification
 *   certification={certification}
 *   setEditingCertification={setEditingCertification}
 *   editingCertification={editingCertification}
 *   user={currentUser}
 *   setIsDoingAction={setIsDoingAction}
 *   setFormIsValid={setFormIsValid}
 *   setServerResponseIserror={setServerResponseIserror}
 *   setErrors={setErrors}
 * />
 */


import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { Label } from "@/components/atoms/texts/label";
import AddCertification from "./AddCertification";

export default function PrintCertification({
  certification,
  setEditingCertification,
  editingCertification,
  user,
  setIsDoingAction,
  setFormIsValid,
  setServerResponseIserror,
  setErrors,
  
}) {
  const [showDetails, setShowDetails] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const handleEdit = () => {
    setEditingCertification(true);
    setShowForm(true);
    setFormIsValid(false)
  };


  return (
    <div className="flex flex-col gap-firstDegree">
      {!showForm && (
        <div className="flex gap-[12px] ">
          {!showDetails && (
            <ExpandMoreIcon onClick={() => setShowDetails(true)} />
          )}
          {showDetails && (
            <ExpandLessIcon onClick={() => setShowDetails(false)} />
          )}
          <span className="font-bold flex items-center">
            {certification.title}
          </span>
          <EditIcon
            className="text-primary cursor-pointer"
            onClick={handleEdit}
          />
        </div>
      )}
      {!showForm && showDetails && (
        <div className="flex flex-col gap-firstDegree">
          <div className="flex flex-row gap-firstDegree py-firstDegree text-bodySmall items-center">
            <Label fontWeight="bold">{certification.organization}</Label>
            <Label>{certification.dateObtained}</Label>
          </div>
          <div className="text-bodySmall">
            <Label>{certification.description}</Label>
          </div>
        </div>
      )}
      <AddCertification
        certification={certification}
        showForm={showForm}
        setShowForm={setShowForm}
        editingCertification={editingCertification}
        setEditingCertification={setEditingCertification}
        user={user}
        setIsDoingAction={setIsDoingAction}
        setFormIsValid={setFormIsValid}
        setServerResponseIserror={setServerResponseIserror}
        setErrors={setErrors}
      />
    </div>
  );
}
