import { isNumeric } from "@/utils/formatDateString";
// EditBills.jsx
const validateFormData = (formData, setErrors) => {
  const newErrors = {
    title: "",
    company: "",
    // city: "",
    country: "",
    startDate: "",
    endDate: "",
    sector: "",
    description: "",

   // technologies: "",
  };

  // Règles de validation
  const validationRules = {
    title: { required: true, field: "title" },
    company: { required: true, field: "company" },
    sector: { required: true, field: "sector" },
    // city: { required: true, field: "city" },
    startDate: { required: true, field: "startDate" },
    endDate: { required: true, field: "endDate" },
    description: { required: true, field: "description" },
    country: { required: true, field: "country" },

    //technologies: { required: true, field: "technologies" },
  };

  // Appliquer les règles de validation à chaque champ
  Object.keys(validationRules).forEach((fieldName) => {
    const rule = validationRules[fieldName];
    const value = formData[fieldName];
    //console.log("the cause v ", value);

    if (rule.required && (typeof value !== "string" || !value.trim())) {
      newErrors[fieldName] = rule.field;
    }
  });

  // Mettez à jour le state des erreurs
  setErrors(newErrors);

  // Retournez la liste des champs non renseignés
  return Object.keys(newErrors).filter((fieldName) => newErrors[fieldName]);
};

export { validateFormData };
