
import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Définition de la requête GraphQL pour obtenir tous les profils de consultants
const GET_ALL_CONSULTANT_PROFILES = gql`
  query ALL_CONSULTANT_PROFILES($companyUuid: ID!) {
    allConsultantProfiles(companyUuid: $companyUuid) {
      name
      uuid
    }
  }
`;

const useAllConsultantProfiles = () => {
  const { user: authUser } = useAuth();
  const companyUuid = authUser.company.uuid;

  const { loading, error, data, refetch } = useQuery(GET_ALL_CONSULTANT_PROFILES, {
    variables: { companyUuid },
    context: {
      headers: {
        Authorization: `Bearer ${authUser.strongToken}`,
      },
    },
    fetchPolicy: "network-only",
  });

  return { 
    loading, 
    error, 
    consultantProfiles: data?.allConsultantProfiles, 
    refetch 
  };
};

export default useAllConsultantProfiles;
