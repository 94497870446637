import Chip from "@/components/molecules/buttons/Chip";

export default function ReadingStatus({ isNew }) {


    // Vérifier si la différence est supérieure à 30 jours
    // console.log("reading status is new =", isNew);
    if (isNew ) {
        return (<Chip item="consultants.companyBenefits.recent" bg="success" fontWeight="bold" />);
    } else {
        return (<Chip item="consultants.companyBenefits.old" bg="medium" fontWeight="bold" />);

    }
}
