
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { useEffect, useState } from "react";
import { Input } from "../ui/input";
import { Label } from "../atoms/texts/label";
import { Button } from "../molecules/buttons/button";
import { Textarea } from "../ui/textarea";
import { Subject } from "@mui/icons-material";
import { useSendSupportEmail } from "@/services/global-services/mutation/sendSupportEmail";
import PrintErrorMessage from "./feedback/PrintErrorMessage";
import { useToast } from "@/components/organisms/feedback/use-toast"

export default function DialogSupport({ isForMobile = false, componentForMobile }) {
    const MAX_LENGTH_MESSAGE = 1000;
    const MAX_LENGTH_SUBJECT = 100;
    const DEFAULT_OBJECT = { message: undefined, subject: undefined }


    const [open, setOpen] = useState(false);
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [formErrors, setFormErrors] = useState(DEFAULT_OBJECT);
    const [formData, setFormData] = useState(DEFAULT_OBJECT);

    const { sendSupportEmail, data: sendData, loading: sendLoading, error: sendError } = useSendSupportEmail();
    const { toast } = useToast();


    const sendForm = () => {
        const isValid = validateForm();
        isValid && handleSendEmail(formData.subject, formData.message)
    }
    // Gestion de l'envoi de l'email
    const handleSendEmail = async (subject_, body_) => {
        try {
            const res = await sendSupportEmail({
                variables: {
                    subject: subject_,
                    body: body_,
                },
            });
            console.log("res =", res)
        } catch (error) {
            console.error("Erreur lors de l'envoi de l'email : ", error);
        }
    };
    const validateForm = () => {
        const errors = {};
        if (formData?.subject?.length <= MAX_LENGTH_SUBJECT && formData.message?.length <= MAX_LENGTH_MESSAGE) {
            setFormErrors(DEFAULT_OBJECT);
            return true
        }

        if (!formData.subject) { errors.subject = "error.mandatory"; }
        if (!formData.message) { errors.message = "error.mandatory"; }
        if (formData.subject?.length > MAX_LENGTH_SUBJECT) { errors.subject = "error.tooLarge"; }
        if (formData.message?.length > MAX_LENGTH_MESSAGE) { errors.message = "error.tooLarge"; }

        setFormErrors(errors);
        console.log("validateForm", formData, formErrors, formData.message, formData.subject, "errors =", errors);


        return false
    }

    const handleSubject = (e) => {
        const value = e.target.value
        if (value.length <= MAX_LENGTH_SUBJECT) {
            setSubject(value);
            setFormData({ ...formData, subject: value })
        }
        // formErrors?.subject && validateForm()
    }

    const handleMessage = (e) => {
        const value = e.target.value
        if (value.length <= MAX_LENGTH_MESSAGE) {
            setMessage(value);
            setFormData({ ...formData, message: value })
        }
        // formErrors?.message && validateForm();
    }

    const resetForm = () => {
        setSubject();
        setMessage();
        setFormErrors(DEFAULT_OBJECT);
        setFormData(DEFAULT_OBJECT);
    }
    useEffect(() => {
        !open && resetForm()
    }, [open]);

    useEffect(() => {
        console.log("sendData", sendData);
        if (!sendData) return;

        if (sendData?.sendSupportEmail == true) {
            setOpen(false);
            toast({
                variant: "success",
                title: "success.messageSent",
                duration: 1500,
            });
        } else {
            setOpen(false);
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: sendError
            });

        }

    }, [sendData]);

    useEffect(() => {
        console.log("formData =", formData)
    }, [formData]);

    useEffect(() => {
        console.log("formError =", formErrors)
    }, [formErrors]);

    return (
        <Dialog open={open} onOpenChange={setOpen} modal className={isForMobile && `w-full`}>

            <DialogTrigger asChild onClick={() => { setOpen(true); console.log("trigger activation") }} className={isForMobile && `w-full`}
            >
                {/* <Button showLeftIcon showText={false} /> */}
                {isForMobile ? componentForMobile : <Button
                    style="ghost"
                    showLeftIcon={true}
                    sizePadding={"pill"}
                    className={"w-min "}
                    showText={false}
                    leftIcon={<HelpOutlineRoundedIcon className="text-white" />}
                />
                }
                {/* <Button variant="outline">Edit Profile</Button> */}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[725px] gap-thirdDegree" xClose={true} onPointerDownOutside={false}>
                <DialogHeader>
                    <DialogTitle>
                        <Label size="h4" message="support.title"/>
                    </DialogTitle>
                    <DialogDescription className="flex flex-col gap-firstDegree">
                        <Label message="support.description1"/>
                        <Label message="support.description2"/>
                    </DialogDescription>
                </DialogHeader>
                {/* {subject}
                {message} */}
                <div className="grid grid-cols-4 gap-secondDegree items-center">
                    <Label className="col-span-1" message="support.subject"/>
                    <div className="col-span-3 flex flex-col items-end gap-betweenText">
                        <Input
                            className={` text-body ${formErrors?.subject && `border border-error`}`}

                            value={subject}
                            onChange={handleSubject}
                        />
                        {formErrors?.subject && <Label className="" color="error" message={formErrors?.subject} />}
                    </div>


                    <Label className="col-span-1" message="support.message"/>
                    <div className="col-span-3 flex flex-col items-end gap-betweenText">
                        <Textarea
                            className={`h-64 bg-white text-body  ${formErrors?.message && `border border-error`}`}
                            value={message}
                            onChange={handleMessage}
                        />
                        {formErrors?.message && <Label className="col-span-4" color="error" message={formErrors?.message} />}
                        <span>
                            <Label size="caption">{message?.length} / {MAX_LENGTH_MESSAGE} </Label>
                            <Label message="support.carMax" size="caption"/>
                        </span>
                    </div>

                </div>
                {sendLoading && <span className="loader w-full" />}
                {sendError && <PrintErrorMessage error={sendError} />}
                <DialogFooter>
                    <DialogClose  >
                        <Button text="global.actions.close" style="ghost" />
                    </DialogClose>

                    <Button className="w-full" text="global.actions.send" onClick={sendForm} disabled={!formData.message || !formData.subject} />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}