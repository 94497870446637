import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useParams } from "react-router-dom";
import { useState } from "react";

const ADD_PROSPECT_CV = gql`
  mutation newTemplateData(
    $personUuid: ID!, 
    $certificationUuids: [ID!], 
    $descriptionUuid: ID!, 
    $educationUuids: [ID!], 
    $experienceUuids: [ID!], 
    $language: CvLanguage!, 
    $mappedSkillUuids: [ID!], 
    $titleUuid: ID!, 
    $projectUuids: [ID!]
    $templateTitle : String!
  ) {
    addProspectCv(newProspectCv: {
      personUuid: $personUuid,
      certificationUuids: $certificationUuids,
      descriptionUuid: $descriptionUuid,
      educationUuids: $educationUuids,
      experienceUuids: $experienceUuids,
      language: $language,
      mappedSkillUuids: $mappedSkillUuids,
      titleUuid: $titleUuid,
      projectUuids: $projectUuids
            templateTitle: $templateTitle

    }) {
      uuid
    }
  }
`;

export const useAddProspectCV = () => {
  const { user } = useAuth();
  const { prospectID } = useParams();
  const [addProspectCv, { loading }] = useMutation(ADD_PROSPECT_CV, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });
  
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const addNewProspectCV = async (templateData, language, title) => {
    setError(null); // Reset error before new operation
    setData(null);  // Reset data before new operation
    
    if (!prospectID) {
      const errorMessage = "prospectID by params is empty";
      console.error(errorMessage);
      setError(errorMessage);
      return;
    }

    try {
      const {
        certifications,
        descriptions,
        educations,
        experiences,
        mappedSkills,
        titles,
        projects
      } = templateData;

      // Validation des données
      if (!descriptions || descriptions.length !== 1) {
        throw new Error("Description must contain exactly one item");
      }
      if (!titles || titles.length !== 1) {
        throw new Error("Title must contain exactly one item");
      }
      if (!prospectID || !language || !title || !titles[0]?.uuid || !descriptions[0]?.uuid) {
        throw new Error("Missing required fields: uuid, title, description, or language");
      }

      const result = await addProspectCv({
        variables: {
          personUuid: prospectID,
          certificationUuids: certifications.map(cert => cert.uuid),
          descriptionUuid: descriptions[0].uuid,
          educationUuids: educations.map(edu => edu.uuid),
          experienceUuids: experiences.map(exp => exp.uuid),
          language: language,
          mappedSkillUuids: mappedSkills.map(skill => skill.uuid),
          titleUuid: titles[0].uuid,
          projectUuids: projects.map(proj => proj.uuid),
          templateTitle:title
        }
      });
      
      setData(result.data);
      return result.data.addProspectCv.uuid;
    } catch (e) {
      console.error("useAddProspectCV Error:", e);
      setError(e.message);
    }
  };

  return { addNewProspectCV, data, loading, error };
};

export default useAddProspectCV;
