/* eslint-disable react/prop-types */
/**
 * @component UpdateProspectInfo
 *
 * The `UpdateProspectInfo` component allows users to view and update their personal information and cooptation details. It includes functionalities for form validation, handling user input, and interacting with backend services to update prospect information.
 *
 * Key Features:
 * - Displays current personal information and cooptation details.
 * - Provides an editable form for updating personal information and selecting a cooptant.
 * - Supports form validation and displays error messages for invalid inputs.
 * - Handles file uploads and form submissions to update user information.
 * - Uses a select component to choose a cooptant from a list of users.
 *
 * Props:
 * - @param {Object} user - The user object containing current information (e.g., `lastname`, `firstname`, `personalEmail`, `birthdate`, `gender`, `cooptedBy`, `uuid`).
 * - @param {Function} setIsDoingAction - Function to indicate that an action (like saving) is in progress.
 * - @param {Function} setFormIsValid - Function to set the form validity state.
 * - @param {Function} setServerResponseIserror - Function to set the server response error state.
 * - @param {Function} setErrors - Function to update error messages for invalid inputs.
 * - @param {Object} ClassName - Additional class names to apply to the component.
 *
 * Usage:
 * - This component is used in a user profile or admin interface to allow updates to personal information and cooptation details.
 *
 * Example:
 * ```jsx
 * <UpdateProspectInfo
 *   user={currentUser}
 *   setIsDoingAction={setLoadingState}
 *   setFormIsValid={validateFormState}
 *   setServerResponseIserror={handleServerError}
 *   setErrors={updateErrors}
 * />
 * ```
 *
 * Dependencies:
 * - `Button` component for form actions (Save, Cancel).
 * - `Label` component for displaying form labels and error messages.
 * - `Select` component for choosing a cooptant from a list.
 * - `TextFieldInput` and `Input` components for form input fields.
 * - `useUpdateCooptedBy` and `useUpdateProspectUpdateItems` hooks for handling API calls.
 * - `useAllUsers` hook for fetching the list of all users for cooptation.
 * - `validateFormData` and `deleteFromInvalidInputs` utility functions for form validation.
 * - `useTranslation` hook from `react-i18next` for internationalization.
 *
 * Sub-Components:
 * - @component Button: Provides Save and Cancel buttons for the form.
 * - @component Label: Displays labels and error messages.
 * - @component Select: Allows the selection of a cooptant from a list of users.
 * - @component TextFieldInput: A text input field used in the form.
 * - @component Input: An alternative text input field used in the form.
 * - @component Form: Wraps the form elements and handles submission.
 */

import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { useState } from "react";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { useUpdateCooptedBy } from "@/services/admin-services/mutations/useUpdateCooptedBy";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import { validateFormData } from "./validateForm";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import { useTranslation } from "react-i18next";
import { useAllUsers } from "@/services/admin-services/query/useAllUsersV2";
import EditIcon from "@mui/icons-material/Edit";
import useUpdateProspectUpdateItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectUpdateItems";
import { Input } from "@/components/ui/input";
import { GENDERS } from "@/utils/globalConst";
export default function UpdateProspectInfo({ user, setIsDoingAction, setFormIsValid, setServerResponseIserror, setErrors, ...ClassName }) {
    const [showForm, setShowForm] = useState(false);
    const [civilities, setCivilities] = useState(GENDERS);
    const [selectedCooptant, setCooptant] = useState(user?.cooptedBy ? user?.cooptedBy?.uuid : "");

    const form = useForm();
    const [t] = useTranslation("global");

    const { loading: loadingUsers, error: errorUsers, data, refetch, } = useAllUsers();

    const notFilled = <span className="text-disabled text-body">{t("error.unfilled")}</span>;

    const [formData, setFormData] = useState({
        name: user ? (user.lastname == "XXX" ? "" : user.lastname) : "",
        firstName: user ? (user.firstname == "XXX" ? "" : user.firstname) : "",
        personalEmail: user ? user.personalEmail : "",
        dayOfBirth: user ? user.birthdate : "",
        gender: user ? user.gender : "",
        cooptant: user ? user?.cooptedBy?.uuid : "",
        uuid: user ? user.uuid : "",
    });
    // const [errors, setErrors] = useState({
    //     name: "",
    //     firstName: "",
    //     personalEmail: "",
    //     dayOfBirth: "",
    //     gender: "",
    // });

    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const {
        updateCooptedBy,
        loading: loadingCooptedBy,
        error: errorCoopted,
    } = useUpdateCooptedBy();
    const {
        updateProspectInfo,
        errorUpdateProspectInfo,
        loadingUpdateProspectInfo,
    } = useUpdateProspectUpdateItems();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErrors);
        setlistOfInvalidInput(isValid);
        if (isValid.length == 0) {
            try {
                await updateProspectInfo(transformToProspectData(formData));

                setIsDoingAction && setIsDoingAction(true);
                setShowForm(false);
            } catch (err) {
                alert(err.message);
            }
            console.log("Form is valid", formData);

        } else {
            console.log("invalid form");
            console.log("value invalid", isValid);
            console.log("form data", formData);
        }
    };
    const handleSecondStep = async (valueSelected) => {
        try {

            console.log("valeue selected", valueSelected);

            await updateCooptedBy(user.uuid, valueSelected);
            if (errorCoopted) {
                setErrors(errorCoopted);
                setServerResponseIserror(true);
                console.log(errorCoopted)
            }
            else {
                setCooptant(valueSelected);
                setIsDoingAction(true)
            }

            refetch();


        } catch (err) {
            alert(`Failed to update coopted by: ${err.message}`);
        }
    };

    const handleChange = (e, name) => {
        const value = name != "gender" ? e.target.value : e;

        deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);
        console.log(value, e, name != "gender" ? e.target : e);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

    };

    return (
        <>
            {!showForm ? (
                <>
                    <Label
                        message="consultants.statement.prospectConsultantPanel.personalInfoAndCooptation"
                        size="h3"
                        fontWeight="bold"
                    />

                    <div className="flex flex-row gap-secondDegree items-center">
                        <div className="flex flex-col gap-betweenText grow ">
                            <span className="flex gap-betweenText">
                                <Label fontWeight="bold" message="consultants.statement.prospectConsultantPanel.formPlaceHolder.name" />
                                <Label fontWeight="bold" message="consultants.statement.prospectConsultantPanel.formPlaceHolder.firstName" />
                                <Label>{user.lastname == "XXX" ? notFilled : user.lastname}</Label>
                                <Label>{user.firstname == "XXX" ? notFilled : user.firstname}</Label>


                            </span>
                            <span className="flex gap-betweenText">
                                <Label fontWeight="bold" message="consultants.statement.prospectConsultantPanel.formPlaceHolder.dayOfBirth" />
                                <Label>{user?.birthdate || notFilled}</Label>
                            </span>
                            <span className="flex gap-betweenText">
                                <Label fontWeight="bold" message="consultants.statement.prospectConsultantPanel.formPlaceHolder.personalEmail" />
                                <Label>{user?.personalEmail || notFilled}</Label>
                            </span>
                            <span className="flex gap-betweenText">
                                <Label fontWeight="bold" message="consultants.informations.cooptedBy" />
                                <Label>{user?.cooptedBy ? (user?.cooptedBy?.firstname +
                                    " " +
                                    user?.cooptedBy?.lastname + " - " +
                                    user?.cooptedBy?.trigram) : notFilled}</Label>
                            </span>
                        </div>
                        <Button
                            showLeftIcon={true}
                            leftIcon={<EditIcon className="text-primary" fontSize="small" />}
                            showText={false}
                            style="ghost"
                            onClick={() => setShowForm(true)}
                        />
                    </div>
                </>
            ) : (
                <div>
                    <Form {...form}>
                        <form onSubmit={handleSubmit}>
                            <div className="py-secondDegree flex flex-col gap-secondDegree w-full grid grid-cols-4 items-center">
                                {/* {console.log(formData)} */}
                                <Label className="col-span-1 " fontWeight="bold" message="global.information.genre" />
                                <Select
                                    value={formData.gender}
                                    onValueChange={(e) => handleChange(e, "gender")}
                                >
                                    <SelectTrigger className={` bg-white col-span-3 ${!formData.gender && `border border-error`}`}>


                                        <SelectValue
                                            placeholder={
                                                formData.gender
                                                    ? formData.gender
                                                    : t("global.information.genre")
                                            }
                                        />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectGroup>
                                                {civilities?.map((civility) => (
                                                    <SelectItem key={civility.enum} value={civility.enum}>
                                                        <Label message={civility.label}></Label>
                                                    </SelectItem>
                                                ))}
                                            </SelectGroup>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>

                                {/* firstname */}
                                <TextFieldInput
                                    required
                                    placeHolder={
                                        "consultants.statement.prospectConsultantPanel.formPlaceHolder.firstName"
                                    }
                                    value={formData.firstName}
                                    onChange={(e) => handleChange(e, "firstName")}
                                    //name="firstName"
                                    error={listOfInvalidInput.includes("firstName")}
                                    type="text"
                                    className="hidden"
                                />

                                <Label className="col-span-1" fontWeight="bold" message="consultants.statement.prospectConsultantPanel.formPlaceHolder.firstName" />
                                <Input
                                    required
                                    placeHolder={t("consultants.statement.prospectConsultantPanel.formPlaceHolder.firstName")}
                                    value={formData.firstName}
                                    onChange={(e) => handleChange(e, "firstName")}
                                    //name="firstName"
                                    error={listOfInvalidInput.includes("firstName")}
                                    type="text"
                                    className={`col-span-3 ${!formData.firstName && `border border-error`}`}
                                />
                                {/* name */}
                                <TextFieldInput
                                    placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.name"
                                    value={formData.name}
                                    onChange={(e) => handleChange(e, "name")}
                                    required
                                    //name="name"
                                    type="text"
                                    error={listOfInvalidInput.includes("name")}
                                    className="hidden"

                                />
                                <Label className="col-span-1" fontWeight="bold" message="consultants.statement.prospectConsultantPanel.formPlaceHolder.name" />
                                <Input
                                    required
                                    placeHolder={t("consultants.statement.prospectConsultantPanel.formPlaceHolder.name")}
                                    value={formData.name}
                                    onChange={(e) => handleChange(e, "name")}
                                    type="text"
                                    error={listOfInvalidInput.includes("name")}
                                    className={`col-span-3 ${!formData.name && `border border-error`}`}

                                />
                                {/* birthday */}
                                <Label className="col-span-1" fontWeight="bold" message="consultants.statement.prospectConsultantPanel.formPlaceHolder.dayOfBirth" />
                                <TextFieldInput
                                    required
                                    placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.dayOfBirth"
                                    value={formData.dayOfBirth}
                                    onChange={(e) => handleChange(e, "dayOfBirth")}
                                    //name="firstName"
                                    error={listOfInvalidInput.includes("dayOfBirth")}
                                    type="date"
                                    className="hidden"
                                />
                                <Input
                                    required
                                    placeHolder={t("consultants.statement.prospectConsultantPanel.formPlaceHolder.dayOfBirth")}
                                    value={formData.dayOfBirth}
                                    onChange={(e) => handleChange(e, "dayOfBirth")}
                                    //name="firstName"
                                    error={listOfInvalidInput.includes("dayOfBirth")}
                                    type="date"
                                    className={`col-span-3 ${!formData.dayOfBirth && `border border-error`}`}


                                />

                                {/* personalEmail */}
                                <Label className="col-span-1" fontWeight="bold" message="consultants.statement.prospectConsultantPanel.formPlaceHolder.personalEmail" />

                                <TextFieldInput
                                    placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.personalEmail"
                                    // name="personalEmail"
                                    value={formData.personalEmail}
                                    required
                                    onChange={(e) => handleChange(e, "personalEmail")}
                                    type="email"
                                    error={listOfInvalidInput.includes("personalEmail")}
                                    className="hidden"
                                />
                                <Input
                                    required
                                    placeHolder={t("consultants.statement.prospectConsultantPanel.formPlaceHolder.personalEmail")}
                                    value={formData.personalEmail}

                                    onChange={(e) => handleChange(e, "personalEmail")}
                                    type="email"
                                    error={listOfInvalidInput.includes("personalEmail")}
                                    className={`col-span-3 ${!formData.personalEmail && `border border-error`}`}

                                />

                                {/* cooptation */}
                                <Label className="col-span-1" fontWeight="bold" message="consultants.informations.cooptedBy" />
                                <Select value={selectedCooptant} onValueChange={handleSecondStep} >
                                    <SelectTrigger className={` bg-white col-span-3 `}>
                                        <SelectValue placeholder={<Label message="global.actions.select" />} />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <>
                                            {data?.allUsers?.map((user) => (
                                                <SelectItem value={user.uuid} key={user.uuid}>
                                                    <Label>
                                                        {user.firstname +
                                                            " " +
                                                            user.lastname +
                                                            " - " +
                                                            user.trigram}
                                                    </Label>
                                                </SelectItem>
                                            ))}
                                        </>
                                    </SelectContent>
                                </Select>

                            </div>
                            <div className="flex flex-row gap-[12px] items-center w-full">
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<ClearIcon fontSize="small" />}
                                    showText={true}
                                    text="global.actions.cancel"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="outline"
                                    className="font-bold border-primary w-full"
                                    onClick={() => setShowForm(false)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                />
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<SaveIcon fontSize="small" />}
                                    showText={true}
                                    text="global.actions.save"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="fill"
                                    type="submit"
                                    className="font-bold w-full"
                                //onClick={listOfInvalidInput.length==0 && setShowForm(false) }// Options: "fill", "outline", "ghost", "dressedGhost"
                                />
                            </div>
                        </form>
                    </Form>
                </div>
            )}
        </>
    );
}
const transformToProspectData = (formData) => {
    return {
        lastname: formData.name,
        firstname: formData.firstName,
        gender: formData.gender,
        personalEmail: formData.personalEmail,
        birthdate: formData.dayOfBirth,
        uuid: formData.uuid


    };
};