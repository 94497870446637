/* eslint-disable react/prop-types */
/**
 * @component EditCVItemProspectPanel
 *
 * This component provides a flexible panel for editing various CV items (e.g., certification, project, experience, education) 
 * for a prospect. It integrates with different subcomponents based on the `type` of CV item being edited and manages the 
 * form's visibility, validation state, and error handling.
 *
 * Key Features:
 * - Displays a button that triggers a sheet (drawer) to open and show the edit form.
 * - Dynamically renders the appropriate form (certification, project, experience, education) based on the `type` prop.
 * - Handles form state, including validation and error management, passing state back to the parent component.
 * - Uses `useToast` for user notifications and feedback after a form submission (e.g., showing success or error messages).
 * - Manages open/close state of the sheet using internal state (`isOpen`), and provides callback functions for form cancellation.
 * - Updates prospect information and passes relevant props such as `setIsDoingAction` to manage UI state during form submission.
 *
 * Props:
 * - @param {String} [templateUuid] - The UUID of the template associated with the CV item (optional).
 * - @param {String} type - The type of CV item being edited (e.g., "certification", "project", "experience", "education").
 * - @param {Object} dataItem - The data related to the specific CV item being edited.
 * - @param {Function} setIsDoingAction - Callback function to signal that an action (like form submission) is in progress.
 * - @param {...Object} props - Additional props are spread onto the root element for further customization.
 *
 * Internal State:
 * - @state {Boolean} isOpen - Controls whether the sheet is open or closed.
 * - @state {Boolean} formIsValid - Tracks the form's validation state, showing a success message when true.
 * - @state {Boolean} serverresponseIsError - Indicates if an error occurred during form submission, displaying the appropriate error message.
 * - @state {Object} errors - Stores error details for display.
 * - @state {Boolean} showForm - Manages the visibility of the form content.
 * - @state {Boolean} isVisible - Handles the visibility of the form input elements (not fully used in this version).
 *
 * Usage:
 * - This component can be used in the context of editing a prospect's CV, providing flexible handling for different types of CV data.
 */


import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetSlot,
} from "@/components/templates/sheet";
import { useParams } from "react-router-dom";
import { useToast } from "@/components/organisms/feedback/use-toast";
import EditIcon from "@mui/icons-material/Edit";
import AddCertification from "./certification/AddCertification";
import FormIsSend from "@/components/organisms/feedback/formIsSend";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import { Label } from "@/components/atoms/texts/label";
import AddProject from "./project/AddProject";
import AddEducation from "./education/AddEducation";
import AddExperience from "./experience/AddExperience";
export default function EditCVItemProspectPanel({
  templateUuid,
  type,
  dataItem,
  setIsDoingAction,
  setOpenShowMoreButton,
  ...props
}) {
  const [isOpen, setIsOpen] = useState();
  const [formIsValid, setFormIsValid] = useState(false);
  const [serverresponseIsError, setServerResponseIserror] = useState(false);
  const [errors, setErrors] = useState(null);
  const { prospectID } = useParams();
  const [showForm, setShowForm] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const user = {
    uuid: prospectID,
   // firstname: dataMinimal.firstname,
   // lastname: dataMinimal.lastname,
  };
  const { toast } = useToast();

//   const handleOpen = () => {
//     setIsOpen(!isOpen);
//   };

  function onCancel(flag) {
    setShowForm(flag);

    setIsOpen(false);
  }
  return (
      <Sheet open={isOpen} onOpenChange={setIsOpen} >
        <SheetTrigger className="w-full flex flex-row" >
          <Button
            style="ghost"
            showLeftIcon
            leftIcon={<EditIcon className="text-bodySmall" />}
            showText
            text={"global.actions.editInfos"}
            
            {...props}
          />
        </SheetTrigger>
        <SheetContent>
          <SheetHeader
            title={"consultants.statement.prospectConsultantPanel.header"}
            // subheader="consultants.statement.vatSettings.autoSaved"
          />
          <SheetSlot>
            {formIsValid && (
              <FormIsSend message={"success.changesSaved"} />
            )}
            {serverresponseIsError && <PrintErrorMessage error={errors} />}
            {type == "certification" && (
              <AddCertification
                certification={dataItem}
                showForm={showForm}
                setShowForm={onCancel}
                editingCertification
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
                setOpenShowMoreButton={setOpenShowMoreButton}
              />
            )}

            {type == "project" && (
              <AddProject
                project={dataItem}
                showForm={showForm}
                setShowForm={onCancel}
                editingProject
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
                setOpenShowMoreButton={setOpenShowMoreButton}
              />
            )}

            {type == "experience" && (
              <AddExperience
                experience={dataItem}
                showForm={showForm}
                setShowForm={onCancel}
                editingExperience
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
                setOpenShowMoreButton={setOpenShowMoreButton}

              />
            )}

            {type == "education" && (
              <AddEducation
                education={dataItem}
                showForm={showForm}
                setShowForm={onCancel}
                editingEducation
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
                setOpenShowMoreButton={setOpenShowMoreButton}

              />
            )}
          </SheetSlot>
        </SheetContent>
      </Sheet>
  );
}
