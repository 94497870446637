import { useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { gql } from "@apollo/client";
const GET_ALL_USERS = gql`
  query GetAllUsers {
    allUsers {
      firstname
      lastname
      uuid
      trigram
    }
  }
`;

export const useAllUsers = () => {
  const { user } = useAuth();
  const { loading, error, data, refetch } = useQuery(GET_ALL_USERS, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    fetchPolicy: "network-only", // Ensure fresh data is fetched from the server
  });

  return { loading, error, data, refetch };
};
