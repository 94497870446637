// Rôle requis : ADMIN_TIMESHEET
// Ce hook permet à un administrateur de récupérer le fichier PDF signé par le client.
// Seuls les utilisateurs ayant le rôle ADMIN_TIMESHEET peuvent accéder à cette requête.

import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';

function useSignedTimesheet() {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);

    const requestSignedTimesheet = async (timesheetUuid, year, month) => {
        const apiUrl = `${window.ENV.BACKEND_URL}/api/secured/admin/timesheet/signedTimesheet/${timesheetUuid}`;
        setLoading(true);
        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user?.strongToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            // console.log("blob file timesheet ", blob, url)

            setData({ url, type: blob.type });
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { requestSignedTimesheet, data, loading, error };
}

export default useSignedTimesheet;
