// Fonction pour vérifier si une chaîne de caractères est un email valide
export function isEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@.]{2,}$/;
    return emailRegex.test(email);
}

// Fonction pour vérifier si une chaîne de caractères est un numéro de téléphone valide
export function isPhoneNumber(phone) {
    // const phoneRegex = /^(\+?\d{11})$|^(0\d{9})$/;
    const phoneRegex = /^(\+?\d{11,13})$|^(0\d{9})$/;

    return phoneRegex.test(phone);
}

// Fonction pour vérifier si une chaîne de caractères n'est pas vide
export function isNotEmpty(str) {
    return str?.trim()?.length > 0;
}

// Fonction pour vérifier si une chaîne de caractères est un IBAN valide
export function isIban(iban) {
    const ibanRegex = /^[A-Z]{2}\d{2}[A-Z\d]{11,30}$/;
    return ibanRegex.test(iban);
}

// Fonction pour vérifier si une chaîne de caractères est un BIC valide
export function isBic(bic) {
    const bicRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
    return bicRegex.test(bic);
}
export function isZipCode(zipCode) {
    // Cette regex vérifie si le code postal contient 4 à 10 chiffres, avec ou sans espaces
    const zipCodeRegex = /^[A-Za-z0-9\s-]{4,10}$/;
    
    return zipCodeRegex.test(zipCode);
}
export function isAddressLine(addressLine) {
    // Cette regex vérifie que l'adresse contient des lettres, des chiffres, des espaces, des virgules, des points, des traits d'union et des apostrophes
    const addressLine1Regex = /^[a-zA-Z0-9\s,'-]{1,100}$/;
    return addressLine1Regex.test(addressLine);
}
export function isVatRate(rate){
    const regex = /^(100(\.0{1,2})?|[0-9]?[0-9](\.[0-9]{1,2})?)$/;
    return regex.test(rate);
};
