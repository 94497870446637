import { isNumeric } from "@/utils/formatDateString";
// EditBills.jsx
const validateFormData = (formData, setErrors) => {
    const newErrors = {
        name: "",
        firstName: "",
        personalEmail: "",
        dayOfBirth: "",
        gender: ""
    };

    // Règles de validation
    const validationRules = {
        name: { required: true, field: "name" },
        firstName: { required: true, field: "firstName" },
        personalEmail: { required: true, field: "personalEmail" },
        dayOfBirth: { required: true, field: "dayOfBirth" },
        gender: { required: true, field: "gender" },
    };

    // Appliquer les règles de validation à chaque champ
    Object.keys(validationRules).forEach((fieldName) => {
        const rule = validationRules[fieldName];
        const value = formData[fieldName];
        //console.log("the cause v ", value);

        if (rule.required && (typeof value !== "string" || !value.trim())) {
            newErrors[fieldName] = rule.field;
        }
    });

    // Mettez à jour le state des erreurs
    setErrors(newErrors);

    // Retournez la liste des champs non renseignés
    return Object.keys(newErrors).filter((fieldName) => newErrors[fieldName]);
};

export { validateFormData };
