/* eslint-disable react/prop-types */
/* eslint-disable react/prop-types */
/**
 * @component Filter
 *
 * The `Filter` component provides a user interface for filtering items based on a selection. It allows single or multiple selections, depending on the `inclusive` prop. The component sorts the items before displaying them and handles user interactions to update the selected items.
 *
 * Key Features:
 * - Displays a list of filter items.
 * - Supports single or multiple item selection based on the `inclusive` prop.
 * - Automatically selects the first item in the list if no item is selected.
 * - Sorts items in ascending or descending order based on the `sortItemDESC` prop.
 *
 * Props:
 * - @param {Array} items - List of items to be displayed in the filter. Defaults to `['202', '2023', '2022', '2021']`.
 * - @param {string} className - Additional CSS classes to be applied to the filter container.
 * - @param {Function} informSelectedItem - Callback function to inform the parent component of the selected item(s).
 * - @param {boolean} sortItemDESC - Determines the sorting order of items. Defaults to `true` (descending order).
 * - @param {boolean} inclusive - Determines whether multiple items can be selected (true) or only one item (false). Defaults to `false`.
 *
 * Local State:
 * - `selectedItem` (string|null): The currently selected item when `inclusive` is false.
 * - `selectedItems` (Array<string>): The list of selected items when `inclusive` is true.
 *
 * Computed Values:
 * - `sortedItems` (Array<string>): The sorted list of items based on the `sortItemDESC` prop.
 *
 * Usage:
 * - This component is used to provide a filtering interface where users can select items. It can be integrated into forms, dashboards, or any UI where filtering is needed.
 *
 * Example:
 * ```jsx
 * <Filter
 *   items={['2023', '2022', '2021']}
 *   className="my-custom-class"
 *   informSelectedItem={(selected) => console.log(selected)}
 *   sortItemDESC={true}
 *   inclusive={true}
 * />
 * ```
 *
 * Dependencies:
 * - @component FilterItem: Component for rendering individual filter items.
 * - @lib utils: Utility functions for handling class names.
 *
 * Functions:
 * - `handleItemClick(item)`: Handles the click event on a filter item, updating the selected item(s) and invoking the `informSelectedItem` callback.
 *
 * @example
 * const handleFilterSelection = (selected) => {
 *   console.log('Selected items:', selected);
 * };
 *
 * <Filter
 *   items={['2024', '2023', '2022']}
 *   informSelectedItem={handleFilterSelection}
 *   sortItemDESC={true}
 *   inclusive={true}
 * />
 * ```
 */

import React, { useEffect, useState } from 'react';
import FilterItem from '../molecules/FilterItem';
import { cn } from '@/lib/utils';

const defaultFilterItem = ['202', '2023', '2022', '2021'];

function Filter({ items = defaultFilterItem, className, informSelectedItem, sortItemDESC = true, inclusive = false }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  // const [numberItem, setNumberItem] = useState(items.length);

  //setNumberItem(items.length);
  const sortItems = (a, b) => {
    return sortItemDESC ? b - a : a - b;
  };

  // Tri des éléments
  const sortedItems = [...items].sort(sortItems);

  useEffect(() => {
    // Set the first item in the sorted list as the default selected item

    if (inclusive) {
      // console.log("filter selectedItems = ", (selectedItems.length == 0))
      (!selectedItems || selectedItems.length == 0) && setSelectedItems( [ sortedItems[0]] );

      (!selectedItems || selectedItems.length == 0) && informSelectedItem && informSelectedItem([ sortedItems[0]]);


      
    } else {
      !selectedItem && setSelectedItem(sortedItems[0]);
      !selectedItem && informSelectedItem && informSelectedItem(sortedItems[0]);

    }
  }, [sortedItems]);

  const handleItemClick = (item) => {
    if (inclusive) {
      setSelectedItems(prevItems => {
        if (prevItems.includes(item)) {
          informSelectedItem(prevItems.filter(currentItem => currentItem !== item).sort((a, b) => b - a));
          return prevItems.filter(currentItem => currentItem !== item);
        } else {
          informSelectedItem([...prevItems, item].sort((a, b) => b - a));
          return [...prevItems, item];
        }
      });
    } else {
      setSelectedItem(item);
      informSelectedItem(item);

    }
  };
  const gridColsClass = `flex flex-row grow ${
    inclusive ? `gap-firstDegree` : `border-primary border rounded-[50px]`
  }   `;
  // const gridColsClass = `flex flex-row    grow  outline outline-offset-0`;


  //you should define your own w in your component where you call this filter
  return (
    <div className='w-auto '>
      {inclusive ?
        <div className={cn(gridColsClass, className)}>
          { (selectedItems) && sortedItems.map((item, index) => (
            <FilterItem
              key={index}
              itemContent={item}
              isSelected={inclusive ? selectedItems.includes(item) : item === selectedItem}
              className={`cursor-pointer ${inclusive && `border border-primary rounded-filterItemBorder text-primary`}`}
              onClick={() => handleItemClick(item)}

            />
          ))}
        </div>
        :
        <div className={cn(gridColsClass, className)}>
          {sortedItems.map((item, index) => (
            <FilterItem
              key={index}
              itemContent={item}
              isSelected={item === selectedItem}
              className="cursor-pointer "
              onClick={() => handleItemClick(item)}

            />
          ))}
        </div>
      }

    </div>
  );
}

export default Filter;
