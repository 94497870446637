/* eslint-disable react/prop-types */
/* eslint-disable react/prop-types */
/**
 * @component DisplayHoverContent
 *
 * The `DisplayHoverContent` component is used to display detailed hover content for a table cell, including employee cost and cooptation details. It fetches and displays data based on the hovered cell's ID and the provided user month data.
 *
 * Key Features:
 * - Fetches data based on the hovered cell's ID.
 * - Computes and displays hover content including employee costs and cooptation details.
 * - Supports dynamic content rendering based on the cell's context and user month data.
 *
 * Props:
 * - @param {string} Id - The ID of the currently hovered cell, used to determine which data to display.
 * - @param {Object} userMonthdata - The user month data used to fetch and display detailed information.
 *
 * Local State:
 * - `loading` (boolean): Indicates if the data is currently being fetched.
 * - `indexHovered` (number): The index of the hovered month data.
 *
 * Computed Values:
 * - `findMonthIndex(id, monthData)`: Finds the index of the month in the `monthData` based on the cell ID.
 * - `computeHoverTitle(actualCellId)`: Computes the hover title based on the cell ID.
 * - `computeHoverData(actualCellId, userMonthData, hoverDataEmployerCost)`: Computes the hover data including labels and values based on the cell ID and user month data.
 * - `printHoverDataCooptation(coopations)`: (Not used in the current implementation) Placeholder function for printing cooptation data.
 *
 * Usage:
 * - This component is used to display additional information in a hover card when a user hovers over a cell in a table. It dynamically fetches and displays data relevant to the hovered cell.
 *
 * Example:
 * ```jsx
 * <DisplayHoverContent
 *   Id="January_1"
 *   userMonthdata={{
 *     // Example user month data
 *     January: {
 *       employeeContributions: 5000,
 *       employerContributions: 2000,
 *       taxes: 300,
 *       expenses: 150,
 *       companyBenefit: 100,
 *       netSalary: 3500,
 *       coopations: [
 *         { cooptedName: "John Doe", percentage: 10, amount: 1000, currency: "EUR" },
 *       ],
 *     },
 *   }}
 * />
 * ```
 *
 * Dependencies:
 * - @component Label: Component for displaying text labels with customizable size and weight.
 * - @context ConsultantContext: Provides additional context for the consultant (not used in the current implementation).
 * - @utils transformUserData: Utility functions for mapping month names and handling user data.
 *
 * Functions:
 * - `findMonthIndex(id, monthData)`: Finds the index of the month based on the cell ID and month data.
 * - `computeHoverTitle(actualCellId)`: Computes the hover title based on the cell ID.
 * - `computeHoverData(actualCellId, userMonthData, hoverDataEmployerCost)`: Computes the hover data including labels and values based on the cell ID and user month data.
 * - `printHoverDataCooptation(coopations)`: (Not used in the current implementation) Placeholder function for printing cooptation data.
 *
 * @example
 * <DisplayHoverContent
 *   Id="February_3"
 *   userMonthdata={{
 *     // Example user month data
 *     February: {
 *       employeeContributions: 4500,
 *       employerContributions: 1800,
 *       taxes: 250,
 *       expenses: 120,
 *       companyBenefit: 80,
 *       netSalary: 3500,
 *       coopations: [
 *         { cooptedName: "Jane Smith", percentage: 5, amount: 500, currency: "USD" },
 *       ],
 *     },
 *   }}
 * />
 * ```
 */

import React from 'react';
import { Label } from '@/components/atoms/texts/label';
import { monthMap } from '@/utils/transformUserData';
import { useEffect, useState } from 'react';
// import { translaterRowNameTab } from '@/utils/monthStringTranslater';
import { useConsultantContext } from '@/context/ConsultantContext';


const hoverLabelEmployeeCost = [
  { employeeCharge: "consultants.statement.overview.employeeCharge" },
  {
    employerContributions:
      "consultants.statement.overview.employerContributions",
  },
  { taxes: "consultants.statement.overview.taxes" },
  { expenses: "consultants.dashboard.tabs.expense" },
  { companyBenefit: "consultants.dashboard.tabs.companyBenefits" },
  { netSalary: "consultants.statement.overview.netSalary" }
];




const employerCostOverview = 'consultants.statement.overflow.employerCostOverview';
const cooptationIncomeDetails = 'consultants.statement.overflow.cooptationIncomeDetails';
const findMonthIndex=(id, monthData)=> {
  // Extraire le nom du mois de l'ID
  const [monthName] = id.split("_");

  // Trouver le numéro du mois correspondant
  let monthNumber = null;
  for (const [key, value] of Object.entries(monthMap)) {
    if (value.toLowerCase() === monthName.toLowerCase()) {
      monthNumber = parseInt(key);
      break;
    }
  }

  // Vérifier si le numéro de mois a été trouvé
  if (monthNumber === null) {
    console.error("Mois non trouvé dans le mapping");
    return -1; // Retourne -1 si le mois n'a pas été trouvé
  }
    let monthIndex=-1
if (monthData)
  // Chercher l'indice de l'objet avec le numéro de mois correspondant
    monthIndex = monthData.findIndex((item) => item.month === monthNumber);

  return monthIndex
}
  const computeHoverTitle = (actualCellId) => {
    const titleHover = actualCellId.split("_");
    let result = "";
    if (titleHover[1] == 1) result = employerCostOverview;
    else if (titleHover[1] == 3) result = cooptationIncomeDetails;

    return result;
  };

  const computeHoverData = (actualCellId = "", userMonthData, hoverDataEmployerCost) => {
    const currentCol = computeHoverTitle(actualCellId);
    let label = [];
    let data = [];
    if (currentCol.includes("employerCostOverview")) {
      label = hoverLabelEmployeeCost;
      data = hoverDataEmployerCost;
    } 

    console.log("user month dddd", userMonthData);
    return { label, data };
  };


const printHoverDataCooptation = (coopations) => {

    
  }
//shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.30)]
export default function DisplayHoverContent({ Id, userMonthdata }) {
    const [loading, setLoading] = useState(true);
    const [indexHovered, setIndexHovered] = useState(-1);
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        const index = findMonthIndex(Id, userMonthdata);
        setIndexHovered(index);
        setLoading(false);
      };

      fetchData();
    }, [Id, userMonthdata]);

    //const indexHovered=findMonthIndex(Id,userMonthdata)
    const hoverDataEmployeeCost = [
      // {
      //   employeeCharge:
      //     indexHovered != -1 ? userMonthdata[indexHovered].employeeCost : 0,
      // },
      {
        employeeCharge:
          indexHovered != -1
            ? userMonthdata[indexHovered].employeeContributions
            : 0,
      },
      {
        employerContributions:
          indexHovered != -1
            ? userMonthdata[indexHovered].employerContributions
            : 0,
      },
      {
        taxes: indexHovered != -1 ? userMonthdata[indexHovered].taxes : 0,
      },
      {
        expenses: indexHovered != -1 ? userMonthdata[indexHovered].expenses : 0,
      },
      {
        companyBenefit: indexHovered != -1 ? userMonthdata[indexHovered].companyBenefit : 0,
      },
      {
        netSalary:
          indexHovered != -1 ? userMonthdata[indexHovered].netSalary : 0,
      },
    ];
  
  
  
     //const { currentTableCellHovered } = useConsultantContext();
    const titleHover = computeHoverTitle(Id);
    const { label, data } = computeHoverData(
      Id,
      userMonthdata,
      hoverDataEmployeeCost
    );
    // const actualMonth=actualCell.split('_')[0]
    return (
      <div
        className={`${
          titleHover.includes("cooptationIncomeDetails")
            ? "w-[170px]"
            : "w-[160px]"
        } bg-[#FFF] px-[16px] py-[8px] rounded-sm hoverCardContent`}
      >
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="w-full py-2 border-b-2 border-black font-bold ">
              <Label
                size="h7"
                fontWeight=""
                fontFamily=""
                message={titleHover}
                color="black"
                className="text-black text-h7"
              />
            </div>
            <div className="w-full mt-[10px] flex flex-row justify-center">
              <div>
                {label.map((item, index) => (
                  <div key={index} className="flex justify-end w-full">
                    <Label
                      size="h8"
                      fontWeight=""
                      fontFamily=""
                      message={
                        !titleHover.includes("cooptationIncomeDetails") &&
                        Object.values(item)[0]
                      }
                      color=""
                    >
                      {titleHover.includes("cooptationIncomeDetails") &&
                        Object.values(item)[0]}
                    </Label>
                  </div>
                ))}
              </div>
              <div>
                {data.map((item, index) => (
                  <div key={index} className="flex">
                    <Label className="ml-[10px] text-h8" size="h8">
                      {Object.values(item)[0]}
                    </Label>
                  </div>
                ))}
              </div>
              {!computeHoverTitle(Id).includes("employerCostOverview") && (
                <div className="flex flex-col gap-2 w-full">
                  {userMonthdata.coopations && userMonthdata.coopations.map(
                    (cooptation, index) => (
                      <Label className=" text-h8" size="h8" key={index}>
                        {cooptation.cooptedName +
                          "_" +
                          cooptation.percentage +
                          "%" +
                          ":  " +
                          cooptation.amount +
                          cooptation.currency}
                      </Label>
                    )
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
};
