"use client"

import {
  Toast,
  ToastClose,
  ToastProvider,
  ToastViewport,
} from "@/components/organisms/feedback/toast"
import { useToast } from "@/components/organisms/feedback/use-toast"
import { Label } from "../../atoms/texts/label";

export function Toaster() {
  const { toasts } = useToast()

  return (
    (<ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          (<Toast key={id} {...props}>
            <div className="grid gap-1 z-50">
              {title && <Label message={title} fontWeight="bold">{title}</Label>}
              {description && (<Label message={description}>{description}</Label>)}
            </div>
            {action}
            
            <ToastClose />
          </Toast>)
        );
      })}
      <ToastViewport />
    </ToastProvider>)
  );
}
