import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Mutation pour désactiver une TVA
const INACTIVE_VAT_BY_UUID = gql`
  mutation InactiveVat($uuid: ID!) {
    updateVat(updatedVatDto: {uuid: $uuid, status: INACTIVE}) {
      uuid
    }
  }
`;

// Mutation pour activer une TVA
const ACTIVE_VAT_BY_UUID = gql`
  mutation ActiveVat($uuid: ID!) {
    updateVat(updatedVatDto: {uuid: $uuid, status: ACTIVE}) {
      uuid
    }
  }
`;

// Hook personnalisé pour mettre à jour une TVA
export const useUpdateVat = (refetch) => {
  const { user } = useAuth();

  // Mutation pour désactiver une TVA
  const [inactiveVat, { loading: inactiveLoading, error: inactiveError }] = useMutation(INACTIVE_VAT_BY_UUID, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    onCompleted: refetch // Rafraîchir les données après la mutation
  });

  // Mutation pour activer une TVA
  const [activeVat, { loading: activeLoading, error: activeError }] = useMutation(ACTIVE_VAT_BY_UUID, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    onCompleted: refetch // Rafraîchir les données après la mutation
  });

  return { 
    inactiveVat,
    activeVat,
    inactiveLoading,
    inactiveError,
    activeLoading,
    activeError
  };
};
