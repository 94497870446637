import React, { useState, useEffect } from 'react';
import { Label } from '@/components/atoms/texts/label';


export default function FormIsSend({ message }) {

    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false); // Cache le div après 30 secondes
        }, 5000); // 5000 ms = 5 secondes

        return () => clearTimeout(timer); 
    }, []);

    useEffect(() => {
        setIsVisible(true);
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, [message]); 

    return (
        <>
            {isVisible && (
                <div className={` border-success bg-success20 m-4 mb-0 flex flex-col p-secondDegree border  rounded-sm `} >
                    <Label message={message}>{message}</Label>
                </div>
            )}
        </>
    );
}