/* eslint-disable react/prop-types */

/**
 * @component AddExperience
 *
 * The `AddExperience` component allows users to add or edit professional experiences. It displays a form with various fields for entering experience details and handles the addition, updating, and deletion of experiences.
 *
 * Props:
 * - @param {Object} experience - The experience object to edit (can be null to add a new experience).
 * - @param {boolean} showForm - Boolean indicating whether the form should be displayed.
 * - @param {Function} setShowForm - Function to control the visibility of the form.
 * - @param {boolean} editingExperience - Boolean indicating if an experience is being edited.
 * - @param {Function} setEditingExperience - Function to set the editing state of the experience.
 * - @param {Object} user - User object containing user details.
 * - @param {Function} setIsDoingAction - Function to indicate that an action (such as adding or editing an experience) is in progress.
 * - @param {Function} setFormIsValid - Function to set the validity of the form.
 * - @param {Function} setServerResponseIserror - Function to handle server response errors.
 * - @param {Function} setErrors - Function to set error messages.
 *
 * Usage:
 * - This component is used to manage the addition, modification, and deletion of professional experiences in a user profile or an admin interface.
 *
 * Example:
 * ```jsx
 * <AddExperience
 *   experience={currentExperience}
 *   showForm={showExperienceForm}
 *   setShowForm={setShowExperienceForm}
 *   editingExperience={isEditingExperience}
 *   setEditingExperience={setEditingExperience}
 *   user={currentUser}
 *   setIsDoingAction={setLoadingState}
 *   setFormIsValid={setFormValidity}
 *   setServerResponseIserror={setServerErrorState}
 *   setErrors={setErrorMessages}
 * />
 * ```
 *
 * Dependencies:
 * - `Button` for action buttons (e.g., Save, Delete, Cancel).
 * - `Label` for displaying titles and field labels.
 * - `TextArea` for multi-line input fields.
 * - `TextFieldInput` for single-line input fields.
 * - `Select` for country selection.
 * - `Form` for form encapsulation.
 * - `motion` from `framer-motion` for animations.
 * - `useUpdateProspect` for adding experience mutations.
 * - `useUpdateProspectUpdateItems` for updating experience mutations.
 * - `useUpdateProspectDeleteItems` for deleting experience mutations.
 * - `getAppLanguage` for getting the application language.
 * - `transformedCountries` for the list of countries.
 *
 * Local State:
 * - `formData` : Local state to store form data.
 * - `erros` : Local state to store form validation errors.
 * - `listOfInvalidInput` : List of invalid fields.
 * - `confirmDelete` : Boolean to show the delete confirmation.
 *
 * Functions:
 * - `handleChange` : Handles changes to form fields.
 * - `handleSubmit` : Handles form submission for adding or updating an experience.
 * - `handleCancel` : Handles cancellation of adding or editing.
 * - `handleDelete` : Handles the deletion of an experience.
 * - `handleChangeCountry` : Handles the country selection in the form.
 *
 * Constants:
 * - `transformToBackendData` : Function to transform form data into the format required by the backend.
 */

import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import { validateFormData } from "./validateForm";
import TextArea from "@/components/molecules/inputs/TextArea";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import useUpdateProspect from "@/services/admin-services/mutations/updateProspect/useUpdateProspectAddItems";
import getAppLanguage from "@/utils/getAppLanguage";
import { transformedCountries } from "@/utils/getCountriesLanguagesAndCode";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import { motion } from "framer-motion";
import useUpdateProspectUpdateItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectUpdateItems";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import useUpdateProspectDeleteItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectDeleteItems";
export default function AddExperience({
    experience,
    showForm,
    setShowForm,
    editingExperience = false,

    setEditingExperience,
    user,
    setIsDoingAction,
    setFormIsValid,
    setServerResponseIserror,
    setErrors,
    setOpenShowMoreButton,
}) {
    const form = useForm();

    const { addNewExperience, errorAddExperience, loadingAddCertification } =
        useUpdateProspect();
    const appLanguage = getAppLanguage();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const { updateExperience, errorUpdateExperience } =
        useUpdateProspectUpdateItems();
    const { deleteExperience, errorDeleteExperience } =
        useUpdateProspectDeleteItems();

    const [formData, setFormData] = useState({
        title: experience ? experience.title : "",
        company: experience ? experience.company : "",
        country: experience ? experience.countryCode : "",
        // city: experience ? experience.city : "",
        startDate: experience ? experience.startDate : "",
        endDate: experience ? experience.endDate : "",
        description: experience ? experience.description : "",
        technologies: experience ? experience.technologies : "",
        sector: experience ? experience.industry : "",
        experienceUuid: experience ? experience.uuid : "",
    });

    const [erros, setErros] = useState({
        title: "",
        company: "",
        // city: "",
        country: "",
        startDate: "",
        endDate: "",
        sector: "",
        description: "",

        technologies: "",
    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);

    const handleChange = (e, name) => {
        const { value } = e.target;
        deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);

        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // console.log(formData);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErros);

        setlistOfInvalidInput(isValid);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            if (editingExperience) {
                await updateExperience(
                    transformToBackendData(formData, appLanguage),
                    user.uuid
                );

                if (errorUpdateExperience) {
                    setErrors && setErrors(errorUpdateExperience);
                    setFormIsValid && setFormIsValid(false);
                    setServerResponseIserror && setServerResponseIserror(true);
                } else {
                    setFormIsValid && setFormIsValid(true);
                    setIsDoingAction && setIsDoingAction(true);
                }
            } else {
                // Sinon, ajoutez simplement la nouvelle description à la liste
                try {
                    await addNewExperience(
                        transformToBackendData(formData, appLanguage),
                        user.uuid
                    );
                    // Optionnel: gérer les actions supplémentaires après le succès
                    // alert("Add successfully ");
                    setIsDoingAction && setIsDoingAction(true);
                    setFormIsValid && setFormIsValid(true);
                    setServerResponseIserror && setServerResponseIserror(false);
                    setEditingExperience && setEditingExperience(false);
                    if (errorAddExperience) setErrors && setErrors(errorAddExperience);
                } catch (err) {
                    setFormIsValid && setFormIsValid(false);
                    if (errorAddExperience) setErrors && setErrors(errorAddExperience);
                    //alert("error adding certfication", err);
                    console.error("Error adding certification:", err);
                    console.log(errorAddExperience);
                    setServerResponseIserror && setServerResponseIserror(true);
                }
            }

            setShowForm && setShowForm(false);
            setOpenShowMoreButton && setOpenShowMoreButton(false);

        } else {
            console.log("invalid form");
            console.log("value invalid", erros);
            console.log("value invalid", isValid);
            console.log("form data", formData);
        }
        setConfirmDelete(false);
    };
    const handleCancel = () => {
        setEditingExperience && setEditingExperience(false);
        setShowForm && setShowForm(false);
        setConfirmDelete(false);
        setOpenShowMoreButton && setOpenShowMoreButton(false);

    };
    const handleDelete = async () => {
        setShowForm && setShowForm(false);
        setOpenShowMoreButton && setOpenShowMoreButton(false);
        const result = await deleteExperience(user.uuid, experience.uuid);

        if (errorDeleteExperience) {
            console.error("Failed to delete experience:", result?.error);
            setErrors && setErrors(result?.error);
            setFormIsValid && setFormIsValid(false);
            setServerResponseIserror && setServerResponseIserror(true);
        } else {
            setIsDoingAction && setIsDoingAction(true);

            setFormIsValid && setFormIsValid(true);

            console.log("Experience deleted successfully:", result);
            // Mettre à jour l'interface utilisateur en fonction du résultat
        }
        setConfirmDelete(false);
    };

    const handleChangeCountry = (countryCode) => {
        const name = "country";
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: countryCode,
        }));

        console.log("selected counrty, ", countryCode);
    };
    return (
        <div>
            {showForm && (
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit}
                        className={` flex flex-col gap-secondDegree shadow-md bg-lighter  border-sm border-medium   p-secondDegree`}
                    >
                        <TextFieldInput
                            required
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.jobTitle"
                            }
                            type="text"
                            value={formData.title}
                            onChange={(e) => handleChange(e, "title")}
                            error={listOfInvalidInput.includes("title")}
                        />
                        <TextFieldInput
                            required
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.enterprise"
                            }
                            type="text"
                            value={formData.company}
                            onChange={(e) => handleChange(e, "company")}
                            error={listOfInvalidInput.includes("company")}
                        />
                        {/* <CountrySelect/>       */}
                        <TextFieldInput
                            required
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.sector"
                            }
                            type="text"
                            value={formData.sector}
                            onChange={(e) => handleChange(e, "sector")}
                            error={listOfInvalidInput.includes("sector")}
                        />

                        <Select
                            onValueChange={handleChangeCountry}
                            value={formData.country}
                        >
                            <SelectTrigger className="rounded-[5px] bg-transparent border-greyStrong focus:ring-0 focus:ring-offset-0">
                                <SelectValue
                                    placeholder={
                                        <Label message="consultants.statement.prospectConsultantPanel.formPlaceHolder.country" />
                                    }
                                />
                            </SelectTrigger>

                            <SelectContent>
                                <>
                                    {transformedCountries &&
                                        Object.values(transformedCountries).map(
                                            (country, index) => (
                                                <SelectItem value={country.code} key={index}>
                                                    <Label>{country.name}</Label>
                                                </SelectItem>
                                            )
                                        )}
                                </>
                            </SelectContent>
                        </Select>

                        <TextFieldInput
                            required
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.startDate"
                            }
                            type={"date"}
                            value={formData.startDate}
                            onChange={(e) => handleChange(e, "startDate")}
                            error={listOfInvalidInput.includes("startDate")}
                        />
                        <TextFieldInput
                            required
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.endDate"
                            }
                            type={"date"}
                            value={formData.endDate}
                            onChange={(e) => handleChange(e, "endDate")}
                            error={listOfInvalidInput.includes("endDate")}
                        />
                        {/* <DatePickerWithRange/> */}
                        <TextArea
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.description"
                            value={formData.description}
                            rows={4} // Nombre de lignes par défaut
                            cols={40} // Nombre de colonnes par défaut
                            onChange={(e) => handleChange(e, "description")}
                            border={
                                listOfInvalidInput.includes("description") ? "border-error" : ""
                            } // Ajoutez d'autres attributs ou styles au besoin
                        />

                        <div className="flex flex-row gap-[12px] items-center w-full">
                            {editingExperience && (
                                <>
                                    {!confirmDelete ? (
                                        <Button
                                            onClick={() => setConfirmDelete(true)}
                                            showLeftIcon={true}
                                            showText={false}
                                            //sizePadding="firstDegree"
                                            leftIcon={
                                                <DeleteIcon className="text-primary cursor-pointer" />
                                            }
                                            style="ghost"
                                        />
                                    ) : (
                                        <motion.div
                                            className="flex flex-row gap-2 w-[70%] items-center"
                                            initial={{ x: -100, opacity: 0 }}
                                            animate={{ x: 0, opacity: 1 }}
                                            transition={{ duration: 0.5 }}
                                        >
                                            <Label message="consultants.statement.vatSettings.confirmDelete" />
                                            <Button
                                                showLeftIcon
                                                className="text-error border-error rounded-sm bg-white"
                                                leftIcon={
                                                    <DeleteOutlineIcon
                                                        className="text-error"
                                                        fontSize="small"
                                                    />
                                                }
                                                // showText={false}
                                                text={"global.actions.confirmDelete"}
                                                sizePadding="small"
                                                style="outline"
                                                onClick={() => handleDelete(experience.uuid)}
                                            />
                                        </motion.div>
                                    )}
                                </>
                            )}
                            <Button
                                showLeftIcon={true}
                                leftIcon={<ClearIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.cancel"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="outline"
                                className={`${confirmDelete ? "w-[17%]" : "font-bold border-primary w-full"
                                    }`}
                                showToolTip={confirmDelete ? true : false}
                                onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                            <Button
                                showLeftIcon={true}
                                leftIcon={<SaveIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.save"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="fill"
                                showToolTip={confirmDelete ? true : false}
                                className={`${confirmDelete ? "w-[16%]" : "font-bold w-full"}`}
                                type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                    </form>
                </Form>
            )}
        </div>
    );
}

const transformToBackendData = (formdata, lang) => {
    return {
        language: lang,
        company: formdata.company,
        countryCode: formdata.country,
        description: formdata.description,
        endDate: formdata.endDate,
        industry: formdata.sector,
        startDate: formdata.startDate,
        title: formdata.title,
        uuid: formdata.experienceUuid,
    };
};
