import { useAuth } from "@/context/AuthContext";

// variantLogic.js
export const getVariantItems = (user) => {
    const { isAuth, hasPrivileges, viewAs } = useAuth();

    const itemsByRole = {
        admin: [
            { name: "navbarAdmin.consultant", path: "/consultant"},
            { name: "prospects.prospects", path: "/prospects", },
            { name: "navbarAdmin.finance", path: "/finance"  , disabled:true},
            { name: "navbarAdmin.statistics", path: "/statistics"  , disabled:true},
            { name: "navbarAdmin.overflowOptimization", path: "/overflowOptimization",   disabled:false},
        ],
        consultant: [
            { name: "navbarConsultant.administration", path: "/administration" },
            { name: "navbarConsultant.profitSharing", path: "/profitSharing"  , disabled:true},
            { name: "navbarConsultant.compagnyValuation", path: "/companyValuation"  , disabled:true},
            { name: "navbarConsultant.overflowOptimization", path: "/overflow" , disabled:false },
        ],
    };
    // console.log("getVariantItems : user", user, "hasPrivileges('ADMIN_')",hasPrivileges('ADMIN_'));
    // if (isAuth()) {
    //     if (hasPrivileges('ADMIN_')) {
    //         console.log("returning admin variants");
    //         return itemsByRole.admin;
    //     } else if (hasPrivileges('USER_')) {
    //         console.log("returning consultant variants");

    //         return itemsByRole.consultant;
    //     } else {
    //         return [];
    //     }
    // }
    if (isAuth()) {
        if (viewAs == 'ADMIN_') {
            return itemsByRole.admin;
        } else if (viewAs == 'USER_') {
            return itemsByRole.consultant;
        } else {
            return [];
        }
    }
    return;

};
