import { useState } from 'react';
import errorToText from '@/utils/errorToText';

const useLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const login = async (email, password, rememberMe) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(window.ENV.BACKEND_URL+'/api/public/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: email,
                    password: password,
                    rememberMe: rememberMe
                })
            });
            //loginFailed
            if (response.status == 500) {//mot de passe ou mail erroné

                setLoading(false);
                console.error(response, "loginFailed")
                throw new Error("error.loginFailed");
            } else if (!response.ok) {
                const errMessage = errorToText(response);
                setLoading(false);
                console.error(response, response.status)
                throw new Error(errMessage);
            }

            const data = await response.json();
            setLoading(false);
            return { data, error: null };
        } catch (err) {
            setError(err.message);
            setLoading(false);
            return { data: null, error: err.message };
        }
    };

    return { login };
};


const useLoginOTP = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const loginOTP = async (otp, jwtToken) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(window.ENV.BACKEND_URL+'/api/secured/users/loginOTP', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({
                    otp: otp,
                })
            });

            if (response.status == 401) {
                setLoading(false);
                throw new Error("error.invalidOTP");
            }
            if (!response.ok) {
                const errMessage = errorToText(response);
                setLoading(false);
                throw new Error(errMessage);
            }

            const data = await response.json();
            setLoading(false);
            return { data, error: null };
        } catch (err) {
            setError(err.message);
            setLoading(false);
            return { data: null, error: err.message };
        }
    };

    return { loginOTP };
};

export { useLogin, useLoginOTP };

