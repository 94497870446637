import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";



// Définir la mutation GraphQL pour supprimer une certification
const DELETE_CERTIFICATION = gql`
  mutation DeleteCertification($prospectUuid: ID!, $certificationUuid: [ID!]) {
    updateProspect(
      updatedProspect: {
        uuid: $prospectUuid,
        deletedCertifications: $certificationUuid
      }
    ) {
      certifications {
        uuid
      }
    }
  }
`;
// Définir la mutation GraphQL
const ADD_CERTIFICATION = gql`
  mutation AddCertification(
    $uuid: ID!
    $language: CvLanguage!
    $dateObtained: String!
    $description: String!
    $organization: String!
    $title: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid
        newCertifications: {
          language: $language
          dateObtained: $dateObtained
          description: $description
          organization: $organization
          title: $title
        }
      }
    ) {
      certifications {
        dateObtained
        description
        language
        organization
        title
      }
    }
  }
`;

// Définir la mutation GraphQL pour ajouter une compétence mappée
const ADD_MAPPED_SKILL = gql`
  mutation AddMappedSkill($prospectUuid: ID!, $skillUuid: ID!, $rating: Int!) {
    updateProspect(
      updatedProspect: {
        uuid: $prospectUuid
        newMappedSkills: { skillUuid: $skillUuid, rating: $rating }
      }
    ) {
      mappedSkills {
        rating
        skill {
          uuid
          labelDe
          labelEn
          labelEs
          labelFr
          labelIt
          labelNl
          skillType
        }
      }
    }
  }
`;
// Définir la mutation GraphQL pour ajouter une expérience
const ADD_EXPERIENCE = gql`
  mutation AddExperience(
    $prospectUuid: ID!, 
    $language: CvLanguage!, 
    $company: String!, 
    $countryCode: String!, 
    $description: String!, 
    $endDate: String!, 
    $industry: String!, 
    $startDate: String!, 
    $title: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $prospectUuid,
        newExperiences: {
          language: $language,
          company: $company,
          countryCode: $countryCode,
          description: $description,
          endDate: $endDate,
          industry: $industry,
          startDate: $startDate,
          title: $title
        }
      }
    ) {
      experiences {
        company
        countryCode
        description
        endDate
        industry
        language
        startDate
        title
      }
    }
  }
`;
const ADD_TITLE = gql`
  mutation AddTitle(
    $prospectUuid: ID!
    $language: CvLanguage!
    $title: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $prospectUuid
        newTitles: { language: $language, title: $title }
      }
    ) {
      titles {
        language
        title
      }
    }
  }
`;

// Définir la mutation GraphQL pour supprimer une expérience
const DELETE_EXPERIENCE = gql`
  mutation DeleteExperience($prospectUuid: ID!, $experienceUuid: [ID!]) {
    updateProspect(
      updatedProspect: {
        uuid: $prospectUuid,
        deletedExperiences: $experienceUuid
      }
    ) {
      experiences {
        uuid
      }
    }
  }
`;

// Définir la mutation GraphQL pour ajouter une description
const ADD_DESCRIPTION = gql`
  mutation AddDescription($prospectUuid: ID!, $language: CvLanguage!, $description: String!) {
    updateProspect(
      updatedProspect: {
        uuid: $prospectUuid,
        newDescriptions: {
          language: $language,
          description: $description
        }
      }
    ) {
      descriptions {
        description
        language
      }
    }
  }
`;

// Définir la mutation GraphQL ajouter une education
const ADD_EDUCATION = gql`
  mutation AddEducation(
    $uuid: ID!,
    $language: CvLanguage!,
    $city: String!,
    $countryCode: String!,
    $description: String,
    $endDate: String!,
    $school: String!,
    $startDate: String!,
    $title: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid,
        newEducations: {
          language: $language,
          city: $city,
          countryCode: $countryCode,
          description: $description,
          endDate: $endDate,
          school: $school,
          startDate: $startDate,
          title: $title
        }
      }
    ) {
      educations {
        city
        countryCode
        description
        endDate
        language
        school
        startDate
        title
      }
    }
  }
`;
// Définir la mutation GraphQL ajouter 
const ADD_PROJECT = gql`
  mutation AddProject(
    $uuid: ID!,
    $language: CvLanguage!,
    $city: String!,
    $company: String!,
    $countryCode: String!,
    $description: String,
    $endDate: String!,
    $industry: String,
    $startDate: String!,
    $technologies: String,
    $title: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid,
        newProjects: {
          language: $language,
          city: $city,
          company: $company,
          countryCode: $countryCode,
          description: $description,
          endDate: $endDate,
          industry: $industry,
          startDate: $startDate,
          technologies: $technologies,
          title: $title
        }
      }
    ) {
      projects {
        city
        company
        countryCode
        description
        endDate
        industry
        language
        startDate
        technologies
        title
      }
    }
  }
`;


// Définir la mutation GraphQL
const ADD_SPOKEN_LANGUAGE_MUTATION = gql`
  mutation UpdateSpokenLanguage(
    $uuid: ID!,
    $spokenLanguage: String!,
    $rating: Int!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid,
        newSpokenLanguages: {
          spokenLanguage: $spokenLanguage,
          rating: $rating
        }
      }
    ) {
      spokenLanguages {
        rating
        spokenLanguage
      }
    }
  }
`;

// Créer le hook personnalisé
export const useUpdateProspect = () => {
  const { user } = useAuth();
  const [addCertification, { data: dataAddCertification, loading:loadingAddCertification, error:errorAddingCertification }] = useMutation(
    ADD_CERTIFICATION,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      },
    }
  );
  const addNewCertification = async ( certificationData, uuid) => {
    const {  language, dateObtained, description, organization, title } =
      certificationData;
   console.log("les valeurs reçu certifications", certificationData, uuid)
    // Validation des champs obligatoires
  

    try {
      const result = await addCertification({
        variables: {
          uuid,
          language,
          dateObtained,
          description,
          organization,
          title,
        },
      });

      return result.data.updateProspect.certifications;
    } catch (e) {
      console.error(
        "useAddCertification Error adding certification:",
        e.message
      );
      return { error: e.message };
    }
  };

const [deleteCertification, { data:datadeleteCertification, loading:loadingDeleteCertification, error:errorDeleteCertification }] = useMutation(DELETE_CERTIFICATION, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const removeCertification = async (prospectUuid, certificationUuid) => {
    try {
      const result = await deleteCertification({
        variables: {
          prospectUuid,
          certificationUuid,
        },
      });

      return result.data.updateProspect.certifications;
    } catch (e) {
      console.error("Erreur lors de la suppression de la certification :", e.message);
      return { error: e.message };
    }
  };

  const [addExperience, { data:dataExperience, loading:loadingAddExperience, error:errorAddExperience }] = useMutation(ADD_EXPERIENCE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const addNewExperience = async (experienceData, prospectUuid) => {
    const {
      language,
      company,
      countryCode,
      description,
      endDate,
      industry,
      startDate,
      title,
    } = experienceData;

    // Validation des champs obligatoires
    if (!prospectUuid || !company || !countryCode || !startDate || !title) {
      throw new Error(
        "Missing required fields: prospectUuid, company, countryCode, startDate, or title"
      );
    }

    try {
      const result = await addExperience({
        variables: {
          prospectUuid,
          language,
          company,
          countryCode,
          description,
          endDate,
          industry,
          startDate,
          title,
        },
      });

      return result.data.updateProspect.experiences;
    } catch (e) {
      console.error("Erreur lors de l'ajout de l'expérience :", e.message);
      return { error: e.message };
    }
  };


  const [deleteExperience, { data:dataDeleteExperience, loading:loadingDeleteExperience, error:errorDeleteExperience }] = useMutation(DELETE_EXPERIENCE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });


  const [addDescription, { data:dataAddDescription, loading:loadingAddDescription, error:errorAddDescription }] = useMutation(
    ADD_DESCRIPTION,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      },
    }
  );

  const addNewDescription = async (prospectUuid, language, description) => {
    if (!prospectUuid || !language || !description) {
      throw new Error("Prospect UUID, language, and description are required");
    }

    try {
      const result = await addDescription({
        variables: {
          prospectUuid,
          language,
          description,
        },
      });

      return result.data.updateProspect.descriptions;
    } catch (e) {
      console.error("Erreur lors de l'ajout de la description :", e.message);
      return { error: e.message };
    }
  };

  const removeExperience = async (prospectUuid, experienceUuid) => {

    if (!prospectUuid || !experienceUuid) {
      throw new Error("Prospect UUID and Experience UUID are required");
    }

    try {
      const result = await deleteExperience({
        variables: {
          prospectUuid,
          experienceUuid,
        },
      });

      return result.data.updateProspect.experiences;
    } catch (e) {
      console.error("Erreur lors de la suppression de l'expérience :", e.message);
      return { error: e.message };
    }
  };
const [addTitle, { data:dataAddTitle, loading:loadingAddTitle, error:errorAddTitle }] = useMutation(ADD_TITLE, {
  context: {
    headers: {
      Authorization: `Bearer ${user.strongToken}`,
    },
  },
});

const addNewTitle = async (prospectUuid, language, title) => {
  if (!prospectUuid || !language || !title) {
    throw new Error("Prospect UUID, language, and title are required");
  }

  try {
    const result = await addTitle({
      variables: {
        prospectUuid,
        language,
        title,
      },
    });

    return result.data.updateProspect.titles;
  } catch (e) {
    console.error("Erreur lors de l'ajout du titre :", e.message);
    return { error: e.message };
  }
};
  
  
  const [addMappedSkill, { data:dataAddSkill, loading:loadingAddSkill, error:errorAddSkill }] = useMutation(
    ADD_MAPPED_SKILL,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      },
    }
  );
  const addNewMappedSkill = async (prospectUuid, skillUuid, rating) => {
    if (!prospectUuid || !skillUuid || rating == null) {
      throw new Error("Prospect UUID, Skill UUID, and rating are required");
    }

    try {
      const result = await addMappedSkill({
        variables: {
          prospectUuid,
          skillUuid,
          rating,
        },
      });

      return result.data.updateProspect.mappedSkills;
    } catch (e) {
      console.error(
        "Erreur lors de l'ajout de la compétence mappée :",
        e.message
      );
      return { error: e.message };
    }
  };
const [addEducationMutation, { data: dataAddEducation, loading: loadingAddEducation, error:errorAddEducation }] = useMutation(
  ADD_EDUCATION,
  {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`, // Utiliser le token de l'utilisateur pour l'autorisation
      },
    },
  }
);

const addEducation = async (educationData, uuid) => {
  const {
    
    language,
    city,
    countryCode,
    description,
    endDate,
    school,
    startDate,
    title,
  } = educationData;

 

  try {
    const result = await addEducationMutation({
      variables: {
        uuid,
        language,
        city,
        countryCode,
        description,
        endDate,
        school,
        startDate,
        title,
      },
    });

    return result.data.updateProspect.educations;
  } catch (e) {
    console.error("Error adding education:", e.message);
    return { error: e.message };
  }
};
  
  const [addProjectMutation, { data:dataAddProject, loading:loadingAddProject, error:errorAddProject }] = useMutation(
    ADD_PROJECT,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`, // Utiliser le token de l'utilisateur pour l'autorisation
        },
      },
    }
  );

  const addProject = async (projectData, uuid) => {
    const {
     
      language,
      city,
      company,
      countryCode,
      description,
      endDate,
      industry,
      startDate,
      technologies,
      title,
    } = projectData;

  

    try {
      const result = await addProjectMutation({
        variables: {
          uuid,
          language,
          city,
          company,
          countryCode,
          description,
          endDate,
          industry,
          startDate,
          technologies,
          title,
        },
      });

      return result.data.updateProspect.projects;
    } catch (e) {
      console.error("Error adding project:", e.message);
      return { error: e.message };
    }
  };
const [addSpokenLanguageMutation, { data:dataAddSpokenLanguage, loading:loadingAddSpokenLanguage, error: errorAddSpokenLanguage }] = useMutation(
  ADD_SPOKEN_LANGUAGE_MUTATION,
  {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`, // Ajouter le `strongToken` dans les headers pour l'authentification
      },
    },
  }
);

// Fonction pour exécuter la mutation
const addSpokenLanguage = async (uuid, spokenLanguage, rating) => {
  try {
    const response = await addSpokenLanguageMutation({
      variables: {
        uuid,
        spokenLanguage,
        rating,
      },
    });
    return response.data.updateProspect.spokenLanguages;
  } catch (err) {
    console.error("Failed to update spoken language:", err.message);
    throw new Error(err.message);
  }
};


  return {
    addNewCertification,
    removeCertification,
    addNewExperience,
    removeExperience,
    addNewDescription,
    addNewTitle,
    addNewMappedSkill,
    addEducation,
    addProject,
    addSpokenLanguage,

    errorAddSpokenLanguage,
    dataAddSpokenLanguage,
    loadingAddSpokenLanguage,
  

    dataAddProject,
    errorAddProject,
    loadingAddProject,

    dataAddEducation,
    errorAddEducation,
    loadingAddEducation,
    
    dataAddSkill,
    errorAddSkill,
    loadingAddSkill,


    dataAddTitle,
    loadingAddTitle,
    errorAddTitle,

    dataAddCertification,
    loadingAddCertification,
    errorAddingCertification,

    datadeleteCertification,
    errorDeleteCertification,
    loadingDeleteCertification,

    dataExperience,
    loadingAddExperience,
    errorAddExperience,

    dataDeleteExperience,
    loadingDeleteExperience,
    errorDeleteExperience,

    dataAddDescription,
    errorAddDescription,
    loadingAddDescription,
  };
};

export default useUpdateProspect;

