import { gql } from "@apollo/client";

import { useAuth } from "@/context/AuthContext";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

export const GET_PROSPECT_CV_ITEMS_WITH_UUID = gql`
  query getProspectCVItemsWithUuid($prospectUuid: ID!) {
    prospect(prospectUuid: $prospectUuid) {
      firstname
      lastname
      uuid
      projects {
        city
        company
        countryCode
        description
        endDate
        industry
        language
        startDate
        title
        technologies
        uuid
      }
      cooptedBy {
        firstname
        gender
        trigram
        lastname
        email
        language
        uuid
      }
      titles {
        language
        title
        titleCompleted
        uuid
      }
      mappedSkills {
        rating
        skill {
          labelDe
          labelEn
          labelEs
          labelFr
          labelIt
          labelNl
          skillType
          uuid
        }
                uuid

      }
      unmappedSkills {
        labelDe
        labelEn
        labelEs
        labelFr
        labelIt
        labelNl
        rating
        uuid
      }
      descriptions {
        description
        language
        uuid
      }
      educations {
        city
        cityCompleted
        countryCode
        countryCodeCompleted
        description
        descriptionCompleted
        endDate
        endDateCompleted
        language
        school
        schoolCompleted
        startDate
        startDateCompleted
        title
        titleCompleted
        uuid
      }
      experiences {
        company
        companyCompleted
        countryCode
        countryCodeCompleted
        description
        descriptionCompleted
        endDate
        endDateCompleted
        industry
        industryCompleted
        language
        startDate
        startDateCompleted
        title
        titleCompleted
        uuid
      }
      spokenLanguages {
        rating
        spokenLanguage
        uuid
      }
      certifications {
        dateObtained
        dateObtainedCompleted
        description
        descriptionCompleted
        language
        organization
        organizationCompleted
        title
        titleCompleted
        uuid
      }
    }
  }
`;
const PROSPECT_MINIMAL_QUERY = gql`
  query ProspectQuery($prospectUuid: ID!) {
    prospect(prospectUuid: $prospectUuid) {
      firstname
      lastname
      personalEmail
      uuid
    }
  }
`;


export const useProspect = (uuid) => {
    const { user } = useAuth();
    const { prospectID } = useParams();
    
   
    let prospectUuid = uuid || prospectID;
  
    const { loading, error, data, refetch } = useQuery(GET_PROSPECT_CV_ITEMS_WITH_UUID, {
      variables: { prospectUuid },
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      },
      
    });
    // if (!prospectID) {
    //     const error = "prospectID by params is empty"
    //     return {loading: false,error, data:null, refetch:null}
    // } else {
    // }
    // const { loading, error, data, refetch } = useQuery(GET_PROSPECT_CV_ITEMS_WITH_UUID, {
    //     variables: { prospectUuid },
    //     context: {
    //         headers: {
    //             Authorization: `Bearer ${user.strongToken}`,
    //         },
    //     },
    //     fetchPolicy: "network-only", // S'assure que les données sont toujours récupérées du serveur
    // });
    // const { loading:loadingMinimal, error: errorMinimal, data:dataMinimal, refetch:refetchMinimal } = useQuery(
    //   PROSPECT_MINIMAL_QUERY,
    //   {
    //     variables: { prospectUuid: prospectID },
    //     context: {
    //       headers: {
    //         Authorization: `Bearer ${user.strongToken}`,
    //       },
    //     },
    //     fetchPolicy: "network-only", // S'assure que les données sont toujours récupérées du serveur
    //   }
    // );


    return {
        loading,
        error,
        data: data?.prospect,
        refetch,
        // loadingMinimal,
        // refetchMinimal,
        // dataMinimal: data && data.prospect,
    };


};
