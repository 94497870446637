//useFirstConnection.js
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const useFirstConnection = () => {
    const { activationToken } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const activateUser = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await fetch(window.ENV.BACKEND_URL+'/api/public/users/activate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ activationToken })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                // console.log("useFirstConnection data", data);
                setLoading(false);
                return data;
            } catch (err) {
                console.log("useFirstConnection err", err);
                setError(err.message);
                setLoading(false);
                return null;
            }
        };

        if (activationToken) {
            activateUser();
        }
    }, [activationToken]);

    return { loading, error };
};

export default useFirstConnection;
