import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const ADD_COMPANY_BENEFITS_INVOICE = gql`
  mutation AddCompanyBenefitInvoice(
    $companyBenefitInvoiceFileUuid: ID!,
    $consultantUuid: ID!,
    $period: Date!,
    $invoiceDate: Date!,
    $currency: String!,
    $amountExcludingVat: Float!,
    $totalAmount: Float!,
    $comment: String,
    $vatList: [NewCompanyBenefitInvoiceVatRequestDto],
    $benefitType: CompanyBenefitInvoiceType!
  ) {
    addCompanyBenefitInvoice(newCompanyBenefitInvoice: {
      companyBenefitInvoiceFileUuid: $companyBenefitInvoiceFileUuid,
      consultantUuid: $consultantUuid,
      period: $period,
      invoiceDate: $invoiceDate,
      currency: $currency,
      amountExcludingVat: $amountExcludingVat,
      totalAmount: $totalAmount,
      comment: $comment,
      vatList: $vatList,
      benefitType : $benefitType
    }) {
      uuid
      companyBenefitInvoiceFileUuid
      year
      month
      invoiceDate
      currency
      amountExcludingVat
      totalAmount
      vatList {
        vatRate
        vatAmount
      }
        benefitType
    }
  }
`;

export const useAddCompanyBenefitInvoice = () => {
    const { user } = useAuth();

    const [addCompanyBenefitInvoice, { data, loading, error }] = useMutation(ADD_COMPANY_BENEFITS_INVOICE, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },

    });

    return { addCompanyBenefitInvoice, loading, error, data };
};
