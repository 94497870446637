/* eslint-disable react/prop-types */
/**
 * @component SectionsCvOverview
 *
 * This component displays an overview section for a CV, showcasing a list of items such as qualifications or experiences. It includes a button to navigate to additional details or sections.
 *
 * Key Features:
 * - Displays a list of items related to the user's qualifications or experiences.
 * - Provides a title for the section.
 * - Offers a button to navigate to more detailed information or sections.
 * - Utilizes `framer-motion` for smooth animations and transitions.
 *
 * Props:
 * - @param {Array} items - List of items to be displayed in the overview section. Defaults to a predefined set of qualifications.
 * - @param {String} title - The title of the section, such as "Experience". Defaults to a predefined string.
 * - @param {Function} hideAllOverview - Function to hide the overview section.
 * - @param {Function} showComponent - Function to show additional details or components.
 * - @param {Boolean} showingInProspect - Flag indicating if the section is being shown in a prospect view.
 *
 * Usage:
 * - This component is used to present an overview of the user's qualifications or experiences.
 * - It includes a button for navigation, allowing users to proceed to more detailed sections.
 * - The component uses a list format to display items and a title to describe the section.
 *
 * Example:
 * <SectionsCvOverview
 *   items={userQualifications}
 *   title="Experience"
 *   hideAllOverview={hideOverview}
 *   showComponent={showDetails}
 *   showingInProspect={true}
 * />
 *
 * Dependencies:
 * - `Button` component for navigation actions.
 * - `Separator` component for visual separation in the UI.
 * - `Label` component for displaying section titles.
 * - `framer-motion` for UI animations.
 */

import { Button } from "@/components/molecules/buttons/button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Label } from "@/components/atoms/texts/label";
import { useState } from "react";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
const defaultItems = [
    "Master informatique",
    "Licence informatique",
    "Bac STMG ",
    "Licence informatique",
];
const experience = "consultants.statement.prospectConsultantPanel.experience";

export default function SectionsCvOverview({
    items = defaultItems,
    title = experience,
    hideAllOverview,
    showComponent,
    showingInProspect = true
}) {
    const hider = () => {
        hideAllOverview(true);
        showComponent(true);
    };
    //   console.log(items);
    return (
        <motion.div variants={item}>
            <div
                className={`${showingInProspect ? '' : ''} py-paddingTopAndBottom px-paddingSmallLeftAndRight 
      flex flex-row gap-firstDegree bg-medium 
      shadow-md rounded-sm border-l-[1px] border-l-solid  w-full `}
            >
                {/* flex flex-row place-content-end */}
                <div className="flex flex-row place-content-end items-center  py-firstDegree truncate basis-1/3 ">
                    <Label message={title} size="body" fontWeight="bold" />
                </div>
                <div className="flex ">
                    <Separator orientation="vertical" className="bg-black" />
                </div>
                <div className="flex flex-col gap-firstDegree place-content-center text-bodySmall truncate basis-1/2">
                    {items &&
                        items.map((item, index) => {
                            return (
                                <Label className="truncate" key={index} size="small">
                                    {item}
                                </Label>
                            );
                        })}
                </div>
                <div className="grid items-center">
                    <Button
                        showLeftIcon={true}
                        leftIcon={<ArrowForwardIcon fontSize="small" />}
                        showText={false}
                        text="global.actions.continue"
                        sizePadding="" // Utilisez "small" pour un padding plus petit
                        style="ghost"
                        className="font-bold"
                        onClick={() => hider()} // Options: "fill", "outline", "ghost", "dressedGhost"
                    />
                </div>
            </div>
        </motion.div>
    );
}
