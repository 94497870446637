/* eslint-disable react/prop-types */
/**
 * @component AddProject
 *
 * This component provides a form for adding or editing a project associated with a prospect. 
 * It handles form state, validation, and interactions with backend services for creating, updating, or deleting projects.
 *
 * Key Features:
 * - Displays a form to input project details, including title, company, country, city, start and end dates, description, and technologies.
 * - Manages form state using React hooks and `react-hook-form`.
 * - Validates form data before submission and handles errors.
 * - Supports adding new projects or editing existing ones, with different handling based on the `editingProject` prop.
 * - Provides functionality to delete a project with a confirmation step.
 * - Displays toast notifications for form submission results and other important updates.
 *
 * Props:
 * - @param {Object} project - The project data to be edited (optional). If not provided, a new project is created.
 * - @param {Boolean} showForm - Boolean to control the visibility of the form.
 * - @param {Function} setShowForm - Function to set the visibility of the form.
 * - @param {Boolean} [editingProject=false] - Indicates if the form is in edit mode.
 * - @param {Function} setEditingProject - Function to set the edit mode state.
 * - @param {Object} user - The current user data.
 * - @param {Function} setIsDoingAction - Function to set the state of ongoing actions.
 * - @param {Function} setFormIsValid - Function to set the form validity state.
 * - @param {Function} setServerResponseIserror - Function to set the server response error state.
 * - @param {Function} setUserTapping - Function to indicate if user is interacting with the form.
 * - @param {Function} setErrors - Function to set form error details.
 *
 * Internal State:
 * - @state {Object} formData - Stores the current values of the form fields.
 * - @state {Object} erros - Stores error messages for each field.
 * - @state {Array} listOfInvalidInput - List of fields with validation errors.
 * - @state {Boolean} confirmDelete - Indicates if the user has confirmed deletion of the project.
 *
 * Usage:
 * - This component is used in contexts where users need to add or edit project details related to a prospect.
 * - It integrates with backend services to manage project data and provides feedback to users about the success or failure of their actions.
 *
 * Example:
 * <AddProject 
 *   project={projectData}
 *   showForm={showProjectForm}
 *   setShowForm={setProjectFormVisibility}
 *   editingProject={isEditingProject}
 *   setEditingProject={setEditingProjectState}
 *   user={currentUser}
 *   setIsDoingAction={setActionState}
 *   setFormIsValid={setFormValidity}
 *   setServerResponseIserror={setServerErrorState}
 *   setUserTapping={setUserInteraction}
 *   setErrors={setFormErrors}
 * />
 *
 * Dependencies:
 * - `react-hook-form` for form handling.
 * - Backend service hooks for project operations (`useUpdateProspect`, `useUpdateProspectUpdateItems`, `useUpdateProspectDeleteItems`).
 * - `framer-motion` for animations.
 * - Custom utility functions for form validation and transformation.
 */

import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import SaveIcon from "@mui/icons-material/Save";
import { validateFormData } from "./validateForm";
import TextArea from "@/components/molecules/inputs/TextArea";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import { Label } from "@/components/atoms/texts/label";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import { motion } from "framer-motion";
import { transformedCountries } from "@/utils/getCountriesLanguagesAndCode";
import useUpdateProspect from "@/services/admin-services/mutations/updateProspect/useUpdateProspectAddItems";
import useUpdateProspectUpdateItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectUpdateItems";

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import getAppLanguage from "@/utils/getAppLanguage";
import useUpdateProspectDeleteItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectDeleteItems";

const skillsMapLang = {
    en: "labelEn",
    es: "labelEs",
    fr: "labelFr",
    it: "labelIt",
    nl: "labelNl",
};

export default function AddProject({
    project,
    showForm,
    setShowForm,
    editingProject = false,
    setEditingProject,
    user,
    setIsDoingAction,
    setFormIsValid,
    setServerResponseIserror,
    setUserTapping,
    setErrors,
    setOpenShowMoreButton,
}) {
    const form = useForm();
    const [confirmDelete, setConfirmDelete] = useState(false);

    const appLanguage = getAppLanguage();
    const { deleteProject, errorDeleteProject } = useUpdateProspectDeleteItems()
    const { updateProject, errorUpdateProject } = useUpdateProspectUpdateItems()
    const { addProject, errorAddProject } = useUpdateProspect();

    console.log("le projet reçu", project);
    const [formData, setFormData] = useState({
        title: project ? project.title : "",
        company: project ? project.company : "",
        country: project ? project.countryCode : "",
        city: project ? project.city : "",
        startDate: project ? project.startDate : "",
        endDate: project ? project.endDate : "",
        description: project ? project.description : "",
        responsabilities: project ? project.responsabilities : "",
        technologies: project ? project.technologies : "",
        uuid: project ? project.uuid : "",
    });
    const [erros, setErros] = useState({
        title: "",
        company: "",
        city: "",
        country: "",
        startDate: "",
        endDate: "",
        description: "",

        // technologies: "",
    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const handleChange = (e, name) => {
        setUserTapping && setUserTapping(true);
        const { value } = e.target;
        deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);

        console.log("La valeur reçu", value);
        setFormIsValid(false);

        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // console.log(formData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //setlistOfInvalidInput(Object.keys(formData));
        const isValid = validateFormData(formData, setErros);

        setlistOfInvalidInput(isValid);
        setUserTapping && setUserTapping(false);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            if (editingProject) {
                // setProjects((prevData) =>
                //     prevData.map((item) => (item.uuid === project.uui ? formData : item))
                // );
                try {
                    const updatedProspectData = await updateProject(
                        transformToProjectDataUpdate(
                            formData,
                            appLanguage
                        ),
                        user.uuid
                    );
                    setIsDoingAction && setIsDoingAction(true)
                    if (errorUpdateProject) {
                        //alert("Invoice updated successfully!");
                        setServerResponseIserror && setServerResponseIserror(true);

                    } else {
                        setFormIsValid && setFormIsValid(true);

                        //throw new Error("Error updating invoice.");
                    }
                    //alert("Prospect updated successfully");
                } catch (err) {
                    alert(`Failed to update prospect: ${err.message}`);
                }
            } else {
                try {
                    const updatedProspect = await addProject(
                        transformToProjectData(formData, appLanguage),
                        user.uuid
                    );
                    setIsDoingAction && setIsDoingAction(true);

                    if (errorAddProject) {
                        console.error("Failed to add description:", errorAddProject);
                        // alert("Add successfully ");
                        setFormIsValid && setFormIsValid(false);

                        setServerResponseIserror && setServerResponseIserror(false);
                        setEditingProject && setEditingProject(false);
                        if (errorAddProject) setErrors && setErrors(errorAddProject);
                    } else {
                        setFormIsValid && setFormIsValid(true);
                    }
                    console.log("Updated Prospect Projects:", updatedProspect.projects);
                } catch (err) {
                    console.log(`Failed to update prospect: ${err.message}`);
                }
                //  setProjects((prevData) => [...prevData, formData]);
            }

            setShowForm && setShowForm(false);
            setOpenShowMoreButton && setOpenShowMoreButton(false);
        } else {
            console.log("value invalid", isValid);
            console.log("form data", formData);
            console.log("value invalid", erros);
        }
        setConfirmDelete(false);
    };
    const handleCancel = () => {
        setEditingProject && setEditingProject(false);
        setShowForm && setShowForm(false);
        setFormIsValid && setFormIsValid(false);
        setConfirmDelete(false);
        setOpenShowMoreButton && setOpenShowMoreButton(false);
    };

    const handleChangeCountry = (countryCode) => {
        const name = "country";
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: countryCode,
        }));

        console.log("selected counrty, ", countryCode);
    };
    const onDelete = async (uuid) => {
        try {
            // Replace with actual project UUID to delete
            const updatedProjects = await deleteProject(user.uuid, uuid);
            //alert("Updated Projects:", updatedProjects);
            setIsDoingAction && setIsDoingAction(true);

            if (errorDeleteProject) {
                setErrors && setErrors(errorDeleteProject);
                setFormIsValid && setFormIsValid(false);
                setServerResponseIserror && setServerResponseIserror(true);
            }
            else {
                setFormIsValid && setFormIsValid(true);
            }
            setOpenShowMoreButton && setOpenShowMoreButton(false);

        } catch (err) {
            // alert("Failed to delete project:", err);
            console.error("Failed to delete project:", err);
        }
        setConfirmDelete(false)
    };

    return (
        <div>
            {showForm && (
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit}
                        className={` flex flex-col gap-secondDegree shadow-md bg-lighter  border-sm border-medium  p-secondDegree `}
                    >
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.projectTitle"
                            }
                            type="text"
                            value={formData.title}
                            onChange={(e) => handleChange(e, "title")}
                            error={listOfInvalidInput.includes("title")}
                            required

                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.enterprise"
                            }
                            type="text"
                            value={formData.company}
                            onChange={(e) => handleChange(e, "company")}
                            error={listOfInvalidInput.includes("company")}
                            required

                        />


                        <Select
                            onValueChange={handleChangeCountry}
                            value={formData.country}
                        >
                            <SelectTrigger className="rounded-[5px] bg-transparent border-greyStrong focus:ring-0 focus:ring-offset-0">
                                <SelectValue
                                    placeholder={
                                        <Label message="consultants.statement.prospectConsultantPanel.formPlaceHolder.country" />
                                    }
                                />
                            </SelectTrigger>

                            <SelectContent>
                                <>
                                    {transformedCountries &&
                                        Object.values(transformedCountries).map(
                                            (country, index) => (
                                                <SelectItem value={country.code} key={index}>
                                                    <Label>{country.name}</Label>
                                                </SelectItem>
                                            )
                                        )}
                                </>
                            </SelectContent>
                        </Select>

                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.city"
                            }
                            type="text"
                            value={formData.city}
                            onChange={(e) => handleChange(e, "city")}
                            error={listOfInvalidInput.includes("city")}
                            required
                        // border={listOfInvalidInput.includes("city") ? "border-error" : ""}
                        />

                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.startDate"
                            }
                            type={"date"}
                            value={formData.startDate}
                            onChange={(e) => handleChange(e, "startDate")}
                            error={listOfInvalidInput.includes("startDate")}
                            required
                        // border={
                        //   listOfInvalidInput.includes("startDate") ? "border-error" : ""
                        // }
                        />
                        <TextFieldInput
                            required
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.endDate"
                            }
                            type={"date"}
                            value={formData.endDate}
                            onChange={(e) => handleChange(e, "endDate")}
                            error={listOfInvalidInput.includes("endDate")}
                        // border={
                        //   listOfInvalidInput.includes("endDate") ? "border-error" : ""
                        // }
                        />
                        {/* <DatePickerWithRange/> */}
                        <TextArea
                            //value={value}
                            //onChange={onChange}
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.description"
                            value={formData.description}
                            rows={4} // Nombre de lignes par défaut
                            cols={40} // Nombre de colonnes par défaut
                            onChange={(e) => handleChange(e, "description")}
                            border={
                                listOfInvalidInput.includes("description") ? "border-error" : ""
                            } // Ajoutez d'autres attributs ou styles au besoin
                        />

                        <TextFieldInput
                            required
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.technologies"
                            }
                            value={formData.technologies}
                            type={"text"}
                            onChange={(e) => handleChange(e, "technologies")}
                            error={listOfInvalidInput.includes("technologies")}
                        // border={
                        //   listOfInvalidInput.includes("technologies")
                        //     ? "border-error"
                        //     : ""
                        // }
                        />

                        <div className="flex flex-row gap-[12px] items-center w-full">
                            {editingProject && (
                                <>
                                    {!confirmDelete ? (
                                        <Button
                                            onClick={() => setConfirmDelete(true)}
                                            showLeftIcon={true}
                                            showText={false}
                                            //sizePadding="firstDegree"
                                            leftIcon={
                                                <DeleteIcon className="text-primary cursor-pointer" />
                                            }
                                            style="ghost"
                                        />
                                    ) : (
                                        <motion.div
                                            className="flex flex-row gap-2 w-[70%] items-center"
                                            initial={{ x: -100, opacity: 0 }}
                                            animate={{ x: 0, opacity: 1 }}
                                            transition={{ duration: 0.5 }}
                                        >
                                            <Label message="consultants.statement.vatSettings.confirmDelete" />
                                            <Button
                                                showLeftIcon
                                                className="text-error border-error rounded-sm bg-white"
                                                leftIcon={
                                                    <DeleteOutlineIcon
                                                        className="text-error"
                                                        fontSize="small"
                                                    />
                                                }
                                                // showText={false}
                                                text={"global.actions.confirmDelete"}
                                                sizePadding="small"
                                                style="outline"
                                                onClick={() => onDelete(project.uuid)}
                                            />
                                        </motion.div>
                                    )}
                                </>
                            )}
                            <Button
                                showLeftIcon={true}
                                leftIcon={<ClearIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.cancel"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="outline"
                                className={`${confirmDelete ? "w-[17%]" : "font-bold border-primary w-full"
                                    }`}
                                showToolTip={confirmDelete ? true : false}
                                onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                            <Button
                                showLeftIcon={true}
                                leftIcon={<SaveIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.save"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="fill"
                                showToolTip={confirmDelete ? true : false}
                                className={`${confirmDelete ? "w-[16%]" : "font-bold w-full"}`}
                                type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                    </form>
                </Form>
            )}
        </div>
    );
}

const transformToProjectData = (formdata, lang) => {
    return {
        city: formdata.city,
        company: formdata.company,
        countryCode: formdata.country,
        description: formdata.description,
        endDate: formdata.endDate,
        industry: formdata.industry,
        language: lang,
        startDate: formdata.startDate,
        technologies: formdata.technologies,
        title: formdata.title,
    };
};

const transformToProjectDataUpdate = (formdata, lang) => {
    return {
        city: formdata.city,
        company: formdata.company,
        countryCode: formdata.country,
        description: formdata.description,
        endDate: formdata.endDate,
        industry: formdata.industry,
        language: lang,
        startDate: formdata.startDate,
        title: formdata.title,
        projectUuid: formdata.uuid,
        technologies: formdata.technologies,
    };
};
