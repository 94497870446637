import { BENEFITS_TYPE } from '@/utils/globalConst';
import { useState, useEffect } from 'react';

const useFormNewCompanyBenefit = ({ setSubmissionError, setSubmissionSuccess }) => {

    const [file, setFile] = useState(null);
    const [currency, setCurrency] = useState("EUR");
    const [date, setDate] = useState('');
    const [period, setPeriod] = useState('');
    const [selectValue, setSelectValue] = useState();
    const [amount, setAmount] = useState('');
    const [vatValues, setVatValues] = useState({});
    const [totalVat, setTotalVat] = useState(0);
    const [comment, setComment] = useState();
    const [benefitType, setBenefitType] = useState(BENEFITS_TYPE[0].enum)

    const [fileError, setFileError] = useState(null);
    const [dateError, setDateError] = useState(null);
    const [periodError, setPeriodError] = useState('');
    const [selectValueError, setSelectValueError] = useState(null);
    const [amountError, setAmountError] = useState(null);
    const [totalVatError, setTotalVatError] = useState(null);
    const [commentError, setCommentError] = useState(null);
    const [benefitTypeError, setBenefitTypeError] = useState(null);

    const handleVatChange = (e, item) => {
        const newVatValues = { ...vatValues, [item.uuid]: parseFloat(e.target.value) || 0 };
        setVatValues(newVatValues);
    };

    useEffect(() => {
        // console.log("vatValues", vatValues);
        const total = Object.values(vatValues).reduce((sum, current) => sum + current, 0);
        setTotalVat(total);
    }, [vatValues]);

    useEffect(() => {
        totalVat && validateForm("totalVatErrorTest");
    }, [totalVat]);

    useEffect(() => {
        date && validateForm("dateErrorTest");
    }, [date]);

    useEffect(() => {
        period && validateForm("periodErrorTest");
    }, [period]);

    useEffect(() => {
        amount && validateForm("amountErrorTest");
    }, [amount]);

    useEffect(() => {
        selectValue && validateForm("selectValueErrorTest");
        // console.log("useEffect, selectValue = ", selectValue)

    }, [selectValue]);

    useEffect(() => {
        amount && validateForm("totalVatErrorTest");
    }, [amount]);

    useEffect(() => {
        comment && validateForm("commentErrorTest");
    }, [comment]);

    useEffect(() => {
        benefitType && validateForm("benefitTypeErrorTest");
        console.log("benefitType change ", benefitType, BENEFITS_TYPE[0].enum)
    }, [benefitType]);

    const resetForm = () => {
        setFile(null);
        setDate('');
        setSelectValue('');
        setAmount('');
        setVatValues({});
        setTotalVat(0);
        setComment('')
        setPeriod('');

        setFileError(null);
        setDateError(null);
        setSelectValueError(null);
        setAmountError(null);
        setTotalVatError(null);
        setPeriodError(null);
        setCommentError(null);
        setBenefitTypeError(null)

        setSubmissionError && setSubmissionError(false);
        setSubmissionSuccess && setSubmissionSuccess(false);
    };



    function validateForm(varToTest) {

        const numericAmount = parseFloat(amount);
        const numericTotalVat = parseFloat(totalVat);
        switch (varToTest) {
            case "all":
                setTotalVatError(!numericTotalVat);
                setFileError(!file);
                setDateError(!date);
                setPeriodError(!period);
                setSelectValueError(!selectValue);
                setAmountError(!amount);
                setCommentError(!comment);
                setBenefitTypeError(!benefitType);
                break;
            case "fileErrorTest":
                setFileError(!file);
                break;
            case "dateErrorTest":
                setDateError(!date);
                break;
            case "periodErrorTest":
                setPeriodError(!period);
                break;
            case "selectValueErrorTest":
                setSelectValueError(!selectValue);
                break;
            case "amountErrorTest":
                setAmountError(!amount);
                break;
            case "totalVatErrorTest":
                setTotalVatError((numericTotalVat >= numericAmount) || numericTotalVat == 0);
                break;
            case "commentErrorTest":
                setCommentError(!comment);
                break;
            case "benefitTypeErrorTest":
                setBenefitTypeError(!benefitType);
                break;
            default:
                console.error("Unknown error test:", varToTest);
                break;
        }
        if (!file || !date || !period || !comment || !selectValue || !amount || !numericTotalVat || !benefitType) {
            return false;
        }
        return true;
    };

    return {
        file,
        date,
        period,
        currency,
        selectValue,
        amount,
        vatValues,
        totalVat,
        benefitType,
        comment,
        handleVatChange,
        resetForm,
        fileError,
        dateError,
        periodError,
        selectValueError,
        amountError,
        totalVatError,
        benefitTypeError,
        commentError,
        setFile,
        setDate,
        setPeriod,
        setCurrency,
        setSelectValue,
        setAmount,
        setBenefitType,
        setComment,
        validateForm
    };
};

export default useFormNewCompanyBenefit;
