import React from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

// `PrivateRoute` ajusté pour fonctionner comme un wrapper
const PrivateRoute = ({ element: Element }) => {
    const { isAuth, isBrowsingAllowedTo } = useAuth();
    const location = useLocation();

    // Vérifie si l'utilisateur est authentifié
    if (!isAuth()) {
        return <Navigate to="/login" replace />;
    }

    // Vérifie si l'utilisateur a le droit d'accéder à l'URL actuelle
    if (!isBrowsingAllowedTo(location.pathname)) {
        console.error("user hasn't privileges required for ", location.pathname)
        return <Navigate to="/unauthorized" replace />;
    }

    // Rendu du composant cible si toutes les conditions sont satisfaites
    return Element;
};

export default PrivateRoute;
