import { useAuth } from "@/context/AuthContext";
import { gql, useMutation } from "@apollo/client";

const UPDATE_COOPTED_BY = gql`
  mutation UpdateCooptedBy($updatedProspect: UpdateProspectDto!) {
    updateProspect(updatedProspect: $updatedProspect) {
      cooptedBy {
        uuid
        lastname
        firstname
        trigram
      }
    }
  }
`;

export const useUpdateCooptedBy = () => {
  const { user } = useAuth();
  const [updateCooptedByMutation, { loading, error, data }] =
    useMutation(UPDATE_COOPTED_BY);

  const updateCooptedBy = async (prospectUuid, cooptedByUuid) => {
    try {
      const response = await updateCooptedByMutation({
        variables: {
          updatedProspect: {
            uuid: prospectUuid,
            cooptedByUuid: cooptedByUuid,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${user.strongToken}`,
          },
        },
      });
      return response.data.updateProspect;
    } catch (err) {
      console.error("Failed to update cooptedBy:", err.message);
      throw err;
    }
  };

  return { updateCooptedBy, loading, error, data };
};
