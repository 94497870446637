import { gql } from "@apollo/client";

import { useAuth } from "@/context/AuthContext";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

export const GET_ITEMS_UUID_SELECTED_IN_TEMPLATE = gql`
  query templateCV($prospectCvUuid : ID!) {
  prospectCv(prospectCvUuid: $prospectCvUuid) {
    description {
      uuid
    }
    projects {
      uuid
    }
    experiences {
      uuid
    }
    certifications {
      uuid
    }
    educations {
      uuid
    }
    title {
      uuid
    }
    description {
      uuid
    }
    uuid
    skills {
      uuid
    }
  }
}
`;


export const useProspectCv = () => {
    const { user } = useAuth();
    const { templateID } = useParams();
    if (!templateID) {
        const error = "templateID by params is empty"
        return { loading: false, error, data: null, refetch: null }
    } else {
        const { loading, error, data, refetch } = useQuery(GET_ITEMS_UUID_SELECTED_IN_TEMPLATE, {
            variables: { prospectCvUuid: templateID },
            context: {
                headers: {
                    Authorization: `Bearer ${user.strongToken}`,
                },
            },
            fetchPolicy: "network-only", // S'assure que les données sont toujours récupérées du serveur
        });

        return { loading, error, data: (data && data.prospectCv), refetch };
    }

};
