import { useState } from "react";
import { useAuth } from "@/context/AuthContext";

export const useGetPayslip = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const getPayslip = async (payslipFileUuid) => {
    setLoading(true);
    setError(null);
    // console.log("getConsultantPayslip ", payslipFileUuid)

    try {
      const response = await fetch(window.ENV.BACKEND_URL+`/api/secured/users/payslip/payslipFile/${payslipFileUuid}`,{
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.strongToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch payslip");
      }

      const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            // console.log("blob file payslip ", blob, url)

            setData({ url, type: blob.type });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { getPayslip, loading, error, data };
};
