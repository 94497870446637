import useAllCurrencies from '@/services/global-services/query/useAllCurrencies';
import React from 'react';

const getCurrencySymbolFromCode = (code) => {
  const { currencies } = useAllCurrencies();

  if (!currencies || currencies.length === 0) {
    // console.warn("No currencies available or hook not loaded");
    return null;
  }

  const currency = currencies.find((currency) => currency.code === code);

  if (!currency) {
    console.warn(`Currency code ${code} not found`);
    return null;
  }

  return currency.symbol;
};

export default getCurrencySymbolFromCode;
