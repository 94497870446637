/* eslint-disable react/prop-types */
/**
 * @component ProspectsHome
 *
 * The `ProspectsHome` component serves as the main interface for managing and viewing prospects. It integrates various sub-components to handle prospect-related tasks and displays information in a structured layout.
 *
 * Key Features:
 * - Displays a header for the prospects section.
 * - Includes a panel for adding new prospects.
 * - Shows a table of existing prospects with functionality to load more data.
 * - Provides functionality to handle ongoing actions and data refetching.
 *
 * Props:
 * - None
 *
 * Local State:
 * - `isDoingAction`: Boolean indicating whether an action (e.g., loading) is in progress.
 * - `sliceNumber`: Integer representing the current slice of data to fetch (not used in the current implementation).
 * - `lastSlice`: Integer indicating the last slice number used for data fetching.
 *
 * Usage:
 * - This component is used to manage and display a list of prospects. It combines various components for adding, viewing, and managing prospects and handles data loading and refetching.
 *
 * Example:
 * ```jsx
 * <ProspectsHome />
 * ```
 *
 * Dependencies:
 * - @component BlueHeader: Component for displaying the page header.
 * - @component AddProspectsPanel: Component for adding new prospects.
 * - @component TableProspects: Component for displaying a table of prospects.
 * - @component InViewComponent: Component for detecting when an element is in view (used for lazy loading).
 * - @hook useState: React hook for managing local state.
 * - @hook useEffect: React hook for side effects and data fetching.
 * - @services/admin-services/query/useAllProspects: Hook for fetching prospects data.
 *
 * Functions:
 * - `refetch()`: Function from the `useAllProspects` hook to refetch the prospects data.
 *
 * @example
 * <ProspectsHome />
 * ```
 */

import { useState, useEffect } from "react";
import BlueHeader from "@/components/organisms/blueHeader";
import AddProspectsPanel from "@/components/macro-organisms/panels/panels-admin/AddProspects/AddProspectsPanel";
import TableProspects from "./TableProspects";
import { useAllProspects } from "@/services/admin-services/query/useAllProspects";

import InViewComponent from "@/components/organisms/InViewComponent";
export default function ProspectsHome() {
    const [isDoingAction, setIsDoingAction] = useState(false);
    const [sliceNumber, setSliceNumber] = useState(1);
    const [lastSlice, setLastSlice] = useState(1);
    const [prospectsCopy, setProspectsCopy] = useState([]);
    const [shouldPrintNumberOfProspects, setShouldPrintNumberProspects] = useState(false)
    const { loading, error, data, loadMoreProspects, refetch } =
        useAllProspects(lastSlice);

   

    useEffect(() => {
        refetch();

    }, [isDoingAction]);
    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setProspectsCopy([]);
        refetch(n);
        console.log("refetch from Scratch")
    };
    useEffect(() => {
        refetch();

    }, [lastSlice, refetch]);

    useEffect(() => {
        refetchFromScratch();
    }, []);
    const fetchNewSlice = () => {

        if (!loading && prospectsCopy.length > 0 && data?.allProspects?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };

    useEffect(() => {
        if (data) {
            if (sliceNumber > 1)

                setProspectsCopy((prevProspects) => [
                    ...prevProspects,
                    ...(Array.isArray(data?.allProspects.prospects) ? data.allProspects.prospects : [])
                ]);
            else
                setProspectsCopy(data?.allProspects.prospects)

            //console.log("les datas", data)
        }

    }, [loading, data]);


    return (
        <div>
            <BlueHeader title="prospects.prospectsConsultants" />
            <div className="px-[10vw] py-thirdDegree  gap-thirdDegree flex flex-col w-full  ">
                <AddProspectsPanel
                    isDoingAction={isDoingAction}
                    setIsDoingAction={setIsDoingAction}
                    refetch={refetch}
                    setShouldPrintNumberProspects={setShouldPrintNumberProspects}
                />
                <TableProspects
                    isDoingAction={isDoingAction}
                    setIsDoingAction={setIsDoingAction}
                    loading={loading}
                    error={error}
                    data={prospectsCopy}
                    loadMoreProspects={loadMoreProspects}
                    refetch={refetch}
                    shouldPrintNumberOfProspects={shouldPrintNumberOfProspects}
                    setShouldPrintNumberProspects={setShouldPrintNumberProspects}
                />
                <InViewComponent inView={fetchNewSlice} />
            </div>
        </div>
    );
}
