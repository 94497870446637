import { useState } from "react";
import { useAuth } from "@/context/AuthContext";
import { useParams } from "react-router-dom";

export const useGetConsultantPayslip = ( ) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const [file, setFile] = useState(null);
  const { id: consultantUuid } = useParams();
  if (!consultantUuid) return;

  const getConsultantPayslip = async (payslipFileUuid) => {
    setLoading(true);
    setError(null);
    // console.log("getConsultantPayslip ", payslipFileUuid)

    try {
      const response = await fetch(
        window.ENV.BACKEND_URL+`/api/secured/admin/payslip/payslipFile/${consultantUuid}/${payslipFileUuid}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.strongToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch payslip");
      }

    //   const fileBlob = await response.blob();
    //   setFile(fileBlob);


      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
    //   console.log("blob file payslip ", blob, url)

      setData({ url, type: blob.type });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { getPayslip :getConsultantPayslip, loading, error, data };
};
