// Rôle requis : USER_TIMESHEET
// Ce hook permet à un utilisateur de supprimer une entrée de temps d’une timesheet.
// Seuls les utilisateurs ayant le rôle USER_TIMESHEET peuvent accéder à cette mutation.

import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";


const DELETE_TIME_ENTRY = gql`
  mutation DeleteTimeEntry($uuid: ID!, $year: Int!) {
    deleteTimeEntry(deletedTimeEntry: { uuid: $uuid, year: $year })
  }
`;

export const useDeleteTimeEntry = () => {
  const { user } = useAuth();
  const [deleteTimeEntryMutation, { loading, error, data }] = useMutation(DELETE_TIME_ENTRY, {
    context: {
      headers: {
        Authorization: `Bearer ${user?.strongToken}`,
      },
    },
  });

  const deleteTimeEntry = async (uuid, year) => {
    try {
      const response = await deleteTimeEntryMutation({
        variables: { uuid, year },
      });
      return { data: response.data?.deleteTimeEntry, error: null };
    } catch (err) {
      return { data: null, error: err.message };
    }
  };

  return { deleteTimeEntry, loading, error, data };
};
