import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Définir les mutations GraphQL pour supprimer des éléments spécifiques
export const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProject($updatedProspect: UpdateProspectDto!) {
    updateProspect(updatedProspect: $updatedProspect) {
      uuid
      projects {
        uuid
      }
    }
  }
`;

export const DELETE_EXPERIENCE_MUTATION = gql`
  mutation DeleteExperience($updatedProspect: UpdateProspectDto!) {
    updateProspect(updatedProspect: $updatedProspect) {
      uuid
      experiences {
        uuid
      }
    }
  }
`;

export const DELETE_EDUCATION_MUTATION = gql`
  mutation DeleteEducation($updatedProspect: UpdateProspectDto!) {
    updateProspect(updatedProspect: $updatedProspect) {
      uuid
      educations {
        uuid
      }
    }
  }
`;

export const DELETE_CERTIFICATION_MUTATION = gql`
  mutation DeleteCertification($updatedProspect: UpdateProspectDto!) {
    updateProspect(updatedProspect: $updatedProspect) {
      uuid
      certifications {
        uuid
      }
    }
  }
`;

export const DELETE_DESCRIPTION_MUTATION = gql`
  mutation DeleteDescription($updatedProspect: UpdateProspectDto!) {
    updateProspect(updatedProspect: $updatedProspect) {
      uuid
      descriptions {
        uuid
      }
    }
  }
`;
// Définir la mutation GraphQL pour supprimer un titre
const DELETE_TITLE = gql`
  mutation DeleteTitle($uuid: ID!, $titleUuid: ID!) {
    updateProspect(
      updatedProspect: { uuid: $uuid, deletedTitles: [$titleUuid] }
    ) {
      titles {
        uuid
        language
        title
      }
    }
  }
`;
const DELETE_MAPPED_SKILL = gql`
  mutation DeleteMappedSkill($uuid: ID!, $skillUuid: [ID!]) {
    updateProspect(
      updatedProspect: { uuid: $uuid, deletedMappedSkills: $skillUuid }
    ) {
      mappedSkills {
        rating
        skill {
          uuid
        }
      }
    }
  }
`;


const DELETE_SPOKEN_LANGUAGE_MUTATION = gql`
  mutation DeleteSpokenLanguage($uuid: ID!, $deletedSpokenLanguage: [ID!]) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid
        deletedSpokenLanguages: $deletedSpokenLanguage
      }
    ) {
      spokenLanguages {
        uuid
      }
    }
  }
`;


const DELETE_UNMAPPED_SKILL = gql`
  mutation deleteUnmappedSkill($uuid: ID!) {
    deleteUnmappedSkill(uuid: $uuid)
  }
`;

const useUpdateProspectDeleteItems = () => {
  const { user } = useAuth();

  // Définir les mutations pour chaque type de suppression
  const [
    deleteProjectMutation,
    {
      loading: loadingDeleteProject,
      error: errorDeleteProject,
      data: dataDeleteProject,
    },
  ] = useMutation(DELETE_PROJECT_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const [
    deleteExperienceMutation,
    {
      loading: loadingDeleteExperience,
      error: errorDeleteExperience,
      data: dataDeleteExperience,
    },
  ] = useMutation(DELETE_EXPERIENCE_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const [
    deleteEducationMutation,
    {
      loading: loadingDeleteEducation,
      error: errorDeleteEducation,
      data: dataDeleteEducation,
    },
  ] = useMutation(DELETE_EDUCATION_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const [
    deleteCertificationMutation,
    {
      loading: loadingDeleteCertification,
      error: errorDeleteCertification,
      data: dataDeleteCertification,
    },
  ] = useMutation(DELETE_CERTIFICATION_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const [
    deleteDescriptionMutation,
    {
      loading: loadingDeleteDescription,
      error: errorDeleteDescription,
      data: dataDeleteDescription,
    },
  ] = useMutation(DELETE_DESCRIPTION_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  // Fonction pour supprimer un projet
  const deleteProject = async (prospectUuid, projectId) => {
    try {
      const response = await deleteProjectMutation({
        variables: {
          updatedProspect: {
            uuid: prospectUuid,
            deletedProjects: [projectId],
          },
        },
      });
      return response.data.updateProspect.projects;
    } catch (err) {
      console.error("Failed to delete project:", err.message);
      throw new Error(err.message);
    }
  };

  // Fonction pour supprimer une expérience
  const deleteExperience = async (prospectUuid, experienceId) => {
    try {
      const response = await deleteExperienceMutation({
        variables: {
          updatedProspect: {
            uuid: prospectUuid,
            deletedExperiences: [experienceId],
          },
        },
      });
      return response.data.updateProspect.experiences;
    } catch (err) {
      console.error("Failed to delete experience:", err.message);
      throw new Error(err.message);
    }
  };

  // Fonction pour supprimer une éducation
  const deleteEducation = async (prospectUuid, educationId) => {
    try {
      const response = await deleteEducationMutation({
        variables: {
          updatedProspect: {
            uuid: prospectUuid,
            deletedEducations: [educationId],
          },
        },
      });
      return response.data.updateProspect.educations;
    } catch (err) {
      console.error("Failed to delete education:", err.message);
      throw new Error(err.message);
    }
  };

  // Fonction pour supprimer une certification
  const deleteCertification = async (prospectUuid, certificationId) => {
    try {
      const response = await deleteCertificationMutation({
        variables: {
          updatedProspect: {
            uuid: prospectUuid,
            deletedCertifications: [certificationId],
          },
        },
      });
      return response.data.updateProspect.certifications;
    } catch (err) {
      console.error("Failed to delete certification:", err.message);
      throw new Error(err.message);
    }
  };

  // Fonction pour supprimer une description
  const deleteDescription = async (prospectUuid, descriptionId) => {
    try {
      const response = await deleteDescriptionMutation({
        variables: {
          updatedProspect: {
            uuid: prospectUuid,
            deletedDescriptions: [descriptionId],
          },
        },
      });
      return response.data.updateProspect.descriptions;
    } catch (err) {
      console.error("Failed to delete description:", err.message);
      throw new Error(err.message);
    }
  };
  const [
    deleteTitleMutation,
    {
      loading: loadingDeleteTitle,
      error: errorDeleteTitle,
      data: dataDeleteTitle,
    },
  ] = useMutation(DELETE_TITLE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const deleteTitle = async (prospectUuid, titleUuid) => {
    try {
      const response = await deleteTitleMutation({
        variables: {
          uuid: prospectUuid,
          titleUuid: titleUuid,
        },
      });

      return response.data.updateProspect.titles;
    } catch (err) {
      console.error("Failed to delete title:", err.message);
      throw new Error(err.message);
    }
  };
    
    
    const [deleteMappedSkillMutation, { data: dataDeleteMappedSkill, loading:loadingDeleteMappedSkill, error:errorDeleteMappedSkill }] = useMutation(
      DELETE_MAPPED_SKILL,
      {
        context: {
          headers: {
            Authorization: `Bearer ${user.strongToken}`, // Utilisation du token d'authentification
          },
        },
      }
    );

    const deleteMappedSkill = async (prospectUuid, skillUuid) => {
      try {
        const response = await deleteMappedSkillMutation({
          variables: {
            uuid: prospectUuid,
            skillUuid:[skillUuid],
          },
        });

        return response.data.updateProspect.mappedSkills;
      } catch (err) {
        console.error("Failed to delete mapped skill:", err.message);
        throw new Error(err.message);
      }
    };
  
  const [deleteSpokenLanguageMutation, { data: dataDeleteSpokenLanguage, loading:loadingDeleteSpokenLanguage, error:errorDeleteSpokenLanguage }] = useMutation(
    DELETE_SPOKEN_LANGUAGE_MUTATION,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`, // Ajouter le token de l'utilisateur
        },
      }
    }
  );

  const deleteSpokenLanguage = async (prospectUuid, languageUuid) => {
    try {
      const response = await deleteSpokenLanguageMutation({
        variables: {
          uuid: prospectUuid,
          deletedSpokenLanguage: languageUuid,
        }
      });
      return response.data;
    } catch (err) {
      console.error("Error deleting spoken language:", err);
      throw err;
    }
  };
 const [deleteUnmappedSkillMutation, { data: dataDeleteUnmappedSkill, loading:loadingDeleteUnmappedSkill, error:errorDeleteUnmappedSkill }] = useMutation(
    DELETE_UNMAPPED_SKILL,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`, // Ajouter le token de l'utilisateur
        },
      }
    }
  );
  const deleteUnmappedSkill = async (unmappedSkillUuid) => {
    try {
      const response = await deleteUnmappedSkillMutation({
        variables: {
          uuid: unmappedSkillUuid
        
        }
      });
      return response.data;
    } catch (err) {
      console.error("Error deleting spoken language:", err);
      throw err;
    }
  };
  return {
    deleteProject,
    deleteExperience,
    deleteEducation,
    deleteCertification,
    deleteDescription,
    deleteTitle,
    deleteMappedSkill,
    deleteSpokenLanguage,
    deleteUnmappedSkill,


    dataDeleteUnmappedSkill,
    loadingDeleteUnmappedSkill,
    errorDeleteUnmappedSkill ,

    errorDeleteSpokenLanguage,
    loadingDeleteSpokenLanguage,
    dataDeleteSpokenLanguage,
    

    dataDeleteMappedSkill,
    loadingDeleteMappedSkill,
    errorDeleteMappedSkill,
    
    loadingDeleteTitle,
    errorDeleteTitle,
    dataDeleteTitle,

    loadingDeleteProject,
    errorDeleteProject,
    dataDeleteProject,

    loadingDeleteExperience,
    errorDeleteExperience,
    dataDeleteExperience,

    loadingDeleteEducation,
    errorDeleteEducation,
    dataDeleteEducation,

    loadingDeleteCertification,
    errorDeleteCertification,
    dataDeleteCertification,

    loadingDeleteDescription,
    errorDeleteDescription,
    dataDeleteDescription,
  };
};

export default useUpdateProspectDeleteItems;
