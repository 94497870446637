/* eslint-disable react/prop-types */
/**
 * @component PrintEducation
 *
 * The `PrintEducation` component is responsible for displaying an individual educational entry. It provides the functionality to show or hide details of the education, and allows users to edit the entry by toggling the display of an edit form.
 *
 * Key Features:
 * - Display education details with the option to expand or collapse the view.
 * - Toggle the visibility of the edit form for updating the educational entry.
 * - Integrate with the `AddEducation` component to manage the editing process.
 *
 * Props:
 * - @param {Object} education - The education object containing details like title, city, country, start date, end date, and description.
 * - @param {Function} setEditingEducation - Function to set the state indicating if an education entry is being edited.
 * - @param {Boolean} editingEducation - Boolean to indicate if an education entry is currently being edited.
 * - @param {Object} user - User object containing user details necessary for API interactions.
 * - @param {Function} setIsDoingAction - Function to toggle the state indicating if an action (such as adding or editing) is in progress.
 * - @param {Function} setFormIsValid - Function to set the validity state of the form.
 * - @param {Function} setServerResponseIserror - Function to set the state indicating if there was an error in the server response.
 * - @param {Boolean} fomrIsValid - Boolean indicating if the form is valid.
 * - @param {Function} setErrors - Function to set the error messages related to form submission.
 *
 * Local State:
 * - `showDetails`: Boolean to control the visibility of education details.
 * - `showForm`: Boolean to control the visibility of the edit form.
 *
 * Functions:
 * - `handleEdit`: Function to set the component into edit mode and display the edit form.
 *
 * Usage:
 * - This component is used to display and manage individual education entries within a list. It allows users to view details and edit the entries.
 * - Integrates with the `AddEducation` component to handle the edit form functionality.
 *
 * Example:
 * ```jsx
 * <PrintEducation
 *   education={educationData}
 *   setEditingEducation={setEditing}
 *   editingEducation={isEditing}
 *   user={currentUser}
 *   setIsDoingAction={toggleLoading}
 *   setFormIsValid={setFormValidity}
 *   setServerResponseIserror={setServerError}
 *   fomrIsValid={formIsValid}
 *   setErrors={setFormErrors}
 * />
 * ```
 *
 * Dependencies:
 * - @component AddEducation: Provides the form for editing education details.
 * - @component EditIcon from `@mui/icons-material`: Used for the edit action icon.
 * - @component ExpandMoreIcon and ExpandLessIcon from `@mui/icons-material`: Used for toggling the visibility of education details.
 * - @component Label: Displays labels and messages.
 *
 * Local State Management:
 * - `showDetails` and `showForm` are managed locally to control the visibility of education details and the edit form.
 */

import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Label } from "@/components/atoms/texts/label";
import AddEducation from "./AddEducation";


export default function PrintEducation({
  education,
  setEditingEducation,
  editingEducation,
  user,
  setIsDoingAction,
  setFormIsValid,
  setServerResponseIserror,
  fomrIsValid ,
   setErrors,
}) {
  const [showDetails, setShowDetails] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const handleEdit = () => {
    setEditingEducation(true);
    setShowForm(true);
     setFormIsValid(false)
  };

  return (
    <div className="flex flex-col gap-firstDegree">
      {!showForm && (
        <div className="flex gap-[12px] ">
          {!showDetails && (
            <ExpandMoreIcon onClick={() => setShowDetails(true)} />
          )}
          {showDetails && (
            <ExpandLessIcon onClick={() => setShowDetails(false)} />
          )}
          <span className="font-bold flex items-center">{education.title}</span>
          <EditIcon
            className="text-primary cursor-pointer"
            onClick={handleEdit}
          />
        </div>
      )}
      {!showForm && showDetails && (
        <div className="flex flex-col gap-firstDegree">
          <div className="flex flex-row gap-firstDegree py-firstDegree text-bodySmall items-center">
            <Label fontWeight="bold">{education.city}</Label>
            <Label>{education.country}</Label>
            <Label>{education.startDate}</Label>

            <Label
              message="global.information.fromAtoB"
              size="small"
              className=""
            />
            <Label>{education.endDate}</Label>
          </div>
          <div className="text-bodySmall">
            <Label>{education.description}</Label>
          </div>
        </div>
      )}

      <AddEducation
        education={education}
        showForm={showForm}
        setShowForm={setShowForm}
        editingEducation={editingEducation}
        setEditingEducation={setEditingEducation}
        user={user}
        setIsDoingAction={setIsDoingAction}
        setFormIsValid={setFormIsValid}
        setServerResponseIserror={setServerResponseIserror}
        fomrIsValid={fomrIsValid}
         setErrors={setErrors}
      />
    </div>
  );
}
