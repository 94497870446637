export function correctUrl(url) {
    // Obtenir le chemin actuel de l'URL
    const currentPath = window.location.pathname;
    
    // Combiner le chemin actuel et l'URL fournie
    const fullUrl = new URL(url, `http://localhost:5173${currentPath}`).pathname;

    // Remplacer les répétitions dans l'URL combinée
    const correctedUrl = fullUrl.replace(/\/(\w+)(\/\1)+/g, '/$1');
    // Assurer que l'URL corrigée est relative
    return correctedUrl.replace('http://localhost:5173', '');
}
