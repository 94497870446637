import React, { useEffect, useRef } from "react";

export default function InViewComponent({ inView }) {
    const divRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    inView();
                    console.log("InViewComponent activation")
                }
            },
            { threshold: 0.1 } // Le seuil indique combien de la div doit être visible (10% ici)
        );

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        // Nettoyer l'observer lors du démontage du composant
        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
    }, [inView]);

    return (
        <div ref={divRef} className=" w-full h-4">
            
        </div>
    );
}
