import Chip from "@/components/molecules/buttons/Chip";
//category : 0 is salary, 1 is bonus
//status : 0 is read, 1 is unread
import Badge from '@mui/material/Badge';

export default function ReadingStatus({ isBonus, isNew }) {


    switch (isBonus) {
        case false:
            return (<Chip item="consultants.payslip.salary" bg="medium" fontWeight="bold" />);
        case true:
            return (<Chip item="consultants.payslip.bonus" bg="success" fontWeight="bold" />);
        default:
            break;
    }

    switch (isNew) {
        case true:
            return (<Chip item="consultants.companyBenefits.recent" bg="success20" border="success" fontWeight="bold" />);
        case false:
            return (<Chip item="consultants.companyBenefits.old" bg="medium" fontWeight="bold" />);
        default:
            break;
    }




}