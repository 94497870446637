export function transformFakeDataCV(fakeData) {
        // Transformations pour les titres de CV
        const titles = fakeData.jobTitle.map(title => ({
            __typename: "CvTitleResponseDto",
            title: title.description,
            language: "EN", // Assuming all titles are in English in fake data
            titleCompleted: false, // Assuming titleCompleted is false
            uuid: title.id
        }));

        // Transformations pour les descriptions de CV
        const descriptions = fakeData.description.map(desc => ({
            __typename: "CvDescriptionResponseDto",
            description: desc.description,
            language: "EN", // Assuming all descriptions are in English in fake data
            uuid: desc.id
        }));

        // Transformations pour les compétences (skills)
        const mappedSkills = fakeData.skills.map(skill => ({
            __typename: "CvSkillResponseDto",
            skill: skill.title,
            type: skill.type,
            level: skill.level,
            uuid: skill.id
        }));

        // Transformations pour les projets
        const projects = fakeData.project.map(project => ({
            __typename: "CvProjectResponseDto",
            title: project.title,
            industry: project.sector,
            company: project.company,
            city: project.city,
            endDate: project.endDate,
            description: project.description,
            language: "EN", // Assuming all projects are in English in fake data
            startDate: project.startDate,
            uuid: project.id
        }));

        // Transformations pour les expériences
        const experiences = fakeData.experience.map(experience => ({
            __typename: "CvExperienceResponseDto",
            title: experience.title,
            company: experience.company,
            endDate: experience.endDate,
            startDate: experience.startDate,
            language: "EN", // Assuming all experiences are in English in fake data
            description: experience.description,
            uuid: experience.id
        }));

        // Transformations pour les certifications
        const certifications = fakeData.certification.map(cert => ({
            __typename: "CvCertificationResponseDto",
            title: cert.title,
            organization: cert.organism,
            dateObtained: cert.date,
            description: cert.description,
            uuid: cert.id,
            language: "EN" // Assuming all certifications are in English in fake data
        }));

        // Transformations pour les éducations
        const educations = fakeData.education.map(edu => ({
            __typename: "CvEducationResponseDto",
            title: edu.title,
            school: edu.school,
            startDate: edu.startDate,
            endDate: edu.endDate,
            description: edu.description,
            city: edu.city,
            uuid: edu.id,
            language: "EN" // Assuming all educations are in English in fake data
        }));

        // Assemblage de l'objet final transformé
        const transformedData = {
            __typename: "ProspectDto",
            firstname: fakeData.user.firstName,
            lastname: fakeData.user.name,
            titles,
            descriptions,
            mappedSkills,
            unmappedSkills: [], // Assuming unmappedSkills is empty
            projects,
            experiences,
            certifications,
            educations
        };
        console.log("transformedData", transformedData);

        return transformedData;
    }