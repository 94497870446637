/* eslint-disable react/prop-types */

/**
 * @component AddProfileTitle
 *
 * The `AddProfileTitle` component provides a form for adding or editing a profile title in a prospect's information. 
 * Users can either add a new title or update an existing one, with options to save, cancel, or delete the title. 
 * It also provides validation feedback based on user input and server responses.
 *
 * Key Features:
 * - Display a form to add or edit a profile title.
 * - Allows saving, canceling, or deleting a title.
 * - Provides form validation and shows errors for invalid inputs.
 * - Handles server response errors for add, update, and delete actions.
 *
 * Props:
 * - @param {Object} profile - The profile object containing information such as `title` and `uuid` for editing.
 * - @param {boolean} showForm - Flag indicating whether the form should be displayed.
 * - @param {Function} setShowForm - Function to toggle the visibility of the form.
 * - @param {boolean} [editingProfile=false] - Flag indicating whether the profile is being edited or created.
 * - @param {Function} setProfiles - Function to update the list of profiles after changes.
 * - @param {Function} setEditingProfile - Function to toggle the editing mode.
 * - @param {Object} user - The user object containing at least the `uuid` of the current user.
 * - @param {Function} setIsDoingAction - Function to trigger loading or actions during form submission.
 * - @param {Function} setFormIsValid - Function to indicate if the form submission was valid.
 * - @param {Function} setServerResponseIserror - Function to indicate if there was an error from the server response.
 * - @param {Function} setErrors - Function to update the error messages in case of submission failure.
 *
 * Usage:
 * - This component is used in admin or user interfaces to manage prospect profiles, specifically for adding or editing the profile title.
 *
 * Example:
 * ```jsx
 * <AddProfileTitle
 *   profile={profileData}
 *   showForm={isFormVisible}
 *   setShowForm={toggleFormVisibility}
 *   editingProfile={isEditing}
 *   setProfiles={updateProfilesList}
 *   setEditingProfile={toggleEditing}
 *   user={currentUser}
 *   setIsDoingAction={triggerAction}
 *   setFormIsValid={checkFormValidity}
 *   setServerResponseIserror={handleErrorResponse}
 *   setErrors={displayErrors}
 * />
 * ```
 *
 * Dependencies:
 * - `Button` component for form actions (Save, Cancel, Delete).
 * - `TextFieldInput` component for input fields (Title).
 * - `useForm` hook from `react-hook-form` for managing form state.
 * - `useUpdateProspect`, `useUpdateProspectUpdateItems`, and `useUpdateProspectDeleteItems` hooks for API calls to manage adding, updating, and deleting titles.
 * - Utility functions like `deleteFromInvalidInputs` for form validation and `getAppLanguage` for handling language preferences.
 *
 * Sub-Components:
 * - @component Button: Used for actions such as save, cancel, and delete.
 * - @component TextFieldInput: A custom input field component for capturing the profile title.
 * - @component Label: Displays confirmation messages or form labels.
 */



import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import  { useState } from "react";
import { validateFormData } from "./validateFormProfile";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import useUpdateProspect from "@/services/admin-services/mutations/updateProspect/useUpdateProspectAddItems";
import getAppLanguage from "@/utils/getAppLanguage";
import useUpdateProspectUpdateItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectUpdateItems";
import useUpdateProspectDeleteItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectDeleteItems";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
export default function AddProfileTitle({
    profile,
    showForm,
    setShowForm,
    editingProfile = false,
    setEditingProfile,
    user,
    setIsDoingAction,
    setFormIsValid,
    setServerResponseIserror,
    setErrors,
}) {
    const form = useForm();

    const appLanguage = getAppLanguage();
    const { addNewTitle, errorAddTitle } = useUpdateProspect();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const { updateTitle, errorUpdateTitle } = useUpdateProspectUpdateItems()
    const { deleteTitle, errorDeleteTitle } = useUpdateProspectDeleteItems()
    const [formData, setFormData] = useState({
        title: profile ? profile.title : "",
        language: appLanguage,
        uuid: profile ? profile.uuid : "",
    });

    const [erros, setErros] = useState({
        profileTitle: "",
    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const handleChange = (e, name) => {
        const { value } = e.target;
        deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);
        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // console.log(formData);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErros);

        setlistOfInvalidInput(isValid);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            if (editingProfile) {
                try {
                    const updatedProspectData = await updateTitle(user.uuid,
                        formData

                    );
                    setIsDoingAction(true);
                    if (errorUpdateTitle) {
                        //alert("Invoice updated successfully!");
                        setServerResponseIserror(true);
                    } else {
                        setFormIsValid(true);

                        //throw new Error("Error updating invoice.");
                    }
                    //alert("Prospect updated successfully");
                } catch (err) {
                    alert(`Failed to update prospect: ${err.message}`);
                }
            } else {
                const result = await addNewTitle(
                    user.uuid,
                    appLanguage,
                    formData.title
                );

                setIsDoingAction(true);
                if (result.error) {
                    console.error("Failed to add description:", result.error);
                    alert("Add successfully ");

                    setFormIsValid(true);
                    setServerResponseIserror(false);
                    setEditingProfile(false);
                    if (errorAddTitle) setErrors(errorAddTitle);
                } else {
                    setFormIsValid(false);
                }
            }

            setShowForm(false);
        } else {
            console.log("invalid form");
            console.log("value invalid", isValid);
            console.log("form data", formData);
        }
    };
    const handleCancel = () => {
        setEditingProfile(false);
        setShowForm(false);
    };
    const handleDelete = async (uuid) => {
        try {
            // Replace with actual project UUID to delete
            const updatedProjects = await deleteTitle(user.uuid, uuid);
            //alert("Updated Projects:", updatedProjects);
            setIsDoingAction(true);

            if (errorDeleteTitle) {
                setErrors(errorDeleteTitle);
                setFormIsValid(false);
                setServerResponseIserror(true);
            }
        } catch (err) {
            // alert("Failed to delete project:", err);
            console.error("Failed to delete project:", err);
        }
    };

    return (
      <div>
        {showForm && (
          <div>
            <Form {...form}>
              <form
                onSubmit={handleSubmit}
                className={` flex flex-col gap-firstDegree bg-lighter  border-sm border-medium  p-secondDegree w-full`}
              >
                <TextFieldInput
                  placeHolder="consultants.statement.prospectConsultantPanel.profileTitle"
                  value={formData.title}
                  onChange={(e) => handleChange(e, "title")}
                  error={listOfInvalidInput.includes("title")}
                />
                <div className="flex flex-row gap-[12px] items-center w-full">
                  {editingProfile ? (
                    <>
                      {!confirmDelete ? (
                        <>
                          <Button
                            onClick={() => setConfirmDelete(true)}
                            showLeftIcon={true}
                            showText={false}
                            //sizePadding="firstDegree"
                            leftIcon={
                              <DeleteIcon className="text-primary cursor-pointer" />
                            }
                            style="ghost"
                          />
                          <Button
                            showLeftIcon={true}
                            leftIcon={<ClearIcon fontSize="small" />}
                            showText={true}
                            text="global.actions.cancel"
                            sizePadding="default" // Utilisez "small" pour un padding plus petit
                            style="outline"
                            className={`${
                              confirmDelete
                                ? "w-[17%]"
                                : "font-bold border-primary w-full"
                            }`}
                            showToolTip={confirmDelete ? true : false}
                            onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                          />
                          <Button
                            showLeftIcon={true}
                            leftIcon={<SaveIcon fontSize="small" />}
                            showText={true}
                            text="global.actions.save"
                            sizePadding="default" // Utilisez "small" pour un padding plus petit
                            style="fill"
                            showToolTip={confirmDelete ? true : false}
                            className={`${
                              confirmDelete ? "w-[16%]" : "font-bold w-full"
                            }`}
                            type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                          />
                        </>
                      ) : (
                        <div className="flex flex-row gap-2 items-center">
                          <Label message="consultants.statement.vatSettings.confirmDelete" />
                          <Button
                            showLeftIcon
                            className="text-error border-error rounded-sm bg-white"
                            leftIcon={
                              <DeleteOutlineIcon
                                className="text-error"
                                fontSize="small"
                              />
                            }
                            // showText={false}
                            text={"global.actions.confirmDelete"}
                            sizePadding="small"
                            style="outline"
                            onClick={() => handleDelete(formData.uuid)}
                          />
                          <Button
                            className="text-primary border-primary py-2 rounded-sm"
                            text={"global.actions.cancel"}
                            sizePadding="small"
                            style="ghost"
                            onClick={() => setConfirmDelete(false)}
                          />
                        </div>
                      )}
                      {/* <Button
                        showLeftIcon={true}
                        leftIcon={<ClearIcon fontSize="small" />}
                        showText={true}
                        text="global.actions.cancel"
                        sizePadding="default" // Utilisez "small" pour un padding plus petit
                        style="outline"
                        className={`${
                          confirmDelete
                            ? "w-[17%]"
                            : "font-bold border-primary w-full"
                        }`}
                        showToolTip={confirmDelete ? true : false}
                        onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                      />
                      <Button
                        showLeftIcon={true}
                        leftIcon={<SaveIcon fontSize="small" />}
                        showText={true}
                        text="global.actions.save"
                        sizePadding="default" // Utilisez "small" pour un padding plus petit
                        style="fill"
                        showToolTip={confirmDelete ? true : false}
                        className={`${
                          confirmDelete ? "w-[16%]" : "font-bold w-full"
                        }`}
                        type="submit" // Options: "fill", name "outline", "ghost", "dressedGhost"
                      /> */}
                    </>
                  ) : (
                    <>
                      <Button
                        showLeftIcon={true}
                        leftIcon={<ClearIcon fontSize="small" />}
                        showText={true}
                        text="global.actions.cancel"
                        sizePadding="default" // Utilisez "small" pour un padding plus petit
                        style="outline"
                        className={`${
                          confirmDelete
                            ? "w-[17%]"
                            : "font-bold border-primary w-full"
                        }`}
                        showToolTip={confirmDelete ? true : false}
                        onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                      />
                      <Button
                        showLeftIcon={true}
                        leftIcon={<SaveIcon fontSize="small" />}
                        showText={true}
                        text="global.actions.save"
                        sizePadding="default" // Utilisez "small" pour un padding plus petit
                        style="fill"
                        showToolTip={confirmDelete ? true : false}
                        className={`${
                          confirmDelete ? "w-[16%]" : "font-bold w-full"
                        }`}
                        type="submit" // Options: "fill", name "outline", "ghost", "dressedGhost"
                      />
                    </>
                  )}
                </div>
              </form>
            </Form>
          </div>
        )}
      </div>
    );
}
