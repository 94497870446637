
import { useTranslation } from 'react-i18next'
//if the message doesn't exist in translation file, it will return empty arrow function and display an error log in the console.
function useTranslate(message) {
    const [translate, i18n] = useTranslation("global");
    
    
    if (i18n.exists('global:' + message)) {
        return translate;
    } else {
        console.error("the message "+ message + " does not exist in the translation file: global in the language : " + i18n.language );
        return ()=>{return(false)};
    }    
}

export default useTranslate;

