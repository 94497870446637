import React, { useState } from 'react'
import { Label } from '../../atoms/texts/label';
export default function PrintInformationMessage({ information, isComponent = false, className, dismissible }) {
    const [visible, setVisible] = useState(true);

    if (visible) {
        return (
            <div
                className={`flex flex-col p-secondDegree border border-information rounded-sm bg-information20 ${className}`}
            >
                {isComponent && information}
                {!isComponent && <Label message={information}>{information}</Label>}
                {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={() => setVisible(false)} className="text-error rounded-sm"></Button>}

            </div>
        );
    } else {
        return <></>
    }

}
