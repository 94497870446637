import React, { useEffect, useState } from "react";
import { Label } from "@/components/atoms/texts/label";
// import { Button } from "@/components/molecules/buttons/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
    DialogClose,
} from "@/components/ui/dialog";
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import { Button } from "@/components/molecules/buttons/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/organisms/forms/select";
import { Input } from "../ui/input";
import useTranslate from "@/services/global-services/useTranslate";
import { useTranslation } from "react-i18next";
// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/organisms/forms/select";
import useAllConsultantProfiles from "@/services/admin-services/query/useAllConsultantProfiles";
import useAddUserFromProspect from "@/services/admin-services/mutations/useAddUserFromProspect";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import useAllRetributionModels from "@/services/admin-services/query/useAllRetributionModels";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Separator } from "../atoms/divider/separatorShadcn";
import { SeparatorHorizontal } from "lucide-react";
import useAllRoles from "@/services/admin-services/query/useAllRoles";
import getAppLanguage from "@/utils/getAppLanguage";
import useAddRetributionModel from "@/services/mutation/admin-mutations/useAddRetributionModel";
import { useToast } from "../organisms/feedback/use-toast";
import { Toaster } from "../organisms/feedback/toaster";
import PrintErrorMessage from "../organisms/feedback/PrintErrorMessage";

export default function StepperFormTransfer({ open, setOpen, prospect, refetch }) {
    const [step, setStep] = useState(1);
    const steps = [1, 2, 3, 4];
    const [formErrors, setFormErrors] = useState({});
    const [selectedProfile, setSelectedProfile] = useState();
    const [selectedRole, setSelectedRole] = useState();
    const [selectedRetribution, setSelectedRetribution] = useState();
    const appLanguage = getAppLanguage();
    const { toast } = useToast();

    const [formData, setFormData] = useState({ profile: undefined, iban: undefined, bic: undefined, email: undefined, entryDate: undefined, prospectUuid: prospect.uuid, roleUuid: undefined, retribution: undefined, inputRetribution: undefined, language: appLanguage });
    const { addNewUserFromProspect, data, loading, error } = useAddUserFromProspect();




    const [t] = useTranslation("global");

    // const { consultantProfiles } = useAllConsultantProfiles();
    // const { retributionModels } = useAllRetributionModels();
    // const { allRoles } = useAllRoles();

    const { consultantProfiles, loading: profilesLoading, error: profilesError } = useAllConsultantProfiles();
    const { retributionModels, loading: retributionLoading, error: retributionError } = useAllRetributionModels();
    const { allRoles, loading: rolesLoading, error: rolesError } = useAllRoles();
    const { addRetributionModel } = useAddRetributionModel(); //IMPORT MUTATION


    //TODO:gérer getUser avec Apollo. 

    const handleChange = (e, specific) => {
        // console.log("debug handleChange e =", e, "specific =", specific, "formErrors =", formErrors, "formData", formData);
        if (specific == "profile") {
            setSelectedProfile(e)
            setFormData(prev => ({ ...prev, ["profile"]: e }));
            formErrors.profile && validateForm();
        } else if (specific == "retribution") {
            setSelectedRetribution(e)
            setFormData(prev => ({ ...prev, ["retribution"]: e }));
            formErrors.retribution && validateForm();
        } else if (specific == "role") {
            setSelectedRole(e)
            setFormData(prev => ({ ...prev, ["roleUuid"]: e }));
            formErrors.role && validateForm();
        } else if (specific === "iban") {
            let iban = e.replace(/\s+/g, ''); // Supprimer les espaces
            setFormData(prev => ({ ...prev, ["iban"]: iban }));
            validateForm({ iban: iban });
        } else if (specific === "bic") {
            let bic = e.replace(/\s+/g, ''); // Supprimer les espaces
            setFormData(prev => ({ ...prev, ["bic"]: bic }));
            validateForm({ bic: bic });
            // } else if (specific === "entryDate") {
            //     setFormData(prev => ({ ...prev, ["entryDate"]: e }));
            //     validateForm();
        } else {
            const { name, value } = e.target;
            // console.log("debug specific non reconnu e = ", e, "name =", name, "value=", value);
            setFormData(prev => ({ ...prev, [name]: value }));
            formErrors[name] && validateForm();
        }

        if (formErrors[e?.target?.name]) {
            setFormErrors(prev => ({ ...prev, [e?.target?.name]: null }));
        }
    };

    const resetStep = () => { setStep(1) }

    const handleNext = () => {
        if (step == 1) { setStep(step + 1) }
        if (step == 2) {
            const errors = validateForm();
            if (!errors.profile && !errors.email && !errors.entryDate && !errors.language && !errors.prospectUuid && !errors.roleUuid && !errors.bic && !errors.iban) {
                setStep(step + 1)
            } else {
                console.error("error en step 2", formData, appLanguage, errors.profile, errors.email, errors.entryDate, errors.language, errors.prospectUuid, errors.roleUuid, errors.bic, errors.iban)
            };
            // console.log("debug handleNext errors =", errors);
            if (formData.inputRetribution) {
                sendDataAddRetributionModel()
            }
        }
        if (step === 3) {

            const errors = validateForm();
            // console.log("debug handleNext step 3 errors =", errors);

            // profile uuid                     ok step 2
            // email                            ok step 3
            // entry date - nouveau champ       ok step 2
            // language - langue de l'app       ok
            // prospect uuid                    ok auto
            // role uuid - nouveau champ        ok step 2

            // optionnel : 
            // retribution model                ok step2
            // iban                             ok step2
            // bic                              ok step2
            if (!errors.email) {
                handleSubmit();
                !error && !loading && data && setStep(step + 1)
            }
        }
        if (step === 4) { resetStep() }
    }
    const sendDataAddRetributionModel = async () => {
        const [upToFiveConsultants, upToTenConsultants, moreThanTenConsultants] = formData.inputRetribution.split('/');
        const { data: dataAddRetribution, error: errorAddRetribution } = await addRetributionModel(upToFiveConsultants, upToTenConsultants, moreThanTenConsultants);
        if (errorAddRetribution) {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                message: errorAddRetribution
            })
            console.error("Error updating data:", errorAddRetribution);
        } else {
            toast({
                variant: "success",
                title: "success.changesSaved",
                duration: 1500,
            })
            // console.log("Cooptations updated data:", dataAddRetribution, errorAddRetribution);

            setFormData(prev => ({ ...prev, [inputRetribution]: undefined }));
            setFormData(prev => ({ ...prev, [retribution]: dataAddRetribution.uuid }));

        }
    };
    const validateForm = ({ iban = undefined, bic = undefined } = { undefined }) => {

        const errors = {};
        switch (step) {
            case 2:
                if (!formData.profile) { errors.profile = t("error.mandatory"); }

                if (!formData.profile) { errors.profile = t("error.mandatory"); }
                if (!formData.entryDate) { errors.entryDate = t("error.mandatory"); }
                if (!formData.language) { errors.language = t("error.tryLater"); }
                if (!formData.prospectUuid) { errors.prospectUuid = t("error.tryLater"); }
                if (!formData.roleUuid) { errors.roleUuid = t("error.mandatory"); }

                // Validation de l'IBAN
                const ibanIsValid = /^[A-Za-z0-9]+$/.test(iban || formData.iban);
                if (!ibanIsValid) {
                    errors.iban = t("error.letterOrNumberOnly");
                }


                // Validation du BIC
                const bicIsValid = /^[A-Za-z0-9]+$/.test(bic || formData.bic);
                if (!bicIsValid) {
                    errors.bic = t("error.letterOrNumberOnly");
                }
                // console.log("debug validateForm step 2 errors=", errors, formData, !formData.entryDate);

                break;
            case 3:

                if (!formData.email) { errors.email = t("error.mandatory"); }
                // console.log("debug validateForm formData.email =", formData.email);

                break;
            default:
                break;
        }

        setFormErrors(errors)
        return errors;
    }
    const handleSubmit = async () => {

        try {
            const newUserUuid = await addNewUserFromProspect(formData);
            // refetch();
            // console.log("New user created with UUID:", newUserUuid, "formData send was ", formData);
            // Gestion de la soumission réussie
        } catch (e) {
            console.error("Error creating new user from prospect:", e);
            // Afficher un message d'erreur à l'utilisateur
        }
    };
    // useEffect(() => {
    //     validateForm();
    // }, [formData]);
    // Components
    function DialogSubtitle() {
        switch (step) {
            case 1:
                return (<span><Label> : </Label><Label message="prospects.profileTransfer.warning" size="h4" /></span>)
            case 2:
                return (<span><Label> : </Label><Label message="prospects.profileTransfer.addInformations" size="h4" /></span>)
            case 3:
                return (<span><Label> : </Label><Label message="prospects.profileTransfer.corporateEmail" size="h4" /></span>)
            default:
                break;
        }
    }
    function DialogDescriptions() {
        switch (step) {
            case 1:
                return (
                    <DialogDescription className="flex flex-col gap-4 py-4">
                        <span>
                            <Label message="prospects.profileTransfer.step1_text1"></Label>
                            <Label fontWeight="bold">{prospect.firstname + " " + prospect.lastname}</Label>
                            <Label message="prospects.profileTransfer.step1_text2"></Label>
                        </span>
                        <span>
                            <Label message="prospects.profileTransfer.step1_text3_1"></Label>
                            <Label fontWeight="bold" message="prospects.profileTransfer.step1_text3_2"></Label>
                            <Label message="prospects.profileTransfer.step1_text3_3"></Label>
                        </span>
                        <Label fontWeight="bold" message="prospects.profileTransfer.step1_text4"></Label>
                        <span>
                            <Label message="prospects.profileTransfer.step1_text5"></Label>
                            <Label fontWeight="bold" >{prospect.firstname + " " + prospect.lastname}</Label>
                            <Label message="prospects.profileTransfer.step1_text6"></Label>
                        </span>
                    </DialogDescription>
                )
            case 2:

                return (
                    <><DialogDescription className="flex flex-col gap-firstDegree py-0" >
                        <Label message="prospects.profileTransfer.mandatory"></Label>
                    </DialogDescription>
                        <div className="grid grid-cols-7 items-center gap-y-1 gap-x-2">
                            {/* Profile profilesLoading profilesError*/}
                            <span className=" col-span-2"><Label className=" text-primary col-span-2 " message="consultants.informations.profile" /><Label className="text-error"> * </Label></span>
                            {profilesLoading && <span className="loader" />}
                            {profilesError && <PrintErrorMessage error={profilesError} className="col-span-5 w-full" />}
                            {(!profilesLoading && !profilesError) && <Select defaultValue={selectedProfile} onValueChange={(value) => handleChange(value, "profile")}>
                                <SelectTrigger className={`col-span-5 bg-hoverLight ${formErrors.profile && `border-error`}`}>
                                    {<SelectValue placeholder={t("global.actions.select")} />}
                                </SelectTrigger>
                                <SelectContent>
                                    {consultantProfiles && consultantProfiles.map((item, index) => (
                                        <SelectItem value={item.uuid} id={index} >{item.name}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>}
                            <div className="col-span-6 text-error w-full"><Label >{formErrors?.profile}</Label></div>

                            {/* Entry Date */}
                            <span className=" col-span-2"><Label className=" text-primary col-span-2" message="consultants.informations.entryDate" /><Label className="text-error"> * </Label></span>
                            <Input placeholder={t("consultants.informations.entryDate")} type="date" name="entryDate" className={`col-span-5 w-full flex justify-end bg-hoverLight ${formErrors.entryDate && `border-error`}`} onChange={(value) => handleChange(value, "entryDate")} defaultValue={formData.entryDate} />
                            <div className="col-span-6 text-error w-full"><Label >{formErrors?.entryDate}</Label></div>

                            {/* Role */}
                            <span className=" col-span-2"><Label className=" text-primary col-span-2 " message="consultants.informations.role" /><Label className="text-error"> * </Label></span>
                            {rolesLoading && <span className="loader" />}
                            {rolesError && <PrintErrorMessage error={rolesError} className="col-span-5 w-full" />}
                            {(!rolesLoading && !rolesError) && <Select defaultValue={selectedRole} onValueChange={(value) => handleChange(value, "role")}>
                                <SelectTrigger className={`col-span-5 bg-hoverLight ${formErrors.roleUuid && `border-error`}`}>
                                    {<SelectValue placeholder={t("global.actions.select")} />}
                                </SelectTrigger>
                                <SelectContent>
                                    {allRoles && allRoles.map((item, index) => (
                                        item.eligibleToProspectMigration && <SelectItem value={item.uuid} id={item.uuid} >{item.name}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>}
                            <div className="col-span-6 text-error w-full"><Label >{formErrors?.roleUuid}</Label></div>

                            <Separator className="col-span-6 m-2" />

                            {/* Retribution */}
                            <span className=" col-span-2"><Label className=" text-primary col-span-2" message="consultants.informations.retribution.name" /></span>
                            {retributionLoading && <span className="loader" />}
                            {retributionError && <PrintErrorMessage error={retributionError} className="col-span-5 w-full" />}
                            {(!retributionLoading && !retributionError) && <div className="col-span-5 flex gap-firstDegree">
                                <Select defaultValue={selectedRetribution} onValueChange={(value) => handleChange(value, "retribution")} disabled={formData.inputRetribution}>
                                    <Button style="ghost" sizePadding="small" leftIcon={<ClearRoundedIcon fontSizeize="small" />} showLeftIcon disabled={!formData.retribution} showText={false} onClick={() => handleChange(undefined, "retribution")} />

                                    <SelectTrigger className={` bg-hoverLight ${formErrors?.retribution && `border-error`}`}>
                                        {<SelectValue placeholder={t("global.actions.select")} />}

                                    </SelectTrigger>
                                    <SelectContent>
                                        {retributionModels && retributionModels.map((item, index) => (
                                            <SelectItem value={item.uuid} id={index} >{item.upToFiveConsultants + "/" + item.upToTenConsultants + "/" + item.moreThanTenConsultants}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <Input
                                    htmlFor="r3"
                                    placeholder='--/--/--'
                                    name="inputRetribution"
                                    className={` w-full bg-hoverLight ${formErrors?.inputRetribution && `border-error`}`}
                                    onBlur={handleChange}
                                    disabled={formData.retribution}
                                    defaultValue={formData.inputRetribution}
                                />
                            </div>}
                            <div className="col-span-6 text-error w-full"><Label>{formErrors?.retribution}</Label></div>

                            {/* IBAN */}
                            <span className=" col-span-2"><Label className=" text-primary col-span-2" message="consultants.informations.iban" /></span>
                            <Input placeholder={t("consultants.informations.iban")} type="iban" name="iban" className={`col-span-5 w-full bg-hoverLight ${formErrors.iban && `border-error`}`} onBlur={(e) => handleChange(e.target.value, "iban")} defaultValue={formData.iban} />
                            <div className="col-span-6 text-error w-full"><Label >{formErrors?.iban}</Label></div>

                            {/* BIC */}
                            <span className=" col-span-2"><Label className=" text-primary col-span-2" message="consultants.informations.bic" /></span>
                            <Input placeholder={t("consultants.informations.bic")} type="" name="bic" className={`col-span-5 w-full bg-hoverLight ${formErrors.bic && `border-error`}`} onBlur={(e) => handleChange(e.target.value, "bic")} defaultValue={formData.bic} />
                            <div className="col-span-6 text-error w-full"><Label >{formErrors?.bic}</Label></div>



                        </div>
                        {loading && <span className="loader" />}
                        {error && <PrintErrorMessage error={error} />}
                    </>)

            case 3:
                return (<><DialogDescription className="flex flex-col gap-firstDegree py-0" >
                    <Label message="prospects.profileTransfer.step3_text1"></Label>
                </DialogDescription>
                    <div className="grid grid-cols-6 items-center gap-y-1 gap-x-2">
                        <span><Label className="col-span-1 text-primary w-[300px]" message="consultants.informations.proEmail" /><Label className="text-error"> * </Label></span>
                        <Input placeholder={t("consultants.informations.proEmail")} type="" name="email" className={`col-span-5 w-full bg-hoverLight ${formErrors.email && `border-error`}`} onBlur={handleChange} defaultValue={formData.email} />

                    </div>
                    {error && <PrintErrorMessage error={error} />}
                </>)
                break;
            case 4:
                return (<><DialogDescription className="flex flex-col gap-firstDegree py-0" >
                    <Label message="prospects.profileTransfer.step4_text1"></Label>
                    <Label message="prospects.profileTransfer.step4_text2"></Label>

                </DialogDescription></>)

            default:
                break;
        }
    }
    function DialogFooters() {
        switch (step) {
            case 1:
            case 2:
            case 3:
                return (
                    <DialogFooter className="sm:justify-start">
                        {/* <DialogClose asChild> */}
                            <Button type="button" style="ghost" text='global.actions.cancel' onClick={() => { setOpen(false) }} />
                        {/* </DialogClose> */}
                        {/* <DialogClose className="w-full" > */}
                            <Button style="fill" text='global.actions.continue' className="w-full" onClick={handleNext} />
                        {/* </DialogClose> */}
                    </DialogFooter >
                )
            case 4:
                return (
                    <DialogFooter className="sm:justify-start">
                        {/* <DialogClose className="w-full" > */}
                            <Button style="fill" text='global.actions.close' className="w-full" onClick={() => { setOpen(false); resetStep() }} />
                        {/* </DialogClose> */}
                    </DialogFooter >
                )
            default:
                break;
        }
    }
    return (
        <DialogContent xClose={false}>
            <Toaster />
            <DialogHeader>
                <DialogTitle>
                    <Stepper activeStep={(step - 1)} className="mb-5 " >
                        {steps.map((label, index) => {

                            return (
                                // <Step key={label} onClick={() => { (index + 1) < step && setStep(index + 1) }}>
                                <Step key={label} onClick={() => {  setStep(index + 1) }}>

                                    <StepLabel></StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <span>
                        <Label fontWeight="bold" size="h4">{prospect.firstname + " " + prospect.lastname + " : "}</Label>
                        <Label message="prospects.profileTransfer.title" size="h4" />
                        <DialogSubtitle />
                    </span>
                </DialogTitle>
            </DialogHeader>
            <DialogDescriptions />

            <DialogFooters />
        </DialogContent>
    )
}