import React, { useState } from 'react';
import { Label } from '@/components/atoms/texts/label';
import { useTranslation } from "react-i18next";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export default function DragAndDropZone({ file, setFile, fileError, isMultifiles = false, className, ...props }) {
    const [t] = useTranslation("global");
    const choosefile = t("global.actions.chooseFile");
    const [dragOver, setDragOver] = useState(false);

    // Gère le changement de fichier
    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files.length) {
            if (isMultifiles) {
                setFile([...files]); // Pour plusieurs fichiers, on stocke tous les fichiers dans un tableau
            } else {
                setFile(files[0]); // Pour un seul fichier, on ne prend que le premier
            }
        }
    };

    // Fonction pour gérer le dépôt de fichier
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer.files;
        if (files.length) {
            if (isMultifiles) {
                setFile([...files]); // Pour plusieurs fichiers, on stocke tous les fichiers dans un tableau
            } else {
                setFile(files[0]); // Pour un seul fichier, on ne prend que le premier
            }
            document.getElementById("fileInput").files = files;
        }
        setDragOver(false); // Réinitialiser l'état de survol
    };

    // Prévenir le comportement par défaut pour permettre le dépôt de fichier
    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(true); // Mettre à jour l'état lors du survol avec un fichier
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(false); // Réinitialiser l'état lorsque le fichier quitte la zone
    };

    // Appliquer une classe conditionnelle pour le survol
    const bgClassName = dragOver ? 'bg-hover' : 'bg-medium';

    return (
        <div
            className={`flex flex-col ${bgClassName} rounded-md justify-center items-center w-full py-thirdDegree gap-secondDegree border ${className}  ${isMultifiles ?(file?.length>0 ? `border-success` : (fileError ? `border-error`:`border-medium`)) : (file ? `border-success` : (fileError ? `border-error`:`border-medium`))} `}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            {...props}
        >
            <div className='flex flex-col justify-center items-center gap-firstDegree '>
                <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                    className='hidden'
                    multiple={isMultifiles} // Permet de sélectionner plusieurs fichiers si isMultifiles est true
                />

                <Label size="" fontWeight="bold" color="gray400" message="global.actions.dragAndDrop" />
                <Label size="" color="gray400" message="global.actions.OrBrowse" />
                <label onChange={handleFileChange} htmlFor="fileInput" className="cursor-pointer inline-block bg-white text-body text-night font-medium py-2 px-4 rounded-md hover:bg-hover">
                    {choosefile}
                </label>
            </div>

            <div className='h-auto flex flex-col text-body text-success justify-center items-center gap-firstDegree'>
                {/* Afficher les fichiers sélectionnés */}
                {file && (isMultifiles ? (
                    file.map((f, index) => (
                        <div key={index} className="flex   grow h-auto items-center">
                            <CheckCircleRoundedIcon color='success' />
                            <span className="ml-2 text-body font-bold">{f.name}</span>
                        </div>
                    ))
                ) : (
                    <div className="flex items-center">
                        <CheckCircleRoundedIcon color='success' />
                        <span className="ml-2 text-body font-bold">{file.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
