import React, { useState } from "react";
import { ResizablePanel } from "@/components/ui/resizable";
import { Label } from "@/components/atoms/texts/label";
import ListOfActions from "@/components/organisms/navigation/ListOfActions";

import SortButton from "@/components/molecules/buttons/Sort";
// import useNotifications from "@/hooks/global-hooks/useNotification";
import Notification from "@/components/organisms/notification/Notification";
import { useConsultantContext } from "@/context/ConsultantContext";
import { Fragment } from "react";
import { Toaster } from "@/components/organisms/feedback/toaster";
import LineArt from "@/assets/images/lineArt.png"
import { Button } from "@/components/molecules/buttons/button";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useNotifications } from "@/hooks/global-hooks/NotificationContext";
export default function ConsultantIndex() {
    // const { actions, informations, error, sortNotifications, loading, readNotification, markAllAsRead, refetch } = useNotifications();
    const { actions, informations, error, sortNotifications, loading, readNotification, markAllAsRead, refetch } = useNotifications();

    const [sortByDateAscInformations, setSortByDateAscInformations] = useState(true);
    const [sortByDateAscActions, setSortByDateAscActions] = useState(true);

    const handleSortNotifications = (type) => {
        if (type == "informations") {
            sortNotifications(sortByDateAscInformations ? "asc" : "desc", type);
            setSortByDateAscInformations(!sortByDateAscInformations);
        } else {
            sortNotifications(sortByDateAscActions ? "asc" : "desc", type);
            setSortByDateAscActions(!sortByDateAscActions);
        }
    };



    return (
        <Fragment>


            <ResizablePanel
                defaultSize={15}
                className=" min-w-[260px] p-secondDegree  z-20"
                id="actions-panel"
                order={2}
            >
                <div className="flex flex-col text-center gap-secondDegree overflow-none h-full">
                    {/* <div> */}
                    <Label
                        size="h3"
                        fontWeight="bold"
                        className="sticky top-0 bg-lighter pb-4 "
                        message="consultants.dashboard.title.grpActions"
                    />
                    {/* </div> */}
                    <ListOfActions className="h-full flex flex-col gap-4 overflow-auto pb-8" />
                </div>
            </ResizablePanel>

            <ResizablePanel
                defaultSize={85}
                className="h-full z-10  overflow-auto"
                id="notifications-panel"
                order={3}
            >
                <img src={LineArt} className="bgLineArt overflow-hidden" />
                <div className="grid grid-cols-2 divide-x h-full">


                    <div className="flex flex-col overflow-auto	">
                        <div className="p-secondDegree flex justify-between gap-2">
                            <div className="flex flex-col	grow">
                                <Label
                                    size="h3"
                                    fontWeight="bold"
                                    className=""
                                    
                                    message="consultants.dashboard.title.recent"
                                />
                                <Label
                                    size="body"
                                    className=""
                                    message="consultants.dashboard.title.recentMessage"
                                />
                            </div>
                            <Button onClick={() => markAllAsRead("actions")} style="ghost" sizePadding="pill" className="w-min rounded-sm" showLeftIcon leftIcon={<DoneAllIcon/>} showText={false}/>
                            <SortButton
                                type="date"
                                sort={sortByDateAscActions ? "up" : "down"}
                                onClick={() => handleSortNotifications("actions")}
                            />
                        </div>
                        <div className="space-y-4 p-4 flex flex-col h-full overflow-auto ">
                            {/* {console.log("actions dans consultant index", actions)} */}
                            {(loading) ? <span className="loader"></span> : <>
                                {actions.map((notification, index) => (

                                    <Notification key={index} notification={notification} />
                                ))}</>}
                            {(error && !actions) && <Label
                                size="bodySmall"
                                fontWeight="regular"
                                fontFamily=""
                                className=" "
                                message="error.fetchUnsuccessfull"
                            />}
                        </div>
                    </div>
                    <div className="flex flex-col overflow-auto	">
                        <div className="p-secondDegree flex justify-between gap-2">
                            <div className="flex flex-col	grow">
                                <Label
                                    size="h3"
                                    fontWeight="bold"
                                    className=""
                                    message="consultants.dashboard.title.notifications"
                                />
                                <Label
                                    size="body"
                                    className=""
                                    message="consultants.dashboard.title.notificationsMessage"
                                />
                            </div>
                            <Button onClick={() => markAllAsRead("informations")} style="ghost" sizePadding="pill" className="w-min rounded-sm" showLeftIcon leftIcon={<DoneAllIcon/>} showText={false}/>

                            <SortButton
                                type="date"
                                sort={sortByDateAscInformations ? "up" : "down"}
                                onClick={() => handleSortNotifications("informations")}
                            />
                        </div>
                        <div className="space-y-4 p-4 flex flex-col h-full overflow-auto">
                            {(loading) ? <span className="loader" ></span> : informations.map((notification, index) => (
                                <Notification key={index} notification={notification} readNotification={readNotification} />
                            ))}
                            {(error && !informations) && <Label
                                size="bodySmall"
                                fontWeight="regular"
                                fontFamily=""
                                className=" "
                                message="error.fetchUnsuccessfull"
                            />}

                            { }
                        </div>
                    </div>
                </div>
            </ResizablePanel>
            <Toaster />
        </Fragment>
    );
}
