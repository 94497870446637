/* eslint-disable react/prop-types */
/**
 * @component PrintProject
 *
 * This component is responsible for displaying project details and providing an interface to edit the project. 
 * It integrates with the `AddProject` component to handle adding or updating project information.
 *
 * Key Features:
 * - Displays project details in a collapsible format, allowing users to expand or collapse additional information.
 * - Provides an edit button to switch to an editable state where users can modify project details using the `AddProject` component.
 * - Manages internal state to control the visibility of project details and the edit form.
 * - Handles user interactions, including toggling the visibility of project details and the edit form.
 *
 * Props:
 * - @param {Object} project - The project data to be displayed and edited.
 * - @param {Function} setEditingProject - Function to set the editing state of the project.
 * - @param {Boolean} editingProject - Indicates if the form is in edit mode.
 * - @param {Object} user - The current user data.
 * - @param {Function} setIsDoingAction - Function to set the state of ongoing actions.
 * - @param {Function} setFormIsValid - Function to set the form validity state.
 * - @param {Function} setServerResponseIserror - Function to set the server response error state.
 * - @param {Function} setUserTapping - Function to indicate if the user is interacting with the form.
 * - @param {Function} setErrors - Function to set form error details.
 *
 * Internal State:
 * - @state {Boolean} showDetails - Controls the visibility of detailed project information.
 * - @state {Boolean} showForm - Controls the visibility of the `AddProject` form.
 *
 * Usage:
 * - This component is typically used in a context where project details need to be displayed and edited.
 * - It integrates with the `AddProject` component to provide a form for adding or updating project information.
 * - The `PrintProject` component manages the display of project details and provides a button to toggle the form for editing.
 *
 * Example:
 * <PrintProject
 *   project={projectData}
 *   setEditingProject={setEditingProjectState}
 *   editingProject={isEditingProject}
 *   user={currentUser}
 *   setIsDoingAction={setActionState}
 *   setFormIsValid={setFormValidity}
 *   setServerResponseIserror={setServerErrorState}
 *   setUserTapping={setUserInteraction}
 *   setErrors={setFormErrors}
 * />
 *
 * Dependencies:
 * - `AddProject` component for handling project form operations.
 * - Material-UI icons (`EditIcon`, `ExpandMoreIcon`, `ExpandLessIcon`) for user interface interactions.
 */
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddProject from "./AddProject";
import { Label } from "@/components/atoms/texts/label";


export default function PrintProject({
  project,
  setEditingProject,
  editingProject,
  user,
  setIsDoingAction,
  setFormIsValid,
  setServerResponseIserror,
  setUserTapping,
setErrors,
}) {
  const [showDetails, setShowDetails] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const handleEdit = () => {
    setEditingProject(true);
    setShowForm(true);
    setFormIsValid(false)
  };
  return (
    <div className="flex flex-col gap-firstDegree">
      {!showForm && (
        <div className="flex gap-[12px] ">
          {!showDetails && (
            <ExpandMoreIcon onClick={() => setShowDetails(true)} />
          )}
          {showDetails && (
            <ExpandLessIcon onClick={() => setShowDetails(false)} />
          )}
          <span className="font-bold flex items-center">{project.title}</span>
          <EditIcon
            className="text-primary cursor-pointer"
            onClick={handleEdit}
          />
        </div>
      )}
      {!showForm && showDetails && (
        <div className="flex flex-col gap-firstDegree">
          <div className="flex flex-row gap-firstDegree py-firstDegree text-bodySmall items-center">
            <Label className="font-bold">{project.enterprise}</Label>
            <Label>{project.city}</Label>
            <Label>{project.country}</Label>
            <Label>{project.startDate}</Label>
            <Label
              message="global.information.fromAtoB"
              size="small"
              className=""
            />
            <Label>{project.endDate}</Label>
          </div>
          <div className="text-bodySmall">
            <Label>{project.description}</Label>
          </div>
        </div>
      )}

      <AddProject
        project={project}
        showForm={showForm}
        setShowForm={setShowForm}
        editingProject={editingProject}
        setEditingProject={setEditingProject}

        user={user}
        setIsDoingAction={setIsDoingAction}
        setFormIsValid={setFormIsValid}
        setServerResponseIserror={setServerResponseIserror}
        setUserTapping={setUserTapping}
        setErrors={setErrors}
      />
    </div>
  );
}
