/* eslint-disable react/prop-types */
/**
 * @component Printdescription
 *
 * The `Printdescription` component displays a description with an option to edit it. It shows the description text along with an edit icon. Clicking the edit icon reveals a form for modifying the description.
 *
 * Key Features:
 * - Display a description text with an edit button.
 * - Show an edit form when the edit button is clicked.
 * - Passes data and state management functions to the `AddDescription` component for handling form submissions.
 *
 * Props:
 * - @param {Object} description - The description object to display and edit. Includes `description` text.
 * - @param {Function} setEditingDescription - Function to set the editing state of the description.
 * - @param {Boolean} editingDescription - Boolean indicating if the description is being edited.
 * - @param {Object} user - User object containing user details necessary for API interactions.
 * - @param {Function} setIsDoingAction - Function to toggle the state indicating if an action (such as adding or editing) is in progress.
 * - @param {Function} setFormIsValid - Function to set the form's validity state.
 * - @param {Function} setServerResponseIserror - Function to set the state for server response errors.
 * - @param {Function} setErrors - Function to set and display error messages.
 *
 * Local State:
 * - `showForm`: Boolean to control the visibility of the edit form.
 *
 * Functions:
 * - `handleEdit`: Toggles the visibility of the edit form and sets the editing state.
 *
 * Usage:
 * - This component is used to display individual descriptions within a user profile or similar context. It integrates with the `AddDescription` component for editing functionality.
 *
 * Example:
 * ```jsx
 * <Printdescription
 *   description={currentDescription}
 *   setEditingDescription={setEditing}
 *   editingDescription={isEditing}
 *   user={currentUser}
 *   setIsDoingAction={setLoading}
 *   setFormIsValid={setFormValid}
 *   setServerResponseIserror={setServerError}
 *   setErrors={setFormErrors}
 * />
 * ```
 *
 * Dependencies:
 * - `EditIcon` from `@mui/icons-material` for the edit button icon.
 * - `AddDescription` component for the form used to edit the description.
 * - `Label` component for displaying the description text with appropriate styling.
 */

import EditIcon from "@mui/icons-material/Edit";
import AddDescription from "./AddDescription";
import { useState } from "react";
import { Label } from "@/components/atoms/texts/label";
export default function Printdescription({
  description,
  setEditingDescription,
  editingDescription,

  user,
  setIsDoingAction,
  setFormIsValid,
  setServerResponseIserror,
  setErrors,
}) {
  const [showForm, setShowForm] = useState(false);
  const handleEdit = () => {
    setShowForm(true);
    setEditingDescription(true);
    setFormIsValid(false)
  };
  return (
    <div className="">
      {!showForm && (
        <div className="flex  p-2 flex-row gap-[12px] w-full  ">
          <div className=" ">
            <Label className=" items-center" size="body">
              {description.description}
            </Label>{" "}
          </div>
          <div>
            <EditIcon
              className="text-primary cursor-pointer"
              onClick={handleEdit}
            />
          </div>
        </div>
      )}
      <AddDescription
        description={description}
        showForm={showForm}
        setShowForm={setShowForm}
        editingDescription={editingDescription}
        setEditingDescription={setEditingDescription}
        user={user}
        setIsDoingAction={setIsDoingAction}
        setFormIsValid={setFormIsValid}
        setServerResponseIserror={setServerResponseIserror}
        setErrors={setErrors}
      />
    </div>
  );
}
