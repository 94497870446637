const deleteFromInvalidInputs = (
  elementASupprimer,
  setlistOfInvalidInput,
  listOfInvalidInput
) => {
  // Copier la liste actuelle
 
  const nouvelleListe = [...listOfInvalidInput];
  // Trouver l'index de l'élément à supprimer
  const index = nouvelleListe.indexOf(elementASupprimer);
  // Supprimer l'élément de la copie de la liste
  if (index !== -1) {
    nouvelleListe.splice(index, 1);
    // Mettre à jour l'état avec la nouvelle liste
    setlistOfInvalidInput(nouvelleListe);
  }

};
export { deleteFromInvalidInputs };