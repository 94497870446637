import { useEffect, useState, useRef } from "react";
import { useAuth } from "@/context/AuthContext";

const useProspectPicture = (prospectId) => {
    const [profilePicture, setProfilePicture] = useState(null);
    const [newProspectId, setNewProspectId] = useState(prospectId);
    let loading = false;
    const [error, setError] = useState(null);
    const { user } = useAuth();

    const getProspectPicture = async () => {
        if (profilePicture || loading) {return;}
        loading = true;
        setError(null);
        

        console.log("getProspectPicture", "loading", loading, "error", error, "profilePicture", profilePicture, "newProspectId", newProspectId);
        try {
            const response = await fetch(
                window.ENV.BACKEND_URL + `/api/secured/admin/prospect/picture/${newProspectId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user?.strongToken}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);
            console.log("response prospectpictuure",response, imageUrl);
            setProfilePicture(imageUrl);
        } catch (err) {
            setError(err);
        } finally {
            loading = false;
        }
    };

    const uploadProspectPicture = async (file) => {
        loading = true;
        setError(null);
        try {
            const formData = new FormData();
            formData.append("picture", file);

            const response = await fetch(
                `${window.ENV.BACKEND_URL}/api/secured/admin/prospect/picture/${prospectId}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${user?.strongToken}`,
                    },
                    body: formData,
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            if(response.ok){
                await getProspectPicture();
            }
        } catch (err) {
            setError(err);
        } finally {
            loading = false;
        }
    };

    useEffect(() => {
        prospectId != newProspectId && setNewProspectId(prospectId);
        console.log("prospectId change",prospectId, "newProspectId",newProspectId, "appel fetch picture ? ",(prospectId != newProspectId && !!prospectId));
    }, [prospectId]);

    useEffect(() => {
        console.log("DEBUG newProspectId change",newProspectId);
        const fetchPicture = async () => {
            console.log("fetch picture condition =", !profilePicture && prospectId && !loading && !error);
            if (!profilePicture && prospectId && !loading && !error) {
                await getProspectPicture();
            }
        };
        !!newProspectId && fetchPicture();
    }, []);

    useEffect(() => {
        console.log("**** DEBUG  profilePicture change",profilePicture);
    }, [profilePicture]);

    return {
        profilePicture,
        loading,
        error,
        getProspectPicture,
        uploadProspectPicture,
    };
};

export default useProspectPicture;
