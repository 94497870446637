import { useAuth } from "@/context/AuthContext";
import { gql, useQuery } from "@apollo/client";

const GET_EXPENSE_REPORT_CATEGORIES = gql`
  query getExpenseReportCategories(   $companyUuid: ID!) {
    expenseReportCategories(
      expenseReportCategoriesRequest: {  companyUuid: $companyUuid }
    ) {
      uuid
      accountingAccountNumber
      label
    }
  }
`;

const useExpenseReportCategories = (language) => {
    const { user } = useAuth();
    
    const companyUuid = user.company.uuid;

    const { loading, error, data, refetch } = useQuery(GET_EXPENSE_REPORT_CATEGORIES, {
        variables: {  companyUuid },
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
        fetchPolicy: "network-only",

    });
    return {
        loading,
        error,
        categories: data?.expenseReportCategories,
        refetch,
    };
};
export default useExpenseReportCategories;
