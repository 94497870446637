
import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Définition de la requête GraphQL pour obtenir tous les modèles de rétribution
const GET_ALL_RETRIBUTION_MODELS = gql`
  query ALL_RETRIBUTION_MODELS($companyUuid: ID!) {
    allRetributionModels(companyUuid: $companyUuid) {
      moreThanTenConsultants
      upToFiveConsultants
      upToTenConsultants
      uuid
    }
  }
`;

const useAllRetributionModels = () => {
  const { user: authUser } = useAuth();
  const companyUuid = authUser.company.uuid;

  const { loading, error, data, refetch } = useQuery(GET_ALL_RETRIBUTION_MODELS, {
    variables: { companyUuid },
    context: {
      headers: {
        Authorization: `Bearer ${authUser.strongToken}`,
      },
    },
    fetchPolicy: "network-only",
  });

  return { 
    loading, 
    error, 
    retributionModels: data?.allRetributionModels, 
    refetch 
  };
};

export default useAllRetributionModels;
