import { Label } from "@/components/atoms/texts/label";
import CVTab from "@/components/macro-organisms/tabs/consultants-vision-admin/cv/cvTab";
import { Button } from "@/components/molecules/buttons/button";
import { useProspect } from "@/services/admin-services/query/useProspect";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';

export default function CVProspect() {
    const navigate = useNavigate();
    const { prospectID } = useParams();
    const { loading, error, data, refetch } = useProspect();
    !loading && console.log("prospect cv data = ", data, { error });
    // if(data){
    return (
        <div className="flex flex-col px-[10vw] gap-secondDegree py-thirdDegree">
            <div className="flex gap-secondDegree items-center">
                <Button showLeftIcon={true} leftIcon={<ArrowBack />} style="ghost" text="global.actions.back" onClick={() => { navigate("../") }} />
                <span className="w-full mr-[100px] text-center">
                    {data && <Label size="h3" fontWeight="bold" > {data.firstname + " " + data.lastname}</Label>}                    </span>
                <Button style="ghost" showLeftIcon leftIcon={<ReplayRoundedIcon />} onClick={() => refetch()} showText={false} sizePadding={"firstDegree"}/>
            </div>

            {/* <CVTab isForProspect/> */}
            <Outlet />
        </div>
    )
    //}

}