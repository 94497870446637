import { Label } from '@/components/atoms/texts/label';
import React from 'react';

export default function JobTitleItem({ data , className, ...props}) {
    console.log("JobTitleItem : data = ", data);
    return (
        <div className={`p-firstDegree  shrink-0  ${className}`} {...props} >
            <Label fontWeight="bold" {...props}>{data.title}</Label>
        </div>
    )
}