import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const DELETE_COMPANY_BENEFITS_INVOICE = gql`
  mutation DeleteCompanyBenefitInvoice($uuid: ID!) {
    deleteCompanyBenefitInvoice(deletedCompanyBenefitInvoice: { uuid: $uuid })
  }
`;

export const useDeleteCompanyBenefitInvoice = () => {
  const { user } = useAuth();

  const [deleteCompanyBenefitInvoiceMutation, { loading, error, data }] = useMutation(DELETE_COMPANY_BENEFITS_INVOICE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    
  });
  
  const deleteCompanyBenefitInvoice = async (uuid) => {
    const response = await deleteCompanyBenefitInvoiceMutation({
      variables: { uuid },
    });
    return response.data.deleteExpenseReport;
  };
  return { deleteCompanyBenefitInvoice, loading, error, data };
};
