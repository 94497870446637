/* eslint-disable react/prop-types */
/**
 * @component Description
 *
 * The `Description` component manages the display and editing of descriptions. It allows users to view a list of descriptions, add new ones, and handle server responses and errors.
 *
 * Key Features:
 * - Toggle the visibility of the add description form.
 * - Display a list of existing descriptions.
 * - Handle errors and server responses.
 * - Integrate with `AddDescription` for adding new descriptions and `Printdescription` for displaying individual descriptions.
 *
 * Props:
 * - @param {Function} hideAllOverview - Function to hide all overview sections.
 * - @param {Function} showComponent - Function to control the visibility of the component.
 * - @param {Array} descriptions - Array of description objects to be displayed.
 * - @param {Object} user - User object containing user details necessary for API interactions.
 * - @param {Function} setIsDoingAction - Function to toggle the state indicating if an action (such as adding or editing) is in progress.
 *
 * Local State:
 * - `showForm`: Boolean to control the visibility of the add description form.
 * - `editingDescription`: Boolean to indicate if a description is being edited.
 * - `fomrIsValid`: Boolean to indicate if the form is valid.
 * - `serverresponseIsError`: Boolean to indicate if there was an error in server response.
 * - `errors`: Error object to store and display error messages.
 *
 * Functions:
 * - `hider`: Hides the overview and current component view.
 *
 * Usage:
 * - This component is used to manage a list of descriptions within a user profile or similar context. It allows users to add new descriptions and view existing ones.
 * - Integrates with the `AddDescription` component for adding new entries and the `Printdescription` component for displaying individual descriptions.
 *
 * Example:
 * ```jsx
 * <Description
 *   hideAllOverview={hideOverview}
 *   showComponent={toggleComponent}
 *   descriptions={userDescriptions}
 *   user={currentUser}
 *   setIsDoingAction={toggleLoading}
 * />
 * ```
 *
 * Dependencies:
 * - @component AddDescription: Provides the form for adding new descriptions.
 * - @component Printdescription: Displays individual descriptions.
 * - @component ArrowBackIcon from `@mui/icons-material`: Used for the back navigation icon.
 * - @component AddIcon from `@mui/icons-material`: Used for the add action icon.
 * - @component Label: Displays section headers and labels.
 * - @component Button: Action buttons for navigating and adding.
 * - @component motion from `framer-motion`: Provides animation support.
 * - @component PrintErrorMessage: Displays error messages.
 */

import AddDescription from "./AddDescription";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import Printdescription from "./PrintDescription";

import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";

import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
export default function Description({
  hideAllOverview,
  showComponent,
  descriptions,

  user,
  setIsDoingAction
}) {
  const [showForm, setShowForm] = useState(false);
  
  const [editingDescription, setEditingDescription] = useState(false);
   const [fomrIsValid, setFormIsValid] = useState(false);
   const [serverresponseIsError, setServerResponseIserror] = useState(false);
   const [errors, setErrors] = useState(null);
  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };


  return (
    <motion.div variants={item}>
      {serverresponseIsError && <PrintErrorMessage error={errors} />}
      <div className="flex items-center gap-fourthDegree w-full">
        <Button
          showLeftIcon={true}
          leftIcon={
            <ArrowBackIcon className="cursor-pointer" onClick={hider} />
          }
          showText={false}
          style="ghost"
          onClick={hider}
        />
        <div className="basis-3/4">
          <Label
            message="consultants.statement.prospectConsultantPanel.description"
            size="h3"
            fontWeight="bold"
          />
        </div>

        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={() => setShowForm(true)}
        />
      </div>
      <div className="flex flex-col gap-secondDegree">
        {showForm && (
          <AddDescription
            showForm={showForm}
            setShowForm={setShowForm}
         
            editingDescription={false}
            setEditingDescription={setEditingDescription}
            user={user}
            setIsDoingAction={setIsDoingAction}
            setFormIsValid={setFormIsValid}
            setServerResponseIserror={setServerResponseIserror}
            setErrors={setErrors}
          />
        )}
        {descriptions &&
          descriptions.map((description) => {
            return (
              <Printdescription
                key={description.uuid}
                description={description}
                editingDescription={editingDescription}
                setEditingDescription={setEditingDescription}
              
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
              />
            );
          })}
      </div>
    </motion.div>
  );
}
