import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const GET_PAYSLIPS = gql`
  query Payslips($sliceNumber: Int!) {
    payslips(payslipsRequest: { sliceNumber: $sliceNumber }) {
      payslips {
        uuid
        payslipFileUuid
        year
        month
        currency
        grossSalary
        netSalary
        taxes
        createdAt
        bonus
        employerContributions
        employeeContributions
        comment
      }
      hasNext
    }
  }
`;

export const usePayslips = (sliceNumber) => {
  const { user } = useAuth();
  const { loading, error, data, refetch } = useQuery(GET_PAYSLIPS, {
    variables: { sliceNumber },
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    fetchPolicy: "network-only",
  });

  return { loading, error, data: data?.payslips, refetch };
};
