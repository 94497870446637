import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';

const RootRedirect = () => {
    const { isAuth, hasPrivileges } = useAuth();
    const navigate = useNavigate();
    const location = useLocation(); // Permet d'accéder à l'URL actuelle


    useEffect(() => {
        console.log("redirection", location.pathname, isAuth())

        if (isAuth()) {
            if (hasPrivileges("ADMIN_")) {
                navigate('/consultant');
            } else if (hasPrivileges("USER_")) {
                navigate('/administration');
            }
        } else {
            // Rediriger vers /login uniquement si l'URL actuelle n'est pas déjà /login
            if (location.pathname !== '/login') {
                navigate('/login');
            }
        }
    }, [isAuth, hasPrivileges, navigate, location]);

    return null;
};

export default RootRedirect;
