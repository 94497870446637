import { Label } from "@/components/atoms/texts/label";
import React from "react";
import ProgressBar from "./ProgressBar";
import { Button } from "@/components/molecules/buttons/button";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
export default function TrainingCard() {
  return (
    <div className="px-secondDegree py-firstDegree  gap-firstDegree flex flex-col  bg-medium rounded-sm   w-full text-body overflow-auto text-center">
      <span className="bg-white rounded-sm p-firstDegree  text-center ">
        {" "}
        <Label message="consultants.skillsPage.youReleased" size="body" />
        <span className="font-bold"> 22% </span>
        <Label message="consultants.skillsPage.youStill" size="body" />{" "}
        <span className="font-bold"> 45 </span>
        <Label message="consultants.skillsPage.daysShort" size="body" />
        <Label message="consultants.skillsPage.each220Days" size="body" />
      </span>
      <div>
        <ProgressBar percentage={70} />
      </div>
      <div className="truncate text-center">
        {" "}
        <Label message="consultants.skillsPage.yourTrainingIs" size="body" />
        <span className="font-bold text-h5"> 2500 €</span>
      </div>
      <Label message="consultants.skillsPage.updateYourSkills" size="body" />
      <Button
        showLeftIcon={true}
        leftIcon={<RocketLaunchOutlinedIcon fontSize="body" />}
        showRightIcon={true}
        rightIcon={<OpenInNewRoundedIcon />}
        showText={true}
        text="consultants.skillsPage.findTraining"
        sizePadding="default" // Utilisez "small" pour un padding plus petit
        style="outline"
        className="w-[73%] mx-[50px]"
        onClick={() =>
          window.open(
            "https://www.houseoftraining.lu",
            "_blank",
            "noopener,noreferrer"
          )
        }
        // Options: "fill", "outline", "ghost", "dressedGhost"
      />
    </div>
  );
}
