import { useAuth } from "@/context/AuthContext";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import client from "@/apolloClient";
import { useState } from "react";

export const GET_INVOICES_PER_YEAR = gql`
  query ConsultantInvoices($invoiceRequest: ConsultantInvoiceRequestDto!) {
    consultantInvoices(invoiceRequest: $invoiceRequest) {
      invoices {
        year
        uuid
        periodStart
        periodEnd
        month
        invoiceNumber
        invoiceDate
        daysWorked
        dailyRateConsultant
        dailyRateClient
        currency
        clientName
        amountConsultant
        amountClient
        commission
      }
      year
    }
  }
`;


export const useInvoicesPerYear = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const [allInvoices, setData] = useState(null);
  const [loadingInvoices, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const fetchInvoicesPerYear = async ( year) => {
    const { data, loading, error } = await client.query({
      query: GET_INVOICES_PER_YEAR,
      variables: { invoiceRequest: { consultantUuid: id, year: year } },
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      },
      fetchPolicy: "network-only",
    });
    setLoading(loading);
    if (data) {
      setData(data);
    }
    if (error) {
      setErrors(error);
    }

    return data.consultantInvoices.invoices;
  };

  return { loadingInvoices, errors, allInvoices, fetchInvoicesPerYear };
};

