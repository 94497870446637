import { useState } from "react";
import { useAuth } from "@/context/AuthContext";
import { useParams } from "react-router-dom";

export const useGetConsultantCompanyBenefitInvoice = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const {id} = useParams();

  const getConsultantCompanyBenefitInvoice = async (companyBenefitInvoiceFileUuid) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(window.ENV.BACKEND_URL+`/api/secured/admin/companyBenefit/companyBenefitInvoiceFile/${id}/${companyBenefitInvoiceFileUuid}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to retrieve consultant company benefit invoice");
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setData({ url, type: blob.type });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { getCompanyBenefitInvoice : getConsultantCompanyBenefitInvoice, loading, error, data };
};
