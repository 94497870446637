import { useAuth } from "@/context/AuthContext";
import { Label } from "../atoms/texts/label"
import { Button } from "../molecules/buttons/button"
import { Popover, PopoverContent, PopoverTrigger } from "../molecules/popover"
import Translate from "./Translate"
import { Navigate, useNavigate } from "react-router-dom";
import { Switch } from "../ui/SwitchForAccountSelection";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";



export default function PopUpProfile({ component, isForMobile = false }) {

    const navigate = useNavigate();
    // console.log('user : ', user)
    const { user, isAuth, hasPrivileges, viewAs, handleSetViewAs } = useAuth();
    const [open, setOpen] = useState(false);

    const { i18n } = useTranslation("global");

    useEffect(() => {
        user && i18n.changeLanguage(user.language.toLowerCase())
        // console.log("user in app = ", user)
    }, []);

    useEffect(() => {
        !!user.language && i18n.changeLanguage(user.language.toLowerCase())

    }, [user.language])

    return (

        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger className={isForMobile && `w-full`}>{component}</PopoverTrigger>
            <PopoverContent className={`mr-3 items-end flex flex-col gap-firstDegree p-secondDegree shadow-md  overflow-hidden bg-white w-max rounded-md ${isForMobile && ` rounded-sm shadow-3xl absolute left-[50vw] -translate-x-1/2 top-[50vh] transform -translate-y-1/2`}`}>
                <span>
                    <Label message="global.popUpProfile.hi" />
                    <Label fontWeight="bold" >{user.surname} {user.name}</Label>
                </span>
                <span className="text-end">
                    <Label message="global.popUpProfile.role" />
                    {isAuth && hasPrivileges('ADMIN_') && <Label fontWeight="bold" message="role.admin" />}
                    {isAuth && hasPrivileges('ADMIN_') && hasPrivileges('USER_') && <Label message="role.both" />}
                    {isAuth && hasPrivileges('USER_') && <Label fontWeight="bold" message="role.consultant" />}


                </span>
                {
                    hasPrivileges('ADMIN_') && hasPrivileges('USER_') &&
                    <div className="justify-end items-center flex flex-row gap-firstDegree bg-lighter flex-nowrap p-secondDegree rounded-sm border border-medium w-full">
                        <Label fontWeight="bold">Espace : </Label>
                        <div className="flex items-center gap-firstDegree">
                            <Label fontWeight={viewAs == "ADMIN_" && `bold`} className={viewAs == "ADMIN_" && `text-error`} message="role.admin" />
                            <Switch checked={viewAs == "USER_"} onCheckedChange={(e) => { handleSetViewAs(); setOpen(false) }} />
                            <Label fontWeight={viewAs == "USER_" && `bold`} className={viewAs == "USER_" && `text-secondary`} message="role.consultant" />
                        </div>
                    </div>
                }
                <Translate className="w-full" />
                <Button style="outline" text="global.actions.logout" className="w-full" onClick={() => navigate("/logout")} />
            </PopoverContent>
        </Popover>
    )
};