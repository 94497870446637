import React, { useEffect, useState } from "react";
import { Avatar } from "@/components/atoms/avatar";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import DataDeletion from "@/components/macro-organisms/panels/panels-consultant/DataDeletion";
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/molecules/table";
import { Toaster } from "@/components/organisms/feedback/toaster";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import Copy from "@/components/atoms/Copy";
import EditInformationData from "../../../../organisms/forms/editInformationData";
import useCurrentUser from "@/services/global-services/query/useCurrentUser";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";

export default function InformationTabUser() {
    const { getCurrentUserInformation, currentUserInformation, loading, error, refetchCurrentUser } = useCurrentUser();

    const [selectedIBAN, setSelectedIBAN] = useState();
    const [selectedBIC, setSelectedBIC] = useState();
    const [selectedAddress, setSelectedAddress] = useState();
    const [selectedEmail, setSelectedEmail] = useState();
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState();


    const [openRGPD, setOpenRGPD] = useState(false);

    const { toast } = useToast();

    useEffect(() => {
        getCurrentUserInformation();
    }, []);

    const renderCooptingRows = () => {
        if (!currentUserInformation || !currentUserInformation.cooptantFor) return null;

        const sortedCooptantFor = currentUserInformation.cooptantFor.sort((a, b) => {
            const dateA = new Date(a.startDate);
            const dateB = new Date(b.startDate);
            return dateA - dateB;
        });

        return sortedCooptantFor.map((cooptingItem, index) => (
            <TableRow key={index} className="border-primary border-4 border-solid">
                <TableCell className={`${(index == 4 || index == 9) && `border-secondary border-b-2 border-solid tdHover`}`}>
                    {cooptingItem.coopted?.firstname} {cooptingItem.coopted?.lastname}
                </TableCell>
                <TableCell className={`${(index == 4 || index == 9) && `border-secondary border-b-2 border-solid tdHover`}`}>
                    {currentUserInformation.retributionModel
                        ? index < 5
                            ? <Label>{currentUserInformation.retributionModel.upToFiveConsultants} %</Label>
                            : index < 10
                                ? <Label>{currentUserInformation.retributionModel.upToTenConsultants} %</Label>
                                : <Label>{currentUserInformation.retributionModel.moreThanTenConsultants} %</Label>
                        : <Label color="gray400" message="error.unfilled" />
                    }
                </TableCell>
                <TableCell className={`${(index == 4 || index == 9) && `border-secondary border-b-2 border-solid tdHover`}`}>
                    {cooptingItem.percentage} <Label size="small">%</Label>{" "}
                </TableCell>
                <TableCell className={`${(index == 4 || index == 9) && `border-secondary border-b-2 border-solid tdHover`}`}>
                    {cooptingItem.monthsDuration === "∞" ? (
                        cooptingItem.monthsDuration
                    ) : (
                        <>
                            <Label>{cooptingItem.monthsDuration}</Label>
                            <Label size="small" color="gray400"> - ({cooptingItem.startDate})</Label>
                        </>
                    )}
                </TableCell>
            </TableRow>
        ));
    };

    if (loading) {
        return (
            <div className="flex gap-firstDegree">
                <span className="loader"></span>
                <Label
                    size="bodySmall"
                    fontWeight="regular"
                    fontFamily=""
                    message="listingConsultant.loading"
                />
            </div>
        );
    }

    if (error) {
        return (
            <PrintErrorMessage error={error}/>
            // <Label
            //     size="bodySmall"
            //     fontWeight="regular"
            //     fontFamily=""
            //     className=" "
            //     message="listingConsultant.error"
            // />
        );
    }

    if (!currentUserInformation) {
        return (
            <Label
                size="bodySmall"
                fontWeight="regular"
                fontFamily=""
                className=" "
                message="listingConsultant.loading"
            />
        );
    }

    const addressLines = [
        currentUserInformation.addressLine1,
        currentUserInformation.addressLine2,
        currentUserInformation.zipCode,
        currentUserInformation.city,
        currentUserInformation.countryCode,
    ].filter(Boolean).join(', ');

    return (
        <div className="flex gap-thirdDegree">
            <Toaster />
            <Avatar
                fallback={(currentUserInformation.firstname || '') + " " + (currentUserInformation.lastname || '')}
                size="medium"
                className="shrink-0 grow-0 "
            />
            <div className="flex flex-col gap-secondDegree grow">
                <div className="flex justify-between">
                    <Label
                        size="h3"
                        fontWeight="bold"
                        message="consultants.dashboard.tabs.information"
                    />
                </div>
                <div className="flex gap-secondDegree">
                    <div className="flex flex-col grow justify-start gap-secondDegree">
                        {/* Email personnel  */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.email"
                            />
                            <aside className="flex gap-firstDegree items-center group">
                                {currentUserInformation.personalEmail ? <Label className="group">
                                    {currentUserInformation.personalEmail} <Copy value={currentUserInformation.personalEmail} />
                                </Label>
                                    :
                                    <Label color="gray400" message="error.unfilled" />}
                                <EditInformationData
                                    inputValue={selectedEmail}
                                    setInputValue={setSelectedEmail}
                                    title="consultants.informations.email"
                                    actualLabel="consultants.informations.actualEmail"
                                    selectLabel="consultants.informations.selectEmail"
                                    data={currentUserInformation.personalEmail}
                                    type="personalEmail"
                                    refetchCurrentUser={refetchCurrentUser}
                                />
                            </aside>
                        </div>

                        {/* Email pro*/}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.proEmail"
                            />
                            <aside className="flex gap-firstDegree items-center group">
                                {currentUserInformation.email ? <Label className="group">
                                    {currentUserInformation.email} <Copy value={currentUserInformation.email} />
                                </Label>
                                    :
                                    <Label color="gray400" message="error.unfilled" />}
                                
                            </aside>
                        </div>
                        {/* phoneNumber */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.phoneNumber" />
                            <aside className="flex gap-firstDegree items-center group">

                                {currentUserInformation.phoneNumber ? <Label className="group">{currentUserInformation.phoneNumber}<Copy value={currentUserInformation.phoneNumber} /></Label> : <Label color="gray400" message="error.unfilled" />}
                                <EditInformationData
                                    inputValue={selectedPhoneNumber}
                                    setInputValue={setSelectedPhoneNumber}
                                    title="consultants.informations.phoneNumber"
                                    actualLabel="consultants.informations.actualPhoneNumber"
                                    selectLabel="consultants.informations.selectPhoneNumber"
                                    data={currentUserInformation.phoneNumber}
                                    type="phoneNumber"
                                    refetchCurrentUser={refetchCurrentUser}
                                />
                            </aside>

                        </div>
                        {/* Address */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.address"
                            />
                            <aside className="flex gap-firstDegree items-center group">
                                {addressLines ? <Label className="group">
                                    {addressLines}
                                    <Copy value={addressLines} />
                                </Label>
                                    :
                                    <Label color="gray400" message="error.unfilled" />}
                                <EditInformationData
                                    inputValue={selectedAddress}
                                    setInputValue={setSelectedAddress}
                                    title="consultants.informations.address"
                                    actualLabel="consultants.informations.actualAddress"
                                    selectLabel="consultants.informations.selectAddress"
                                    data={currentUserInformation.addressLine1 + ", " + currentUserInformation.addressLine2 + ", " + currentUserInformation.zipCode + ", " + currentUserInformation.city + ", " + currentUserInformation.countryCode}
                                    type="address"
                                    refetchCurrentUser={refetchCurrentUser}
                                />
                            </aside>
                        </div>

                        {/* Seniority */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.seniority"
                            />
                            <aside className="flex gap-firstDegree items-center group">
                                {currentUserInformation.entryDate ? <Label className="group">
                                    {currentUserInformation.entryDate} <Copy value={currentUserInformation.entryDate} />
                                </Label>
                                    :
                                    <Label color="gray400" message="error.unfilled" />}
                            </aside>
                        </div>

                        {/* IBAN */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.iban" />
                            <aside className="flex gap-firstDegree items-center group">
                                {currentUserInformation.iban ? <Label className="group">
                                    {currentUserInformation.iban} <Copy value={currentUserInformation.iban} />
                                </Label>
                                    :
                                    <Label color="gray400" message="error.unfilled" />}
                                <EditInformationData
                                    inputValue={selectedIBAN}
                                    setInputValue={setSelectedIBAN}
                                    title="consultants.informations.iban"
                                    actualLabel="consultants.informations.actualIBAN"
                                    selectLabel="consultants.informations.selectIBAN"
                                    data={currentUserInformation.iban}
                                    type="iban"
                                    refetchCurrentUser={refetchCurrentUser}
                                />
                            </aside>
                        </div>

                        {/* BIC */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.bic" />
                            <aside className="flex gap-firstDegree items-center group">
                                {currentUserInformation.bicCode ? <Label className="group">
                                    {currentUserInformation.bicCode} <Copy value={currentUserInformation.bicCode} />
                                </Label>
                                    :
                                    <Label color="gray400" message="error.unfilled" />}
                                <EditInformationData
                                    inputValue={selectedBIC}
                                    setInputValue={setSelectedBIC}
                                    title="consultants.informations.bic"
                                    actualLabel="consultants.informations.actualBIC"
                                    selectLabel="consultants.informations.selectBIC"
                                    data={currentUserInformation.bicCode}
                                    type="bicCode"
                                    refetchCurrentUser={refetchCurrentUser}
                                />
                            </aside>
                        </div>

                        {/* Data privacy */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.dataPrivacy"
                            />
                            <Sheet open={openRGPD} onOpenChange={setOpenRGPD}>
                                <SheetTrigger className="w-min">
                                    <Button
                                        text="global.panelOpening.showMore"
                                        className="w-min"
                                        style="dressedGhost"
                                    />
                                </SheetTrigger>
                                <SheetContent>
                                    <SheetHeader
                                        title={"consultants.informations.dataDeletionInfo.panel.title"}
                                    />
                                    <SheetSlot className="flex">
                                        <DataDeletion />
                                    </SheetSlot>
                                    <SheetFooter
                                        buttonOutlineText={"global.actions.cancel"}
                                        buttonDressedText={"global.actions.request"}
                                        warning={"consultants.informations.dataDeletionInfo.warning.dataDeletionRequest"}
                                        consent={"consultants.informations.dataDeletionInfo.consent.beforeDataDeletion"}
                                        onClickOutline={() => setOpenRGPD(false)}
                                        onClickDressed={(checkboxIsChecked) => {
                                            console.log("user ask data deletion, consent done : ", checkboxIsChecked);
                                        }}
                                    />
                                </SheetContent>
                            </Sheet>
                        </div>
                    </div>
                    <Separator orientation="vertical" />
                    <div className="flex flex-col grow-5 justify-start gap-secondDegree ">
                        {/* CooptedBy */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.cooptedBy"
                            />
                            <aside className="flex gap-firstDegree items-center group">
                                {currentUserInformation.cooptedBy && currentUserInformation.cooptedBy.length > 0 ? <Label className="group">
                                    {currentUserInformation.cooptedBy.map(coopted => coopted.cooptant?.firstname + " " + coopted.cooptant?.lastname).join(", ")} <Copy value={currentUserInformation.cooptedBy.map(coopted => coopted.cooptant?.firstname + " " + coopted.cooptant?.lastname).join(", ")} />
                                </Label>
                                    :
                                    <Label color="gray400" message="error.unfilled" />}
                            </aside>
                        </div>

                        {/* Profile */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.profile"
                            />
                            <aside className="flex gap-secondDegree items-center group">
                                {currentUserInformation.consultantProfile ? <Label className="group">
                                    {currentUserInformation.consultantProfile.name} <Copy value={currentUserInformation.consultantProfile.name} />
                                </Label>
                                    :
                                    <Label color="gray400" message="error.unfilled" />}
                            </aside>
                        </div>

                        {/* Active cooptations */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.activeCooptation"
                            />
                            <span>
                                <Label message="consultants.informations.commissionRate" />
                            </span>
                            <ul className="text-body">
                                <li
                                    className={
                                        (currentUserInformation.cooptantFor.length >= 1
                                            ? "text-primary "
                                            : "text-disabled ") +
                                        (currentUserInformation.cooptantFor.length <= 5 ? "font-bold" : "font-regular")
                                    }
                                >
                                    1 - 5{" "}
                                    <Label
                                        size="body"
                                        message="consultants.informations.consulantLowerCase"
                                    />{" "}
                                    : {currentUserInformation.retributionModel.upToFiveConsultants}%
                                </li>
                                <li
                                    className={
                                        (currentUserInformation.cooptantFor.length > 5
                                            ? "text-primary "
                                            : "text-disabled ") +
                                        (5 < currentUserInformation.cooptantFor.length && currentUserInformation.cooptantFor.length <= 10
                                            ? "font-bold"
                                            : "font-regular")
                                    }
                                >
                                    6 - 10{" "}
                                    <Label message="consultants.informations.consulantLowerCase" />{" "}
                                    : {currentUserInformation.retributionModel.upToTenConsultants}%
                                </li>
                                <li
                                    className={
                                        (currentUserInformation.cooptantFor.length > 10
                                            ? "text-primary "
                                            : "text-disabled ") +
                                        (currentUserInformation.cooptantFor.length > 10 ? "font-bold" : "font-regular")
                                    }
                                >
                                    11+{" "}
                                    <Label message="consultants.informations.consulantLowerCase" />{" "}
                                    : {currentUserInformation.retributionModel.moreThanTenConsultants}%
                                </li>
                            </ul>

                            <Table>
                                <TableHeader>
                                    
                                    {currentUserInformation?.cooptantFor?.length>0 && <TableRow>
                                        <TableHead className="bg-transparent w-[25%] h-auto py-firstDegree" />
                                        <TableHead className="py-2">
                                            <Label message="consultants.dashboard.newCooptation.table.regime" />
                                        </TableHead>
                                        <TableHead className="py-2">
                                            <Label message="consultants.dashboard.newCooptation.table.ownership" />
                                        </TableHead>
                                        <TableHead className="py-2">
                                            <Label message="consultants.dashboard.newCooptation.table.duration" />
                                        </TableHead>
                                    </TableRow>}
                                </TableHeader>
                                <TableBody>{renderCooptingRows()}</TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
