/* eslint-disable react/prop-types */
import { useState } from "react";
import DataCell from "../../molecules/data-cell/DataCell";
import { isNumeric } from "@/utils/formatDateString";

/* eslint-disable react/prop-types */
/**
 * @component TableRow
 *
 * The `TableRow` component renders a row in a data table with customizable features for display and interactivity. It supports data rendering, cell hovering, and conditionally displays edit icons for users with administrative privileges.
 *
 * Key Features:
 * - Displays data in a table row format with customizable styling and interactivity.
 * - Supports conditional display of currency formatting and translation of cell values.
 * - Handles hover and click events to provide interactive feedback.
 * - Conditionally displays edit icons based on user privileges.
 *
 * Props:
 * - @param {Array} rowData - Array of data to be displayed in the row.
 * - @param {boolean} enableHover - Determines if hovering effects are enabled.
 * - @param {number} indexOfCellWithSpecificColor - Index of the cell to be highlighted with a specific color.
 * - @param {string} colorOfSpecificCell - Color to be applied to the specific cell.
 * - @param {Array} tableOfIndexOfCellsToTranslate - Array of indices of cells that should be translated.
 * - @param {number} indexOfCellWithSpecificBorder - Index of the cell to be bordered.
 * - @param {string} borderName - Name of the border style to apply.
 * - @param {boolean} makeTansparentFirstCell - Determines if the first cell should be transparent.
 * - @param {boolean} displayHoveredIcon - Determines if the edit icon should be displayed on hover.
 * - @param {string} className - Additional CSS classes to be applied to the row container.
 * - @param {Function} handleClick - Function to be called when the edit icon is clicked.
 * - @param {string} currency - Currency code for formatting numbers. Defaults to "EUR".
 * - @param {boolean} printCurency - Determines if currency formatting should be applied.
 * - @param {boolean} printToolTip - Determines if tooltips should be displayed.
 * - @param {Array} tableOfIndexCellsWithoutCurrency - Array of indices of cells that should not display currency formatting.
 * - @param {boolean} isPrintedOnStatement - Determines if the row data should be formatted for a statement.
 * - @param {Object} userMonthdata - Additional user month data for context.
 * - @param {string} rowId - Optional ID for the row.
 *
 * Local State:
 * - `idCell` (string|null): The ID of the cell currently hovered over.
 * - `hover` (boolean): Indicates if the row is being hovered over.
 * - `pencilHovered` (boolean): Indicates if the pencil icon is being hovered over.
 *
 * Computed Values:
 * - `prinData(data, index)` - Formats and returns data based on provided conditions (e.g., currency formatting).
 *
 * Usage:
 * - This component is used to display a row of data within a table, with customizable options for display and interactivity. It can be integrated into tables that require dynamic features such as hovering and editing.
 *
 * Example:
 * ```jsx
 * <TableRow
 *   rowData={['January', 5000, 2000, 3000]}
 *   enableHover={true}
 *   indexOfCellWithSpecificColor={2}
 *   colorOfSpecificCell="blue"
 *   tableOfIndexOfCellsToTranslate={[0]}
 *   indexOfCellWithSpecificBorder={1}
 *   borderName="left"
 *   makeTansparentFirstCell={true}
 *   displayHoveredIcon={true}
 *   handleClick={() => console.log('Edit clicked')}
 *   currency="USD"
 *   printCurency={true}
 *   printToolTip={false}
 *   tableOfIndexCellsWithoutCurrency={[2]}
 *   isPrintedOnStatement={false}
 *   userMonthdata={{}}
 *   rowId="row1"
 * />
 * ```
 *
 * Dependencies:
 * - @component DataCell: Component for rendering individual cells within the row.
 * - @mui/icons-material/Create: Icon used for the edit button.
 * - @context AuthContext: Provides user authentication context.
 * - @utils formatCurrency: Utility function for formatting numbers as currency.
 * - @lib utils: Utility functions for handling class names.
 *
 * Functions:
 * - `findTheTranslationString(str)`: Finds the translation string for a given key.
 * - `handleCellHover(rowName, index)`: Handles cell hover events.
 * - `handleMouseEnter()`: Handles mouse enter events for displaying icons.
 * - `handleMouseLeave()`: Handles mouse leave events for hiding icons.
 * - `prinData(data, index)`: Formats and returns data based on the provided conditions.
 *
 * @example
 * const rowData = ['January', 5000, 2000, 3000];
 *
 * <TableRow
 *   rowData={rowData}
 *   enableHover={true}
 *   indexOfCellWithSpecificColor={2}
 *   colorOfSpecificCell="blue"
 *   tableOfIndexOfCellsToTranslate={[0]}
 *   indexOfCellWithSpecificBorder={1}
 *   borderName="left"
 *   makeTansparentFirstCell={true}
 *   displayHoveredIcon={true}
 *   handleClick={() => console.log('Edit clicked')}
 *   currency="USD"
 *   printCurency={true}
 *   printToolTip={false}
 *   tableOfIndexCellsWithoutCurrency={[2]}
 *   isPrintedOnStatement={false}
 *   userMonthdata={{}}
 *   rowId="row1"
 * />
 * ```
 */

import CreateIcon from "@mui/icons-material/Create";
import { cn } from "@/lib/utils";

import { formatCurrency } from "@/utils/transformUserData";
import { useAuth } from "@/context/AuthContext";
import { translationsPaths } from "@/utils/translationPath";
//import { Button } from '@/components/molecules/buttons/button';

//********************ADD  your string on translationPath.js in /utils/ so that it can be translated

// exemple:  {monthData.map((month, index) => (
//         <TableRow
//           key={index}
//           rowData={Object.values(month)}
//           enableHover={true}
//           indexOfCellWithSpecificColor={4}
//           colorOfSpecificCell='primaryLight'
//           indexOfCellWithSpecificBorder={0}
//           borderName={'left'}
//           tableOfIndexOfCellsToTranslate={[0]}
//           makeTansparentFirstCell={true}
//           // onRowDataChange={(newData) => handleMonthDataChange(newData, index)}
//         />
//       ))}
const findTheTranslationString = (str) => {
    let result = "";
    if (str && translationsPaths[str.toLocaleLowerCase()]) {
        result = translationsPaths[str.toLocaleLowerCase()];
    } else {
        result = "consultants.statement.overflow.totranslate"; //by default
    }
    return result;
};
export default function TableRow({
    rowData = [],
    enableHover,
    indexOfCellWithSpecificColor,
    colorOfSpecificCell,
    tableOfIndexOfCellsToTranslate = [],
    indexOfCellWithSpecificBorder,
    borderName,
    makeTansparentFirstCell = false,
    displayHoveredIcon = false,
    className,
    handleClick,
    currency = "EUR",
    printCurency = true,
    printToolTip = false,
  tableOfIndexCellsWithoutCurrency = [],
    isPrintedOnStatement=false,
  userMonthdata,
  rowId
    
}) {
    const { user, hasPrivileges } = useAuth();
    const [idCell, setIdCell] = useState(null);
    //const { setCurrentTableCellHovered } = useConsultantContext();// Initialement, pas de cellule active
    const [hover, setHover] = useState(false);
    const [pencilHovered, setPencilHovered] = useState(false);

    const handleCellHover = (rowName, index) => {
        // On met à jour l'ID de la cellule lorsqu'elle est survolée
        if (enableHover) {
            setIdCell(`${rowName}_${index}`);
            // setCurrentTableCellHovered(`${rowName}_${index}`);
              console.log("Row id:", idCell);
        }
    };

    const handleMouseEnter = () => {
        displayHoveredIcon && setHover(true);
    };
    const handleMouseLeave = () => {
        // displayHoveredIcon &&
        setHover(false);
    };

  const prinData = (data, index) => {
    if (data === 0) return "0.00 €";
     if (isNaN(data) && isPrintedOnStatement) return "0.00 €";
    // si il y'a un soucis sur l'affichage des 00
        return printCurency &&
          data != "" &&
          
          !tableOfIndexCellsWithoutCurrency.includes(index)
          ? formatCurrency(data, currency)
          : data;
    };
    //grid grid-cols-5 gap-betweenText my-betweenText w-full
    return (
      <div
        className={cn(
          "flex flex-row gap-betweenText my-betweenText w-full relative",
          className
        )}
        onClick={() => setHover(true)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {rowData.map((data, index) => (
          <DataCell
            key={index}
            data={
              isNumeric(data) ||
              data === "" ||
              !tableOfIndexOfCellsToTranslate.includes(index)
                ? prinData(data, index)
                : findTheTranslationString(data)
            }
            border={
              data == rowData[indexOfCellWithSpecificBorder] ? borderName : ""
            } // no worry total is translated
            className={`${
              rowData[0] === "annuel" && data != "annuel" ? "bg-bgLight " : ""
            }  h-[26px] w-[20%] }`} // && index!=4 to colored last cell of total
            contentType={index === 0 ? "text" : "numb"}
            background={
              data == "annuel"
                ? ""
                : index === 0 && makeTansparentFirstCell
                ? "transparent"
                : index === indexOfCellWithSpecificColor &&
                  rowData[0] != "annuel"
                ? colorOfSpecificCell
                : "medium"
            }
            padding={index === 0 ? "textAtEnd" : ""}
            id={idCell}
            userMonthdata={userMonthdata}
            printToolTip={printToolTip}
            sizeText={"small"}
            textColor={data == "annuel" ? "white" : "black"}
            //onMouseLeave={handleMouseLeave}
            onHoverChange={() => handleCellHover(rowData[0], index)}
            enableHover={(index === 1 || index === 3) && enableHover}
            Id={idCell}
            //enable hover just item2 and item4
          />
        ))}

        {/* edit a row  just for admin*/}

        {hasPrivileges("ADMIN_") &&
          rowData[0] != "annuel" &&
          hover &&
          displayHoveredIcon && (
            <div
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              className="absolute  ml-[100%] z-50 text-primary  cursor:pointer  bg-grey w-[32px] h-7 rounded-md items-center"
            >
              <CreateIcon className=" ml-1" />
            </div>
          )}
      </div>
    );
}
