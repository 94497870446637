import { Label } from "@/components/atoms/texts/label";
import { useCallback } from "react";

export default function DaysSynthesis({ data, message }) {
    
        return (
            <div className="px-secondDegree py-firstDegree bg-medium rounded-sm flex flex-col">
    
                <Label fontWeight="bold" message={`consultants.timesheet.${message}`} />
    
                <div className="flex gap-betweenText">
                    <Label>{data}</Label>
                    <Label message="consultants.timesheet._days" />
                </div>
    
            </div>
        )
    
}