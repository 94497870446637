import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';
import errorToText from '@/utils/errorToText';

const useAllUsers = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [allUsers, setAllUsers] = useState(null);
    const { user: authUser } = useAuth();

    const getAllUsers = async (uuid) => {
        if (!authUser.strongToken) {
            setError('No strong token available');
            return { data: null, error: 'No strong token available' };
        }

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(window.ENV.BACKEND_URL+'/api/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authUser.strongToken}`
                },
                body: JSON.stringify({
                    query: `
                        query MyQuery {
                            allUsers {
                                uuid
                                trigram
                                firstname
                                lastname
                              }
                        }
                    `
                })
            });
            
						if (response.status == 500) {
                //gestion spécifique d'un cas d'erreur particulier
            }
            
            if (!response.ok) {
                const errMessage = errorToText(response);
                setLoading(false);
                throw new Error(errMessage);
            }

            const data = await response.json();
            if (data.errors) {
                const errMessage = data.errors[0].message;
                setLoading(false);
                throw new Error(errMessage);
            }
// console.log("data: ", data);
            setAllUsers(data.data.allUsers);
            setLoading(false);
            return { data: data.data.allUsers, error: null };
        } catch (err) {
            setError(err.message);
            setLoading(false);
            return { data: null, error: err.message };
        }
    };

    return { getAllUsers, allUsers };
};

export default useAllUsers;