import { useQuery, gql } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Définir la requête GraphQL
const ALL_CV_PROCESSING_QUERY = gql`
  query MyQuery($companyUuid: ID!, $createdAfter: DateTime!) {
    allCvProcessing(cvProcessingRequest: { companyUuid: $companyUuid, createdAfter: $createdAfter }) {
      createdAt
      filename
      status
      uuid
      prospect {
        uuid
        firstname
        lastname
      }
    }
  }
`;
 
const useAllCvProcessing = (actualDate) => {
    // Utiliser le hook `useQuery` d'Apollo pour exécuter la requête
    const { user } = useAuth();
    const companyUuid = user.company.uuid;
     const date = new Date();
    //const [actualDate, setActualDate]=useState()
   

// Soustrait 2 minutes à la date actuelle
    date.setMinutes(date.getMinutes() - 3);

// Convertit la date au format ISO avec un décalage de temps explicite
// const createdAfter= date.toISOString().replace(/\.\d{3}Z$/, "+00:00");
const createdAfter=actualDate
  const {
    loading: loadingAllCvProcessing,
    error: errorAllCvProcessing,
    data,
    refetch,
  } = useQuery(ALL_CV_PROCESSING_QUERY, {
    variables: { companyUuid ,createdAfter },
    fetchPolicy: "network-only",
    //skip: !companyUuid,
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`, // Ajustez selon la méthode d'authentification
      },
    }, // Assure que les données sont toujours récupérées depuis le réseau
  });

  return {
    loadingAllCvProcessing,
    errorAllCvProcessing,
    data: data?.allCvProcessing,
    refetch,
  };
};

export default useAllCvProcessing;
