// Rôle requis : USER_TIMESHEET
// Ce hook permet à un utilisateur de créer une entrée de temps dans une timesheet.
// Seuls les utilisateurs ayant le rôle USER_TIMESHEET peuvent accéder à cette mutation.

import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Mutation pour ajouter une seule entrée de temps
const ADD_TIME_ENTRY = gql`
  mutation AddTimeEntry($timesheetUuid: ID!, $day: Int!, $type: TimeEntryType!, $duration: TimeEntryDuration!) {
    addTimeEntry(newTimeEntry: { timesheetUuid: $timesheetUuid, day: $day, type: $type, duration: $duration }) {
      uuid
      day
      type
      duration
    }
  }
`;

// Mutation pour ajouter plusieurs entrées de temps
const ADD_TIME_ENTRIES = gql`
  mutation AddTimeEntries($newTimeEntries:  [NewTimeEntryDto]!) {
    addTimeEntries(newTimeEntries: $newTimeEntries) {
      uuid
      day
      type
      duration
      timesheetUuid
    }
  }
`;
export const useAddTimeEntry = () => {
    const { user } = useAuth();

    const [addTimeEntryMutation, { loading: loadingSingle, error: errorSingle, data: dataSingle }] = useMutation(ADD_TIME_ENTRY, {
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
    });

    const [addTimeEntriesMutation, { loading: loadingMultiple, error: errorMultiple, data: dataMultiple }] = useMutation(ADD_TIME_ENTRIES, {
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
    });

    // Fonction pour ajouter une seule entrée
    const addTimeEntry = async (timesheetUuid, day, type, duration) => {
        try {
            const response = await addTimeEntryMutation({
                variables: { timesheetUuid, day, type, duration },
            });
            return { data: response.data?.addTimeEntry, error: null };
        } catch (err) {
            return { data: null, error: err.message };
        }
    };

    // Fonction pour ajouter plusieurs entrées
    const addTimeEntries = async (entries) => {
        try {
            const response = await addTimeEntriesMutation({
                variables: { newTimeEntries: entries },
            });
            return { data: response.data?.addTimeEntries, error: null };
        } catch (err) {
            return { data: null, error: err.message };
        }
    };


    return {
        addTimeEntry,
        addTimeEntries,
        loading: loadingSingle || loadingMultiple,
        error: errorSingle || errorMultiple,
        data: dataSingle || dataMultiple,
    };
};
