import React, { useEffect, useState } from 'react';
import NewTimesheetPanel from '../../consultants-vision-admin/timesheet/NewTimesheetPanel';
import Timesheet from '../../consultants-vision-admin/timesheet/Timesheet';
import { Toaster } from '@/components/organisms/feedback/toaster';
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import InViewComponent from '@/components/organisms/InViewComponent';
import { useConsultantTimesheets } from '@/services/admin-services/query/useConsultantTimesheets';
import { useParams } from 'react-router-dom';
import PrintWarningMessage from '@/components/organisms/feedback/PrintWarningMessagee';
import PrintEmptyDataMessage from '@/components/molecules/PrintEmptyDataMessage';

export default function TimesheetTabUser() {
    const [lastSlice, setLastSlice] = useState(1);
    const [timesheetsCopy, setTimesheetsCopy] = useState([]);
    const { loading, error, data: timesheets, refetch } = useConsultantTimesheets(1);
    const { id } = useParams();

    const refetchFromScratch = (n = 1) => {
        // console.log("debug refetchFromScratch")

        setLastSlice(n);
        setTimesheetsCopy([]);
        // pour éviter que refetchFromScratch vide les données
        refetch(n).then((res) => {
            // console.log("debug res = ", res.loading, res.error, res.data);
            if (res.data.consultantTimesheets.timesheets) {
                const timesheetsNotDraft = res.data.consultantTimesheets.timesheets.filter(item => item.status !== "DRAFT");
                setTimesheetsCopy(timesheetsNotDraft);
            }
        });
    };

    const fetchNewSlice = () => {
        if (!loading && timesheetsCopy.length > 0 && timesheets?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };

    useEffect(() => {
        // console.log("debug timesheets", timesheets,"timesheetsCopy",timesheetsCopy, loading,"lastSlice", lastSlice)
        if (!loading && timesheets) {
            const timesheetsNotDraft = timesheets.timesheets.filter(item => item.status !== "DRAFT");
            // console.log("debug timesheetsNotDraft", timesheetsNotDraft)
            if (lastSlice == 1) {
                setTimesheetsCopy(() => [
                    ...timesheetsNotDraft
                ]);
            } else {
                setTimesheetsCopy((prevTimesheets) => [
                    ...prevTimesheets,
                    ...timesheetsNotDraft
                ]);
            }

        }
    }, [timesheets]);
    
useEffect(() => {
    // console.log("debug timesheetsCopy change", timesheetsCopy)
}, [timesheetsCopy]);

    useEffect(() => {
        refetchFromScratch();
    }, [id]);

    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-auto pb-secondDegree px-1">
            <div className="flex gap-secondDegree">
                <Toaster />
            </div>

            {loading && <div className="w-full flex justify-center"><span className="loader" /></div>}
            {error && <PrintErrorMessage error={error} />}
            {(!error && !loading && timesheetsCopy.length > 0) && timesheetsCopy.map((item, index) => (
                <Timesheet key={index} data={item} refetchTimesheets={refetchFromScratch} />
            ))}
            {/* {console.log("debug", !error && !loading && timesheetsCopy.length > 0, error, loading, timesheetsCopy, timesheetsCopy.length)} */}
            {(!error && !loading && timesheetsCopy.length === 0) && <PrintEmptyDataMessage message="error.emptyDataTimesheet" />}

            <InViewComponent inView={fetchNewSlice} />
        </div>
    );
}