import React, { useRef, useState, useEffect } from 'react';

import { Toaster } from '@/components/organisms/feedback/toaster';
import { useCompanyBenefitInvoices } from '@/services/consultant-services/query/useCompanyBenefitInvoices';
import InViewComponent from '@/components/organisms/InViewComponent';
import PrintEmptyDataMessage from '@/components/molecules/PrintEmptyDataMessage';
import CompanyBenefits from '@/components/macro-organisms/tabs/consultant-vision-user/companyBenefit/CompanyBenefits';



export default function CompanyBenefitsTabUser() {
    // const { companyBenefits, error } = useCompanyBenefitData();
    const [lastSlice, setLastSlice] = useState(1);

    const [companyBenefitsCopy, setCompanyBenefitsCopy] = useState([]);
    const { loading, error, data: companyBenefitInvoices, refetch } = useCompanyBenefitInvoices(lastSlice);


    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setCompanyBenefitsCopy([]);
        refetch(n);
        console.log(n)
    };

    const fetchNewSlice = () => {
        console.log("fetchNewSlice", companyBenefitInvoices?.hasNext, lastSlice, "loading =", loading, error, "companyBenefitInvoices =", companyBenefitInvoices, "setCompanyBenefitsCopy =", companyBenefitsCopy.length > 0)
        if (!loading && companyBenefitsCopy.length > 0 && companyBenefitInvoices?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };

    // Concaténation des nouvelles données avec les anciennes
    useEffect(() => {
        if (!loading && companyBenefitInvoices) {
            // Mettre à jour la copie des dépenses avec les nouvelles données
            if (lastSlice == 1) {
                setCompanyBenefitsCopy(() => [
                    ...companyBenefitInvoices?.companyBenefits
                ]);
            } else {
                setCompanyBenefitsCopy((prevCompanyBenefit) => [
                    ...prevCompanyBenefit,
                    ...companyBenefitInvoices?.companyBenefits
                ]);
            }
        }
    }, [companyBenefitInvoices]);

    useEffect(() => {
        refetchFromScratch();
    }, []);
    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-auto pb-secondDegree px-1">

            <Toaster />

            {(!error && !loading && companyBenefitsCopy) && companyBenefitsCopy.map((item, index) => (
                <CompanyBenefits key={index} data={item} dataOwner={false} refetchFromScratch={refetchFromScratch} />
            ))}
            {loading && <div className="w-full flex justify-center"><span className="loader " /></div>}
            {!error && !loading &&
                companyBenefitsCopy?.length == 0 &&
                <PrintEmptyDataMessage message="error.emptyDataCompanyBenefit" />}
            <InViewComponent inView={fetchNewSlice} />

        </div>
    )
}
