import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Définir la mutation GraphQL
const ADD_USER_FROM_PROSPECT = gql`
  mutation TranferUserFromProspeect(
    $prospectUuid: ID!, 
    $email: String!, 
    $roleUuid: ID!, 
    $consultantProfileUuid: ID!, 
    $language: InterfaceLanguage!, 
    $entryDate: Date!, 
    $iban: String, 
    $bicCode: String, 
    $retributionModelUuid: ID
  ) {
    addUserFromProspect(
      newUser: {
        prospectUuid: $prospectUuid,
        email: $email,
        roleUuid: $roleUuid,
        consultantProfileUuid: $consultantProfileUuid,
        language: $language,
        entryDate: $entryDate,
        iban: $iban,
        bicCode: $bicCode,
        retributionModelUuid: $retributionModelUuid
      }
    ) {
      uuid
    }
  }
`;

// Créer le hook personnalisé
export const useAddUserFromProspect = () => {
    const { user } = useAuth();
    const [addUserFromProspect, { data, loading, error }] = useMutation(ADD_USER_FROM_PROSPECT, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
    });

    const addNewUserFromProspect = async (newUserData) => {
        const {
            prospectUuid: prospectUuid,
            email: email,
            roleUuid: roleUuid,
            profile: consultantProfileUuid,
            language: language,
            entryDate: entryDate,
            iban: iban,
            bic: bicCode,
            retribution: retributionModelUuid
        } = newUserData;

        // Validation des champs obligatoires
        if (!prospectUuid || !email || !roleUuid || !consultantProfileUuid || !language || !entryDate) {
            throw new Error("Missing required fields: prospectUuid, email, roleUuid, consultantProfileUuid, language, or entryDate");
        }

        try {
            const result = await addUserFromProspect({
                variables: {
                    prospectUuid,
                    email,
                    roleUuid,
                    consultantProfileUuid,
                    language,
                    entryDate,
                    iban,
                    bicCode,
                    retributionModelUuid
                }
            });

            return result.data.addUserFromProspect.uuid;
        } catch (e) {
            console.error("useAddUserFromProspect Error adding new user from prospect:", e.message);
            return { error: e.message };
        }
    };

    return { addNewUserFromProspect, data, loading, error };
};

export default useAddUserFromProspect;
