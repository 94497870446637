import { useState } from "react";
import { getVatData } from "@/services/admin-services/vatData";
import HandleVats from "./HandleVats";
import AddIcon from "@mui/icons-material/Add";
import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
} from "../../../../templates/sheet";
import Action from "@/components/molecules/buttons/Action";
import { Euro as EuroIcon } from "@mui/icons-material";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { Input } from "@/components/ui/input";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAllVats } from "@/services/admin-services/query/useAllVats";
import { useUpdateVat } from "@/services/admin-services/mutations/useUpdateVat";
import { useAddVat } from "@/services/admin-services/mutations/useAddVat";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import { useDeleteVat } from "@/services/admin-services/mutations/useDeleteVat";
import { isVatRate } from "@/utils/validationInput";
import { useEffect } from "react";


export default function VatSettingsPanel({ ...props }) {
    const [vatData, setVatData] = useState(getVatData());

    const [newVatValue, setNewVatValue] = useState("");
    const [showInput, setShowInput] = useState(false); // Nouveau pourcentage de TVA saisi
    const [isOpen, setIsOpen] = useState();
    const [errorVatValidation, setErrorVatValidation] = useState(false);

    const { loading, error, data, refetch } = useAllVats();
    const { deleteVat, deleteLoading, deleteError } = useDeleteVat(refetch);
    const { inactiveVat, activeVat, inactiveLoading, inactiveError, activeLoading, activeError } = useUpdateVat(refetch);
    const { addVat, addLoading, addError } = useAddVat(refetch);

    useEffect(() => {
        setNewVatValue(undefined);
        setShowInput(false);
        setErrorVatValidation(false);
    }, [isOpen == false])

    const handleCheckboxChange = (uuid, status) => {
        if (uuid && status) {
            switch (status) {
                case "ACTIVE":
                    handleInactiveVat(uuid)
                    break;
                case "INACTIVE":
                    handleActiveVat(uuid)
                    break;
                default:
                    break;
            }
        }
    };

    const handleAddVat = async () => {
        const isValid = isVatRate(newVatValue);
        setErrorVatValidation(!isValid);
        if (isValid) {
            try {
                const rate = parseFloat(newVatValue);
                if (isNaN(rate) || rate <= 0) {
                    console.error("Invalid VAT rate");
                    return;
                }
                await addVat({ variables: { rate } });
                setNewVatValue(""); // Reset the input after adding VAT
                setShowInput(true)
            } catch (e) {
                console.error("Error adding VAT:", e);
            }
        }

    };
    const handleDeleteVat = async (uuid) => {
        try {
            await deleteVat({ variables: { uuid } });
        } catch (e) {
            console.error("Error deleting VAT:", e);
        }
    };
    const handleInactiveVat = async (uuid) => {
        try {
            await inactiveVat({ variables: { uuid } });
        } catch (e) {
            console.error("Error inactivating VAT:", e);
        }
    };

    const handleActiveVat = async (uuid) => {
        try {
            await activeVat({ variables: { uuid } });
        } catch (e) {
            console.error("Error activating VAT:", e);
        }
    };

    return (
        <div>
            <Sheet open={isOpen} onOpenChange={setIsOpen}>
                <SheetTrigger asChild >
                    <button className="w-full">
                        {" "}
                        <Action
                            icon={<EuroIcon />}
                            text="consultants.dashboard.actions.vat"
                            initialVariant={isOpen ? "active" : "default"}
                            {...props}
                        />
                    </button>
                </SheetTrigger>
                <SheetContent>
                    <SheetHeader
                        title={"consultants.dashboard.actions.vat"}
                        subheader="consultants.statement.vatSettings.autoSaved"
                    />
                    <SheetSlot>
                        {loading || inactiveLoading || activeLoading || addLoading && <span className="loader"></span>}
                        {/* {error || inactiveError || activeError || addError || deleteError && <PrintErrorMessage error={error || inactiveError || activeError || addError || deleteError} />} */}
                        <PrintErrorMessage error={error || inactiveError || activeError || addError || deleteError} />
                        {(!loading && !error) &&
                            <div className=" flex flex-col p-secondDegree gap-thirdDegree">
                                {/*Insert here your component selectOrAdd*/}
                                <Label
                                    message="consultants.statement.vatSettings.selectOrAdd"
                                    size="body"
                                />
                                <HandleVats
                                    vatData={data}
                                    onCheckboxChange={handleCheckboxChange}
                                    onDeleteVatRate={handleDeleteVat}
                                />
                                <div>
                                    {!showInput && <Button
                                        showLeftIcon={true}
                                        leftIcon={<AddIcon fontSize="small" />}
                                        showText={true}
                                        text="consultants.statement.vatSettings.addNewVAT"
                                        sizePadding="default"
                                        style="ghost"
                                        onClick={() => setShowInput(true)}
                                    />}
                                    {showInput && (
                                        <div className="flex flex-col gap-firstDegree">
                                            <Label message="consultants.statement.vatSettings.addNewVAT" size="h3"></Label>
                                            <Label message="global.actions.inputValue" size=""></Label>
                                            <div className="flex gap-firstDegree relative items-center mt-2">
                                                <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={() => setShowInput(!showInput)}></Button>
                                                <Input
                                                    type="number"
                                                    step="0.01"  // Permet de saisir des nombres décimaux
                                                    onWheel={(e) => e.target.blur()}
                                                    value={newVatValue}
                                                    onChange={(e) => setNewVatValue(e.target.value)}
                                                    className="rounded-sm bg-white text-right"
                                                    placeholder="-- % "
                                                ></Input>
                                                <Button onClick={handleAddVat} text="global.actions.add" style="ghost" sizePadding="medium"></Button>
                                            </div>
                                            {errorVatValidation && <Label className="text-error" message={"error.formatVat"} />}
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </SheetSlot>
                </SheetContent>
            </Sheet>
        </div >
    );
}
