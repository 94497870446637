/* eslint-disable react/prop-types */
import React from "react";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import { isNumeric } from "@/utils/formatDateString";
import { Label } from "@/components/atoms/texts/label";
//the bg should be defined
/**
 * Chip Component: a component for displaying "chip" elements.
 * Chips can be used to display tags, categories, badges, etc.
 *
 * @component
 * @param {string|number} item - The content of the chip to display. Can be a string or a number.
 * @param {string} [rounded="large"] - Rounded style of the chip. Options: "rounded", "large".
 * @param {string} [bg="default"] - Background color of the chip. Options: "selected", "default", "medium".
 * @param {string} [border="default"] - Border style of the chip. Options: "outline", "default", "solid"
 * @param {string} [fontWeight="font-600"] - Font weight of the chip's text. Options: "extralight", "regular", "semibold", "bold".
 * @param {string} [size="body"] - Text size of the chip. Options: "h1", "h2", "h3", "h4", "h5", "h6", "h7", "h8", "body", "small", "caption".
 * @param {string} [className] - Additional CSS class to customize the style of the chip.
 * @param {...any} props - Other HTML properties to pass to the div component.
 * @returns {JSX.Element} Chip Component with the specified content.
 *
 * @example
 * // Basic Usage:
 * <Chip item="Tag" />
 *
 * @example
 * // Usage with customization:
 * <Chip
 *   item="Category"
 *   isSelected={true}
 *   rounded="rounded"
 *   bg="selected"
 *   border="outline"
 *   fontWeight="semibold"
 *   size="h6"
 *   className="customClassName"
 * />
 */
const chipVariants = cva(
  "text-center px-firstDegree py-betweenText", // Ajout de 'relative' ici pour tous les variants
  {
    variants: {
      rounded: {
        chip: "rounded-filterItemBorder  text-center border-solid px-firstDegree",
        large: "rounded-[50px] px-chipPadding",
      },
      bg: {
        selected: "bg-primary",
        medium: "bg-medium",
        default: "bg-white",
        
        success: "bg-success",
        success20: "bg-success20",

        error: "bg-error",
        error20: "bg-error20",

        information: "bg-information",
        information20: "bg-information20",

        warning: "bg-warning",
        warning20: "bg-warning20",
      },
      border: {
        outline: "outline-secondary outline outline-2",
        solid: "outline-primary outline outline-2",
        success: "border-success border border-dashed ",

        default: "",
      },
      size: {
        h1: "text-h1",
        h2: "text-h2",
        h3: "text-h3",
        h4: "text-h4",
        h5: "text-h5",
        h6: "text-h6",
        h7: "text-h7",
        h8: "text-h8",
        body: "text-body",
        small: "text-bodySmall",
        caption: "text-caption",
      },
      fontWeight: {
        extralight: "font-extralight",
        regular: "font-regular",
        semibold: "font-semibold",
        bold: "font-bold",
      },
    },
    defaultVariants: {
      rounded: "large",
      bg: "default",
      border: "default",
      size: "body",
    },
  }
);

export default function Chip({
  item,
  printPercentage=false,
  rounded,
  bg,
  border,
  fontWeight = "font-600",
  size,

  className,
  ...props
}) {
  return (
    <div
      className={cn(
        chipVariants({ rounded, bg, border, size, fontWeight }),
        className,
        isNumeric(item) ? "w-[70px]" : ""
      )}
      {...props}
    >
      {isNumeric(item) ? (
        <span className={cn(size, fontWeight)}>{item} { printPercentage && '%'}</span>
      ) : (
        <Label message={item} fontWeight={fontWeight} size={size} />
      )}
    </div>
  );
}
