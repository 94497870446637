/* eslint-disable react/prop-types */
/**
 * @component TableProspects
 *
 * The `TableProspects` component displays a table of prospect records with various attributes. It includes functionality for editing, deleting, and transferring prospects, as well as displaying their details in a structured format.
 *
 * Key Features:
 * - Displays a table of prospects with columns for various attributes.
 * - Provides options to edit, delete, and transfer prospects.
 * - Shows a progress indicator for incomplete prospects.
 * - Handles loading states and errors gracefully.
 * - Supports pagination with `loadMoreProspects` and refetching data.
 *
 * Props:
 * - @param {boolean} isDoingAction - Indicates whether an action (e.g., loading) is in progress.
 * - @param {Function} setIsDoingAction - Function to update the action state.
 * - @param {boolean} loading - Indicates if data is being loaded.
 * - @param {Object} error - Error object if there is an issue with data fetching.
 * - @param {Object} data - Data object containing the list of prospects.
 * - @param {Function} loadMoreProspects - Function to load more prospects when needed.
 * - @param {Function} refetch - Function to refetch the data.
 *
 * Local State:
 * - `sliceNumber`: Integer representing the current slice of data to fetch.
 * - `shouldUpdate`: Boolean indicating if the table should be updated based on action state.
 * - `shouldStopRefecth`: Boolean to control whether to stop refetching data.
 * - `mustRefectch`: Boolean indicating if a refetch is required.
 *
 * Usage:
 * - This component is used to display and manage a list of prospects. It includes features for interacting with individual prospects, such as editing and deleting, and provides visual feedback based on their attributes.
 *
 * Example:
 * ```jsx
 * <TableProspects
 *   isDoingAction={isLoading}
 *   setIsDoingAction={setLoading}
 *   loading={dataLoading}
 *   error={fetchError}
 *   data={prospectData}
 *   loadMoreProspects={loadMore}
 *   refetch={refetchData}
 * />
 * ```
 *
 * Dependencies:
 * - @component EditCVPanel: Component for editing a prospect's CV.
 * - @component Chip: Component for displaying a progress indicator.
 * - @component PopUpTransfertProspect: Component for transferring a prospect.
 * - @component PopupOverDelete: Component for confirming prospect deletion.
 * - @component PrintErrorMessage: Component for displaying error messages.
 * - @component PrintNumberOfProspectInProgress: Component for showing the number of prospects in progress.
 * - @component PrintTableCellContent: Component for rendering table cell content.
 * - @mui/icons-material: Icons used in buttons.
 * - @mui/material/Tooltip: Tooltip component for additional information.
 * - @hook useState: React hook for managing local state.
 * - @hook useEffect: React hook for side effects and data fetching.
 * - @hook useNavigate: Hook for programmatic navigation.
 * - @services/admin-services/mutations/useDeleteProspect: Hook for deleting a prospect.
 *
 * Functions:
 * - `handleClickDelete(uuid)`: Handles the delete action for a prospect. Receives the prospect's UUID as an argument.
 * - `extractDate(dateTimeString)`: Extracts and returns the date part from a date-time string.
 *
 * @example
 * const handleDeleteProspect = (uuid) => {
 *   console.log(`Deleting prospect with UUID: ${uuid}`);
 * };
 *
 * <TableProspects
 *   isDoingAction={isLoading}
 *   setIsDoingAction={setLoading}
 *   loading={dataLoading}
 *   error={fetchError}
 *   data={prospectData}
 *   loadMoreProspects={loadMore}
 *   refetch={refetchData}
 * />
 */

import { useState, useEffect } from "react";
import EditCVPanel from "@/components/macro-organisms/panels/panels-admin/EditCV/EditCVPanel";
import Chip from "@/components/molecules/buttons/Chip";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/molecules/TableShadeCn";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { useNavigate } from "react-router-dom";
import PopUpTransfertProspect from "@/components/macro-organisms/PopUpTransfertProspect";
import useDeleteProspect from "@/services/admin-services/mutations/useDeleteProspect";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import PopupOverDelete from "./PopupOverDelete";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import PrintNumberOfProspectInProgress from "./PrintNumberOfProspectInProgress";
import PrintTableCellContent from "./PrintTableCellContent";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

const tableTitle = {
    name: "consultants.statement.prospectConsultantPanel.formPlaceHolder.name",
    surname: "consultants.statement.prospectConsultantPanel.formPlaceHolder.firstName",
    birth: "consultants.statement.prospectConsultantPanel.formPlaceHolder.dayOfBirth",
    gender: "global.information.genre",
    personalEmail:
        "consultants.statement.prospectConsultantPanel.formPlaceHolder.personalEmail",
    coopter: "consultants.informations.cooptedBy",
    iconsColumn: " ",
    creation: "global.information.creation",
    completion: "global.information.completion",
};
export default function TableProspects({ isDoingAction, setIsDoingAction, loading, error, data, loadMoreProspects, refetch, shouldPrintNumberOfProspects , setShouldPrintNumberProspects}) {
    const [sliceNumber, setSliceNumber] = useState(1);
    const [shouldUpdate, setShouldUpdate] = useState(isDoingAction)

    // const { loading, error, data, loadMoreProspects, refetch } = useAllProspects(sliceNumber);
    const [t] = useTranslation("global");
    const text = t("prospects.helptext");
    const [shouldStopRefecth, setShouldStop] = useState(false)
    const [mustRefectch, setMustRefecth] = useState(false);



    const {
        handleDeleteProspect,
        loading: loadingDelete,
        error: errorDelete,
    } = useDeleteProspect();
    const navigate = useNavigate();


    useEffect(() => {
        refetch()
        // console.log("appel miag")
    }, [isDoingAction, setIsDoingAction]);

    // useEffect(() => {
    //     refetch()
    //     console.log("appel miag")
    // }, []);

    useEffect(() => {
        refetch();
    }, [sliceNumber, refetch]);

    const handleClickDelete = async (uuid) => {
        try {
            await handleDeleteProspect(uuid);
            //alert("Prospect deleted successfully");
            refetch();
            // Vous pouvez également ajouter ici du code pour rafraîchir la liste des prospects ou rediriger l'utilisateur
        } catch (err) {
            console.log(`Failed to delete prospect: ${err.message}`);
        }
    };



    if (loading) return <span className="loader"></span>;
    if (error) return <PrintErrorMessage error={error} />;

    return (
        <div>
            <PrintNumberOfProspectInProgress
                setIsDoingAction={setIsDoingAction}
                refetchTableData={refetch}
                shouldStopRefecth={true}
                shouldPrintNumberOfProspects={shouldPrintNumberOfProspects}
                setShouldPrintNumberProspects={setShouldPrintNumberProspects}
                
            />

            {errorDelete && <PrintErrorMessage error={errorDelete} />}
            <Table className="w-full ">
                <TableHeader>
                    <TableRow className="text-bodySmall">
                        {Object.values(tableTitle).map((title, index) => (
                            <TableHead key={index} className=" h-auto ">
                                <Label message={title} fontWeight={"bold"} />
                            </TableHead>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {data &&
                        
                        data.map((prospect) => (
                            <TableRow key={prospect.uuid} className="group relative  ">
                                <TableCell className="tdHover min-w-[100px] w-1/12 ">
                                    {prospect.completionPercentage < 100 ? (
                                        <div className="flex flex-row items-center gap-firstDegree  ">
                                            {/* <div className="flex items-center justify-center p-chipPadding font-bold w-[35px] h-[35px]  rounded-[20px] bg-warning"></div> */}
                                            <Tooltip title={text} placement="top" arrow>
                                                <WarningRoundedIcon
                                                color="warning"
                                                    className="  "
                                                    size="small"
                                                />
                                            </Tooltip>

                      <PrintTableCellContent cellContent={prospect.lastname} />
                    </div>
                  ) : (
                    prospect.lastname
                  )}
                </TableCell>
                <TableCell className="tdHover min-w-[100px] w-1/12">
                  <PrintTableCellContent cellContent={prospect.firstname} />
                </TableCell>
                <TableCell className="tdHover min-w-[80px] w-1/12">
                  <PrintTableCellContent cellContent={prospect.birthdate} />
                </TableCell>
                <TableCell className="tdHover min-w-[60px] w-1/12">
                 <Label> {prospect.gender === "FEMALE" && "F"}</Label>
                 <Label>{prospect.gender === "MALE" && "M"}</Label>
                   <Label>{prospect.gender === "OTHER" && "O"}</Label>
                  {prospect.gender == null && (
                    <PrintTableCellContent cellContent={prospect.gender} />
                  )}
                </TableCell>
                <TableCell className="tdHover min-w-[160px] w-2/12">
                  <PrintTableCellContent cellContent={prospect.personalEmail} />
                 
                </TableCell>
                <TableCell
                  className="tdHover min-w-[70px] w-1/12 underline cursor-pointer  "
                  onClick={() =>
                    navigate(`/consultant/${prospect.cooptedBy.uuid}/statement`)
                  }
                >
                  {prospect.cooptedBy && prospect.cooptedBy.trigram}
                </TableCell>
                <TableCell className="tdHover min-w-[100px] w-3/12">
                  <div className=" flex-row gap-1 items-center justify-center hidden group-hover:flex">
                   
                                        <PopUpTransfertProspect
                                            prospect={prospect}
                                            refetch={refetch}
                                            disabled={prospect.completionPercentage < 100}
                                        />
                                        <div className="w-auto">
                                            <EditCVPanel
                                                showText={false}
                                                user={prospect}
                                                isDoingAction={isDoingAction}
                                                setIsDoingAction={setIsDoingAction}
                                                setShouldStop={setShouldStop}
                                            />
                                        </div>

                                        <PopupOverDelete
                                            handleClickDelete={handleClickDelete}
                                            prospect={prospect}
                                        />

                                        <Button
                                            text="consultants.dashboard.tabs.cv"
                                            sizePadding="small"
                                            onClick={() => navigate(`cv/${prospect.uuid}`)}
                                        />
                                    </div>
                                </TableCell>

                <TableCell className="tdHover min-w-[80px] w-1/12">
                  {extractDate(prospect.createdAt)}
                </TableCell>
                <TableCell className="tdHover min-w-[90px] w-1/12">
                  <Chip
                    item={prospect.profileDensityScore * 10}
                    // isSelected={true}
                    printPercentage={true}
                    rounded="large"
                    bg={
                      prospect.profileDensityScore * 10 >= 80
                        ? "success"
                        : "warning"
                    }
                    //border="outline"
                    fontWeight="semibold"
                    size="h6"
                    className="customClassName"
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
}


function extractDate(dateTimeString) {
    // Vérifie si le format de la date est correct
    if (typeof dateTimeString !== 'string' || !dateTimeString.includes('T')) {
        throw new Error('Invalid date-time format');
    }

    // Sépare la date de l'heure et retourne uniquement la date
    const datePart = dateTimeString.split('T')[0];
    return datePart;
}

// Exemple d'utilisation
// const dateTimeString = '2024-08-03T14:26:13';
// const dateOnly = extractDate(dateTimeString);
// console.log(dateOnly); // "2024-08-03"

