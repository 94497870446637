import Chip from "@/components/molecules/buttons/Chip";
//category : 0 is salary, 1 is bonus
//status : 0 is read, 1 is unread


export default function ReadingStatus({ type }) {


    switch (type) {
        case "DRAFT":
            return (<Chip item="consultants.timesheet.draft" bg="medium" fontWeight="bold" />);
        case "SUBMITTED":
            return (<Chip item="consultants.timesheet.submitted" bg="information" fontWeight="bold" />);
        case "APPROVED":
            return (<Chip item="consultants.timesheet.approved" bg="success" fontWeight="bold" />);
        case "REJECTED":
            return (<Chip item="consultants.timesheet.rejected" bg="error" fontWeight="bold" />);
        default:
            break;
    }
    
    
    
    



}