/* eslint-disable react/prop-types */
/**
 * @component AddDescription
 *
 * The `AddDescription` component provides a form for adding or editing descriptions. It includes functionality for form validation, submission, editing, and deletion of descriptions.
 *
 * Key Features:
 * - Show or hide the form for adding or editing descriptions.
 * - Handle form submission with validation and server interactions.
 * - Enable editing of existing descriptions or adding new ones.
 * - Confirm and execute deletion of descriptions if needed.
 * - Display errors and handle server response errors.
 *
 * Props:
 * - @param {Object} description - The description object to edit (if editing). Includes `description` text and `uuid`.
 * - @param {Boolean} showForm - Boolean to control the visibility of the form.
 * - @param {Function} setShowForm - Function to toggle the visibility of the form.
 * - @param {Boolean} [editingDescription=false] - Boolean to indicate if the description is being edited.
 * - @param {Function} setEditingDescription - Function to toggle the editing state of the description.
 * - @param {Object} user - User object containing user details necessary for API interactions.
 * - @param {Function} setIsDoingAction - Function to toggle the state indicating if an action (such as adding or editing) is in progress.
 * - @param {Function} setFormIsValid - Function to set the form's validity state.
 * - @param {Function} setServerResponseIserror - Function to set the state for server response errors.
 * - @param {Function} setErrors - Function to set and display error messages.
 *
 * Local State:
 * - `isDescriptionEmpty`: Boolean to indicate if the description field is empty.
 * - `confirmDelete`: Boolean to confirm the deletion of a description.
 * - `formData`: Object holding the current form data, including `description`, `language`, and `descriptionUuid`.
 * - `erros`: Object to store error messages for form fields.
 * - `listOfInvalidInput`: Array of invalid inputs in the form.
 *
 * Functions:
 * - `handleChange`: Updates `formData` when form inputs change.
 * - `handleSubmit`: Handles form submission, performs validation, and interacts with the API for adding, updating, or deleting descriptions.
 * - `handleCancel`: Cancels the current action, resets editing state, and hides the form.
 * - `handleDelete`: Confirms and executes the deletion of a description.
 *
 * Usage:
 * - This component is used for managing the addition and editing of descriptions within a user profile or similar context. It integrates with API services to handle data updates and deletions.
 *
 * Example:
 * ```jsx
 * <AddDescription
 *   description={currentDescription}
 *   showForm={isFormVisible}
 *   setShowForm={setFormVisible}
 *   editingDescription={isEditing}
 *   setEditingDescription={setEditing}
 *   user={currentUser}
 *   setIsDoingAction={setLoading}
 *   setFormIsValid={setFormValid}
 *   setServerResponseIserror={setServerError}
 *   setErrors={setFormErrors}
 * />
 * ```
 *
 * Dependencies:
 * - @component Button: Provides action buttons (Save, Cancel, Delete).
 * - @component SaveIcon, DeleteIcon, ClearIcon: Icons used for respective actions.
 * - @component TextArea: Input field for entering description text.
 * - @component Label: Displays labels and messages.
 * - @component Form: Wrapper component for form functionalities.
 * - `react-hook-form`: For form handling and validation.
 * - `validateFormData`: Function to validate form data.
 * - `useUpdateProspect`, `useUpdateProspectUpdateItems`, `useUpdateProspectDeleteItems`: Custom hooks for API interactions related to description updates.
 * - `getAppLanguage`: Function to get the current application language.
 */

import { Button } from "@/components/molecules/buttons/button";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import { validateFormData } from "./validateForm";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import TextArea from "@/components/molecules/inputs/TextArea";
import { Label } from "@/components/atoms/texts/label";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import getAppLanguage from "@/utils/getAppLanguage";
import useUpdateProspectUpdateItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectUpdateItems";
import useUpdateProspect from "@/services/admin-services/mutations/updateProspect/useUpdateProspectAddItems";
import useUpdateProspectDeleteItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectDeleteItems";
export default function AddDescription({
    description,
    showForm,
    setShowForm,
    editingDescription = false,
  
    setEditingDescription,
    user,
    setIsDoingAction,
    setFormIsValid,
    setServerResponseIserror,
    setErrors,
}) {
    const form = useForm();
    const appLanguage = getAppLanguage();
    const [isDescriptionEmpty, setisDescription] = useState(true);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const { addNewDescription, errorAddDescription, loadingAddDescription } =
        useUpdateProspect();
    const { updateDescription, errorUpdatedescription } =
        useUpdateProspectUpdateItems();
    const { deleteDescription, errorDeleteDescription } =
        useUpdateProspectDeleteItems();
    const [formData, setFormData] = useState({
        description: description ? description.description : "",
        language: appLanguage,
        descriptionUuid: description ? description.uuid : "",
    });
    const [erros, setErros] = useState({
        description: "",
    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const handleChange = (e, name) => {
        const { value } = e.target;

        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // console.log(formData);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErros);

        setlistOfInvalidInput(isValid);
        if (formData.description.length > 0) setisDescription(false);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            if (editingDescription) {
                await updateDescription(formData, user.uuid);
                setIsDoingAction(true);
                if (errorUpdatedescription) {
                    setErrors(errorUpdatedescription);
                    setFormIsValid(false);
                    setServerResponseIserror(true);
                } else {
                    setFormIsValid(true);
                }
                // Si nous sommes en mode édition, mettez à jour la description existante
            } else {
                // Sinon, ajoutez simplement la nouvelle description à la liste
                //    console.log("id ",id)
                const result = await addNewDescription(
                    user.uuid,
                    appLanguage,
                    formData.description
                );
                setIsDoingAction(true);
                if (result.error) {
                    console.error("Failed to add description:", result.error);
                    alert("Add successfully ");
                    setFormIsValid(false);

                    setServerResponseIserror(true);
                    setEditingDescription(false);
                    if (errorAddDescription) setErrors(errorAddDescription);
                } else {
                    setFormIsValid(true);
                    console.log(errorAddDescription);
                }
            }

            setShowForm(false);
        } else {
            console.log("invalid form");
            console.log("value invalid", isValid);
            console.log("form data", formData);
        }
        setConfirmDelete(false);
    };
    const handleCancel = () => {
        setEditingDescription(false);
        setShowForm(false);
        setConfirmDelete(false)
    };
    const handleDelete = async (uuid) => {
        try {
            // Replace with actual project UUID to delete
            const updatedProjects = await deleteDescription(user.uuid, uuid);
            //alert("Updated Projects:", updatedProjects);
            setIsDoingAction(true);

            if (errorDeleteDescription) {
                setErrors(errorDeleteDescription);
                setFormIsValid(false);
                setServerResponseIserror(true);
            } else {
                setFormIsValid && setFormIsValid(true);
            }
        } catch (err) {
            // alert("Failed to delete project:", err);
            console.error("Failed to delete project:", err);
        }
        setConfirmDelete(false);
    };
    return (
        <div className="flex flex-col ">
            {showForm && (
                <div className="w-full">
                    <Form {...form}>
                        <form
                            onSubmit={handleSubmit}
                            className={` shadow-md bg-lighter  border-sm border-medium  p-secondDegree`}
                        >
                            <TextArea
                                value={formData.description}
                                onChange={(e) => handleChange(e, "description")}
                                border={isDescriptionEmpty ? "border-error" : ""}
                                className={
                                    listOfInvalidInput.includes("description") && "border-error"
                                }
                                required
                                placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.description"
                                rows={4} // Nombre de lignes par défaut
                                cols={40} // Nombre de colonnes par défaut
                            // Ajoutez d'autres attributs ou styles au besoin
                            />
                            <div className="flex flex-row gap-[12px] items-center w-full">
                                {editingDescription && (
                                    <>
                                        {!confirmDelete ? (
                                            <>
                                                <Button
                                                    onClick={() => setConfirmDelete(true)}
                                                    showLeftIcon={true}
                                                    showText={false}
                                                    //sizePadding="firstDegree"
                                                    leftIcon={
                                                        <DeleteIcon className="text-primary cursor-pointer" />
                                                    }
                                                    style="ghost"
                                                />
                                                <Button
                                                    showLeftIcon={true}
                                                    leftIcon={<ClearIcon fontSize="small" />}
                                                    showText={true}
                                                    text="global.actions.cancel"
                                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                                    style="outline"
                                                    className={`${confirmDelete
                                                        ? "w-[17%]"
                                                        : "font-bold border-primary w-full"
                                                        }`}
                                                    showToolTip={confirmDelete ? true : false}
                                                    onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                                                />
                                                <Button
                                                    showLeftIcon={true}
                                                    leftIcon={<SaveIcon fontSize="small" />}
                                                    showText={true}
                                                    text="global.actions.save"
                                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                                    style="fill"
                                                    showToolTip={confirmDelete ? true : false}
                                                    className={`${confirmDelete ? "w-[16%]" : "font-bold w-full"
                                                        }`}
                                                    type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                                                />
                                                
                                            </>
                                        ) : (
                                            <div
                                                className="flex flex-row gap-2 items-center"
                                                
                                            >
                                                <Label message="consultants.statement.vatSettings.confirmDelete" />
                                                <Button
                                                    showLeftIcon
                                                    className="text-error border-error rounded-sm bg-white"
                                                    leftIcon={
                                                        <DeleteOutlineIcon
                                                            className="text-error"
                                                            fontSize="small"
                                                        />
                                                    }
                                                    // showText={false}
                                                    text={"global.actions.confirmDelete"}
                                                    sizePadding="small"
                                                    style="outline"
                                                    onClick={() => handleDelete(description.uuid)}
                                                />
                                                <Button
                                                    className="text-primary border-primary py-2 rounded-sm"
                                                    text={"global.actions.cancel"}
                                                    sizePadding="small"
                                                    style="ghost"
                                                    onClick={() => setConfirmDelete(false)}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                                {!editingDescription && <Button
                                    showLeftIcon={true}
                                    leftIcon={<ClearIcon fontSize="small" />}
                                    showText={true}
                                    text="global.actions.cancel"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="outline"
                                    className={`${confirmDelete
                                        ? "w-[17%]"
                                        : "font-bold border-primary w-full"
                                        }`}
                                    showToolTip={confirmDelete ? true : false}
                                    onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                                />}
                                {!editingDescription && <Button
                                    showLeftIcon={true}
                                    leftIcon={<SaveIcon fontSize="small" />}
                                    showText={true}
                                    text="global.actions.save"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="fill"
                                    showToolTip={confirmDelete ? true : false}
                                    className={`${confirmDelete ? "w-[16%]" : "font-bold w-full"
                                        }`}
                                    type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                                />}
                            </div>
                        </form>
                    </Form>
                </div>
            )}
        </div>
    );
}
