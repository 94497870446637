import { useAuth } from "@/context/AuthContext";
import { useState } from "react";
import errorToText from "@/utils/errorToText";
import { useEffect } from "react";
const useStatement = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [allData, setAllData] = useState(null);

  const getAllStatamentData = async (token, year) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        window.ENV.BACKEND_URL+"/api/graphql",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: `query {
                        statement(statementRequest: {year: ${year}}) {
                               dailyRateClient
                                dailyRateConsultant
                                daysSick
                                daysWorked
                                employeeContributions
                                employerCost
                                employerContributions
                                expenses
                                grossSalary
                                companyBenefit
                                netSalary
                                overflow
                                statementMonths {
                                  consultantServiceAmount
                                  cooptation
                                  currency
                                  dailyRateClient
                                  dailyRateConsultant
                                  daysSick
                                  daysWorked
                                  employeeContributions
                                  employerCost
                                  employerContributions
                                  expenses
                                  grossSalary
                                  companyBenefit
                                  month
                                  netSalary
                                  overflow
                                  overflowBalance
                                  taxes
                                  uuid
                                  cooptations {
                                    amount
                                    cooptedName
                                    cooptedUuid
                                    currency
                                    percentage
                                    uuid
                                  }
                                }
                                taxes
                                year
                        }
                      }
                    `,
          }),
        }
      );

      if (!response.ok) {
        const errMessage = errorToText(response);
        setLoading(false);
        throw new Error(errMessage);
      }

      const data = await response.json();
      if (data.errors) {
        const errMessage = data.errors[0].message;
        setLoading(false);
        throw new Error(errMessage);
      }

      setAllData(data.data.statement);
      setLoading(false);
      return { data: data.data.statement, error: null };
    } catch (err) {
      setError(err.message);
      setLoading(false);
      return { data: null, error: err.message };
    }
  };

  return { getAllStatamentData, allData, error };
};

export default useStatement;
