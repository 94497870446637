import React from 'react';

export function useInitials(name) {
    if (!name) return undefined
    return React.useMemo(() => {
        return name
            .split(' ')
            .map(part => part[0])
            .join('')
            .toUpperCase();
    }, [name]);
}
