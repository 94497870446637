import moment from 'moment';

/**
 * Transforms timeEntries to events format compatible with react-big-calendar
 * @param {Array} timeEntries - The timeEntries data array
 * @param {number} year - The year to filter the entries
 * @param {number} month - The month to filter the entries
 * @returns {Array} - Array of event objects
 */
const transformTimeEntriesToEvents = (timeEntries, year, month) => {
    // console.log("transformTimeEntriesToEvents  ", timeEntries, year, month);
    
    // Ensure year and month are numbers
    const yearNum = parseInt(year, 10);
    const monthNum = parseInt(month, 10);

    const timeEntriesFiltered = timeEntries
        .map(entry => {
            let start = moment(`${yearNum}-${monthNum}-${entry.day}`, 'YYYY-MM-DD').toDate();
            let end = start;

            if (entry.duration === 'FULL_DAY') {
                end = moment(start).endOf('day').toDate();
            } else if (entry.duration === 'HALF_DAY_MORNING') {
                end = moment(start).set({ hour: 12, minute: 0 }).toDate();
            } else if (entry.duration === 'HALF_DAY_AFTERNOON') {
                start = moment(start).set({ hour: 12, minute: 0 }).toDate();
                end = moment(start).endOf('day').toDate();
            }
            // console.log("transformTimeEntriesToEvents entry is ", entry);

            return {
                id: entry.uuid,
                timesheetUuid: entry.timesheetUuid,
                title: entry.type,
                start,
                end,
                bgColor: getBgColor(entry.type),
                year: yearNum,
                month: monthNum,
                day: entry.day
            };
        });

    // console.log("transformTimeEntriesToEvents  timeEntriesFiltered", timeEntriesFiltered);
    return timeEntriesFiltered;
};

/**
 * Returns the background color based on the event type
 * @param {String} type - The event type
 * @returns {String} - The background color class
 */
const getBgColor = (type) => {
    switch (type) {
        case 'WORK':
            return 'bg-chart1';
        case 'VACATION':
            return 'bg-chart3';
        case 'ILLNESS':
            return 'bg-chart6';
        case 'TRAINING':
            return 'bg-chart7';
        default:
            return '';
    }
};


export default transformTimeEntriesToEvents;
