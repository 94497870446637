
import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Définition de la requête GraphQL pour obtenir tous les modèles de rétribution
const GET_ALL_CURRENCIES = gql`
  query ALL_CURRENCIES{
    currencies {
    code
    symbol
  }
  }
`;

const useAllCurrencies = () => {
  const { user } = useAuth();
  if (!user) return;

  const { loading, error, data, refetch } = useQuery(GET_ALL_CURRENCIES, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    fetchPolicy: "network-only",
  });

  return { 
    loading, 
    error, 
    currencies: data?.currencies, 
    refetch 
  };
};

export default useAllCurrencies;
