import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
    DialogClose,
} from "@/components/ui/dialog";
import { Label } from "@/components/atoms/texts/label";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Button } from "@/components/molecules/buttons/button";
import { useEffect, useState } from "react";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { ToastAction } from "@/components/organisms/feedback/toast";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useUpdateProfile } from "@/services/consultant-services/useUpdateProfile";
import { getCountryCode, getCountryData, getCountryDataList, getEmojiFlag } from 'countries-list'
import { continents, countries, languages } from 'countries-list'
import { Select, SelectContent, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";
import { isEmail, isPhoneNumber, isNotEmpty, isIban, isBic, isZipCode, isAddressLine } from "@/utils/validationInput"
import useUpdateUser from "@/services/mutation/admin-mutations/useUpdateUserl";
import { getCountryList } from "@/utils/getCountriesLanguagesAndCode";
import { useCallback } from "react";

export default function EditInformationData({ inputValue, setInputValue, title, actualLabel, selectLabel, data, type, refetchCurrentUser, consultantUuid, setInformationData }) {
    const [open, setOpen] = useState();
    const { toast } = useToast();
    const { updateIban, updateBicCode, updateAddress, updatePersonalEmail, updatePhoneNumber } = useUpdateProfile();
    const { updateIban: updateIbanForAdmin, updateBic: updateBicForAdmin, updatePhoneNumber: updatePhoneNumberForAdmin, updatePersonalEmail: updatePersonalEmailForAdmin, updateAddress: updateAddressForAdmin } = useUpdateUser();

    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [isWrongFormat, setIsWrongFormat] = useState(false);

    const [t] = useTranslation("global")
    // const countriesWithPriority = useCallback(getCountryList(),[]);
    const [countriesWithPriority, setcountriesWithPriority] = useState();

    useEffect(() => {
        let countries = getCountryList();
        setcountriesWithPriority(countries);
    }, []);

    const validateInput = (optionalValue) => {
        let isValid = true;
        if (type === 'iban') {
            isValid = isIban(optionalValue || inputValue);
        } else if (type === 'bicCode') {
            isValid = isBic(optionalValue || inputValue);
        } else if (type === 'phoneNumber') {
            isValid = isPhoneNumber(optionalValue || inputValue);
        } else if (type === 'personalEmail') {
            isValid = isEmail(optionalValue || inputValue);
        } else if (type === 'address') {
            isValid = isAddressLine(optionalValue || addressLine1) && isZipCode(optionalValue || zipCode) && isNotEmpty(optionalValue || city) && isNotEmpty(optionalValue || country);
        }
        console.log("validateInput", inputValue, isAddressLine(optionalValue || addressLine1), isZipCode(optionalValue || zipCode), isNotEmpty(optionalValue || city), isNotEmpty(optionalValue || country), isValid)

        setIsWrongFormat(!isValid);
        return isValid;
    };

    const handleClose = (open_, dataSaved = false, inputValue) => {
        setOpen(open_);
        if (open_ === false) {
            if (!dataSaved) {
                inputValue && toast({
                    variant: "warning",
                    title: "error.editWithoutSave",
                    description: inputValue,
                    duration: 2500,
                    action: (
                        <ToastAction
                            altText={inputValue}
                            onClick={async () => {
                                await navigator.clipboard.writeText(inputValue);
                            }}
                            className="border-0 py-thirdDegree"
                        >
                            <ContentCopyRoundedIcon />
                        </ToastAction>
                    ),
                });

            } else {
                inputValue && toast({
                    variant: "success",
                    title: "success.changesSaved",
                    description: inputValue,
                    duration: 4000,
                    action: (
                        <ToastAction
                            altText={inputValue}
                            onClick={async () => {
                                await navigator.clipboard.writeText(inputValue);
                            }}
                            className="border-0 py-thirdDegree"
                        >
                            <ContentCopyRoundedIcon />
                        </ToastAction>
                    ),
                });
            }
        }
        resetForm();
    }

    const handleSave = async () => {

        if (validateInput() == false) return null
            (!inputValue && type != "address") && toast({
                variant: "error",
                title: "error.cantBeNull",
                duration: 8000,
            });

        try {
            if (consultantUuid) {
                // if consultantUuid > is admin use case
                if (type === 'iban') {
                    await updateIbanForAdmin({ variables: { uuid: consultantUuid, iban: inputValue } });

                    setInformationData && setInformationData((prevData) => ({
                        ...prevData,
                        iban: inputValue
                    }));

                    handleClose(false, true, inputValue);
                } else if (type === 'bicCode') {
                    await updateBicForAdmin({ variables: { uuid: consultantUuid, bicCode: inputValue } });
                    setInformationData && setInformationData((prevData) => ({
                        ...prevData,
                        bicCode: inputValue
                    }));
                    handleClose(false, true, inputValue);
                } else if (type === 'phoneNumber') {
                    await updatePhoneNumberForAdmin({ variables: { uuid: consultantUuid, phoneNumber: inputValue } });
                    setInformationData && setInformationData((prevData) => ({
                        ...prevData,
                        phoneNumber: inputValue
                    }));
                    handleClose(false, true, inputValue);
                } else if (type === 'personalEmail') {
                    await updatePersonalEmailForAdmin({ variables: { uuid: consultantUuid, personalEmail: inputValue } });
                    setInformationData && setInformationData((prevData) => ({
                        ...prevData,
                        personalEmail: inputValue
                    }));
                    handleClose(false, true, inputValue);
                } else if (type === 'address') {
                    // const countryCode = getCountryCode(country);
                    const countryCode = country;
                    const address = `${addressLine1}, ${addressLine2}, ${zipCode}, ${city}, ${countryCode}`;
                    await updateAddressForAdmin({ variables: { uuid: consultantUuid, addressLine1, addressLine2, zipCode, city, countryCode } });
                    setInformationData && setInformationData((prevData) => ({
                        ...prevData,
                        addressLine1: addressLine1,
                        addressLine2: addressLine2,
                        zipCode: zipCode,
                        city: city,
                        countryCode: countryCode
                    }));
                    handleClose(false, true, address);
                }

            } else {
                if (type === 'iban') {
                    await updateIban({ variables: { iban: inputValue } });
                    handleClose(false, true, inputValue);
                } else if (type === 'bicCode') {
                    await updateBicCode({ variables: { bicCode: inputValue } });
                    handleClose(false, true, inputValue);
                } else if (type === 'phoneNumber') {
                    await updatePhoneNumber({ variables: { phoneNumber: inputValue } });
                    handleClose(false, true, inputValue);
                } else if (type === 'personalEmail') {
                    await updatePersonalEmail({ variables: { personalEmail: inputValue } });
                    handleClose(false, true, inputValue);
                } else if (type === 'address') {

//                     const countryCode = getCountryCode(country);
                    // console.log("countryCode 2", countryCode);
                    const countryCode = country;

                    const address = `${addressLine1}, ${addressLine2}, ${zipCode}, ${city}, ${countryCode}`;
                    await updateAddress({ variables: { addressLine1, addressLine2, zipCode, city, countryCode } });
                    handleClose(false, true, address);
                }
                refetchCurrentUser();

            }
            resetForm()

        } catch (error) {
            handleClose(false, true);

            console.error("Failed to update information:", error);
            toast({
                variant: "error",
                title: "error.failedToUpdate",
                description: error.message,
                duration: 2500,
            });
        }
        setInputValue("");
    };

    const resetForm = () => {
        setAddressLine1();
        setAddressLine2();
        setZipCode();
        setCity();
        setCountry();
        setIsWrongFormat(false);
    }
    return (
        <Dialog open={open} onOpenChange={(e) => { handleClose(e, false, inputValue); setInputValue(""); }}>
            <DialogTrigger className="invisible group-hover:visible">
                <EditRoundedIcon color='secondary' />
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle><Label message={title} size="h4" /></DialogTitle>
                    <DialogDescription className="flex flex-col gap-4 py-4">
                        <span>
                            <Label message={actualLabel} />
                            <Label fontWeight="bold">{data}</Label>
                        </span>
                        <Label message={selectLabel} />
                        {type === 'address' ? (
                            <>
                                <Input value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} placeholder={t("consultants.informations.adress1")} />
                                <Input value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} placeholder={t("consultants.informations.adress2")} />
                                <Input value={zipCode} onChange={(e) => setZipCode(e.target.value)} placeholder={t("consultants.informations.zipCode")} />
                                <Input value={city} onChange={(e) => setCity(e.target.value)} placeholder={t("consultants.informations.city")} />
                                <Select onValueChange={(value) => setCountry(value)}>

                                    <SelectTrigger className="text-body border-0 bg-lighter py-1">
                                        <SelectValue
                                            placeholder={<Label message="consultants.informations.country" />}
                                        />
                                    </SelectTrigger>
                                    <SelectContent className="h-48">
                                        {/* {countriesWithPriority && Object.keys(countriesWithPriority).map((countryCode) => (
                                            <SelectItem key={countryCode} value={countriesWithPriority[countryCode].name} className="text-body ">
                                                {countriesWithPriority[countryCode].name}
                                            </SelectItem>
                                        ))} */}
                                        {countriesWithPriority && Object.keys(countriesWithPriority).map((countryCode) => (
                                            <SelectItem key={countryCode} value={countryCode} className="text-body ">
                                                <Label>{countriesWithPriority[countryCode].name}</Label>
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </>
                        ) : (
                            <Input value={inputValue} onChange={(e) => { isWrongFormat && validateInput(e.target.value); setInputValue(e.target.value) }} onBlur={() => validateInput()} />
                        )}
                        {isWrongFormat && <Label className="text-error" message="error.wrongFormat" />}
                    </DialogDescription>
                </DialogHeader>

                <DialogFooter className="sm:justify-start">
                    <Button type="button" style="ghost" text='global.actions.cancel' onClick={() => { setInputValue(""); handleClose(false, false, inputValue) }} />
                    <Button style="fill" text='global.actions.save' className="w-full" onClick={handleSave} disabled={(type != 'address') && !inputValue || isWrongFormat} />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
