/* eslint-disable react/prop-types */
import React from 'react'
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Label } from "@/components/atoms/texts/label";
import { cn } from '@/lib/utils';
import { useParams } from 'react-router-dom';
export default function PrintEmptyDataMessage({message="error.emptyData", icon=<WarningAmberRoundedIcon/>, className }) {
    const { id } = useParams();

  return (
    <div className={"w-full flex justify-center items-center "  + className}>
      <div className=" w-full bg-warning20  border-warning  p-firstDegree px-secondDegree flex flex-row gap-secondDegree justify-center items-center rounded-sm  border-2">
        {icon}
        <Label message={message}  color="black" />
      </div>
    </div>
  );
}
