


import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { Label } from '@/components/atoms/texts/label';
import { Button } from '@/components/molecules/buttons/button';
import { CustomKanban } from './CustomKanban';
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import useCVTab from '../../../../../hooks/global-hooks/useCVTab';
import useManageTemplate from './useManageTemplate';
import { useEffect, useState } from 'react';
import RenderCvContent from './RenderCvContent';
import WaitPdf from './WaitPdf';
import { transformFakeDataCV } from './transformFakeDataCV';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import { Toaster } from '@/components/organisms/feedback/toaster';
import { useProspect } from '@/services/admin-services/query/useProspect';

const steps = ['consultants.cv.description', 'consultants.cv.jobTitle', 'consultants.cv.skills', 'consultants.cv.project', 'consultants.cv.experience', 'consultants.cv.certification', 'consultants.cv.education', 'consultants.cv.check'];

export default function GenerateCV({ isForProspect }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [template_copy, setTemplate_copy] = useState();
    const [errorMessage, setErrorMessage] = useState(""); // État pour le message d'erreur
    const [CVData, setCVData] = useState();
    const { template, templateID } = useManageTemplate(CVData?.uuid); // Utilisation du hook mis à jour
    const { CVData: fakeData, error } = useCVTab();
    const { loading, error: errorUseProspect, data, refetch } = useProspect();

    const totalSteps = () => steps.length;
    const completedSteps = () => Object.keys(completed).length;
    const isLastStep = () => activeStep === totalSteps() - 1;
    const allStepsCompleted = () => completedSteps() === totalSteps();

    useEffect(() => {
        setTemplate_copy(template);
    }, [template]);

    useEffect(() => {
        if (isForProspect) {
            data && setCVData(data);
        } else {
            fakeData && setCVData(fakeData);
            const transformedCVData = fakeData && transformFakeDataCV(fakeData);
            setCVData(transformedCVData);
        }
    }, [fakeData, data]);


    // const handleNext = () => {
    //     const newActiveStep = isLastStep() && !allStepsCompleted()
    //         ? steps.findIndex((step, i) => !(i in completed))
    //         : activeStep + 1;
    //     setActiveStep(newActiveStep);
    // };

    const handleNext = () => {
        // Fonction helper pour compter les éléments sélectionnés
        const countSelectedItems = (dataArray) => {
            return template_copy.blockSelected.filter(
                item => dataArray.some(dataItem => dataItem.uuid === item.uuid)
            ).length;
        };
    
        // Vérification spécifique pour chaque étape
        if (activeStep === 0) {
            // Vérifier si exactement un item de description est sélectionné
            const descriptionSelectedCount = countSelectedItems(CVData.descriptions);
            
            if (descriptionSelectedCount !== 1) {
                setErrorMessage("error.mustSelectOneItem");
                return;
            }
        } else if (activeStep === 1) {
            // Vérifier si exactement un item de titre est sélectionné
            const titleSelectedCount = countSelectedItems(CVData.titles);
            
            if (titleSelectedCount !== 1) {
                setErrorMessage("error.mustSelectOneItem");
                return;
            }
        }
    
        // Réinitialiser le message d'erreur
        setErrorMessage("");
    
        // Passer à l'étape suivante
        const newActiveStep = isLastStep() && !allStepsCompleted()
            ? steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const handleStep = (step) => () => setActiveStep(step);
    const handleComplete = () => {
        setCompleted({ ...completed, [activeStep]: true });
        handleNext();
    };
    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        setTemplate_copy(template);
    };



    function filterCVDataByTemplate(CVData, template_copy) {
        const filteredCVData = {};
        console.log("filterCVDataByTemplate CVData = ", CVData, "template_copy = ", template_copy, "CVData", CVData);

        if (CVData && template_copy) {
            // Ajout du champ personUuid directement dans filteredCVData
            filteredCVData.personUuid = CVData.uuid;

            Object.keys(CVData).forEach(category => {

                // console.log("filterCVDataByTemplate map category", category, CVData[category], "isArray : ", Array.isArray(CVData[category]));

                const filteredItems = Array.isArray(CVData[category]) && CVData[category].filter((item) =>
                    template_copy.blockSelected.some(
                        (block) => block.uuid === item.uuid
                    )
                );

                filteredCVData[category] = Array.isArray(CVData[category]) && filteredItems;

            });
        }
        console.log("filterCVDataByTemplate filteredCVData", filteredCVData)

        return filteredCVData;
    }
    if (loading) {
        return <span className='loader'></span>
    }
    if (errorUseProspect) {
        return (<PrintErrorMessage error={errorUseProspect} />);
    }
    if (!CVData && !loading) {
        return (<PrintErrorMessage label="error.fromServer.unknownError" />);
    }

    return (
        <div className='p-secondDegree'>
            <Toaster />
            <Stepper nonLinear activeStep={activeStep} className='w-full overflow-y-auto no-scrollbar'>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton onClick={handleStep(index)}>
                            <Label className={`font-sans ${activeStep === index && 'font-bold text-primary '}`} message={label}></Label>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>

            {allStepsCompleted() ? (
                <React.Fragment>
                    <WaitPdf handleReset={handleReset} template={filterCVDataByTemplate(CVData, template_copy)} templateID={templateID} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className='flex flex-col p-secondDegree gap-thirdDegree mt-5'>
                        {errorMessage && <PrintErrorMessage label={errorMessage} />}

                        {activeStep !== steps.length - 1 ?
                            CVData && <CustomKanban
                                step={activeStep}
                                CVData={CVData}
                                template={template_copy}
                                setTemplate={setTemplate_copy}
                                className="h-[55vh] gap-3 custom"
                            />
                            :
                            <RenderCvContent CVData={filterCVDataByTemplate(CVData, template_copy)} />
                        }
                    </div>

                    <div className='flex justify-between'>
                        <Button style="ghost" disabled={activeStep === 0} onClick={handleBack} text="global.actions.back" />
                        <div>
                            {activeStep !== steps.length &&
                                <Button onClick={handleComplete} style="outline" className="font-semibold" text="global.actions.next">
                                    {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                                </Button>
                            }
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}
