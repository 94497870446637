import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Mutation pour supprimer une TVA
const DELETE_VAT_WITH_UUID = gql`
  mutation DeleteVat($uuid: ID!) {
    deleteVat(uuid: $uuid)
  }
`;

// Hook personnalisé pour supprimer une TVA
export const useDeleteVat = (refetch) => {
    const { user } = useAuth();

    // Mutation pour supprimer une TVA
    const [deleteVat, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_VAT_WITH_UUID, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
        onCompleted: refetch // Rafraîchir les données après la mutation
    });

    return {
        deleteVat,
        deleteLoading,
        deleteError
    };
};

export default useDeleteVat;
