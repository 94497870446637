import { useTranslation } from "react-i18next";

const getMonthName = (monthNumber) => {
    const [t] = useTranslation("global")

    const months = [
      "global.months.january",
      "global.months.february",
      "global.months.march",
      "global.months.april",
      "global.months.may",
      "global.months.june",
      "global.months.july",
      "global.months.august",
      "global.months.september",
      "global.months.october",
      "global.months.november",
      "global.months.december",
    ];
    const label = t(months[monthNumber - 1]);
    return label;
  };
  export default getMonthName;