import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const DELETE_EXPENSE_REPORT = gql`
  mutation DeleteExpenseReport($uuid: ID!) {
    deleteExpenseReport(deletedExpenseReport: { uuid: $uuid })
  }
`;

export const useDeleteExpenseReport = () => {
  const { user } = useAuth();
  const [deleteExpenseReportMutation, { loading, error, data }] = useMutation(DELETE_EXPENSE_REPORT, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const deleteExpenseReport = async (uuid) => {
    const response = await deleteExpenseReportMutation({
      variables: { uuid },
    });
    return response.data.deleteExpenseReport;
  };

  return { deleteExpenseReport, loading, error, data };
};
