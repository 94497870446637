// "use client";

// import * as React from "react";
// import * as AvatarPrimitive from "@radix-ui/react-avatar";
// import { useInitials } from "../../hooks/global-hooks/useInitials";

// import { cn } from "@/lib/utils";
// import { useAuth } from "@/context/AuthContext";
// //Exemple use case
// // <Avatar src={user.avatarUrl} fallback={user.name} size="small"/>
// const AvatarFallback = React.forwardRef(

//     ({ className, fallback, size }, ref) => {
//         const sizes = {
//             small:
//                 "flex h-7 w-7 items-center justify-center rounded-full bg-lighter text-bodySmall ",
//             medium:
//                 "flex h-16 w-16 items-center justify-center rounded-full bg-medium text-body",
//             large:
//                 "flex h-44 w-44 items-center justify-center rounded-full bg-lighter text-body",
//         };

//         const sizeClass = sizes[size] || sizes.medium;

//         // Utiliser la fonction useInitials pour extraire les initiales de fallback
//         const initials = useInitials(fallback);

//         return (
//             <div ref={ref} className={cn(sizeClass,  className)}>
//                 {initials}
//             </div>
//         );
//     }
// );

// AvatarFallback.displayName = "AvatarFallback";

// const Avatar = React.forwardRef(
//     ({ className, src, fallback, size = "medium" }, ref) => {
//         const sizes = {
//             small: "h-7 w-7 ring ring-primary ring-offset-2 ring-offset-primary",
//             medium: "h-16 w-16",
//             large: "h-44 w-44",
//         };
//         const { viewAs } = useAuth();
//         const borderForViewer = {
//             "ADMIN_":" ring-error ",
//             "USER_":"ring-secondary"
//         }

//         const sizeClass = sizes[size] || sizes.medium;
//         // console.log("src = ", src ? true : false, fallback, size)
//         return (
//             <AvatarPrimitive.Root
//                 ref={ref}
//                 className={cn(
//                     "relative flex overflow-hidden rounded-full",
//                     sizeClass,borderForViewer[viewAs],
//                     className
//                 )}
//             >
//                 {src ? (
//                     <AvatarPrimitive.Image
//                         src={src}
//                         className={cn("object-cover", sizeClass)}
//                     />
//                 ) : (
//                     <AvatarFallback fallback={fallback} size={size} />
//                 )}
//             </AvatarPrimitive.Root>
//         );
//     }
// );

// Avatar.displayName = "Avatar";

// export { Avatar };
"use client";

import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { useInitials } from "../../hooks/global-hooks/useInitials";
import { cn } from "@/lib/utils";
import { useAuth } from "@/context/AuthContext";
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Button } from "../molecules/buttons/button";

// Composant pour afficher l'image de l'avatar
const AvatarImage = React.forwardRef(({ src, size, onEdit }, ref) => {
    return (
        <div className="flex overflow-hidden rounded-full items-center justify-center relative group border rounded-full border-black">
            
            <AvatarPrimitive.Image
                src={src}
                className={cn(
                    "object-cover",
                    onEdit ? "group-hover:opacity-0" : "opacity-100",
                    size
                )}
            />
            {onEdit && (
                <Button
                    showLeftIcon
                    showText={false}
                    style="pill"
                    leftIcon={<EditRoundedIcon />}
                    onClick={onEdit}
                    className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute center-0 hover:bg-medium"
                ></Button>
            )}
        </div>
    );
});

AvatarImage.displayName = "AvatarImage";

const AvatarFallback = React.forwardRef(
    ({ className, fallback, size, onEdit }, ref) => {
        const sizes = {
            small: "flex h-7 w-7 items-center justify-center rounded-full bg-lighter text-bodySmall bg-lighter border-primary border relative group",
            medium: "flex h-16 w-16 items-center justify-center rounded-full bg-medium text-body bg-lighter border-primary border relative group",
            large: "flex h-44 w-44 items-center justify-center rounded-full bg-lighter text-body bg-lighter border-primary border relative group",
        };

        const sizeClass = sizes[size] || sizes.medium;

        // Utiliser la fonction useInitials pour extraire les initiales de fallback
        const initials = useInitials(fallback);

        return (
            <div ref={ref} className={cn(sizeClass, className)}>
                <span>{initials}</span>
                {onEdit && (
                    <Button
                        showLeftIcon
                        showText={false}
                        style="pill"
                        leftIcon={<EditRoundedIcon />}
                        onClick={onEdit}
                        className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute center-0 center-0 hover:bg-medium"
                    ></Button>
                )}
            </div>
        );
    }
);

AvatarFallback.displayName = "AvatarFallback";

const AvatarEmpty = React.forwardRef(
    ({ className, size, onEdit }, ref) => {
        const sizes = {
            small: "flex h-7 w-7 items-center justify-center",
            medium: "flex h-16 w-16 items-center justify-center",
            large: "flex h-44 w-44 items-center justify-center",
        };

        const sizeClass = sizes[size] || sizes.medium;

        return (
            <div ref={ref} className={cn(sizeClass, "bg-lighter border-primary border rounded-full relative group", className)}>
                <Person2RoundedIcon fontSize={size} color="primary" className={onEdit ? "group-hover:opacity-0" : "opacity-100"} />
                {onEdit && (
                    <Button
                        showLeftIcon
                        showText={false}
                        style="pill"
                        leftIcon={<EditRoundedIcon />}
                        onClick={onEdit}
                        className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute center-0 center-0 hover:bg-medium"
                    ></Button>
                )}
            </div>
        );
    }
);

AvatarEmpty.displayName = "AvatarEmpty";

const Avatar = React.forwardRef(
    ({ className, src, fallback, size = "medium", isEmpty = false, onEdit = null }, ref) => {
        const sizes = {
            small: "h-7 w-7 ring ring-primary ring-offset-2 ring-offset-primary",
            medium: "h-16 w-16",
            large: "h-44 w-44",
        };
        const { viewAs } = useAuth();
        const borderForViewer = {
            "ADMIN_": "ring-error",
            "USER_": "ring-secondary"
        };

        const sizeClass = sizes[size] || sizes.medium;

        return (
            <AvatarPrimitive.Root
                ref={ref}
                className={cn(
                    "relative flex overflow-hidden rounded-full items-center justify-center",
                    sizeClass,
                    borderForViewer[viewAs],
                    className
                )}
            >
                {isEmpty ? (
                    <AvatarEmpty size={size} onEdit={onEdit} />
                ) : src ? (
                    <AvatarImage src={src} size={sizeClass} onEdit={onEdit} />
                ) : (
                    <AvatarFallback fallback={fallback} size={size} onEdit={onEdit} />
                )}
            </AvatarPrimitive.Root>
        );
    }
);

Avatar.displayName = "Avatar";

export { Avatar };
