import { Label } from '@/components/atoms/texts/label';
import { Button } from '@/components/molecules/buttons/button';
import { useGetSkillLabel } from '@/utils/getSkillLabel';
import React from 'react';

export default function UnmappedSkillItem({ data, className, ...props }) {
    const labelInAppLanguage = useGetSkillLabel();

    //TODO : check the app language and display the label+language
    return (
        <div className={`flex justify-start items-center rounded-sm border border-medium p-2  bg-white shadow-sm ${className}`}>
            <div className='h-6 w-6 flex justify-center bg-lighter  rounded-[50px] mr-2 border border-grey text-greyStrong'>?</div>
            <Label>{data[labelInAppLanguage]}</Label>
        {/* <Button style="outline" sizePadding="small" className='rounded-chip' {...props}></Button> */}
        </div>
    )
}