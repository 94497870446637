/* eslint-disable react/prop-types */
/**
 * @component AddSkill
 *
 * This component provides a form for adding or editing skills. It includes functionalities for selecting a skill, rating it with stars, and saving or deleting the skill. It handles form submission, validation, and displays error messages as needed.
 *
 * Key Features:
 * - Displays a form to add or edit a skill, including a dropdown to select a skill and a star rating system.
 * - Allows users to save or delete the skill, with confirmation prompts for deletion.
 * - Integrates with various hooks for fetching skills, updating, and deleting skills.
 * - Supports form validation and displays error messages using `PrintErrorMessage`.
 * - Uses `framer-motion` for smooth UI transitions and animations.
 *
 * Props:
 * - @param {Boolean} showForm - Determines if the form should be displayed.
 * - @param {Function} setShowForm - Function to toggle the visibility of the form.
 * - @param {Boolean} editingSkill - Indicates if the component is in edit mode.
 * - @param {Object} skill - The skill object to be edited or added.
 * - @param {Object} user - The current user object, used for managing skill data.
 * - @param {Function} setIsDoingAction - Function to indicate if an action (like saving or deleting a skill) is in progress.
 * - @param {Function} setFormIsValid - Function to set the form validity state.
 * - @param {Function} setServerResponseIserror - Function to set the server response error state.
 * - @param {Function} setErrors - Function to set form error details.
 * - @param {Boolean} isMappedSkill - Indicates if the skill is mapped or not.
 *
 * Internal State:
 * - @state {Object} formData - Contains the form data including the skill title, level, and UUID.
 * - @state {Boolean} confirmDelete - Indicates if the delete confirmation prompt is active.
 * - @state {Boolean} inputIsValid - Indicates if the input fields are valid.
 * - @state {Boolean} isStarsValid - Indicates if the star rating is valid.
 * - @state {Object} errors - Holds error messages related to skill input and validation.
 *
 * Usage:
 * - This component is used in contexts where skills need to be added or edited, such as a skill management interface.
 * - It integrates with services for fetching, updating, and deleting skills and provides a user-friendly form with validation and error handling.
 * - The `AddSkill` component uses `framer-motion` for animations and `PrintErrorMessage` for displaying error messages.
 *
 * Example:
 * <AddSkill
 *   showForm={true}
 *   setShowForm={setFormVisibility}
 *   editingSkill={isEditingSkill}
 *   skill={currentSkill}
 *   user={currentUser}
 *   setIsDoingAction={setActionState}
 *   setFormIsValid={setFormValidity}
 *   setServerResponseIserror={setServerErrorState}
 *   setErrors={setFormErrors}
 *   isMappedSkill={true}
 * />
 *
 * Dependencies:
 * - `Stars` component for star rating.
 * - `PrintErrorMessage` component for displaying validation errors.
 * - `framer-motion` for UI animations.
 * - `useAllSkills` hook for fetching skills.
 * - `useUpdateProspect` hook for adding new skills.
 * - `useUpdateProspectUpdateItems` hook for updating existing skills.
 * - `useUpdateProspectDeleteItems` hook for deleting skills.
 */


import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import Stars from "./Stars";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import { motion, useAnimation } from "framer-motion";
import { Label } from "@/components/atoms/texts/label";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import useUpdateProspect from "@/services/admin-services/mutations/updateProspect/useUpdateProspectAddItems";
import { useAllSkills } from "@/services/global-services/query/useAllSkills";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import getAppLanguage from "@/utils/getAppLanguage";
import { useGetSkillLabel } from "@/utils/getSkillLabel";
import useUpdateProspectUpdateItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectUpdateItems";
import useUpdateProspectDeleteItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectDeleteItems";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
export default function AddSkill({
    showForm = true,
    setShowForm,
    editingSkill = false,
    skill,
    user,
    setIsDoingAction,
    setFormIsValid,
    setServerResponseIserror,
    setErrors,
    isMappedSkill = true,
}) {
    const {
        loading,
        error: errorSkills,
        data: dataSkills,
        refetch,
    } = useAllSkills();
    const labelSkill = useGetSkillLabel();
    const { addNewMappedSkill, errorAddSkill } = useUpdateProspect();
    const { updateMappedSkill, errorUpdateMappedSkill } = useUpdateProspectUpdateItems()
  const { deleteMappedSkill, errorDeleteMappedSkill } = useUpdateProspectDeleteItems()
  const [confirmDelete, setConfirmDelete] = useState(false);
    const form = useForm();


    const [formData, setFormData] = useState({
        title: skill ? skill[labelSkill] : "test", // à adapter si CRUD sur unmappedskills
        level: skill ? skill.rating : 0,

        uuid: skill && isMappedSkill ? skill.skill.uuid : "",
    });
    const appLanguage = getAppLanguage();
    // skill && console.log(skill.title);
    const [erros, setErros] = useState({
        skill: "",
        indexOfStar: "",
    });
    const [inputIsValid, setInputIsValid] = useState(false);
    const [isStarsValid, setIsStarsValid] = useState(true);
    const controls = useAnimation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        //const isValid = validateFormData(formData, setErros);

        if (formData.title == "") {
            setInputIsValid(false);
        }
        if (formData.level == 0) {
            controls.start("start");
            console.log("level is invalid");
            setIsStarsValid(false);
        }
        if (formData.title != "" && formData.level != 0) {
            setIsStarsValid(true);
            console.log("l'uuid du skill");
            if (editingSkill) {
                await updateMappedSkill(user.uuid, formData.uuid, formData.level)
                setIsDoingAction(true);
                if (errorUpdateMappedSkill) {
                    console.error("Failed to add :", errorUpdateMappedSkill);
                    // alert("Add Error ");
                    setFormIsValid(false);
                    setErrors(errorUpdateMappedSkill);

                    setServerResponseIserror(true);
                } else {
                    setServerResponseIserror(false);
                    setFormIsValid(true);
                }


            }
            else {
                const result = await addNewMappedSkill(
                    user.uuid,
                    formData.uuid,
                    formData.level
                );
                setIsDoingAction(true);
                if (result.error) {
                    console.error("Failed to add :", result.error);
                    // alert("Add Error ");
                    setFormIsValid(false);
                    setErrors(result.error);

                  setServerResponseIserror(true);
                  setIsDoingAction(true);
                    // setEditin(false);
                    if (errorAddSkill) setErrors(errorAddSkill);
                } else {
                  setIsDoingAction(true);
                    setServerResponseIserror(false);
                    setFormIsValid(true);
                }


            }
        }
        setShowForm(false);
    };

    const handleChange = (value, name) => {
        // const { value } = e.target;
        //const value=e
        setInputIsValid(true);

        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        console.log(formData);
    };
    const setStars = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            level: index,
        }));
        setIsStarsValid(true);
    };
    const handleDelete = async (uuid) => {
        try {
            // Replace with actual project UUID to delete
            await deleteMappedSkill(user.uuid, uuid);
            //alert("Updated Projects:", updatedProjects);
            setIsDoingAction(true);

            if (errorDeleteMappedSkill) {
                setErrors(errorDeleteMappedSkill);
                setFormIsValid(false);
                setServerResponseIserror(true);
            }
        } catch (err) {
            // alert("Failed to delete project:", err);
            console.error("Failed to delete project:", err);
        }
    };
    const setSkillName = (uuid) => {
        handleChange(uuid, "uuid");
        // handleChange(skill.skill[labelSkill], "title")

    };
   const handleCancel = () => {
     //setEditingSkill && setEditingSkill(false);
     setShowForm && setShowForm(false);
     setFormIsValid && setFormIsValid(false);
     setConfirmDelete(false);
   };
  
  if (loading) return <span className="loader"></span>
  if(errorSkills) return <PrintErrorMessage error={errorSkills}/>

    return (
      <motion.div
        initial={{
          scale: 0.9,
        }}
        animate={{
          scale: 1,
        }}
      >
        {showForm && (
          <Form {...form}>
            <form
              onSubmit={handleSubmit}
              className={`bg-lighter  border-sm border-medium   p-secondDegree shadow-md flex flex-col gap-secondDegree w-full`}
            >
              <div className=" flex flex-row gap-secondDegree items-center w-full">
              
                <div className=" w-full flex py-secondDegree">
                  <Select onValueChange={setSkillName} value={formData.uuid}>
                    <SelectTrigger className="rounded-[5px] bg-transparent border-greyStrong focus:ring-0 focus:ring-offset-0">
                      <SelectValue
                        placeholder={<Label message="global.actions.select" />}
                      />
                    </SelectTrigger>

                    <SelectContent>
                      <>
                        {dataSkills?.allSkills &&
                          Object.values(dataSkills?.allSkills).map((skill) => (
                            <SelectItem value={skill.uuid} key={skill.uuid}>
                              <Label>{skill[labelSkill]}</Label>
                            </SelectItem>
                          ))}
                      </>
                    </SelectContent>
                  </Select>
                </div>
                <div
                  className={`${!isStarsValid ? "animate-pulse delay-0" : ""}`}
                >
                  <Stars
                    totalStars={5}
                    informSelectedIndex={setStars}
                    indexSelected={formData.level}
                    allowChange={true}
                  />
                </div>
              </div>

              <div className="flex flex-row gap-[12px] items-center w-full">
                {editingSkill && (
                  <>
                    {!confirmDelete ? (
                      <Button
                        onClick={() => setConfirmDelete(true)}
                        showLeftIcon={true}
                        showText={false}
                        //sizePadding="firstDegree"
                        leftIcon={
                          <DeleteIcon className="text-primary cursor-pointer" />
                        }
                        style="ghost"
                      />
                    ) : (
                      <motion.div
                        className="flex flex-row gap-2 w-[70%] items-center"
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Label message="consultants.statement.vatSettings.confirmDelete" />
                        <Button
                          showLeftIcon
                          className="text-error border-error rounded-sm bg-white"
                          leftIcon={
                            <DeleteOutlineIcon
                              className="text-error"
                              fontSize="small"
                            />
                          }
                          // showText={false}
                          text={"global.actions.confirmDelete"}
                          sizePadding="small"
                          style="outline"
                          onClick={() => handleDelete(formData.uuid)}
                        />
                      </motion.div>
                    )}
                  </>
                )}
                <Button
                  showLeftIcon={true}
                  leftIcon={<ClearIcon fontSize="small" />}
                  showText={true}
                  text="global.actions.cancel"
                  sizePadding="default" // Utilisez "small" pour un padding plus petit
                  style="outline"
                  className={`${
                    confirmDelete
                      ? "w-[17%]"
                      : "font-bold border-primary w-full"
                  }`}
                  showToolTip={confirmDelete ? true : false}
                  onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                />
                <Button
                  showLeftIcon={true}
                  leftIcon={<SaveIcon fontSize="small" />}
                  showText={true}
                  text="global.actions.save"
                  sizePadding="default" // Utilisez "small" pour un padding plus petit
                  style="fill"
                  showToolTip={confirmDelete ? true : false}
                  className={`${
                    confirmDelete ? "w-[16%]" : "font-bold w-full"
                  }`}
                  type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                />
              </div>
            </form>
          </Form>
        )}
      </motion.div>
    );
}
