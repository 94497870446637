// import { useAuth } from '@/context/AuthContext';
// import { useState } from 'react';
// import errorToText from '@/utils/errorToText';

// const useUpdateUser = () => {

//     const [response, setResponse] = useState(null);
//     const { user: authUser } = useAuth();

//     const setRetribution = async (uuid, retributionModelUuid) => {
//         if (!authUser.strongToken) {
//             setError('No strong token available');
//             return { data: null, error: 'No strong token available' };
//         }

//         try {
//             const response = await fetch(window.ENV.BACKEND_URL+'/api/graphql', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${authUser.strongToken}`
//                 },
//                 body: JSON.stringify({
//                     query: `
//                     mutation updateRetribution {
//                         updateUser(updatedUser: {
//                             uuid: "${uuid}", 
//                             retributionModelUuid: "${retributionModelUuid}"
//                           }){
//                             retributionModel {
//                                 uuid
//                                 upToTenConsultants
//                                 upToFiveConsultants
//                                 moreThanTenConsultants
//                               }
//                           }
//                         }
//                     `
//                 })
//             });


//             if (!response.ok) {
//                 const errMessage = errorToText(response);
//                 throw new Error(errMessage);
//             }

//             const data = await response.json();
//             if (data.errors) {
//                 const errMessage = data.errors[0].message;
//                 throw new Error(errMessage);
//             }

//             setResponse(data.data.updateUser);

//             return { data: data.data.updateUser, error: null };
//         } catch (err) {
//             return { data: null, error: err.message };
//         }
//     };

//     return { setRetribution, uuidResponnse: response };
// };

// export default useUpdateUser;


// // updateUser(updatedUser: {uuid: ""}) {
// //     language
// //   }


import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import errorToText from '@/utils/errorToText';

// Define the GraphQL mutations
const UPDATE_USER_RETRIBUTION = gql`
  mutation updateRetribution($uuid: ID!, $retributionModelUuid: ID!) {
    updateUser(updatedUser: {uuid: $uuid, retributionModelUuid: $retributionModelUuid}) {
      retributionModel {
        uuid
        upToTenConsultants
        upToFiveConsultants
        moreThanTenConsultants
      }
    }
  }
`;

const UPDATE_USER_LANGUAGE = gql`
  mutation updateLanguage($uuid: ID!, $language: InterfaceLanguage!) {
    updateUser(updatedUser: {uuid: $uuid, language: $language}) {
      language
    }
  }
`;

const UPDATE_USER_IBAN = gql`
  mutation updateIban($uuid: ID!, $iban: String!) {
    updateUser(updatedUser: {uuid: $uuid, iban: $iban}) {
      iban
    }
  }
`;

const UPDATE_USER_BIC = gql`
  mutation updateBic($uuid: ID!, $bicCode: String!) {
    updateUser(updatedUser: {uuid: $uuid, bicCode: $bicCode}) {
      bicCode
    }
  }
`;

const UPDATE_USER_PHONE_NUMBER = gql`
  mutation updatePhoneNumber($uuid: ID!, $phoneNumber: String!) {
    updateUser(updatedUser: {uuid: $uuid, phoneNumber: $phoneNumber}) {
      phoneNumber
    }
  }
`;

const UPDATE_USER_PERSONAL_EMAIL = gql`
  mutation updatePersonalEmail($uuid: ID!, $personalEmail: String!) {
    updateUser(updatedUser: {uuid: $uuid, personalEmail: $personalEmail}) {
      personalEmail
    }
  }
`;

const UPDATE_USER_ADDRESS = gql`
  mutation updateAddress(
    $uuid: ID!, 
    $addressLine1: String!, 
    $addressLine2: String, 
    $zipCode: String!, 
    $city: String!, 
    $countryCode: String!
  ) {
    updateUser(updatedUser: {
      uuid: $uuid, 
      addressLine1: $addressLine1, 
      addressLine2: $addressLine2, 
      zipCode: $zipCode, 
      city: $city, 
      countryCode: $countryCode
    }) {
      addressLine1
      addressLine2
      zipCode
      city
      countryCode
    }
  }
`;

const useUpdateUser = () => {
    const { user: authUser } = useAuth();
    const [response, setResponse] = useState(null);

    const [updateRetribution] = useMutation(UPDATE_USER_RETRIBUTION, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        onError: (error) => {
            console.error(error);
        },
    });

    const [updateLanguage] = useMutation(UPDATE_USER_LANGUAGE, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        onError: (error) => {
            console.error(error);
        },
    });

    const setRetribution = async (uuid, retributionModelUuid) => {
        try {
            const { data } = await updateRetribution({
                variables: { uuid, retributionModelUuid },
            });
            setResponse(data.updateUser);
            return { data: data.updateUser, error: null };
        } catch (err) {
            return { data: null, error: err.message };
        }
    };

    const setLanguage = async (language) => {
        const uuid = authUser.uuid;
        try {
            const { data } = await updateLanguage({
                variables: { uuid, language },
            });
            setResponse(data.updateUser);
            return { data: data.updateUser, error: null };
        } catch (err) {
            return { data: null, error: err.message };
        }
    };

    const [updateIban] = useMutation(UPDATE_USER_IBAN, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        onError: (error) => {
            console.error(error);
        },
    });
    
    const [updateBic] = useMutation(UPDATE_USER_BIC, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        onError: (error) => {
            console.error(error);
        },
    });
    
    const [updatePhoneNumber] = useMutation(UPDATE_USER_PHONE_NUMBER, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        onError: (error) => {
            console.error(error);
        },
    });
    
    const [updatePersonalEmail] = useMutation(UPDATE_USER_PERSONAL_EMAIL, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        onError: (error) => {
            console.error(error);
        },
    });
    
    const [updateAddress] = useMutation(UPDATE_USER_ADDRESS, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        onError: (error) => {
            console.error(error);
        },
    });
    
    return { setRetribution, setLanguage, updateIban, updateBic, updatePhoneNumber, updatePersonalEmail, updateAddress, uuidResponse: response };
};

export default useUpdateUser;
