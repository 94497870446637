import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useParams } from "react-router-dom";

const GET_CONSULTANT_PAYSLIPS = gql`
  query ConsultantPayslips($consultantUuid: ID!, $sliceNumber: Int!) {
    consultantPayslips(payslipsRequest: { consultantUuid: $consultantUuid, sliceNumber: $sliceNumber }) {
      payslips {
        uuid
        payslipFileUuid
        year
        month
        currency
        grossSalary
        netSalary
        taxes
        bonus
        createdAt
        employerContributions
        employeeContributions
        comment
      }
      hasNext
    }
  }
`;

export const useConsultantPayslips = (sliceNumber) => {
    const { user } = useAuth();
    const { id: consultantUuid } = useParams();
    if (!consultantUuid) return;

    const { loading, error, data, refetch } = useQuery(GET_CONSULTANT_PAYSLIPS, {
        variables: { consultantUuid, sliceNumber },
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
        fetchPolicy: "network-only",
    });
    // console.log("hooks return are :", data, data?.consultantPayslips);

    return { loading, error, data: data?.consultantPayslips, refetch };
};
