import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useEffect } from "react";


const GET_CONSULTANT_EXPENSE_REPORTS = gql`
  query ConsultantExpenseReports($consultantUuid: ID!, $sliceNumber: Int!) {
    consultantExpenseReports(expenseReportsRequest: { consultantUuid: $consultantUuid, sliceNumber: $sliceNumber }) {
      expenses {
        uuid
        expenseReportFileUuid
        createdAt
        invoiceDate
         category {
            accountingAccountNumber
            labelFr
            labelEn
            label
            uuid
            }
        status
        amountExcludingVat
        impactsOverflow
        currency
        invoiceIssuer
        countryCode
        countryCode
        invoiceIssuer
        comment
        totalAmount
        vatList {
          vatRate
          vatAmount
        }
      }
      hasNext
    }
  }
`;



export const useConsultantExpenseReports = (consultantUuid, sliceNumber) => {
    const { user } = useAuth();
    if (!user) return;

    const { loading, error, data, refetch } = useQuery(GET_CONSULTANT_EXPENSE_REPORTS, {
        variables: { consultantUuid, sliceNumber },
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
    });

    useEffect(() => {
        refetch()
    }, [sliceNumber])

    return { loading, error, consultantExpenseReports: data?.consultantExpenseReports, refetch };
};
