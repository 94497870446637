/**
 * @component AddNewSkill
 *
 * The `AddNewSkill` component allows users to add a new skill in a two-step process. It supports both adding basic information for the skill and providing translations for different languages. The component includes form validation, dynamic state updates, and localized translation capabilities.
 *
 * Key Features:
 * - Two-step process for adding a new skill and its translations.
 * - Integration with translation services for multiple languages.
 * - Form validation and error handling for invalid inputs.
 * - Visual feedback on the progress of adding a skill.
 *
 * Props:
 * - @prop {Function} setSkills - Function to update the list of skills with the new skill.
 * - @prop {Function} setAddingSkill - Function to toggle the state of adding a skill (e.g., close the skill panel).
 *
 * State:
 * - @variable {Object} formData - Contains the form data for the skill (title, type, number of usage, etc.).
 * - @variable {Object} completSkill - Holds the translated labels for different languages.
 * - @variable {Boolean} hideFirstStep - Toggles the visibility of the first step in the form.
 * - @variable {Boolean} isFirStepValid - Tracks the validation state of the first step.
 * - @variable {Boolean} hideSecondStep - Toggles the visibility of the second step in the form.
 * - @variable {Boolean} isSecondStepValid - Tracks the validation state of the second step.
 * - @variable {Boolean} formIsSend - Boolean to show if the form is successfully submitted.
 * - @variable {String} sourceLanguage - Stores the currently selected source language for translation.
 * - @variable {Object} translations - Stores the translated text for the skill in various languages.
 *
 * Hooks/Effects:
 * - @hook {useForm} - React Hook Form for managing form state and validation.
 * - @hook {useState} - Manages multiple states such as form data, error states, and visibility of steps.
 * - @hook {useAITranslation} - Fetches translations for the skill in multiple languages.
 * - @hook {useTranslation} - Provides support for multi-language translations using `i18next`.
 * - @hook {useEffect} - Updates the `completSkill` object with translated labels when translations are available.
 * - @hook {useAddSkill} - Custom hook for adding a skill to the database via an API.
 *
 * Helper Functions:
 * - @function handleChangeTitle - Updates formData when an input value changes.
 * - @function handleSelectedType - Sets the skill type and updates the form data.
 * - @function handleTranslate - Triggers the translation of the skill title based on the selected language.
 * - @function handleSubmitFirstStep - Handles form submission for the first step, including validation and translation.
 * - @function handleCompletSkillSubmit - Handles the second step of adding a skill, including API call to add the translated skill.
 * - @function handleFirstStepClick - Toggles visibility of the first step in the form process.
 * - @function handleSecondStepClick - Proceeds to the second step if the first step is valid.
 * - @function deleteFromInvalidInputs - Removes the input from the list of invalid inputs when corrected.
 *
 * Conditional Rendering:
 * - Displays the form for the first step with basic skill information (title, type) if `hideFirstStep` is `false`.
 * - Shows a loading spinner when translations or API data is being fetched.
 * - Displays the second step for adding translations when the first step is valid.
 * - Shows a success message if the skill is successfully added.
 * - Renders error messages if translation or adding skill fails.
 *
 * Example Usage:
 * ```jsx
 * <AddNewSkill
 *   setSkills={setSkillsFunction}
 *   setAddingSkill={setAddingSkillFunction}
 * />
 * ```
 *
 * Dependencies:
 * - `react-hook-form` for form handling.
 * - Custom hooks (`useAITranslation`, `useAddSkill`) for API calls related to translations and adding a skill.
 * - UI components such as `Button`, `TextFieldInput`, `Label`, `Separator`, `Step`, and others for building the form interface.
 * - External libraries like `motion` for animations and `i18next` for translations.
 *
 * Where it is used:
 *
 */

import { Button } from "@/components/molecules/buttons/button";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import { useState } from "react";
import { getSkillsType } from "@/services/global-services/api-rest/useAITranslate";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import AddIcon from "@mui/icons-material/Add";
import { Label } from "@/components/atoms/texts/label";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { motion } from "framer-motion";
import Step from "@/components/molecules/buttons/Step";
import CheckIcon from "@mui/icons-material/Check";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import useAITranslation from "@/services/global-services/api-rest/useAITranslate";
import { useTranslation } from "react-i18next";
import FormIsSend from "@/components/organisms/feedback/formIsSend";
import PrintErrorMessage from "@/components/molecules/PrintErrorMessage";
import { useAddSkill } from "@/services/admin-services/mutations/useAddSkill";
import { useAuth } from "@/context/AuthContext";
import { useEffect } from "react";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/organisms/forms/select";
export default function AddNewSkill({ setSkills, setAddingSkill }) {
    const {
        loading,
        error: errorTranslate,
        translations,
        translate,
    } = useAITranslation();
    const { i18n } = useTranslation("global");
    const currentLanguageCode = i18n.language;
    const { user } = useAuth();

    console.log("user dmsdm", user);
    const form = useForm();

    const [formData, setFormData] = useState({
        title: "",
        type: "",
    });
    const { handleAddSkill, loading: loadingAddSkill, error: errorAddSkill, data, } = useAddSkill();

    const [hideFirstStep, setHideFirstStep] = useState(false);
    const [isFirStepValid, setIsFirsStepValid] = useState(false);
    const [hideSecondStep, setHideSecondStep] = useState(true);
    const [isSecondStepValid, setIsSecondStepValid] = useState(false);
    const [sourceLanguage, setSourceLanguage] = useState(currentLanguageCode);
    const [formIsSend, setFormIsSend] = useState(false);
    const skillTypes = getSkillsType()
    const [skillType, setSkillType] = useState(skillTypes[0]);
    const [t] = useTranslation("global");
    const [firstStepErrors, setFirstStepErrors] = useState({
        title: false,
        type: false
    });
    const [translatedLabelsErrors, setTranslatedLabelsErrors] = useState({
        labelEn: false,
        labelEs: false,
        labelFr: false,
        labelIt: false,
        labelNl: false,
        labelDe: false,
    });
    const [completSkill, setCompleteSkill] = useState({
        // labelDe: sourceLanguage,
        companyUuid: user.company.uuid,
        labelEn: "",
        labelEs: "",
        labelFr: "",
        labelIt: "",
        labelNl: "",
        labelDe: "",
        skillType: skillType,
    });
    const handleChangeTitle = (e) => {
        const value = e.target.value;
        setIsFirsStepValid(false);
        setFormData((prevFormData) => ({
            ...prevFormData,
            title: value,
        }));
    };
    const handleChangeSkillTr = (e, name) => {
        const value = e.target.value;
        switch (name) {
            case "translatedTextDE":
                setCompleteSkill((prevFormData) => ({ ...prevFormData, labelDe: value, }));
                setTranslatedLabelsErrors((errors => ({ ...errors, labelDe: !value, })));
                break;
            case "translatedTextEN":
                setCompleteSkill((prevFormData) => ({ ...prevFormData, labelEn: value, }));
                setTranslatedLabelsErrors((errors => ({ ...errors, labelEn: !value, })));
                break;
            case "translatedTextES":
                setCompleteSkill((prevFormData) => ({ ...prevFormData, labelEs: value, }));
                setTranslatedLabelsErrors((errors => ({ ...errors, labelEs: !value, })));
                break;
            case "translatedTextFR":
                setCompleteSkill((prevFormData) => ({ ...prevFormData, labelFr: value, }));
                setTranslatedLabelsErrors((errors => ({ ...errors, labelFr: !value, })));
                break;
            case "translatedTextIT":
                setCompleteSkill((prevFormData) => ({ ...prevFormData, labelIt: value, }));
                setTranslatedLabelsErrors((errors => ({ ...errors, labelIt: !value, })));
                break;
            case "translatedTextNL":
                setCompleteSkill((prevFormData) => ({ ...prevFormData, labelNl: value, }));
                setTranslatedLabelsErrors((errors => ({ ...errors, labelNl: !value, })));
                break;
            default:
                break;
        }
    };
    const handleTranslate = () => {
        translate(formData.title, sourceLanguage.toLocaleUpperCase());
    };
    const handleSubmitFirstStep = (e) => {
        e.preventDefault();

        const errors = {
            title: !formData.title,
            type: !skillType
        }
        const hasErrors = Object.values(errors).some(error => error === true);
        setFirstStepErrors(errors)

        if (!hasErrors) {
            handleTranslate();
            setHideSecondStep(false);
            setHideFirstStep(true);
            setIsFirsStepValid(true); // Ajout en tête de liste
        } else {
            console.log("value invalid errors =", errors, skillType, !skillType);
        }
        setSkills((prevData) => [formData, ...prevData]);

    };
    function checkCompleteSkillAndSetErrors() {
        // Créer un nouvel objet d'erreurs à partir de completSkill
        const errors = {
            labelEn: completSkill.labelEn === "",
            labelEs: completSkill.labelEs === "",
            labelFr: completSkill.labelFr === "",
            labelIt: completSkill.labelIt === "",
            labelNl: completSkill.labelNl === "",
            labelDe: completSkill.labelDe === ""
        };

        // Mettre à jour l'état avec les erreurs détectées
        setTranslatedLabelsErrors(errors);

        // Vérifier si au moins une propriété est vide
        const hasErrors = Object.values(errors).some(error => error === true);

        // Retourner true s'il y a des erreurs, false sinon
        return hasErrors;
    }



    const handleCompletSkillSubmit = async (e) => {
        e.preventDefault();
        if (checkCompleteSkillAndSetErrors()) return;
        try {
            const result = await handleAddSkill(completSkill);
            console.log("Skill added successfully:", result);
            setFormIsSend(true);
            setIsSecondStepValid(true);
        } catch (err) {
            console.error("Error adding skill:", err);
        }
    };

    // Mettre à jour completSkill avec les données de traduction
    useEffect(() => {
        if (translations) {
            console.log("use effect translations:", translations);

            setCompleteSkill((prevFormData) => ({
                ...prevFormData,
                labelDe: translations.translatedTextDE,
                labelEn: translations.translatedTextEN,
                labelEs: translations.translatedTextES,
                labelFr: translations.translatedTextFR,
                labelIt: translations.translatedTextIT,
                labelNl: translations.translatedTextNL,
            }));
        }
    }, [translations]);

    const handleSelectedType = (type) => {
        setSkillType(type)
        setFormData((prevFormData) => ({
            ...prevFormData,
            type: type,
        }));
        setCompleteSkill((prevFormData) => ({
            ...prevFormData,
            skillType: type,
        }));
    };
    const handleFirstStepClick = () => {
        setHideFirstStep(false);
        setHideSecondStep(true);
    };
    const handleSecondStepClick = () => {
        if (isFirStepValid) {
            setHideFirstStep(true);
            setHideSecondStep(false);
        }
    };
    return (
        <motion.div
            initial={{
                scale: 0.7,
            }}
            animate={{
                scale: 1,
            }}
        >
            {isSecondStepValid && (
                <FormIsSend message={"success.changesSaved"} />
            )}
            {errorAddSkill && <PrintErrorMessage error={errorAddSkill} />}
            {errorTranslate && <PrintErrorMessage error={errorTranslate} />}

            <div className="flex flex-col gap-secondDegree py-paddingTopAndBottom px-paddingLeftAndRight w-full">
                <Button
                    showLeftIcon
                    leftIcon={<ArrowBackIcon fontSize="small" />}
                    showText
                    text="global.actions.back"
                    style="ghost"
                    className="font-bold  w-[150px] "
                    onClick={() => setAddingSkill(false)} 
                />
                <motion.div
                    initial={{
                        scale: 0.9,
                    }}
                    animate={{
                        scale: 1,
                    }}
                    className="flex gap-secondDegree"
                >
                    <div className=" flex flex-col items-center ">
                        <Step
                            item={isFirStepValid ? <CheckIcon /> : "1"}
                            bg={isFirStepValid ? "success" : "white"}
                            borderColor={isFirStepValid && "success"}
                            borderSize={isFirStepValid ? "solid" : "default"}
                            onClick={handleFirstStepClick}
                        />
                        {hideFirstStep && <Separator orientation="vertical" />}
                    </div>

                    <Form {...form} className="w-full ">
                        <div className="flex flex-col w-full gap-secondDegree">
                            <Label
                                message="consultants.statement.setSkillsPanel.titleStep1"
                                size="body"
                                fontWeight="bold"
                            />
                            {!hideFirstStep && (
                                <form
                                    onSubmit={handleSubmitFirstStep}
                                    className="w-full flex flex-col gap-secondDegree "
                                >
                                    <div className="flex flex-row gap-firstDegree w-full ">
                                        {console.log("firstStepErrors.title", firstStepErrors.title)}
                                        <Input
                                            value={formData.title}
                                            placeHolder={t("consultants.statement.prospectConsultantPanel.formPlaceHolder.skill")}
                                            className={"w-full rounded-md bg-white" && firstStepErrors.title === true && 'border-error'}
                                            onChange={handleChangeTitle}
                                        >

                                        </Input>

                                        <Select onValueChange={handleSelectedType} value={skillType}>
                                            <SelectTrigger
                                                className={`col-span-3 p-2 border rounded-md text-body bg-white ` && firstStepErrors.type === true && 'border-error'}
                                            >

                                                <SelectValue placeholder={<Label message="global.actions.select" />} />

                                            </SelectTrigger>
                                            <SelectContent>
                                                {skillTypes && skillTypes.map((item, index) => (
                                                    <SelectItem key={index} value={item}>
                                                        {item}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>

                                    </div>
                                    <div className="flex flex-row gap-firstDegree items-center">
                                        <Label
                                            message="consultants.statement.setSkillsPanel.addingSkill"
                                            size="small"
                                            className="text-end flex w-full"
                                        />
                                        <Button
                                            showLeftIcon
                                            // leftIcon={<AddIcon />}
                                            showText
                                            text="global.actions.next"
                                            sizePadding="default" // Utilisez "small" pour un padding plus petit
                                            style="fill"
                                            className="grow font-bold"
                                            type="submit"
                                        //onClick={() => setNewClicked(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                        />
                                    </div>
                                </form>
                            )}
                        </div>
                    </Form>
                </motion.div>
                <motion.div
                    initial={{
                        scale: 0.8,
                    }}
                    animate={{
                        scale: 1,
                    }}
                    className="flex gap-secondDegree"
                >
                    <div className=" flex flex-col items-center ">
                        <Step
                            item={isSecondStepValid ? <CheckIcon /> : "2"}
                            bg={isSecondStepValid ? "success" : "white"}
                            borderColor={isSecondStepValid && "success"}
                            borderSize={isSecondStepValid ? "solid" : "default"}
                            onClick={handleSecondStepClick}
                        />
                        {!hideSecondStep && <Separator orientation="vertical" />}
                    </div>
                    <div className="w-full flex flex-col">
                        <Label
                            message="consultants.statement.setSkillsPanel.titleSetp2"
                            size="body"
                            fontWeight="bold"
                        />
                        {loading && <span className="loader mt-4" />}

                        {isSecondStepValid ||
                            (isFirStepValid && !hideSecondStep && !loading && (
                                <div className="w-full">
                                    <Form {...form}>
                                        <form onSubmit={handleCompletSkillSubmit}>
                                            <div className="py-secondDegree flex flex-col gap-secondDegree w-full">
                                                <Input
                                                    placeHolder={t("global.languages.english")}
                                                    value={completSkill.labelEn}
                                                    onChange={(e) =>
                                                        handleChangeSkillTr(e, "translatedTextEN")
                                                    }
                                                    className={translatedLabelsErrors.labelEn && `border-error`}
                                                    type="text" />
                                                <Input
                                                    placeHolder={t("global.languages.french")}
                                                    value={completSkill.labelFr}
                                                    onChange={(e) =>
                                                        handleChangeSkillTr(e, "translatedTextFR")
                                                    }
                                                    className={translatedLabelsErrors.labelFr && `border-error`}
                                                    type="text" />
                                                <Input
                                                    placeHolder={t("global.languages.german")}
                                                    value={completSkill.labelDe}
                                                    onChange={(e) =>
                                                        handleChangeSkillTr(e, "translatedTextDE")
                                                    }
                                                    className={translatedLabelsErrors.labelDe && `border-error`}
                                                    type="text" />
                                                <Input
                                                    placeHolder={t("global.languages.italian")}
                                                    value={completSkill.labelIt}
                                                    onChange={(e) =>
                                                        handleChangeSkillTr(e, "translatedTextIT")
                                                    }
                                                    className={translatedLabelsErrors.labelIt && `border-error`}
                                                    type="text" />
                                                <Input
                                                    placeHolder={t("global.languages.spanish")}
                                                    value={completSkill.labelEs}
                                                    onChange={(e) =>
                                                        handleChangeSkillTr(e, "translatedTextES")
                                                    }
                                                    className={translatedLabelsErrors.labelEs && `border-error`}
                                                    type="text">
                                                </Input>
                                                <Input
                                                    placeHolder={t("global.languages.dutch")}
                                                    value={completSkill.labelNl}
                                                    onChange={(e) =>
                                                        handleChangeSkillTr(e, "translatedTextNL")
                                                    }
                                                    className={translatedLabelsErrors.labelNl && `border-error`}
                                                    type="text">
                                                </Input>
                                            </div>

                                            <Button
                                                showText
                                                leftIcon={<AddIcon />}
                                                text="global.actions.add"
                                                type="submit"
                                                style="fill"
                                                className="font-bold float-right"
                                            />
                                        </form>
                                    </Form>
                                </div>
                            ))}
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
}
