// Using API Rest
import { useAuth } from "@/context/AuthContext";

const useAddExpenseReportProof = () => {
  const { user } = useAuth();

  const addExpenseReportProof = async (file) => {
    if (!user.strongToken) {
      return { data: null, error: 'No strong token available' };
    }

    const formData = new FormData();
    formData.append("expenseReport", file);

// console.log("addExpenseReportProof formData=", formData, "file = ", file)
    try {
      const response = await fetch(window.ENV.BACKEND_URL+'/api/secured/users/expense/expenseReportProof', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Error uploading expense report proof');
      }

      const data = await response.json();
      return { data, error: null }; // data contains the UUID of the uploaded proof
    } catch (err) {
      return { data: null, error: err.message };
    }
  };

  return { addExpenseReportProof };
};

export default useAddExpenseReportProof;
