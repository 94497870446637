import { gql } from "@apollo/client";

import { useAuth } from "@/context/AuthContext";
import { useMutation } from "@apollo/client";
// Assurez-vous d'importer correctement la mutation

export const DELETE_PROSPECT_MUTATION = gql`
  mutation DeleteProspect($uuid: ID!) {
    deleteProspect(uuid: $uuid)
  }
`;

const useDeleteProspect = () => {
    const { user } = useAuth();
  // Déclarez la mutation et les états associés
  const [deleteProspect, { loading, error, data }] = useMutation(
    DELETE_PROSPECT_MUTATION,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      },
    }
  );

  // Fonction pour appeler la mutation
  const handleDeleteProspect = async (uuid) => {
    try {
      // Appelle la mutation avec le UUID du prospect à supprimer
      const response = await deleteProspect({ variables: { uuid } });

      // Vérifiez si la suppression a réussi
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return response.data.deleteProspect;
    } catch (err) {
      // Gérez les erreurs ici
      console.error("Error deleting prospect:", err.message);
      throw err;
    }
  };

  return { handleDeleteProspect, loading, error, data };
};

export default useDeleteProspect;
