




import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogDescription, DialogHeader, DialogClose } from '@/components/ui/dialog'; // Ajustez selon la bibliothèque que vous utilisez
import { Button } from '@/components/molecules/buttons/button';
import { Label } from '@/components/atoms/texts/label';
import moment from 'moment';
import {
    ToggleGroup,
    ToggleGroupItem,
} from "@/components/ui/toggle-group";
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import { useTranslation } from 'react-i18next';

const EventSettings = ({ open, setDialogOpen, onSelect, selectedSlot , clearSelectedSlot}) => {
    const [timeSelection, setTimeSelection] = useState("FULL_DAY");
    const [isMultipleDays, setIsMultipleDays] = useState(false);
    const [t] = useTranslation();
    const { i18n } = useTranslation();

    // TODO : dynamiser les eventTypes
    const eventTypes = [
        { title: "consultants.timesheet.daysIllness", type:'ILLNESS', bgColor: 'bg-chart6' },
        { title: "consultants.timesheet.daysWorked", type:'WORK', bgColor: 'bg-chart1' },
        { title: "consultants.timesheet.daysVacation", type:'VACATION', bgColor: 'bg-chart3' },
        { title: "consultants.timesheet.daysTraining", type:'TRAINING', bgColor: 'bg-chart7' }];

    useEffect(() => {
        if (selectedSlot) {
            const isMultipleDays = moment(selectedSlot.end).diff(moment(selectedSlot.start), 'days') >= 1;
            setIsMultipleDays(isMultipleDays);
            if (isMultipleDays) {
                setTimeSelection('FULL_DAY');
            }
        }
    }, [selectedSlot]);

    const handleTimeSelectionChange = (value) => {
        console.log("handleTimeSelectionChange : value", value)
        value && setTimeSelection(value);
    };

    const handleSelectEventType = (title, type, bgColor) => {
        let start = selectedSlot.start;
        let end = selectedSlot.end;

        if (timeSelection === 'HALF_DAY_MORNING') {
            start = moment(start).startOf('day').toDate();
            end = moment(start).hours(12).toDate();
        } else if (timeSelection === 'HALF_DAY_AFTERNOON') {
            start = moment(start).hours(12).toDate();
            end = moment(start).endOf('day').toDate();
        }
        console.log('handleSelectEventType', { title, type, bgColor, start, end });
        onSelect(title,type, bgColor, start, end, timeSelection);
    };


    const formatDate = (date) => {
        return moment(date).locale(i18n.language).format('LL');
    };

    const formatDateTime = (date) => {
        return moment(date).locale(i18n.language).format('LLL');
    };
const handleClearSelectingSlot=()=>{
    console.log("handleClearSelectingSlot", selectedSlot)
    clearSelectedSlot()
}
    if (open) {
        return (

            <div className='rounded-sm absolute px-firstDegree py-secondDegree mx-4 right-[32rem] w-[80%] shadow-md px-paddingLeftAndRight py-paddingTopAndBottom  rounded-sm flex flex-col justify-center bg-medium h-auto max-h-5/6 top-1/2 transform -translate-y-1/2'>
                <DialogHeader>
                    <DialogTitle className="flex justify-between">
                        <Label size="h4" fontWeight="bold" className="" message="global.actions.select" />
                        <Button onClick={handleClearSelectingSlot}>X</Button>

                    </DialogTitle>
                    {/* //TODO textes dans global */}
                    {selectedSlot && (
                        <DialogDescription>
                            <div className='flex flex-col'>
                                <Label>Date de début : {formatDate(selectedSlot.start)}</Label>
                                <Label>Date de fin : {isMultipleDays ? formatDate(selectedSlot.end) : formatDateTime(selectedSlot.end)}</Label>
                            </div>
                        </DialogDescription>
                    )}
                </DialogHeader>
                <div className="flex flex-col gap-4 py-4">
                    <ToggleGroup type="single"  value={timeSelection} onValueChange={handleTimeSelectionChange} className='w-full bg-transparent'>
                        <ToggleGroupItem value="FULL_DAY" aria-label="FULL_DAY" disabled={isMultipleDays}>
                            <Label message="consultants.timesheet.FULL_DAY"/>
                        </ToggleGroupItem>
                        {!isMultipleDays && (
                            <>
                                <ToggleGroupItem value="HALF_DAY_MORNING" aria-label="HALF_DAY_MORNING">
                                    <Label message="consultants.timesheet.HALF_DAY_MORNING"/>
                                </ToggleGroupItem>
                                <ToggleGroupItem value="HALF_DAY_AFTERNOON" aria-label="HALF_DAY_AFTERNOON">
                                    <Label message="consultants.timesheet.HALF_DAY_AFTERNOON"/>
                                </ToggleGroupItem>
                            </>
                        )}
                    </ToggleGroup>
                    <Separator />
                    <div className="flex flex-col space-y-2">
                        {eventTypes.map((type) => (
                            <Button text={type.title} className={`${type.bgColor}`} style="ghost" key={type.title} onClick={() => handleSelectEventType(type.title, type.type, type.bgColor)}>
                                
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
};

export default EventSettings;
