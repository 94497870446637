import React, { useState } from 'react';
import { Button } from '@/components/molecules/buttons/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/molecules/popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation } from 'react-router-dom'; // Importez useLocation


import TranslateIcon from '@mui/icons-material/Translate';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import useCVTab from '../../../../../hooks/global-hooks/useCVTab';
import { useParams } from 'react-router-dom';
import EditCVItemProspectPanel from '@/components/macro-organisms/panels/panels-admin/prospectConsultant/EditCvItemProspectPanel';

export default function ShowMoreButton({ uuid, dataItem, type, setIsDoingAction }) {
    const { templateID } = useParams();
    const [open, setOpen] = useState(false);
    const user = {
        uuid: templateID,
    };

    // type = certification , project, experience ou education
    const location = useLocation(); // Utilisez useLocation pour accéder à l'objet de localisation
    const { deleteBlockById, duplicateBlockById, translateBlockById } = useCVTab();
    // Vérifiez si l'URL contient "generate"
    if (location.pathname.includes("generate")) {
        return null; // Ne rien rendre si l'URL contient "generate"
    }
    return (
        <div className=" absolute top-0 right-0 w-fit">
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger>
                    <Button
                        style="ghost"
                        showLeftIcon
                        showText={false}
                        leftIcon={<MoreVertIcon />}
                    />
                </PopoverTrigger>
                <PopoverContent >
                    <div className="flex flex-col items-start">
                        {/* <Button
                            style="ghost"
                            showLeftIcon
                            leftIcon={<TranslateIcon />}
                            className="w-full justify-start"
                            text="global.actions.translate"
                            onClick={() => {
                                translateBlockById(uuid);
                            }}
                        /> */}
                        {/* <Button style="ghost" showLeftIcon leftIcon={<EditIcon />} className="w-full justify-start" text="global.actions.edit"/> */}
                        <EditCVItemProspectPanel
                            templateUuid={uuid}
                            dataItem={dataItem}
                            type={type}
                            setIsDoingAction={setIsDoingAction}
                            setOpenShowMoreButton={setOpen}
                            className="w-full justify-start "
                        />
                        {/* <Button
                            style="ghost"
                            showLeftIcon
                            leftIcon={<FileCopyIcon />}
                            className="w-full justify-start"
                            text="global.actions.duplicate"
                            onClick={() => {
                                duplicateBlockById(uuid);
                            }}
                        />
                        <Button
                            style="ghost"
                            showLeftIcon
                            leftIcon={<DeleteSweepIcon />}
                            className="w-full justify-start"
                            text="global.actions.delete"
                            onClick={() => {
                                deleteBlockById(uuid);
                            }}
                        /> */}
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
}