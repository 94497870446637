

import React, { useEffect, useState } from 'react';
import NewTimesheetPanel from '../../consultants-vision-admin/timesheet/NewTimesheetPanel';
import Timesheet from '../../consultants-vision-admin/timesheet/Timesheet';
import { Toaster } from '@/components/organisms/feedback/toaster';
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import InViewComponent from '@/components/organisms/InViewComponent';
import { useTimesheets } from '@/services/consultant-services/query/useTimesheets';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { Button } from "@/components/molecules/buttons/button";

export default function TimesheetTabUser() {
    const [lastSlice, setLastSlice] = useState(1);
    const [timesheetsCopy, setTimesheetsCopy] = useState([]);
    const { loading, error, data: timesheets, refetch } = useTimesheets(1);

    const refetchFromScratch = (n = 1) => {
        console.log("debug refetchFromScratch")

        setLastSlice(n);
        setTimesheetsCopy([]);
        // pour éviter que refetchFromScratch vide les données
        refetch(n).then((res) => {
            console.log("debug res = ", res.loading, res.error, res.data);
            if (res.data.timesheets.timesheets) {
                setTimesheetsCopy(res.data.timesheets.timesheets);
            }
        });
    };

    const fetchNewSlice = () => {
        if (!loading && timesheetsCopy.length > 0 && timesheets?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };

    useEffect(() => {
        console.log("debug timesheets", timesheets,"timesheetsCopy",timesheetsCopy, loading,"lastSlice", lastSlice)
        if (!loading && timesheets) {
            if (lastSlice == 1) {
                setTimesheetsCopy(() => [
                    ...timesheets?.timesheets
                ]);
            } else {
                setTimesheetsCopy((prevTimesheets) => [
                    ...prevTimesheets,
                    ...timesheets?.timesheets
                ]);
            }

        }
    }, [timesheets]);

    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-auto pb-secondDegree px-1">
            <div className="flex gap-secondDegree">
                <Toaster />
                <Button style="ghost" disabled={loading} showLeftIcon leftIcon={<ReplayRoundedIcon />} onClick={() => refetchFromScratch()} showText={false} sizePadding={"firstDegree"} />
                <NewTimesheetPanel global={false} refetchTimesheets={refetchFromScratch} newTimesheetButton/>
            </div>

            {loading && <div className="w-full flex justify-center"><span className="loader" /></div>}
            {error && <PrintErrorMessage error={error} />}
            {(!error && !loading && timesheetsCopy.length > 0) && timesheetsCopy.map((item, index) => (
                <Timesheet key={index} data={item} dataOwner refetchTimesheets={refetchFromScratch} />
            ))}
             
            {!error && !loading && timesheetsCopy.length === 0 && <PrintErrorMessage message="error.emptyDataTimesheet" />}
            <InViewComponent inView={fetchNewSlice} />
        </div>
    );
}