export async function isBlobEmpty(blobUrl) {
  try {
    const response = await fetch(blobUrl);
    if (!response.ok) {
      throw new Error("Failed to fetch the Blob");
    }

    const blob = await response.blob();
    return blob.size === 0;
  } catch (error) {
    console.error("Error checking Blob:", error);
    return true; // Assume empty if there is an error
  }
}

