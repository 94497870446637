import { useState } from "react";
import { useAuth } from "@/context/AuthContext";

export const useStoreCompanyBenefitInvoice = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);


    const storeCompanyBenefitInvoice = async (file, consultantUuid) => {
        setLoading(true);
        setError(null);
        console.log("storeCompanyBenefitInvoice file = ", file, "consultantUuid = ", consultantUuid);

        if (!file || !consultantUuid) return;

        const formData = new FormData();
        formData.append("companyBenefitInvoice", file);
        formData.append("consultantUuid", consultantUuid);


        try {
            const response = await fetch(window.ENV.BACKEND_URL + "/api/secured/admin/companyBenefit/companyBenefitInvoiceFile", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${user.strongToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to store company benefit invoice");
            }
            console.log(" storeCompanyBenefitInvoice response", response)
            setLoading(false);
            const result = await response.json();
            setData(result);
            return { data : result, error: null }; // data contains the UUID of the uploaded proof

        } catch (err) {

            setLoading(false);
            setError(err.message);
            return { data: null, error: err.message };

        } finally {
            setLoading(false);
        }
        
    };

    return { storeCompanyBenefitInvoice, loading, error, data };
};
