/* eslint-disable react/prop-types */
import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";

export default function TextFieldInput({
  placeHolder = "",
   required=false,
  color,
  className,
  type,
  value,
  ...props
}) {
  const [t] = useTranslation("global");
  const label = t(placeHolder);

  return (
    <Box
      className={cn(className)}
      sx={{
        maxWidth: "100%",
      }}
    >
      <TextField
        label={label}
        // Remove label for date type when value is present
        id="outlined-size-small"
        size="small"
        fullWidth
        className={cn(className)}
        type={type}
        required={required}
        sx={{
          // Style to hide placeholder text for date type if no value
          "& input[type='date']::-webkit-datetime-edit": {
            color: !value ? "transparent" : "inherit", // Hide the placeholder text in Webkit-based browsers if no value
          },
          "& input[type='date']::-moz-placeholder": {
            color: !value ? "transparent" : "inherit", // Firefox placeholder text color to be transparent if no value
          },
          "& input[type='date']::-ms-input-placeholder": {
            color: !value ? "transparent" : "inherit", // IE/Edge placeholder text color to be transparent if no value
          },
        }}
        value={value}
        {...props}
      />
    </Box>
  );
}
