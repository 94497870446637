import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';
import errorToText from '@/utils/errorToText';

const useUpdateConsultantProfile = () => {

    const [response, setResponse] = useState(null);
    const { user: authUser } = useAuth();

    const setConsultantProfile = async (uuid, consultantProfileUuid) => {
        if (!authUser.strongToken) {
            setError('No strong token available');
            return { data: null, error: 'No strong token available' };
        }
// console.log("send : ", uuid, consultantProfileUuid);
        try {
            const response = await fetch(window.ENV.BACKEND_URL+'/api/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authUser.strongToken}`
                },
                body: JSON.stringify({
                    query: `
                    mutation MyMutation {
                        updateUser(
                          updatedUser: {
                            consultantProfileUuid: "${consultantProfileUuid}", 
                            uuid: "${uuid}"
                          }
                        ) {
                          consultantProfile {
                            name
                            uuid
                          }
                        }
                      }
                    `
                })
            });

            

            if (!response.ok) {
                const errMessage = errorToText(response);
                throw new Error(errMessage);
            }

            const data = await response.json();
            // console.log("received", response, data);
            if (data.errors) {
                const errMessage = data.errors[0].message;
                throw new Error(errMessage);
            }

            setResponse(data.data.getUser);

            return { data: data.data.updateUser, error: null };
        } catch (err) {
            return { data: null, error: err.message };
        }
    };

    return { setConsultantProfile, uuidResponnse: response };
};

export default useUpdateConsultantProfile;