/* eslint-disable react/prop-types */
/* eslint-disable react/prop-types */
/**
 * @component AddEducation
 *
 * The `AddEducation` component is used to add or edit education details. It includes a form for inputting information such as the title, school, country, city, start and end dates, and a description. The component supports both adding new entries and editing existing ones, with functionality for validating form inputs, handling form submission, and deleting education records.
 *
 * Key Features:
 * - Render a form for adding or editing education details.
 * - Validate form inputs and manage form state.
 * - Submit the form to add or update education records.
 * - Handle form cancellation and deletion of education entries.
 * - Provides feedback on form validation and server response errors.
 *
 * Props:
 * - @param {Object} education - Existing education data for editing mode (optional).
 * - @param {boolean} showForm - Whether to show the form.
 * - @param {Function} setShowForm - Function to toggle the visibility of the form.
 * - @param {boolean} [editingEducation=false] - Flag to indicate if the form is in edit mode.
 * - @param {Function} setEditingEducation - Function to toggle the edit mode for education.
 * - @param {Object} user - User object containing user details, such as UUID, required for API interactions.
 * - @param {Function} setIsDoingAction - Function to toggle loading or action states when performing tasks.
 * - @param {Function} setFormIsValid - Function to update the form's validation status.
 * - @param {Function} setServerResponseIserror - Function to handle the error state based on server responses.
 * - @param {Function} setErrors - Function to update the error messages.
 *
 * Local State:
 * - `confirmDelete`: Boolean to control the confirmation state for deletion.
 * - `formData`: Object to manage the form's input data.
 * - `erros`: Object to store error messages for each field.
 * - `listOfInvalidInput`: Array of invalid input fields for validation purposes.
 *
 * Usage:
 * - This component is used in user profile settings or administrative panels to add or edit education records.
 * - Integrates with services for updating and deleting education records, as well as handling server responses.
 *
 * Example:
 * ```jsx
 * <AddEducation
 *   showForm={showForm}
 *   setShowForm={setShowForm}
 *   editingEducation={editingEducation}
 *   setEditingEducation={setEditingEducation}
 *   user={currentUser}
 *   setIsDoingAction={toggleLoading}
 *   setFormIsValid={validateForm}
 *   setServerResponseIserror={handleServerError}
 *   setErrors={updateErrors}
 * />
 * ```
 *
 * Dependencies:
 * - `Button` component for form actions (Save, Cancel, Delete).
 * - `ClearIcon`, `DeleteIcon`, `SaveIcon`, and `DeleteOutlineIcon` from `@mui/icons-material` for icons.
 * - `Form` component from `react-hook-form` for handling form validation and submission.
 * - `TextArea` component for multi-line text input.
 * - `TextFieldInput` component for single-line text input.
 * - `Select`, `SelectContent`, `SelectItem`, `SelectTrigger`, and `SelectValue` components for selecting countries.
 * - `useUpdateProspect`, `useUpdateProspectDeleteItems`, and `useUpdateProspectUpdateItems` hooks for managing education records.
 * - `validateFormData` and `deleteFromInvalidInputs` utility functions for form validation.
 * - `transformedCountries` utility for getting country data.
 * - `getAppLanguage` utility for fetching the application language.
 * - `motion` from `framer-motion` for animations.
 *
 * Sub-Components:
 * - @component Button: A reusable button component used for form actions like Save, Cancel, and Delete.
 * - @component TextFieldInput: A single-line text input component for fields like title, school, city, startDate, and endDate.
 * - @component TextArea: A multi-line text input component for providing a description.
 * - @component Select: A dropdown component used to select a country from a list.
 * - @component SelectContent: Displays the options inside the `Select` dropdown.
 * - @component SelectItem: Represents an individual option in the `Select` dropdown.
 * - @component SelectTrigger: The clickable element that opens the `Select` dropdown.
 * - @component SelectValue: Displays the currently selected value in the `Select` dropdown.
 * - @component Form: A wrapper from `react-hook-form` that handles form state and validation.
 * - @component Label: A text label component used for displaying placeholder and error messages.
 * - @component PrintErrorMessage: Displays error messages related to form submission or server responses.
 */

import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import TextArea from "@/components/molecules/inputs/TextArea";
import { validateFormData } from "./validateForm";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import { transformedCountries } from "@/utils/getCountriesLanguagesAndCode";
import useUpdateProspectUpdateItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectUpdateItems";
import { Label } from "@/components/atoms/texts/label";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import { motion } from "framer-motion";
// Utilisation
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import useUpdateProspect from "@/services/admin-services/mutations/updateProspect/useUpdateProspectAddItems";
import getAppLanguage from "@/utils/getAppLanguage";
import useUpdateProspectDeleteItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectDeleteItems";

export default function AddEducation({
    education,
    showForm,
    setShowForm,
    editingEducation = false,
    setEditingEducation,
    user,
    setIsDoingAction,
    setFormIsValid,
    setServerResponseIserror,
    setErrors,
    setOpenShowMoreButton,
}) {
    const form = useForm();
    const { addEducation, errorAddEducation } = useUpdateProspect();
    const { deleteEducation, errorDeleteEducation } = useUpdateProspectDeleteItems()
    const { updateEducation, errorUpdateEducation } =
        useUpdateProspectUpdateItems();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const appLanguage = getAppLanguage();
    const [formData, setFormData] = useState({
        title: education ? education.title : "",
        school: education ? education.school : "",
        country: education ? education.countryCode : "",
        city: education ? education.city : "",
        startDate: education ? education.startDate : "",
        endDate: education ? education.endDate : "",
        description: education ? education.description : "",
        educationUuid: education ? education.uuid : "",
    });
    const [erros, setErros] = useState({
        title: "",
        school: "",
        country: "",
        city: "",
        startDate: "",
        endDate: "",
        description: "",
    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const handleChange = (e, name) => {
        setEditingEducation && setEditingEducation(true);
        const { value } = e.target;
        deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);
        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setFormIsValid && setFormIsValid(false);
        // console.log(formData);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErros);
        setEditingEducation && setEditingEducation(false);

        setlistOfInvalidInput(isValid);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            if (editingEducation) {
                await updateEducation(
                    transformToEducationData(formData, appLanguage),
                    user.uuid
                );

                setIsDoingAction && setIsDoingAction(true);
                if (errorUpdateEducation) {
                    setErrors && setErrors(errorUpdateEducation);
                    setFormIsValid && setFormIsValid(false);
                    setServerResponseIserror && setServerResponseIserror(true);
                } else {
                    setFormIsValid && setFormIsValid(true);
                }
            } else {
                try {
                    await addEducation(
                        transformToEducationData(formData, appLanguage),
                        user.uuid
                    );

                    setIsDoingAction && setIsDoingAction(true);
                    if (errorAddEducation) {
                        console.error("Failed to add description:", errorAddEducation);
                        //alert("Add successfully ");
                        setFormIsValid && setFormIsValid(false);

                        setServerResponseIserror && setServerResponseIserror(false);
                        setEditingEducation && setEditingEducation(false);
                        if (errorAddEducation && setErrors) setErrors(errorAddEducation);
                    } else {
                        setFormIsValid && setFormIsValid(true);
                    }
                    console.log("Updated Prospect Projects:");
                } catch (err) {
                    //alert(`Failed to update prospect: ${err.message}`);
                    setFormIsValid && setFormIsValid(false);
                }
                // Sinon, ajoutez simplement la nouvelle description à la liste
                // console.log("id ", id);
                // setEducations((prevData) => [...prevData, formData]);
            }
            setShowForm && setShowForm(false);
            setOpenShowMoreButton && setOpenShowMoreButton(false);

        } else {
            console.log("invalid form");
            console.log("value invalid", isValid);
            console.log("form data", formData);
            console.log("value invalid", erros);
            setFormIsValid && setFormIsValid(false);
        }
        setConfirmDelete(false);
    };
    const handleCancel = () => {
        setEditingEducation && setEditingEducation(false);
        setShowForm && setShowForm(false);
        setConfirmDelete(false)
        setOpenShowMoreButton && setOpenShowMoreButton(false);

    };
    const handleDelete = async (uuid) => {
        try {
            // Replace with actual project UUID to delete
            const updatedProjects = await deleteEducation(user.uuid, uuid);
            //alert("Updated Projects:", updatedProjects);
            setIsDoingAction && setIsDoingAction(true);

            if (errorDeleteEducation) {
                setErrors && setErrors(errorDeleteEducation);
                setFormIsValid && setFormIsValid(false);
                setServerResponseIserror && setServerResponseIserror(true);
            } else {
                setFormIsValid && setFormIsValid(true);
            }
            setOpenShowMoreButton && setOpenShowMoreButton(false);

        } catch (err) {
            // alert("Failed to delete project:", err);
            console.error("Failed to delete project:", err);
        }
        setConfirmDelete(false);
    };
    const handleChangeCountry = (countryCode) => {
        const name = "country";
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: countryCode,
        }));

        console.log("selected counrty, ", countryCode);
    };
    return (
        <div>
            {showForm && (
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit}
                        className={` flex flex-col gap-secondDegree shadow-md bg-lighter  border-sm border-medium  p-secondDegree`}
                    >
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.educationTitle"
                            }
                            required
                            type="text"
                            value={formData.title}
                            onChange={(e) => handleChange(e, "title")}
                            error={listOfInvalidInput.includes("title")}
                        // border={
                        //   listOfInvalidInput.includes("educationTitle")
                        //     ? "border-error"
                        //     : ""
                        // }
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.school"
                            }
                            required
                            type="text"
                            value={formData.school}
                            onChange={(e) => handleChange(e, "school")}
                            error={listOfInvalidInput.includes("school")}
                        // border={
                        //   listOfInvalidInput.includes("school") ? "border-error" : ""
                        // }
                        />
                        <TextFieldInput
                            required
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.city"
                            }
                            type="text"
                            value={formData.city}
                            onChange={(e) => handleChange(e, "city")}
                            error={listOfInvalidInput.includes("city")}
                        // border={listOfInvalidInput.includes("city") ? "border-error" : ""}
                        />

                        <Select
                            onValueChange={handleChangeCountry}
                            value={formData.country}
                        >
                            <SelectTrigger className="rounded-[5px] bg-transparent border-greyStrong focus:ring-0 focus:ring-offset-0">
                                <SelectValue
                                    placeholder={
                                        <Label message="consultants.statement.prospectConsultantPanel.formPlaceHolder.country" />
                                    }
                                />
                            </SelectTrigger>

                            <SelectContent>
                                <>
                                    {transformedCountries &&
                                        Object.values(transformedCountries).map(
                                            (country, index) => (
                                                <SelectItem value={country.code} key={index}>
                                                    <Label>{country.name}</Label>
                                                </SelectItem>
                                            )
                                        )}
                                </>
                            </SelectContent>
                        </Select>

                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.startDate"
                            }
                            required
                            type={"date"}
                            value={formData.startDate}
                            onChange={(e) => handleChange(e, "startDate")}
                            error={listOfInvalidInput.includes("startDate")}
                        // border={
                        //   listOfInvalidInput.includes("startDate") ? "border-error" : ""
                        // }
                        />
                        {/* <DatePickerValue /> */}

                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.endDate"
                            }
                            required
                            type={"date"}
                            value={formData.endDate}
                            onChange={(e) => handleChange(e, "endDate")}
                            error={listOfInvalidInput.includes("endDate")}
                        // border={
                        //   listOfInvalidInput.includes("endDate") ? "border-error" : ""
                        // }
                        />
                        <TextArea
                            //value={value}
                            //onChange={onChange}
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.description"
                            rows={4} // Nombre de lignes par défaut
                            cols={40}
                            value={formData.description}
                            onChange={(e) => handleChange(e, "description")}
                            border={
                                listOfInvalidInput.includes("description") ? "border-error" : ""
                            } // Nombre de colonnes par défaut

                        // Ajoutez d'autres attributs ou styles au besoin
                        />

                        <div className="flex flex-row gap-[12px] items-center w-full">
                            {editingEducation && (
                                <>
                                    {!confirmDelete ? (
                                        <Button
                                            onClick={() => setConfirmDelete(true)}
                                            showLeftIcon={true}
                                            showText={false}
                                            //sizePadding="firstDegree"
                                            leftIcon={
                                                <DeleteIcon className="text-primary cursor-pointer" />
                                            }
                                            style="ghost"
                                        />
                                    ) : (
                                        <motion.div
                                            className="flex flex-row gap-2 w-[70%] items-center"
                                            initial={{ x: -100, opacity: 0 }}
                                            animate={{ x: 0, opacity: 1 }}
                                            transition={{ duration: 0.5 }}
                                        >
                                            <Label message="consultants.statement.vatSettings.confirmDelete" />
                                            <Button
                                                showLeftIcon
                                                className="text-error border-error rounded-sm bg-white"
                                                leftIcon={
                                                    <DeleteOutlineIcon
                                                        className="text-error"
                                                        fontSize="small"
                                                    />
                                                }
                                                // showText={false}
                                                text={"global.actions.confirmDelete"}
                                                sizePadding="small"
                                                style="outline"
                                                onClick={() => handleDelete(education.uuid)}
                                            />
                                        </motion.div>
                                    )}
                                </>
                            )}
                            <Button
                                showLeftIcon={true}
                                leftIcon={<ClearIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.cancel"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="outline"
                                className={`${confirmDelete ? "w-[17%]" : "font-bold border-primary w-full"
                                    }`}
                                showToolTip={confirmDelete ? true : false}
                                onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                            <Button
                                showLeftIcon={true}
                                leftIcon={<SaveIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.save"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="fill"
                                showToolTip={confirmDelete ? true : false}
                                className={`${confirmDelete ? "w-[16%]" : "font-bold w-full"}`}
                                type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                    </form>
                </Form>
            )}
        </div>
    );
}

const transformToEducationData = (formdata, lang) => {
    return {
        city: formdata.city,
        company: formdata.company,
        countryCode: formdata.country,
        description: formdata.description,
        endDate: formdata.endDate,
        industry: formdata.industry,
        language: lang,
        startDate: formdata.startDate,
        title: formdata.title,
        school: formdata.school,
        educationUuid: formdata.educationUuid,
    };
};
