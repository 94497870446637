/* eslint-disable react/prop-types */
/**
 * @component ProfileAvatar
 *
 * The `ProfileAvatar` component displays a user's profile avatar with options to edit or save a new avatar image. It includes functionalities for handling file uploads and drag-and-drop image selection. When in editing mode, it allows users to choose a new image and save or cancel the changes.
 *
 * Key Features:
 * - Displays the current profile avatar or a fallback image if the avatar source is empty.
 * - Allows users to enter editing mode to update the avatar image.
 * - Supports drag-and-drop and file selection for uploading a new avatar image.
 * - Provides save and cancel buttons to handle changes.
 * - Displays a loading spinner while the avatar image is being processed.
 *
 * Props:
 * - @param {string} avatarSrc - The URL or base64 string of the current avatar image.
 * - @param {Function} setAvatarSrc - Function to update the avatar source.
 * - @param {string} name - The user's last name, used for the fallback image.
 * - @param {string} firstName - The user's first name, used for the fallback image.
 * - @param {boolean} loading - Flag indicating whether the avatar is being loaded.
 * - @param {Function} setIsDoingAction - Function to indicate that an action (like saving) is in progress.
 *
 * Usage:
 * - This component is used in user profile settings to allow users to view, edit, and update their profile avatars. It integrates with a drag-and-drop zone for file uploads and provides feedback during the upload process.
 *
 * Example:
 * ```jsx
 * <ProfileAvatar
 *   avatarSrc={currentAvatar}
 *   setAvatarSrc={updateAvatar}
 *   name={userLastName}
 *   firstName={userFirstName}
 *   loading={isAvatarLoading}
 *   setIsDoingAction={setLoadingState}
 * />
 * ```
 *
 * Dependencies:
 * - `Button` component for form actions (Save, Cancel).
 * - `Avatar` component for displaying the profile avatar.
 * - `DragAndDropZone` component for handling file uploads via drag-and-drop.
 * - `isBlobEmpty` utility function to check if the avatar image is empty.
 * - `personIcon` for the fallback avatar image.
 *
 * Sub-Components:
 * - @component Button: Provides Save and Cancel buttons in the editing mode.
 * - @component Avatar: Displays the user's profile avatar.
 * - @component DragAndDropZone: Handles the file selection and drag-and-drop for uploading a new avatar.
 */

import { useState, useEffect } from "react";
import { Button } from "@/components/molecules/buttons/button";
import { Avatar } from "@/components/atoms/avatar";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import DragAndDropZone from "@/components/organisms/forms/DragAndDropZone";
import { isBlobEmpty } from "@/utils/isBloobEmpty";
import personIcon from "@/assets/images/Icon/personIcon.jpg";
const ProfileAvatar = ({
    avatarSrc,
    setAvatarSrc,
    name,
    firstName,
    loading,
    setIsDoingAction,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    //   const [avatarSrc, setAvatarSrc] = useState(avatarUrl);
    const [newAvatar, setNewAvatar] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const checkImage = async () => {
            const isEmpty = await isBlobEmpty(avatarSrc);
            if (isEmpty) {
                if (firstName === "XXX") {
                    setImageSrc(personIcon);
                } else {
                    setImageSrc(null); // Use fallback in Avatar
                }
            } else {
                setImageSrc(avatarSrc);
            }
        };

        checkImage();
    }, [avatarSrc, firstName]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        // Ici, vous pouvez implémenter la logique pour enregistrer la nouvelle photo de profil
        if (newAvatar) {
            await setAvatarSrc(newAvatar);
            // Réinitialiser le nouveau avatar après l'avoir enregistré
            setNewAvatar(null);
        }
        setIsEditing(false);
        setIsDoingAction && setIsDoingAction(true);
    };

    const handleCancelClick = () => {
        // Réinitialiser le nouvel avatar et sortir du mode édition
        setNewAvatar(null);
        setIsEditing(false);
    };

    const handleAvatarChange = async (event) => {
        // Mettre à jour l'état du nouvel avatar lorsqu'un fichier est sélectionné
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            //setNewAvatar(reader.result);
            setNewAvatar(file);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    //  const handleDrop = (event) => {
    //    event.preventDefault();
    //    const file = event.dataTransfer.files[0];
    //    const reader = new FileReader();
    //    reader.onloadend = () => {
    //      setNewAvatar(reader.result);
    //    };
    //    if (file) {
    //      reader.readAsDataURL(file);
    //    }
    //  };

    //  const handleDragOver = (event) => {
    //    event.preventDefault();
    //  };

    return (
        <div className="w-full">
            {isEditing ? (
                <div className="flex flex-col gap-secondDegree w-full">
                    {/* <input type="file" accept="image/*" onChange={handleAvatarChange} /> */}
                    <DragAndDropZone
                        file={newAvatar}
                        setFile={setNewAvatar}
                    // fileError={fileError}
                    />
                    <div className="flex flex-row gap-firstDegree w-full">
                        <Button
                            showLeftIcon={true}
                            leftIcon={<ClearIcon fontSize="small" />}
                            showText={true}
                            text="global.actions.cancel"
                            sizePadding="default"
                            style="outline"
                            onClick={handleCancelClick}
                            className="w-full"
                        />
                        <Button
                            showLeftIcon={true}
                            leftIcon={<SaveIcon fontSize="small" />}
                            showText={true}
                            text="global.actions.save"
                            sizePadding="default"
                            style="fill"
                            onClick={handleSaveClick}
                            className="w-full"
                        />
                    </div>
                </div>
            ) : (
                <div className="flex items-center gap-firstDegree relative">
                    {loading ? (
                        <span className="loader"></span>
                    ) : (
                        
                        <Avatar
                            fallback={`${firstName} ${name}`}
                            size="medium"
                            src={imageSrc}
                            onEdit={handleEditClick}
                        />
                    )}

                    {/* <Button
            //showText={true}
            //text="global.actions.edit"
            showLeftIcon={true}
            leftIcon={<EditIcon className="text-white" fontSize="small" />}
            sizePadding="small"
            style="ghost"
            className=" absolute ml-2  mr-3 mt-6 w-[40px] "
            onClick={handleEditClick}
          /> */}
                    {/* <Button
            showLeftIcon={true}
            leftIcon={<EditIcon className="text-primary" fontSize="small" />}
            showText={false}
            style="ghost"
            onClick={handleEditClick}
          /> */}
                </div>
            )}
        </div>
    );
};

export default ProfileAvatar;
