import { useAuth } from "@/context/AuthContext";
import { useParams } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";


const GET_INVOICE_YEARS = gql`
  query ConsultantInvoiceYears($consultantUuid: ID!) {
    consultantInvoiceYears(consultantUuid: $consultantUuid)
  }
`;

// };
export const useConsultantInvoiceYears = () => {
  const { id } = useParams();
  const { user } = useAuth();

  const { loading, error, data, refetch } = useQuery(GET_INVOICE_YEARS, {
    variables: { consultantUuid: id },
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    fetchPolicy: "network-only", // Ensures data is always fetched from the server
  });

  return { loading, error, data, refetch };
};