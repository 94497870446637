import { Label } from '@/components/atoms/texts/label';
import React from 'react';

export default function DescriptionItem({ data, className, ...props }) {
    return (
        <div className={` ${className}`} {...props} >
            <Label {...props}>{data.description} </Label>

</div>

    )
}