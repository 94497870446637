import EditableItemOverview from "@/components/molecules/inputs/EditableOverview/EditableItemOverview";
import { getStatisticValue } from "@/utils/transformUserData";
import { useAuth } from "@/context/AuthContext";
import { cn } from "@/lib/utils";

let labelsOfItems = [
    {
        label: "daysWorked",
        message: "consultants.statement.overview.daysWorked",
    },
    { label: "daysSick", message: "consultants.statement.overview.sickDays" },
    { label: "companyBenefit", message: "consultants.statement.overview.companyBenefits" },
    {
        label: "expenses",
        message: "consultants.statement.overview.expenseAccount",
    },
    { label: "s42Margin", message: "consultants.statement.overview.s42Margin" },
    {
        label: "overflow",
        message: "consultants.statement.overview.globalOverflow",
    },
    {
        label: "grossSalary",
        message: "consultants.statement.overview.grossSalary",
    },
    {
        label: "employerCost",
        message: "consultants.statement.overview.totalCostEmployee",
    },
    {
        label: "employerContributions",
        message: "consultants.statement.overview.employerContributions",
    },
    // {
    //     label: "consultantServiceAmount",
    //     message: "consultants.statement.billsDetails.commission"
    // },
    { label: "taxes", message: "consultants.statement.overview.taxes" },
    {
        label: "dailyRateClient",
        message: "consultants.statement.overview.dailyRate",
    },
    {
        label: "dailyRateConsultant",
        message: "consultants.statement.overview.dailyRateConsultant",
    },
];
export default function ListOfEditableOverview({
    year,
    userActive,
    className,
    yearData,
}) {
    const { user, hasPrivileges, isAuth } = useAuth();

    // if (user.role != "admin")
    if (!hasPrivileges("ADMIN_STATEMENT")) {
        labelsOfItems = labelsOfItems.filter((item) => {
            console.log("item", hasPrivileges("ADMIN_STATEMENT"), item.label != "s42Margin", item.label != "dailyRateClient", item)

            return (
                (item.label != "s42Margin" && item.label != "dailyRateClient") && item

            );
        });
        console.log("item", hasPrivileges("ADMIN_STATEMENT"))
    }


    return (
        // flex flex-col-2 items-start content-start gap-[12px] flex-shrink-0 flex-wrap
        //
        <div
            className={cn(
                "w-full  grid grid-cols-2 items-start content-start gap-[10px]  flex-shrink-0 flex-wrap  ",
                className
            )}
        >
            {labelsOfItems.map((labelItem) => (
                <EditableItemOverview
                    key={labelItem.label}
                    // initialValue={activeUser[labelItem.label]}
                    initialValue={yearData && yearData[labelItem.label]}
                    labelMessage={labelItem.message}
                />
            ))}
        </div>
    );
}
