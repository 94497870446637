/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Button } from "../../../../molecules/buttons/button";
import PaidIcon from "@mui/icons-material/Paid";

import { useAuth } from "@/context/AuthContext";
import { filterUnwantedPropertiesAdmin } from "@/utils/transformUserData";
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetSlot,
} from "../../../../templates/sheet";
import { Toaster } from "@/components/organisms/feedback/toaster";

import { useToast } from "../../../../organisms/feedback/use-toast";
import { translationsPaths } from "@/utils/translationPath";
import { useEffect } from "react";
import useUser from "@/services/admin-services/query/useUser";
import { useParams } from "react-router-dom";
import BillsContentConsultant from "./BillsContentConsultant";

//The deletion of item is complexe that why this comment is leave here
//At first i retrieve the monthdata after the user clicked on edit in billsContent, i initialise the monthdata in billscontent by condition based on existence itemTodelete
//in editBills i execute the monthdatabills after the user confirm the delete

export default function BillsMainConsultant({ years, activeUser }) {
  const [changeContent, setChangeContent] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isButtonSaveClicked, setIsbuttonSaveClicked] = useState();
  const [textInDressed, setTextInDressed] = useState("");
  const [itemTodelete, setItemTodelete] = useState(null);
  const [monthDataBillsBasedOnItemDeleted, setMonthDataItemDeleted] =
    useState(null);
  const [newBillIsClicked, setNewBillIsClicked] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { getUser, user: userClicked } = useUser();
  const { id } = useParams();

  const [isErrorInForm, setisErrorInForm] = useState(false);
  const { user, hasPrivileges } = useAuth();

  const { toast } = useToast();

  const displayToast = () => {
    toast({
      variant: "warning",
      title: "error.editWithoutSave",
      duration: 8000,
      //  action: (
      //    <ToastAction
      //      altText="Try again"
      //      onClick={() => {
      //        alert("test");
      //      }}
      //    >
      //      ici
      //    </ToastAction>
      //  ),
    });
  };
  const handleOpen = (e) => {
    setOpen(e);
    setEditing(false);
    isEditing && displayToast();
  };
  const handleDressedClicked = () => {
    setIsbuttonSaveClicked(false);
    setTextInDressed(translationsPaths.save);
    setEditData(null);
    setChangeContent(true);
    setNewBillIsClicked(true);
    //
  };
  const handleChangeContent = () => {
    changeContent ? setChangeContent(false) : setChangeContent(true);
    setTextInDressed(translationsPaths.newBill);
    setNewBillIsClicked(false);
    // onCancel()
  };
  const handleSaveCliked = () => {
    setIsbuttonSaveClicked(true);
    setTextInDressed(translationsPaths.newBill);
  };
  const handleCalledFunction = () => {
    textInDressed === translationsPaths.save
      ? handleSaveCliked()
      : handleDressedClicked();
  };
  const filterMonthDataAfterdelete = (billDeleted) => {
    const data = filterUnwantedPropertiesAdmin(
      monthDataBillsBasedOnItemDeleted.filter(
        (item) => item.bills !== billDeleted
      )
    );
    setMonthDataItemDeleted(data);
    setItemTodelete(billDeleted);
  };

  function onCancel() {
    isEditing && displayToast();

    // setOpen(false);
  }
  // Videz le tableau de dépendances car setIsButtonSaveClicked ne dépend pas de quelque chose d'autre.
  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await getUser(id);

      if (data) console.log("data user", data);
    };

    fetchUser();
  }, []);

  return (
    <Sheet open={open} onOpenChange={handleOpen}>
      <SheetTrigger asChild>
        <Button
          showLeftIcon={true}
          leftIcon={<PaidIcon fontSize="small" />}
          showRightIcon={true}
          rightIcon=""
          showText={true}
          text={translationsPaths.seeBillsDetails}
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="fill"
          className="h-full bg-primary text-white" // Options: "fill", "outline", "ghost", "dressedGhost"
        />
      </SheetTrigger>
      <SheetContent>
        <SheetHeader title={"consultants.statement.overflow.seeBillsDetails"} />
        <SheetSlot className=" flex ">
          {/*Insert here your component*/}

          <BillsContentConsultant
            activeUser={activeUser}
            setChangeContent={setChangeContent}
            setEditBillsData={setEditData}
            years={years}
            itemToDelete={itemTodelete}
            newMonDatafterDelete={monthDataBillsBasedOnItemDeleted}
            changeMonthDataDeleted={setMonthDataItemDeleted}
            monthDataAfterDelete={monthDataBillsBasedOnItemDeleted}
            informSelectedYear={setSelectedYear}
            changeContent={open}
          />
        </SheetSlot>
        {/* {hasPrivileges("ADMIN_") && (
          <SheetFooter
            //warning={"header.message"}
            //consent={"header.message"}
            //closeButton={true}
            buttonOutlineText={changeContent && translationsPaths.backAction}
            buttonDressedText={
              !changeContent
                ? translationsPaths.newBill
                : translationsPaths.save
            }
            onClickOutline={handleChangeContent}
            onClickDressed={handleCalledFunction}
          ></SheetFooter>
        )} */}
      </SheetContent>
      <Toaster />
    </Sheet>
  );
}
