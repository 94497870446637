import React, { useState } from "react";
import {
    Dialog,
    DialogTrigger
} from "@/components/ui/dialog";
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import { Button } from "@/components/molecules/buttons/button";
import StepperFormTransfer from "./StepperFormTransfer";

export default function PopUpTransfertProspect({ prospect , refetch, disabled}) {
     const [open, setOpen] = useState(false)
    
    return (
        <Dialog open={open} >
            <DialogTrigger>
                <Button
                    style="ghost"
                    showLeftIcon={true}
                    sizePadding={"pill"}
                    className={"w-min "}
                    showText={false}
                    leftIcon={<HowToRegRoundedIcon className="text-primary" />}
                    onClick={() => { setOpen(true) }}
                    disabled={disabled}
                />
            </DialogTrigger>
            
            {open && <StepperFormTransfer prospect={prospect} open={open} setOpen={setOpen} refetch={refetch}/>}

        </Dialog>)
}