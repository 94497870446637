/**
 * @component CreateAndUpdateCV
 *
 * The `CreateAndUpdateCV` component is designed for creating or updating a CV within a consultant or prospect management interface. It enables users to add, modify, or review various sections of a CV, such as personal information, skills, experience, education, certifications, and projects. It also supports file uploads (e.g., profile picture) and translations.
 *
 * Key Features:
 * - Supports the management of different CV sections (information, skills, descriptions, experience, etc.).
 * - Allows users to upload and view profile pictures.
 * - Enables the creation and update of CVs with detailed form-based inputs.
 * - Provides back and save functionality, controlled by button clicks.
 * - Dynamically shows or hides individual sections based on user interaction.
 * - Localized in multiple languages via `useTranslation`.
 *
 * Props:
 * - @prop {Function} openCvSection - Function to open a specific CV section.
 * - @prop {Function} draftIsClicked - Callback triggered when a draft action is clicked.
 * - @prop {Function} setNewIsClicked - Function to set the state of a new action.
 * - @prop {Boolean} showButtonsBackAndSave - Controls whether the back and save buttons are displayed (default: true).
 * - @prop {Boolean} showStepper - Controls the visibility of the stepper (default: true).
 * - @prop {Boolean} showingSectionInProspect - Boolean to determine if the sections should be shown in prospect view (default: true).
 * - @prop {Function} setShowFourthStep - Function to control the display of the fourth step in the process.
 * - @prop {Object} user - The authenticated user object, used for fetching and displaying data.
 * - @prop {Boolean} isDoingAction - Boolean indicating if an action is currently being performed.
 * - @prop {Function} setIsDoingAction - Function to set the state of `isDoingAction`.
 *
 * State:
 * - @variable {Boolean} hideMainComponent - Toggles the visibility of the main component view.
 * - @variable {Boolean} showCertification - Determines if the certifications section is visible.
 * - @variable {Boolean} showInformation - Determines if the information section is visible.
 * - @variable {Boolean} showExperience - Determines if the experience section is visible.
 * - @variable {Boolean} showEducation - Determines if the education section is visible.
 * - @variable {Boolean} showProject - Determines if the project section is visible.
 * - @variable {Boolean} showSkill - Determines if the skills section is visible.
 * - @variable {Boolean} showDescription - Determines if the description section is visible.
 * - @variable {String} avatarSrc - Holds the URL or data of the user's profile picture.
 *
 * Hooks/Effects:
 * - @hook {useProspectPicture} - Fetches, uploads, and manages the profile picture of the prospect.
 * - @hook {useProspect} - Retrieves the prospect data, including CV information, from the backend.
 * - @hook {useParams} - Retrieves the `prospectID` from the URL to load the appropriate prospect data.
 * - @hook {useTranslation} - Provides support for multi-language translations.
 * - @effect Retrieves and sets the prospect's profile picture when the component mounts or when the user changes.
 * - @effect Fetches the prospect's data and updates the CV sections dynamically when an action is performed.
 *
 * Helper Functions:
 * - @function getTitles - Extracts titles from the CV section data.
 * - @function getDescriptionList - Extracts descriptions from the description data.
 * - @function getSkillsList - Retrieves mapped skills based on the current language.
 * - @function getSkillsListUnmapped - Retrieves unmapped skills based on the current language.
 * - @function handleBack - Navigates back to the previous state or section.
 * - @function handleSave - Triggers the save action and moves to the next step in the process.
 *
 * Conditional Rendering:
 * - Renders a loading spinner when data is being fetched.
 * - Displays an error message if there is an issue retrieving the prospect's data or profile picture.
 * - Dynamically shows or hides individual sections (Information, Skills, Description, Experience, etc.) based on the user's interaction.
 * - Displays buttons for saving or navigating back, depending on the `showButtonsBackAndSave` prop.
 *
 * Usage:
 * This component is used in consultant or prospect management systems, enabling users to create and update CVs, manage multiple sections, and perform file uploads (e.g., profile picture). It is highly interactive and supports localization for multiple languages.
 *
 * Example:
 * <CreateAndUpdateCV
 *    openCvSection={openCvSectionFunction}
 *    draftIsClicked={handleDraftClick}
 *    setNewIsClicked={setNewClickedState}
 *    showButtonsBackAndSave={true}
 *    showStepper={true}
 *    user={authenticatedUser}
 *    isDoingAction={isActionInProgress}
 *    setIsDoingAction={setIsActionInProgress}
 * />
 *
 * Dependencies:
 * - React hooks (`useState`, `useEffect`) for managing state and lifecycle events.
 * - Custom hooks (`useProspectPicture`, `useProspect`) for fetching and managing data.
 * - `useTranslation` for handling multi-language support.
 * - UI components such as `OverviewInformationProfile`, `SectionsCvOverview`, `Button`, `Step`, `Label`, `Separator`, and `motion` for animating the components.
 *

 * Where it is used:
 * - @component ProspectConsultantPanel
 * - @component EditCVPanel
 */



import { useState, useEffect } from "react";
import OverviewInformationProfile from "./overviews/OverviewInformationProfile";
import SectionsCvOverview from "./overviews/SectionsCvOverview";
import Information from "./information/Information";
import Description from "./description/Description";
import Experience from "./experience/Experience";
import Education from "./education/Education";
import Certification from "./certification/Certification";
import Project from "./project/Project";
import { Button } from "@/components/molecules/buttons/button";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Save from "@mui/icons-material/Save";
import Step from "@/components/molecules/buttons/Step";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Label } from "@/components/atoms/texts/label";
import Skills from "./skills/SkillsInPanel";
import { motion } from "framer-motion";
import { container } from "@/utils/animationConst";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useProspectPicture from "@/services/admin-services/useProspectPicture";
import { useProspect } from "@/services/admin-services/query/useProspect";





const getTitles = (cvSectionData) => {
    return cvSectionData.map((item) => item.title);
};
const getDescriptionList = (descriptions) => {
    return descriptions.map((description) => description.description);
};
const skillsMapLang = {
    en: "labelEn",
    es: "labelEs",
    fr: "labelFr",
    it: "labelIt",
    nl: "labelNl",
};

export default function CreateAndUpdateCV({
    openCvSection,
    draftIsClicked,
    setNewIsClicked,
    showButtonsBackAndSave = true,
    showStepper = true,
    showingSectionInProspect = true,
    setShowFourthStep,
    user,
    isDoingAction,
    setIsDoingAction,
}) {


    //   //console.log("User received in CreateAndUpdateCV", user)
    const {
        profilePicture,
        loading: loadingPicture,
        error: errorPicture,
        getProspectPicture,
        uploadProspectPicture,
    } = useProspectPicture(user.uuid);

    const [hideMainComponent, setHideMainComponent] = useState(false);
    const [showCertification, setShowCertification] = useState(false);
    const [showInformation, setShowInformation] = useState(false);
    const [showExperience, setShowExperience] = useState(false);
    const [showEducation, setShowEducation] = useState(false);
    const [showProject, setShowProject] = useState(false);
    const [showSkill, setShowSkill] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [avatarSrc, setAvatarSrc] = useState("");
    const { i18n } = useTranslation("global");
    const currentLanguageCode = i18n.language;
    const fieldLangToPrint = skillsMapLang[currentLanguageCode];


    const getSkillsList = (skills) => {
        if (skills) return skills.map((skill) => skill.skill[fieldLangToPrint]);
        else return null;
    };
    const getSkillsListUnmappped = (skills) => {
        if (skills) return skills.map((skill) => skill[fieldLangToPrint]);
        else return null;
    };

    const { prospectID } = useParams();
    const {
        loading,
        error: errorPROSPECT,
        data,
        refetch,
    } = useProspect(prospectID ? prospectID : user.uuid);

    useEffect(() => {
        if (user) {

            //   getProspectPicture();
        }

    }, [user, refetch]);

    useEffect(() => {

        // refetch() //commenté pour éviter les doubles appels sur la photo de profil
        // getProspectPicture();
    }, []);


    useEffect(() => {
        isDoingAction && refetch();
        // isDoingAction && getProspectPicture(user.uuid);
    }, [isDoingAction]);

    useEffect(() => {
        console.log("use efffect isDoingAction", isDoingAction, profilePicture)
        setIsDoingAction && setIsDoingAction(false);
        // getProspectPicture();
    }, [data, isDoingAction]);

    if (errorPROSPECT) {
        return <PrintErrorMessage error={errorPROSPECT} />;
    }
    if (!data) {
        return <span className="loader mt-[25Opx] ml-[250px]"></span>;
    }

    //get data for each sections
    const handleBack = () => {
        openCvSection && openCvSection(false);
        draftIsClicked && draftIsClicked(false);
        setNewIsClicked && setNewIsClicked(true);
        // getProspectPicture()
    };
    const handleSave = () => {
        openCvSection && openCvSection(false);
        setShowFourthStep && setShowFourthStep(true);
    };
    if (loading) return <span className="loader"></span>;

    return (
        <motion.div
            className="p-secondDegree "
            variants={container}
            initial="hidden"
            animate="visible"
        >
            {errorPicture && <PrintErrorMessage error={errorPicture} />}
            {!hideMainComponent && (
                <motion.div className="flex gap-secondDegree">
                    {showStepper && (
                        <div className=" flex flex-col items-center ">
                            <Step item="3" bg="white" borderSize="default" />
                            <Separator orientation="vertical" />
                        </div>
                    )}

                    <div
                        className={`${showingSectionInProspect ? "w-[89%]" : "w-full"
                            } flex flex-col gap-secondDegree `}
                    >
                        {showStepper && (
                            <Label
                                message="consultants.statement.prospectConsultantPanel.createAndUpdateCV"
                                size="body"
                                fontWeight="bold"
                            />
                        )}

                        <OverviewInformationProfile
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowInformation}
                            avatarSrc={profilePicture}
                            languages={data?.spokenLanguages}
                            user={user}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowSkill}
                            title="consultants.statement.prospectConsultantPanel.skills"
                            items={[
                                ...getSkillsList(data?.mappedSkills),
                                ...getSkillsListUnmappped(data?.unmappedSkills),
                            ]}
                            showingInProspect={showingSectionInProspect}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowDescription}
                            title="consultants.statement.prospectConsultantPanel.description"
                            items={getDescriptionList(data?.descriptions)}
                            showingInProspect={showingSectionInProspect}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowEducation}
                            title="consultants.statement.prospectConsultantPanel.education"
                            items={getTitles(data?.educations)}
                            showingInProspect={showingSectionInProspect}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowCertification}
                            title="consultants.statement.prospectConsultantPanel.certifications"
                            items={getTitles(data?.certifications)}
                            showingInProspect={showingSectionInProspect}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowExperience}
                            title="consultants.statement.prospectConsultantPanel.experience"
                            items={getTitles(data?.experiences)}
                            showingInProspect={showingSectionInProspect}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowProject}
                            title="consultants.statement.prospectConsultantPanel.projects"
                            items={getTitles(data?.projects)}
                            showingInProspect={showingSectionInProspect}
                        />
                        {showButtonsBackAndSave && (
                            <div className="flex gap-secondDegree py-secondDegree">
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<ArrowBack />}
                                    showText={true}
                                    text="global.actions.back"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="outline"
                                    className="font-bold border-primary"
                                    onClick={handleBack} // Options: "fill", "outline", "ghost", "dressedGhost"
                                ></Button>
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<Save />}
                                    showText={true}
                                    text="global.actions.save"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="fill"
                                    className="w-full"
                                    onClick={handleSave}
                                // onClick={() => setNewClicked(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                ></Button>
                            </div>
                        )}
                    </div>
                </motion.div>
            )}
            {showInformation && (
                <Information
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowInformation}
                    avatarSrc={avatarSrc}
                    languages={data?.spokenLanguages}
                    setAvatarSrc={setAvatarSrc}
                    user={user}
                    titles={data?.titles}
                    setIsDoingAction={setIsDoingAction}
                />
            )}
            {showSkill && (
                <Skills
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowSkill}
                    skills={data?.mappedSkills}
                    unmappedSkills={data?.unmappedSkills}
                    user={user}
                    setIsDoingAction={setIsDoingAction}
                />
            )}
            {showDescription && (
                <Description
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowDescription}
                    descriptions={data?.descriptions}
                    user={user}
                    setIsDoingAction={setIsDoingAction}
                />
            )}
            {showCertification && (
                <Certification
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowCertification}
                    certifications={data?.certifications}
                    user={user}
                    setIsDoingAction={setIsDoingAction}
                />
            )}

            {showExperience && (
                <Experience
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowExperience}
                    experiences={data?.experiences}
                    user={user}
                    setIsDoingAction={setIsDoingAction}
                />
            )}

            {showEducation && (
                <Education
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowEducation}
                    educations={data?.educations}
                    user={user}
                    setIsDoingAction={setIsDoingAction}
                />
            )}

            {showProject && (
                <Project
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowProject}
                    projects={data?.projects}
                    user={user}
                    setIsDoingAction={setIsDoingAction}
                />
            )}
        </motion.div>
    );
}
