/* eslint-disable react/prop-types */
/**
 * @component Certification
 *
 * The `Certification` component manages the display and addition of certifications for a user profile. It allows users to view existing certifications, add new ones, and handle related interactions.
 *
 * Key Features:
 * - Displays a list of user certifications.
 * - Provides an interface to add a new certification.
 * - Handles form visibility and submission.
 * - Shows error messages related to server responses.
 *
 * Props:
 * - @param {Function} hideAllOverview - Function to hide all overview sections.
 * - @param {Function} showComponent - Function to show or hide the current component.
 * - @param {Array} certifications - Array of certification objects to be displayed.
 * - @param {Object} user - User object containing details related to certifications.
 * - @param {Function} setIsDoingAction - Function to set the action state (e.g., loading state).
 *
 * Local State:
 * - `showForm`: Boolean to control the visibility of the form for adding a new certification.
 * - `formIsValid`: Boolean to indicate if the form is valid.
 * - `serverResponseIsError`: Boolean to indicate if there was an error with the server response.
 * - `errors`: Object to store error messages related to server responses.
 * - `editingCertification`: Boolean to manage the editing state of a certification.
 *
 * Usage:
 * - This component is used to display and manage user certifications within a profile.
 * - It includes functionality to add new certifications and handle form interactions.
 *
 * Example:
 * ```jsx
 * <Certification
 *   hideAllOverview={hideAllOverview}
 *   showComponent={showComponent}
 *   certifications={certifications}
 *   user={currentUser}
 *   setIsDoingAction={setIsDoingAction}
 * />
 * ```
 *
 * Dependencies:
 * - @component AddCertification: Component for adding a new certification.
 * - @component PrintCertification: Component for displaying individual certifications.
 * - @component PrintErrorMessage: Component for showing error messages.
 * - @component Label: Component for displaying text labels.
 * - @component Button: Provides action buttons like "Add".
 * - @mui/icons-material: Icons used in buttons.
 * - @hook useState: React hook for managing local component state.
 * - @utils animationConst: Provides animation constants for Framer Motion.
 *
 * Functions:
 * - `hider()`: Hides the overview and current component.
 *
 * @example
 * const certifications = [
 *   { uuid: '123', name: 'Certification 1' },
 *   { uuid: '456', name: 'Certification 2' }
 * ];
 *
 * <Certification
 *   hideAllOverview={hideAllOverview}
 *   showComponent={showComponent}
 *   certifications={certifications}
 *   user={currentUser}
 *   setIsDoingAction={setIsDoingAction}
 * />
 */

import AddCertification from "./AddCertification";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import { useState} from "react";
import PrintCertification from "./PrintCertification";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import PrintErrorMessage from "@/components/molecules/PrintErrorMessage";
export default function Certification({
  hideAllOverview,
  showComponent,
  certifications,
  user,
  setIsDoingAction,
  
}) {


  const [showForm, setShowForm] = useState(false);
   const [fomrIsValid, setFormIsValid] = useState(false);
  const [serverresponseIsError, setServerResponseIserror] = useState(false);
  const [errors, setErrors]=useState(null)
  // const [certifications, setCertifications] = useState([]);
  const [editingCertification, setEditingCertification] = useState(false);
  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };
  

  return (
    <motion.div variants={item}>
      {serverresponseIsError && <PrintErrorMessage error={errors} />}
      <div className="flex items-center gap-fourthDegree w-full">
        <Button
          showLeftIcon={true}
          leftIcon={
            <ArrowBackIcon className="cursor-pointer" onClick={hider} />
          }
          showText={false}
          style="ghost"
          onClick={hider}
        />
        <div className="basis-3/4">
          <Label
            message="consultants.statement.prospectConsultantPanel.certifications"
            size="h3"
            fontWeight="bold"
          />
        </div>

        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={() => setShowForm(true)}
          //onClick={() => setShowHandler(false)} // Options: "fill", "outline", "ghost", "dressedGhost"
        />
      </div>
      <div className=" flex flex-col gap-secondDegree">
        {showForm && (
          <AddCertification
            showForm={showForm}
            setShowForm={setShowForm}
            editingCertification={false}
            setEditingCertification={setEditingCertification}
            user={user}
            setIsDoingAction={setIsDoingAction}
            setFormIsValid={setFormIsValid}
            setServerResponseIserror={setServerResponseIserror}
            setErrors={setErrors}
          />
        )}
        {certifications &&
          certifications.map((certification) => {
            return (
              <PrintCertification
                key={certification.uuid}
                certification={certification}
                setEditingCertification={setEditingCertification}
                editingCertification={editingCertification}
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
              />
            );
          })}
      </div>
    </motion.div>
  );
}