import * as React from "react";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import SkeletonHandler from "@/components/templates/skeletons/SkeletonHandler";
import { Label } from "@/components/atoms/texts/label";
import LineArt from "@/assets/images/lineArt.png"
import Filter from "@/components/organisms/Filter";
import { useState } from "react";
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded';
import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import BlueHeader from "@/components/organisms/blueHeader";
import { getDataByYear, getYears } from "../statistics/statistic";
import TableFinance from "./TableFinance";
import getFinanceData from "@/services/admin-services/financeData";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import FinanceChart from "./FinanceChart";
import { monthPath } from "@/utils/translationPath";
import { translationsPaths } from "@/utils/translationPath";
import { useTranslation } from "react-i18next"
import { Button } from "@/components/molecules/buttons/button";
import { useEffect } from "react";

const monthDataDefault = [
    { month: "january", data: 100 },
    { month: "february", data: 200 },
    { month: "march", data: 300 },
    { month: "april", data: 20 },
    { month: "may", data: 500 },
    { month: "june", data: 600 },
    { month: "july", data: 700 },
    { month: "august", data: 800 },
    { month: "september", data: 300 },
    { month: "october", data: 1000 },
    { month: "november", data: 1100 },
    { month: "december", data: 1200 },
];

// voir LineChartValuation pour le graphique
// const  [t ] = useTranslation('global');
// const placeHolderTranslated = t(chemin du texte);


export default function Finance() {
    const [financeData, setFinanceData] = useState(getFinanceData());

    const [years, setYears] = useState(getYears(financeData));

    const [showBarChart, setShowBarChart] = useState(true);
    const [showLineChart, setShowLineChart] = useState(true);
    const [showTable, setShowTable] = useState(true);
    const [t] = useTranslation("global");

    const [selectedYear, setSelectedYear] = useState([years[3]]);
    // let years = ['2024', '2023', '2022', '2021']
    // console.log(getDataByYear(financeData, selectedYear));
    const [displaySettings, setDisplaySettings] = useState({});

    // Initialize display settings for each year
    useEffect(() => {
        const initialSettings = {};
        years.forEach(year => {
            initialSettings[year] = {
                showBarChart: true,
                showLineChart: true,
                showTable: true
            };
        });
        setDisplaySettings(initialSettings);
    }, [years]);



    const toggleDisplay = (year, setting) => {
        setDisplaySettings(prevSettings => {
            // Toggle the current setting.
            const updatedSettings = {
                ...prevSettings,
                [year]: {
                    ...prevSettings[year],
                    [setting]: !prevSettings[year][setting]
                }
            };

            // Check if all settings are false.
            const areAllFalse = !updatedSettings[year].showBarChart && !updatedSettings[year].showLineChart && !updatedSettings[year].showTable;

            if (areAllFalse) {
                // If all settings are false, reset them to true.
                updatedSettings[year] = {
                    showBarChart: true,
                    showLineChart: true,
                    showTable: true
                };
            }

            return updatedSettings;
        });

    };

    const handleContentUpdate = (month, value) => {
        // console.log("updateFinanceData requesting back end")
        setFinanceData(getFinanceData())
    };
    const convertToCSV = (statData) => {
        let csv = "";

        Object.values(monthPath).map((month) => {
            const monthTranslated = t(month)
            csv = csv + "," + monthTranslated

        });

        csv = csv + ',Total' + '\n'
        Object.entries(statData.income).forEach(([label, rowData]) => {
            const translatedLabel = t("finance.income");
            csv += `${translatedLabel} ${label},${Object.values(rowData).join(",")}\n`;
        });
        Object.entries(statData.expense).forEach(([label, rowData]) => {
            const translatedLabel = t("finance.expense");
            csv += `${translatedLabel} ${label},${Object.values(rowData).join(",")}\n`;
        });
        return csv;
    };
    const downloadCSV = (statData) => {
        const csv = convertToCSV(statData);
        const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", t("finance.title") + ".csv");
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    };

    return (
        <div className="bg-lighter flex grow overflow-hidden relative">

            <div className="w-full h-[93vh] overflow-y-scroll overflow-x-hidden z-10  ">

                <img src={LineArt} className="bgLineArt " />
                <BlueHeader title="finance.title" />
                <div className=" px-[10vw] py-thirdDegree  gap-thirdDegree flex flex-col items-center ">
                    <Filter inclusive={true} items={years} informSelectedItem={setSelectedYear}></Filter>

                    {
                        Array.isArray(selectedYear) && selectedYear.map((year, index) => (
                            <div className="flex flex-col items-center py-thirdDegree gap-secondDegree w-full" id={'contentToPrint' + index} key={index}>
                                <div className="flex justify-between items-center w-full gap-secondDegree">
                                    <div className="flex min-w-[100px]">
                                        <Button style="ghost" sizePadding="firstDegree" showText={false} showLeftIcon leftIcon={<BarChartRoundedIcon color={displaySettings[year]?.showBarChart ? `primary` : `disabled`} />} onClick={() => toggleDisplay(year, 'showBarChart')} />
                                        <Button style="ghost" sizePadding="firstDegree" showText={false} showLeftIcon leftIcon={<ShowChartRoundedIcon color={displaySettings[year]?.showLineChart ? `primary` : `disabled`} />} onClick={() => toggleDisplay(year, 'showLineChart')} />
                                        <Button style="ghost" sizePadding="firstDegree" showText={false} showLeftIcon leftIcon={<GridOnRoundedIcon color={displaySettings[year]?.showTable ? `primary` : `disabled`} />} onClick={() => toggleDisplay(year, 'showTable')} />
                                    </div>
                                    <Label size="h3" fontWeight="bold">{year}</Label>

                                    <div className="flex min-w-[100px] justify-end">
                                        <Button
                                            style="ghost"
                                            sizePadding="firstDegree"
                                            showText={false}
                                            showLeftIcon={true}
                                            leftIcon={<DownloadRoundedIcon color='primary' />}
                                            className="font-bold"

                                            onClick={() => downloadCSV(getDataByYear(financeData, year))}
                                        />
                                    </div>
                                </div>
                                <Label size="small" message="global.autoSaved" />

                                {displaySettings[year]?.showTable &&
                                    <TableFinance
                                        key={index}
                                        financeData={getDataByYear(financeData, year)}
                                        onContentUpdate={handleContentUpdate}
                                    />
                                }

                                <FinanceChart
                                    financeData={getDataByYear(financeData, year)}
                                    showBarChart={displaySettings[year]?.showBarChart}
                                    showLineChart={displaySettings[year]?.showLineChart}
                                />
                                <Separator />


                            </div>
                        ))
                    }

                </div>
            </div>
        </div>

    )
}