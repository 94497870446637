import React from 'react'
import { useState } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/organisms/forms/select";
import { Label } from '@/components/atoms/texts/label';
export default function UniqueSelect2({
    dataToSelect,
    defaultValue,
    filterDataFunction,
    placeholderSelect,

    className,
    labelClassName,
    isSearchable = false,
    showLabel = true,

    ...props
}) {
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const handleSelectChange = (value) => {
        setSelectedValue(value)
        filterDataFunction(value)

    }
    return (
        <div className={className}>
            {" "}
            <Select onValueChange={(value) => handleSelectChange(value)} value={selectedValue}>
                <SelectTrigger
                    className={`col-span-3 p-2 border rounded-md text-body bg-white `}
                >

                    <SelectValue placeholder={<Label message="global.actions.select" />} />

                </SelectTrigger>
                <SelectContent>
                    {dataToSelect && dataToSelect.map((item, index) => (
                        <SelectItem key={index} value={item}>
                            {item}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    );
}
