// import { useAuth } from "@/context/AuthContext";
// import { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";

// const useGetExpenseReportProof_AsAdmin = ( expenseReportFileUuid) => {
//     const [proof, setProof] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const { user } = useAuth();
//     const { id } = useParams;
//     if (!user || !id) return;

//     console.log("test useGetExpenseReportProof_AsAdmin", expenseReportFileUuid);

//     useEffect(() => {
//         const fetchProof = async () => {
//             if (!consultantUuid || !expenseReportFileUuid || !token) {
//                 return;
//             }

//             setLoading(true);
//             setError(null);

//             try {
//                 const response = await fetch(
//                     window.ENV.BACKEND_URL+`/api/secured/admin/expense/expenseReportProof/${id}/${expenseReportFileUuid}`,
//                     {
//                         method: "GET",
//                         headers: {
//                             Authorization: `Bearer ${user.strongToken}`,
//                             "Content-Type": "multipart/form-data",
//                         },
//                     }
//                 );

//                 if (!response.ok) {
//                     throw new Error("Failed to fetch expense report proof");
//                 }

//                 const proofBlob = await response.blob();
//                 const proofUrl = URL.createObjectURL(proofBlob);
//                 console.log("test useGetExpenseReportProof_AsAdmin response =", response);

//                 setProof(proofUrl);
//             } catch (err) {
//                 setError(err.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchProof();
//     }, [expenseReportFileUuid]);

//     return { loading, error, proof };
// };

// export default useGetExpenseReportProof_AsAdmin;
// import { useAuth } from "@/context/AuthContext";
// import { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";

// const useGetExpenseReportProof_AsAdmin = (expenseReportFileUuid) => {
//     const [proof, setProof] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const { user } = useAuth();
//     const { id: consultantUuid } = useParams();

//     // Vérification de la validité des paramètres avant de continuer
//     if (!user || !consultantUuid) return { loading, error, proof };

//     console.log("test useGetExpenseReportProof_AsAdmin", expenseReportFileUuid);

//     useEffect(() => {
//         const fetchProof = async () => {
//             if (!consultantUuid || !expenseReportFileUuid || !user.strongToken) {
//                 return;
//             }

//             setLoading(true);
//             setError(null);

//             try {
//                 const response = await fetch(
//                     window.ENV.BACKEND_URL+`/api/secured/admin/expense/expenseReportProof/${consultantUuid}/${expenseReportFileUuid}`,
//                     {
//                         method: "GET",
//                         headers: {
//                             Authorization: `Bearer ${user.strongToken}`,
//                             "Content-Type": "multipart/form-data",
//                         },
//                     }
//                 );

//                 if (!response.ok) {
//                     throw new Error("Failed to fetch expense report proof");
//                 }

//                 // Récupérer le fichier sous forme de Blob
//                 const proofBlob = await response.blob();
//                 setProof(proofBlob);  // Stocker le Blob directement
//             } catch (err) {
//                 setError(err.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchProof();
//     }, [consultantUuid, expenseReportFileUuid, user.strongToken]);  // Ajout de user.strongToken aux dépendances

//     return { loading, error, proof };
// };

// export default useGetExpenseReportProof_AsAdmin;


import { useState } from "react";
import { useAuth } from "@/context/AuthContext";
import { useParams } from "react-router-dom";

export const useGetExpenseReportProof_AsAdmin = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const { id: consultantUuid } = useParams();


    const getExpenseProof = async (expenseReportFileUuid) => {
        setLoading(true);
        setError(null);
        // f4bfbb4b-e9ee-4937-bee8-202c455be206
        try {
            const response = await fetch(
                window.ENV.BACKEND_URL+`/api/secured/admin/expense/expenseReportProof/${consultantUuid}/${expenseReportFileUuid}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${user.strongToken}`,
                },
            });
             const contentLength = +response.headers.get('Content-Length');

            if (!response.ok) {
                throw new Error("Failed to retrieve expense invoice");
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            // console.log("blob file expense ", blob, url,"contentLength", contentLength,"blob.size" ,blob?.size)

            setData({ url, type: blob.type });
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    if (!user || !consultantUuid) return { getExpenseProof, loading, error: "user from authentification or consultantUuid are null", proof };

    return { getExpenseProof, loading, error, data };
};
