const translationsPaths = {
  january: "global.months.january",
  february: "global.months.february",
  march: "global.months.march",
  april: "global.months.april",
  may: "global.months.may",
  june: "global.months.june",
  july: "global.months.july",
  august: "global.months.august",
  september: "global.months.september",
  october: "global.months.october",
  november: "global.months.november",
  december: "global.months.december",
  employerCost: "consultants.statement.overflow.tableHead.employerCost",
  customerPaiment: "consultants.statement.overflow.tableHead.customerPaiment",
  cooptation: "consultants.statement.overflow.tableHead.cooptation",
  overflow: "consultants.statement.overflow.tableHead.overflow",
  annuel: "consultants.statement.overflow.annuel",
  mustBeTranslated: "consultants.statement.overflow.totranslate",
  employerCostOverview: "consultants.statement.overflow.employerCostOverview",
  cooptationIncomeDetails:
    "consultants.statement.overflow.cooptationIncomeDetails",
  seeBillsDetails: "consultants.statement.overflow.seeBillsDetails",
  bills: "consultants.statement.billsDetails.bills",

  customer: "consultants.statement.billsDetails.customer",
  addNewBills: "consultants.statement.billsDetails.addNewBills",
  newBill: "consultants.statement.billsDetails.newBill",
  customersTable: "consultants.statement.billsDetails.table.customers",
  billsTable: "consultants.statement.billsDetails.table.bills",
  nbDaysTable: "consultants.statement.billsDetails.table.nbDays",
  amountTable: "consultants.statement.billsDetails.table.amount",
  error: "listingConsultant.error",
  loading: "listingConsultant.loading",
  placeHolder: "listingConsultant.placeHolder",
  panelOpeningNewCooptation: "consultants.dashboard.newCooptation.title",
  showMore: "global.panelOpening.showMore",
  discardChanges: "global.actions.discard",
  cancelAction: "global.actions.cancel",
  saveAction: "global.actions.save",
  nextAction: "global.actions.next",
  deleteData: "global.actions.deleteDate",
  anonymiseData: "global.actions.anonymiseData",
  requestDataDeletion: "global.actions.request",
  selectAction: "global.actions.select",
  backAction: "global.actions.back",
  newConsultant: "consultants.dashboard.newCooptation.newConsultant",
  newConsultantName: "consultants.dashboard.newCooptation.newConsultantName",

  cooptedBy: "consultants.dashboard.newCooptation.cooptedBy",
  cooptingConsultantTable: "consultants.dashboard.newCooptation.table.name",
  retributionRegimeTable: "consultants.dashboard.newCooptation.table.regime",
  ownershipTable: "consultants.dashboard.newCooptation.table.ownership",
  durationTable: "consultants.dashboard.newCooptation.table.duration",
  total: "global.total",
  date: "global.panelOpening.date",
  billNumber: "consultants.statement.billsDetails.billNumber",
  amount: "consultants.statement.billsDetails.amount",
  days: "consultants.statement.billsDetails.days",
  unSaveChange: "consultants.statement.billsDetails.unSaveChange",
  billSaved: "consultants.statement.billsDetails.billSaved",
  save: "global.actions.save",
  errorInForm: "consultants.statement.billsDetails.errorInForm",

  "24S": "statistics.24S",
  S24: "statistics.S24",
  S42: "statistics.S42",
  classic: "statistics.classic",
  consultants: "statistics.consultants",
  vehicleFleet: "statistics.vehicleFleet",
  directContributions: "statistics.directContributions",
  daysWorked: "consultants.statement.overview.daysWorked",
  sickDays: "consultants.statement.overview.sickDays",
  expenseAccount: "consultants.statement.overview.expenseAccount",
  grossSalary: "consultants.statement.overview.grossSalary",
  employerCharges: "consultants.statement.overview.totalCostEmployee",
  salaryCharges: "consultants.statement.overview.taxs",

  income: "finance.income",
  expense: "finance.expense",
  interne: "home.text",
  externe: "home.text",
  year: "global.information.years",
  month: "global.information.months"
};

const monthPath = {
  january: "global.months.january",
  february: "global.months.february",
  march: "global.months.march",
  april: "global.months.april",
  may: "global.months.may",
  june: "global.months.june",
  july: "global.months.july",
  august: "global.months.august",
  september: "global.months.september",
  october: "global.months.october",
  november: "global.months.november",
  december: "global.months.december"
};

export {translationsPaths ,monthPath}