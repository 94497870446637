// Rôle requis : USER_TIMESHEET
// Ce hook permet à un utilisateur de créer une timesheet pour un mois donné.
// Seuls les utilisateurs ayant le rôle USER_TIMESHEET peuvent accéder à cette mutation.

import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const ADD_TIMESHEET = gql`
  mutation AddTimesheet($year: Int!, $month: Int!, $clientName: String!) {
    addTimesheet(newTimesheet: { year: $year, month: $month, clientName: $clientName }) {
      uuid
      year
      month
      status
      clientName
    }
  }
`;

export const useAddTimesheet = () => {
  const { user } = useAuth();
  const [addTimesheetMutation, { loading, error, data }] = useMutation(ADD_TIMESHEET, {
    context: {
      headers: {
        Authorization: `Bearer ${user?.strongToken}`,
      },
    },
  });

  const addTimesheet = async (year, month, clientName) => {
    try {
      const response = await addTimesheetMutation({
        variables: { year, month, clientName },
      });
      return { data: response.data?.addTimesheet, error: null };
    } catch (err) {
      return { data: null, error: err.message };
    }
  };

  return { addTimesheet, loading, error, data };
};
