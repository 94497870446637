/* eslint-disable react/prop-types */

/**
 * @component EditCVPanel
 *
 * The `EditCVPanel` component is a panel that allows users to edit and update CV information. It displays a modal (or "sheet") when triggered, where users can access the `CreateAndUpdateCV` component to modify CV details. The panel also manages its own state for various UI interactions and handles notifications.
 *
 * Props:
 * - @param {boolean} [showText=true] - Controls whether to display text on the button. Default is `true`.
 * - @param {Object} [user] - The user object containing CV details to be edited.
 * - @param {Function} setUser - Function to set the user object.
 * - @param {boolean} isDoingAction - Indicates if an action is currently being performed.
 * - @param {Function} setIsDoingAction - Function to set the action status.
 * - @param {Function} [setShouldStop] - Function to handle stopping actions (optional).
 *
 * State:
 * - @variable {boolean} isNewClicked - Tracks if the "New" button has been clicked.
 * - @variable {boolean} isOpen - Tracks whether the sheet is open or closed.
 * - @variable {boolean} openCvSection - Indicates if the CV section is open.
 * - @variable {boolean} draftIsClicked - Tracks if the "Draft" button has been clicked.
 * - @variable {boolean} isEditing - Indicates if the user is currently editing.
 * - @variable {Object} userCreated - Stores newly created user information.
 * - @variable {boolean} loading - Indicates if data is currently loading.
 * - @variable {Object} error - Stores any errors encountered during data fetching.
 * - @variable {Object} userClicked - Stores the user object that has been clicked.
 *
 * Hooks:
 * - `useParams` from `react-router-dom` to get the current URL parameters.
 * - `useToast` for displaying toast notifications.
 * - `useProspect` to fetch minimal prospect data.
 *
 * Effects:
 * - Toggles the sheet open/closed and handles UI changes when the sheet is opened or closed.
 * - Displays a toast notification when the component is closed while editing.
 * - Calls `setShouldStop` to manage the stopping of actions on mount.
 *
 * Usage:
 * This component is used to open a modal that allows for editing CV details. It integrates with the `CreateAndUpdateCV` component to provide a form for updating user information. The panel can be triggered by a button and shows a sheet with the editing form inside.
 *
 * Example:
 * <EditCVPanel
 *   showText={true}
 *   user={user}
 *   setUser={setUser}
 *   isDoingAction={isDoingAction}
 *   setIsDoingAction={setIsDoingAction}
 *   setShouldStop={setShouldStop}
 * />
 *
 * Dependencies:
 * - `CreateAndUpdateCV` for the CV editing form.
 * - `useToast` for showing notifications.
 * - `useProspect` for fetching minimal prospect data.
 * - `EditIcon` from `@mui/icons-material` for the button icon.
 *
 * Where it is used:
 * - @component ProspectsTable
 * - @component CvTab
 */

import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
} from "@/components/templates/sheet";

import CreateAndUpdateCV from "../prospectConsultant/CreateAndUpdateCV";
import { useParams } from "react-router-dom";
import { useToast } from "@/components/organisms/feedback/use-toast";
import EditIcon from "@mui/icons-material/Edit";
import useUser from "@/services/admin-services/query/useUser";
import { useEffect } from "react";
import { useProspect } from "@/services/admin-services/query/useProspect";


export default function EditCVPanel({
    showText = true,
    user,
    setUser,
    isDoingAction,
    setIsDoingAction,
    setShouldStop,
    ...props
}) {
    const [isNewClicked, setNewClicked] = useState(false);
    const [isOpen, setIsOpen] = useState();
    const [openCvSection, setOpenCvSection] = useState(false);
    const [draftIsClicked, setDraftIsClicked] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const { id } = useParams();
    const { dataMinimal } = useProspect();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userClicked, setUserClicked] = useState(null);

    const [userCreated, setUserCreated] = useState({
        name: "",
        firstName: "",
        personalEmail: "",
    });
    const { toast } = useToast();

    const displayToast = () => {
        toast({
            variant: "warning",
            title: "consultants.statement.prospectConsultantPanel.autosaved",
            duration: 8000,
        });
    };
    const handleOpen = (e) => {
        setIsOpen(e);
        setOpenCvSection(false);
        setNewClicked(false);
        setDraftIsClicked(false);
        isEditing ? displayToast() : "";

        setEditing(false);
        setShouldStop && setShouldStop(false);
    };
    useEffect(() => {
        setShouldStop && setShouldStop(true);
    }, [])
    function onCancel() {
        isEditing ? displayToast() : "";

        setIsOpen(false);
        // setActionVariant("default");
    }
    //   useEffect(() => {
    //   refetch()
    // },[id])
    //changes

    return (
        <div >
            <Sheet open={isOpen} onOpenChange={handleOpen}>
                <SheetTrigger className=" flex flex-row w-full">
                    <Button
                        style="ghost"
                        showLeftIcon={true}
                        sizePadding={!showText ? "pill" : "default"}
                        className={!showText ? "w-min corner-md" : "w-full"}
                        leftIcon={<EditIcon className="text-bodySmall" />}
                        showText={showText}
                        text={showText && "global.actions.editInfos"}
                    />
                </SheetTrigger>
                <SheetContent>
                    <SheetHeader
                        title={"prospects.updateProspect"}
                    // subheader="consultants.statement.vatSettings.autoSaved"
                    />
                    <SheetSlot>
                        <CreateAndUpdateCV
                            shoulGetSectionsData={true}
                            showButtonsBackAndSave={false}
                            showStepper={false}
                            showingSectionInProspect={false}
                            user={user ? user : dataMinimal}
                            isDoingAction={isDoingAction}
                            setIsDoingAction={setIsDoingAction}
                            setUser={setUser}
                        // showStepper={true}
                        />
                    </SheetSlot>
                </SheetContent>
            </Sheet>
        </div>
    );
}
