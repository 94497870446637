// Rôle requis : USER_TIMESHEET
// Ce hook permet à un utilisateur de récupérer les entrées de temps d’une timesheet.
// Seuls les utilisateurs ayant le rôle USER_TIMESHEET peuvent accéder à cette requête.

import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const GET_TIME_ENTRIES = gql`
  query TimeEntries($timesheetUuid: ID!) {
    timeEntries(timeEntriesRequest: { timesheetUuid: $timesheetUuid }) {
      uuid
      day
      type
      duration
    }
  }
`;

export const useTimeEntries = (timesheetUuid) => {
    const { user } = useAuth();

    const { data, loading, error, refetch } = useQuery(GET_TIME_ENTRIES, {
        variables: { timesheetUuid },
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
        errorPolicy: "all",
        fetchPolicy: "network-only",
    });

    return { data: data?.timeEntries, loading, error, refetch };
};
