import { useState } from "react";
import { useAuth } from "@/context/AuthContext";

export const useStorePayslip = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const storePayslip = async (file, consultantUuid) => {
    setLoading(true);
    setError(null);
    

    if (!file) return;

    const formData = new FormData();
    formData.append("payslip", file);
    formData.append("consultantUuid", consultantUuid);

    try {
      const response = await fetch(
        window.ENV.BACKEND_URL+"/api/secured/admin/payslip/payslipFile",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user.strongToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to store payslip");
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { storePayslip, loading, error, data };
};
