import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAuth } from "@/context/AuthContext";
import errorToText from "@/utils/errorToText";

const API_URL =
    window.ENV.BACKEND_URL+"/api/secured/ai/translate";

export default function useAITranslation() {
    const { user } = useAuth(); // Assuming you have an auth context to provide the token
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [translations, setTranslations] = useState({
        translatedTextEN: "",
        translatedTextFR: "",
        translatedTextDE: "",
        translatedTextIT: "",
        translatedTextES: "",
        translatedTextNL: "",
    });

    const translate = useCallback(
        async (text, sourceLanguage) => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.post(
                    API_URL,
                    { text, sourceLanguage },
                    {
                        headers: {
                            Authorization: `Bearer ${user.strongToken}`,
                            "Content-Type": "application/json",
                        },
                    }
                );

                setTranslations(response.data);
                // console.log("La traduction est:", response.data)
            } catch (err) {

                //
            } finally {
                setLoading(false);
            }
        },
        [user.strongToken]
    );

    return { loading, error, translations, translate };
};

export function getSkillsType() {
    return ["SOFT", "HARD", "POWER"];
}


