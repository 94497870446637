import React from 'react'
import { Label } from '../atoms/texts/label';
export default function PrintErrorMessage({error}) {
  return (
    <div className="bg-warning20  border-warning   p-firstDegree px-secondDegree flex flex-row gap-secondDegree justify-center items-center rounded-sm  border-2">
      <Label message="error.fromServer.unknownError" />
      <span>{error}</span>
    </div>
  );
}
