import { useAuth } from "@/context/AuthContext";
import { useParams } from "react-router-dom";

import { gql, useMutation} from "@apollo/client";

const DELETE_INVOICE = gql`
  mutation DeleteConsultantInvoice($uuid: ID!) {
    deleteConsultantInvoice(uuid: $uuid)
  }
`;
export const useDeleteConsultantInvoice = () => {
  const { user } = useAuth();
  if (!user) return;

  const [deleteInvoiceMutation, { loading, error, data }] = useMutation(DELETE_INVOICE,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      },
    }
  );


  const deleteConsultantInvoice = async (uuid) => {
    const response = await deleteInvoiceMutation({
      variables: { uuid },
    });
    return response.data.deleteConsultantInvoice;
  };

  return { deleteConsultantInvoice, loading, error, data };
};