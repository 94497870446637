import React from 'react';

const errorToText = (response) => {
    let errorMessage ;

    // const handleError = (response) => {
        if (!response || !response.status) {
            let error = new Error("Unknown error occurred");
            error.message = "error.fromServer.unknownError";
            errorMessage = error.message;
            throw error;
        }

        let error;
        switch (response.status) {
            case 400:
                error = new Error("Bad Request");
                error.message = "error.fromServer.badRequest";
                break;
            case 401:
                error = new Error("Unauthorized");
                error.message = "error.fromServer.unauthorized";
                break;
            case 403:
                error = new Error("Forbidden");
                error.message = "error.fromServer.forbidden";
                break;
            case 404:
                error = new Error("Not Found");
                error.message = "error.fromServer.notFound";
                break;
            case 405:
                error = new Error("Method Not Allowed");
                error.message = "error.fromServer.methodNotAllowed";
                break;
            case 409:
                error = new Error("Conflict");
                error.message = "error.fromServer.conflict";
                break;
            case 410:
                error = new Error("Gone");
                error.message = "error.fromServer.gone";
                break;
            case 413:
                error = new Error("Payload Too Large");
                error.message = "error.fromServer.payloadTooLarge";
                break;
            case 415:
                error = new Error("Unsupported Media Type");
                error.message = "error.fromServer.unsupportedMediaType";
                break;
            case 429:
                error = new Error("Too Many Requests");
                error.message = "error.fromServer.tooManyRequests";
                break;
            case 500:
                error = new Error("Internal Server Error");
                error.message = "error.fromServer.internalServerError";
                break;
            case 501:
                error = new Error("Not Implemented");
                error.message = "error.fromServer.notImplemented";
                break;
            case 502:
                error = new Error("Bad Gateway");
                error.message = "error.fromServer.badGateway";
                break;
            case 503:
                error = new Error("Service Unavailable");
                error.message = "error.fromServer.serviceUnavailable";
                break;
            case 504:
                error = new Error("Gateway Timeout");
                error.message = "error.fromServer.gatewayTimeout";
                break;
            case 505:
                error = new Error("HTTP Version Not Supported");
                error.message = "error.fromServer.httpVersionNotSupported";
                break;
            default:
                error = new Error("Unknown error occurred");
                error.message = "error.fromServer.unknownError";
                break;
        }

        errorMessage = error.message;
        return errorMessage
        // throw error;
    // };

//return { errorMessage, handleError };
};

export default errorToText;
