import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Définir la mutation GraphQL
const UPDATE_DESCRIPTION = gql`
  mutation UpdateDescription(
    $uuid: ID!
    $descriptionUuid: ID!
    $language: CvLanguage!
    $description: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid
        updatedDescriptions: {
          uuid: $descriptionUuid
          language: $language
          description: $description
        }
      }
    ) {
      descriptions {
        uuid
        description
        language
      }
    }
  }
`;

const UPDATE_EDUCATION = gql`
  mutation UpdateEducation(
    $uuid: ID!
    $educationUuid: ID!
    $language: CvLanguage!
    $city: String!
    $description: String
    $school: String!
    $startDate: String!
    $title: String!
    $endDate: String!
    $countryCode: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid
        updatedEducations: {
          uuid: $educationUuid
          language: $language
          city: $city
          description: $description
          school: $school
          startDate: $startDate
          title: $title
          endDate: $endDate
          countryCode: $countryCode
        }
      }
    ) {
      educations {
        city
        countryCode
        description
        endDate
        language
        school
        startDate
        title
        uuid
      }
    }
  }
`;

const UPDATE_CERTIFICATION = gql`
  mutation UpdateCertification(
    $uuid: ID!
    $certificationUuid: ID!
    $language: CvLanguage!
    $dateObtained: String!
    $description: String
    $organization: String!
    $title: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid
        updatedCertifications: {
          uuid: $certificationUuid
          language: $language
          dateObtained: $dateObtained
          description: $description
          organization: $organization
          title: $title
        }
      }
    ) {
      certifications {
        dateObtained
        description
        language
        organization
        title
        uuid
      }
    }
  }
`;

// Définir la mutation GraphQL
const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $uuid: ID!
    $projectUuid: ID!
    $language: CvLanguage!
    $city: String!
    $company: String!
    $countryCode: String!
    $description: String
    $endDate: String!
    $industry: String
    $startDate: String!
    $technologies: String
    $title: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid
        updatedProjects: {
          uuid: $projectUuid
          language: $language
          city: $city
          company: $company
          countryCode: $countryCode
          description: $description
          endDate: $endDate
          industry: $industry
          startDate: $startDate
          technologies: $technologies
          title: $title
        }
      }
    ) {
      projects {
        city
        company
        countryCode
        description
        endDate
        industry
        language
        startDate
        technologies
        title
        uuid
      }
    }
  }
`;

// Définir la mutation GraphQL pour mettre à jour une expérience
const UPDATE_EXPERIENCE = gql`
  mutation UpdateExperience(
    $uuid: ID!
    $experienceUuid: ID!
    $language: CvLanguage!
    $company: String!
    $countryCode: String!
    $description: String
    $endDate: String!
    $industry: String!
    $startDate: String!
    $title: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid
        updatedExperiences: {
          uuid: $experienceUuid
          language: $language
          company: $company
          countryCode: $countryCode
          description: $description
          endDate: $endDate
          industry: $industry
          startDate: $startDate
          title: $title
        }
      }
    ) {
      experiences {
        company
        countryCode
        description
        endDate
        industry
        language
        startDate
        title
        uuid
      }
    }
  }
`;
// Définir la mutation GraphQL pour mettre à jour un titre
const UPDATE_TITLE = gql`
  mutation UpdateTitle(
    $uuid: ID!
    $titleUuid: ID!
    $language: CvLanguage!
    $title: String!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid
        updatedTitles: { uuid: $titleUuid, language: $language, title: $title }
      }
    ) {
      titles {
        language
        title
        uuid
      }
    }
  }
`;
const UPDATE_MAPPED_SKILL = gql`
  mutation UpdateMappedSkill($uuid: ID!, $skillUuid: ID!, $rating: Int!) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid
        updatedMappedSkills: { skillUuid: $skillUuid, rating: $rating }
      }
    ) {
      mappedSkills {
        rating
        skill {
          uuid
        }
      }
    }
  }
`;

const UPDATE_SPOKEN_LANGUAGE = gql`
  mutation UpdateSpokenLanguage(
    $uuid: ID!
    $spokenLanguage: String!
    $rating: Int!
    $languageUuid: ID!
  ) {
    updateProspect(
      updatedProspect: {
        uuid: $uuid
        updatedSpokenLanguages: {
          uuid: $languageUuid
          spokenLanguage: $spokenLanguage
          rating: $rating
        }
      }
    ) {
      spokenLanguages {
        uuid
        rating
        spokenLanguage
      }
    }
  }
`;

// Définition de la mutation MyMutation
const UPDATE_PROSPECT_INFO = gql`
  mutation MyMutation($updatedProspect: UpdateProspectDto!) {
    updateProspect(updatedProspect: $updatedProspect) {
      city
      gender
      firstname
      lastname
      personalEmail
    }
  }
`;

export const useUpdateProspectUpdateItems = () => {
    const { user } = useAuth();
    const [
        updateDescriptionMutation,
        {
            data: dataUpdateDescription,
            loading: loadingUpdatedescription,
            error: errorUpdatedescription,
        },
    ] = useMutation(UPDATE_DESCRIPTION, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`, // Utiliser le token de l'utilisateur pour l'autorisation
            },
        },
    });

    const updateDescription = async (descriptionData, uuid) => {
        const { descriptionUuid, language, description } = descriptionData;

        try {
            const result = await updateDescriptionMutation({
                variables: {
                    uuid,
                    descriptionUuid,
                    language,
                    description,
                },
            });

            return result.data.updateProspect.descriptions;
        } catch (e) {
            console.error("Error updating description:", e.message);
            return { error: e.message };
        }
    };
    const [
        updateEducationMutation,
        {
            data: dataUpdateEducation,
            loading: loadingUpdateEduction,
            error: errorUpdateEducation,
        },
    ] = useMutation(UPDATE_EDUCATION, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`, // Utiliser le token de l'utilisateur pour l'autorisation
            },
        },
    });

    const updateEducation = async (educationData, uuid) => {
        const {
            educationUuid,
            language,
            city,
            description,
            school,
            startDate,
            title,
            endDate,
            countryCode,
        } = educationData;

        try {
            const result = await updateEducationMutation({
                variables: {
                    uuid,
                    educationUuid,
                    language,
                    city,
                    description,
                    school,
                    startDate,
                    title,
                    endDate,
                    countryCode,
                },
            });

            return result.data.updateProspect.educations;
        } catch (e) {
            console.error("Error updating education:", e.message);
            return { error: e.message };
        }
    };
    const [
        updateCertificationMutation,
        {
            data: dataUpdateCertification,
            loading: loadingUpdateCertification,
            error: errorUpdateCertification,
        },
    ] = useMutation(UPDATE_CERTIFICATION, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`, // Utiliser le token de l'utilisateur pour l'autorisation
            },
        },
    });

    const updateCertification = async (certificationData, uuid) => {
        const {
            certificationUuid,
            language,
            dateObtained,
            description,
            organization,
            title,
        } = certificationData;

        try {
            const result = await updateCertificationMutation({
                variables: {
                    uuid,
                    certificationUuid,
                    language,
                    dateObtained,
                    description,
                    organization,
                    title,
                },
            });

            return result.data.updateProspect.certifications;
        } catch (e) {
            console.error("Error updating certification:", e.message);
            return { error: e.message };
        }
    };

    const [
        updateProjectMutation,
        {
            data: dataUpdateProject,
            loading: loadingUpdateProject,
            erro: errorUpdateProject,
        },
    ] = useMutation(UPDATE_PROJECT, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`, // Utiliser le token de l'utilisateur pour l'autorisation
            },
        },
    });

    const updateProject = async (projectData, uuid) => {
        const {
            projectUuid,
            language,
            city,
            company,
            countryCode,
            description,
            endDate,
            industry,
            startDate,
            technologies,
            title,
        } = projectData;

        try {
            const result = await updateProjectMutation({
                variables: {
                    uuid,
                    projectUuid,
                    language,
                    city,
                    company,
                    countryCode,
                    description,
                    endDate,
                    industry,
                    startDate,
                    technologies,
                    title,
                },
            });

            return result.data.updateProspect.projects;
        } catch (e) {
            console.error("Error updating project:", e.message);
            return { error: e.message };
        }
    };

    const [
        updateExperienceMutation,
        {
            loading: loadingUpdateExperience,
            error: errorUpdateExperience,
            data: dataUpdateExperience,
        },
    ] = useMutation(UPDATE_EXPERIENCE, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
    });

    const updateExperience = async (experienceData, prospectUuid) => {
        try {
            const response = await updateExperienceMutation({
                variables: {
                    uuid: prospectUuid,
                    experienceUuid: experienceData.uuid,
                    language: experienceData.language,
                    company: experienceData.company,
                    countryCode: experienceData.countryCode,
                    description: experienceData.description,
                    endDate: experienceData.endDate,
                    industry: experienceData.industry,
                    startDate: experienceData.startDate,
                    title: experienceData.title,
                },
            });

            return response.data.updateProspect.experiences;
        } catch (err) {
            console.error("Failed to update experience:", err.message);
            throw new Error(err.message);
        }
    };
    const [
        updateTitleMutation,
        {
            loading: loadingUpdateTitle,
            error: errorUpdateTitle,
            data: dataUpdateTitle,
        },
    ] = useMutation(UPDATE_TITLE, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
    });

    const updateTitle = async (prospectUuid, titleData) => {
        try {
            const response = await updateTitleMutation({
                variables: {
                    uuid: prospectUuid,
                    titleUuid: titleData.uuid,
                    language: titleData.language,
                    title: titleData.title,
                },
            });

            return response.data.updateProspect.titles;
        } catch (err) {
            console.error("Failed to update title:", err.message);
            throw new Error(err.message);
        }
    };
    const [
        updateMappedSkillMutation,
        {
            data: dataUpdateMappedSkill,
            loading: loadingUpdateMappedSkill,
            error: errorUpdateMappedSkill,
        },
    ] = useMutation(UPDATE_MAPPED_SKILL, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`, // Utilisation du token d'authentification
            },
        },
    });

    const updateMappedSkill = async (prospectUuid, skillUuid, rating) => {
        try {
            const response = await updateMappedSkillMutation({
                variables: {
                    uuid: prospectUuid,
                    skillUuid,
                    rating,
                },
            });

            return response.data.updateProspect.mappedSkills;
        } catch (err) {
            console.error("Failed to update mapped skill:", err.message);
            throw new Error(err.message);
        }
    };
    const [
        updateSpokenLanguageMutation,
        {
            data: dataUpdateSpokenLanguage,
            loading: loadingUpdateSpokenLanguage,
            error: errorUpdateSpokenLanguage,
        },
    ] = useMutation(UPDATE_SPOKEN_LANGUAGE, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`, // Utilisation du token d'authentification
            },
        },
    });

    // Fonction pour exécuter la mutation avec les variables nécessaires
    const updateSpokenLanguage = async (
        prospectUuid,
        languageUuid,
        spokenLanguage,
        rating
    ) => {
        try {
            await updateSpokenLanguageMutation({
                variables: {
                    uuid: prospectUuid,

                    languageUuid: languageUuid,
                    spokenLanguage: spokenLanguage,
                    rating: rating,
                },
            });
        } catch (err) {
            console.error("Error updating spoken language:", err);
        }
    };
    const [
        updateProspectMutation,
        {
            data: dataUpdateProspectInfo,
            loading: loadingUpdateProspectInfo,
            error: errorUpdateProspectInfo,
        },
    ] = useMutation(UPDATE_PROSPECT_INFO, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`, // Utilisation du token d'authentification
            },
        },
    });
    const updateProspectInfo = async (prospectData) => {
        try {
            const response = await updateProspectMutation({
                variables: {
                    updatedProspect: prospectData,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error updating prospect:", error);
            throw error;
        }
    };

    return {
        updateDescription,
        updateEducation,
        updateCertification,
        updateExperience,
        updateProject,
        updateTitle,
        updateMappedSkill,
        updateSpokenLanguage,
        updateProspectInfo,

        dataUpdateProspectInfo,
        loadingUpdateProspectInfo,
        errorUpdateProspectInfo,

        dataUpdateSpokenLanguage,
        errorUpdateSpokenLanguage,
        loadingUpdateSpokenLanguage,

        dataUpdateMappedSkill,
        loadingUpdateMappedSkill,
        errorUpdateMappedSkill,

        loadingUpdateTitle,
        errorUpdateTitle,
        dataUpdateTitle,

        dataUpdateProject,
        errorUpdateProject,
        loadingUpdateProject,

        dataUpdateExperience,
        errorUpdateExperience,
        loadingUpdateExperience,

        dataUpdateCertification,
        errorUpdateCertification,
        loadingUpdateCertification,

        dataUpdateEducation,
        errorUpdateEducation,
        loadingUpdateEduction,

        dataUpdateDescription,
        errorUpdatedescription,
        loadingUpdatedescription,
    };
};

export default useUpdateProspectUpdateItems;
