import { getCookie } from "./cookie";

export const hasStrongToken = () => {
    const userCookie = getCookie('user');
    if (userCookie) {
        const parsedUser = JSON.parse(userCookie);
        return parsedUser.strongToken ? true : false;
    }
    return false;
};

