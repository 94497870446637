// useExpenseForm.js

import { useApproveExpenseReport } from '@/services/admin-services/mutations/useApproveExpenseReport';
import { IMPACT_OVERFLOW } from '@/utils/globalConst';
import { useState, useEffect, useCallback } from 'react';

const useFormNewExpense = ( data, refetchFromScratch, setSubmissionSuccess, setSubmissionError) => {
    // const currencies = [
    //     { code: "EUR", symbol: "€" },
    //     { code: "test  hard codee", symbol: "$" },
    //     { code: "JPY", symbol: "¥" },
    //     { code: "GBP", symbol: "£" },
    //     { code: "CHF", symbol: "Fr" }
    // ];

    const [file, setFile] = useState(null);
    const [currency, setCurrency] = useState("EUR");
    const [date, setDate] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [amount, setAmount] = useState(0);
    const [vatValues, setVatValues] = useState({});
    const [totalVat, setTotalVat] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [vendor, setVendor] = useState();
    const [country, setCountry] = useState();
    const [comment, setComment] = useState();
    const [impactsOverflow, setImpactsOverflow] = useState(IMPACT_OVERFLOW[true].enum);


    const [fileError, setFileError] = useState(null);
    const [dateError, setDateError] = useState(null);
    const [selectValueError, setSelectValueError] = useState(null);
    const [amountError, setAmountError] = useState(null);
    const [totalVatError, setTotalVatError] = useState(null);
    const [totalAmountError, setTotalAmountError] = useState(null);
    const [commentError, setCommentError] = useState(null);
    const [vendorError, setVendorError] = useState(null);
    const [countryError, setCountryError] = useState(null);

    const { approveExpenseReport, loading, error, data: dataApproval } = useApproveExpenseReport();



    const handleVatChange = (e, rate) => {
        if (e.target.value === '' || e.target.value <= 0) {
            // Créer une copie de vatValues sans l'élément correspondant à rate.uuid
            const { [rate.uuid]: _, ...remainingVatValues } = vatValues;
            setVatValues(remainingVatValues);
        } else {
            // Mettre à jour vatValues avec la nouvelle valeur
            const newVatValues = {
                ...vatValues,
                [rate.uuid]: { value: e.target.value, rate: rate.rate } || 0,
            };
            setVatValues(newVatValues);
        }

        // console.log("vatValues", vatValues);
    };



    // function toNumber(value) {
    //     const number = parseFloat(value);
    //     let result = isNaN(number) ? 0 : Number(number.toFixed(2))
    //     console.log("result = ", result)
    //     return result;  // Retourne 0 si la valeur n'est pas un nombre
    // }
    
    //function toNumber(...values) {
    //    return values.reduce((sum, value) => {
    //        const number = parseFloat(value);
    //        return sum + (isNaN(number) ? 0 : number);
    //    }, 0).toFixed(2); // Retourne un nombre avec 2 décimales
    //}
    function toNumber(...args) {
        const sum = args.reduce((accumulator, current) => {
            // Normalise les chaînes en remplaçant la virgule par un point si nécessaire
            const normalizedCurrent = typeof current === 'string' ? current.replace(',', '.') : current;
            return accumulator + parseFloat(normalizedCurrent);
        }, 0);
        
        // Retourne la somme arrondie à deux décimales
        return parseFloat(sum.toFixed(2));
    }
    useEffect(() => {
        if (!vatValues) return;
        let total;
        // if (vatValues[0]?.rate) {

        // }

        if (vatValues[0]?.vatRate) {
            total = Object.values(vatValues).reduce((sum, vat) => {
                // Extraire la clé de l'objet (le UUID) et son contenu
                return sum + (vat.vatAmount);
            }, 0);
        } else {
            total = Object.values(vatValues).reduce((sum, vat) => {
                // Extraire la clé de l'objet (le UUID) et son contenu
                return sum + (vat.rate * vat.value / 100);
            }, 0);
        }
        // console.log("total", total, vatValues, vatValues[0]?.vatRate)

        setTotalVat(total);
    }, [vatValues]);


    useEffect(() => {
        vendor && validateForm("vendorErrorTest");
    }, [vendor]);

    useEffect(() => {
        country && validateForm("countryErrorTest");
        // console.log("country change", country)
    }, [country]);

    useEffect(() => {
        comment && validateForm("commentErrorTest");
    }, [comment]);

    useEffect(() => {
        totalVat && validateForm("totalVatErrorTest");
        // const totalAmountValue = parseFloat((toNumber(totalVat) + toNumber(amount)).toFixed(2)); 
        const totalAmountValue = parseFloat(toNumber(totalVat, amount)); 

        // console.log("totalVat || amount", totalVat || amount , totalVat , amount, "totalAmountValue", totalAmountValue);
        (totalVat || amount) && setTotalAmount(totalAmountValue);
    }, [totalVat]);

    useEffect(() => {
        totalAmount && validateForm("totalAmountErrorTest");
    }, [totalAmount]);

    useEffect(() => {
        date && validateForm("dateErrorTest");
    }, [date]);

    useEffect(() => {
        amount && validateForm("amountErrorTest");
        // const totalAmountValue = parseFloat((toNumber(totalVat) + toNumber(amount)).toFixed(2)); 
        const totalAmountValue = parseFloat(toNumber(totalVat, amount)); 

        // console.log("totalVat || amount", totalVat || amount , totalVat , amount, "totalAmountValue", totalAmountValue);
        (totalVat || amount) && setTotalAmount(totalAmountValue);
    }, [amount]);

    useEffect(() => {
        selectValue && validateForm("selectValueErrorTest");
    }, [selectValue]);

    useEffect(() => {
        comment && validateForm("commentErrorTest");
    }, [comment]);

    useEffect(() => {
        country && validateForm("countryErrorTest");
    }, [country]);

    useEffect(() => {
        vendor && validateForm("vendorErrorTest");
    }, [vendor]);

    useEffect(() => {
        console.log('file changed', file);
    }, [file]);

    useEffect(() => {
        console.log("data changed", data?.uuid, impactsOverflow)
        if (data) {
            let country = data.countryCode.toUpperCase()

            setDate(data.invoiceDate);
            setSelectValue(data.category.uuid);
            setAmount(data.amountExcludingVat);
            setVatValues(data.vatList);
            setVendor(data.invoiceIssuer);
            setTotalAmount(data.totalAmount);
            setCountry(country);
            setComment(data.comment);
            setCurrency(data.currency);
            setImpactsOverflow(IMPACT_OVERFLOW[true].enum);
            console.log("data are there data = ", data, " et country = ", country)
            // console.log("category selectValue change on hook", data.category.uuid, data)

        }
    }, [data]);

    const resetForm = () => {
        setFile(null);
        setDate('');
        setSelectValue('');
        setAmount(0);
        setVatValues({});
        setTotalVat(0);
        setTotalAmount(0);
        setVendor('')
        setCountry('')
        setComment('')
        setFileError(null);
        setDateError(null);
        setSelectValueError(null);
        setAmountError(null);
        setTotalVatError(null);
        setTotalAmountError(null);
        setCommentError(null);
        setVendorError(null);
        setCountryError(null);
        setSubmissionSuccess(false);
        setSubmissionError(false);
        setImpactsOverflow(IMPACT_OVERFLOW[true].enum);
    };


    const declineExpense = useCallback(async (uuid_) => {
        console.log("click on decline expense",data, uuid_, impactsOverflow);
        try {
            const result = await approveExpenseReport(uuid_, "REJECT", impactsOverflow);
            result && refetchFromScratch();
            console.log("Expense declined successfully:", result);
        } catch (err) {
            console.error("Failed to decline expense:", err);
        }
    }, [impactsOverflow]);

    // const approveExpense = async (uuid_) => {
    //     console.log("click on approveExpense", uuid_, impactsOverflow==true);
    //     try {
    //         // const result = await approveExpenseReport(uuid_, "APPROVE", impactsOverflow);
    //         // result && refetchFromScratch();
    //         // console.log("Expense approved successfully:", result);
    //     } catch (err) {
    //         console.error("Failed to approve expense:", err);
    //     }
    // };

    const approveExpense = useCallback(async (uuid_) => {
        console.log("click on approveExpense", uuid_, impactsOverflow);
        try {
           const result = await approveExpenseReport(uuid_, "APPROVE", impactsOverflow);
            result && refetchFromScratch();
            console.log("Expense approved successfully:", result);
        } catch (err) {
            console.error("Failed to approve expense:", err);
        }
    }, [impactsOverflow]);

    function validateForm(varToTest) {
        //ajouter la validation des champs pour vendor ,country, comment
        const numericAmount = parseFloat(amount);
        const numericTotalVat = parseFloat(totalVat);
        switch (varToTest) {
            case "all":
                setCommentError(!comment);
                setCountryError(!country);
                setVendorError(!vendor);
                !data && setFileError(!file);
                setDateError(!date);
                if (country == "LU") {
                    setAmountError(amount <= 0);
                    setTotalVatError((numericTotalVat >= numericAmount) || numericTotalVat == 0);
                } else {
                    setTotalAmountError(totalAmount <= 0);
                }
                setSelectValueError(!selectValue);
                console.log(!file || !data, !data, file)
                break;
            case "fileErrorTest":
                setFileError(!file || !data);
                break;
            case "dateErrorTest":
                setDateError(!date);
                break;
            case "selectValueErrorTest":
                setSelectValueError(!selectValue);
                break;
            case "amountErrorTest":
                setAmountError(amount <= 0);
                break;
            case "totalVatErrorTest":
                setTotalVatError((numericTotalVat >= numericAmount) || numericTotalVat == 0);
                break;
            case "totalAmountErrorTest":
                setTotalAmountError(totalAmount <= 0);
                break;
            case "commentErrorTest":
                setCommentError(!comment);
                break;
            case "countryErrorTest":
                setCountryError(!country);
                break;
            case "vendorErrorTest":
                setVendorError(!vendor);
                break;
            default:
                console.error("Unknown error test:", varToTest);
                break;
        }
        if ((!data && !file)) {
            return false;
        }
        return true;
    };

    return {
        file,
        date,
        currency,

        selectValue,
        amount,
        vatValues,
        totalVat,
        totalAmount,
        vendor,
        country,
        comment,
        impactsOverflow,
        handleVatChange,
        resetForm,
        declineExpense,
        approveExpense,
        toNumber,
        fileError,
        dateError,
        selectValueError,
        amountError,
        totalVatError,
        totalAmountError,
        commentError,
        vendorError,
        countryError,
        setFile,
        setDate,
        setCurrency,
        setSelectValue,
        setAmount,
        setTotalAmount,
        setVendor,
        setCountry,
        setComment,
        setImpactsOverflow,
        validateForm
    };
};

export default useFormNewExpense;
