import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/organisms/forms/select";
import { Label } from '@/components/atoms/texts/label';
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import DragAndDropZone from '@/components/organisms/forms/DragAndDropZone';
import useConsultant from '@/components/organisms/navigation/MenuConsultant/useConsultant';
import useConsultantData from '@/components/organisms/navigation/MenuConsultant/useConsultantData';
import { useConsultantContext } from '@/context/ConsultantContext';
import useFormNewCompanyBenefit from './useFormNewCompanyBenefit';
import FormIsSend from '@/components/organisms/feedback/formIsSend';
import { Input } from '@/components/ui/input';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import useAllCurrencies from '@/services/global-services/query/useAllCurrencies';
import { useAllVats } from '@/services/admin-services/query/useAllVats';
import { useTranslation } from 'react-i18next';
import { BENEFITS_TYPE } from '@/utils/globalConst';

export default function FormNewCompanyBenefit({ onSubmit, submitRef, global = false, submissionError, submissionSuccess, setSubmissionError, setSubmissionSuccess }) {

    // const vatRates = ["5%", "10%", "12%", "14%", "20%"];
    const { loading: loadingCurrencies, error: errorCurrencies, currencies } = useAllCurrencies();
    const { loading: loadingVat, error: errorVat, data: vatRates } = useAllVats();

    const scrollContainerRef = useRef(null);

    const [dateSend, setDateSend] = useState();
    const [amountSend, setAmountSend] = useState();
    const [currencySend, setCurrencySend] = useState();

    const { users } = useConsultantData();
    const { activeUser } = useConsultantContext();
    const { usersFormated } = useConsultant();
    const nameInUserFormated = activeUser && activeUser.uuid;
    const [t] = useTranslation("global");

    function toNumber(value) {
        const number = parseFloat(value);
        return isNaN(number) ? 0 : number;  // Retourne 0 si la valeur n'est pas un nombre
    }

    const [allCurrencies, setAllCurrencies] = useState();

    useEffect(() => {
        currencies && setAllCurrencies(currencies);
    }, [currencies]);

    useEffect(() => {
        if (submitRef) {
            submitRef.reset = {
                resetForm
            };
            // submitRef.decline = {
            //     sendCompanyBenefit
            // }
        }
    }, [submitRef]);

    const sendCompanyBenefit = () => {
        handleSubmitForm(e, "-1");
    }



    const {
        file,
        date,
        period,
        currency,
        selectValue,
        amount,
        vatValues,
        totalVat,
        comment,
        benefitType,
        handleVatChange,
        resetForm,
        fileError,
        dateError,
        periodError,
        selectValueError,
        amountError,
        totalVatError,
        benefitTypeError,
        commentError,
        setFile,
        setDate,
        setPeriod,
        setCurrency,
        setSelectValue,
        setAmount,
        setComment,
        setBenefitType,
        validateForm

    } = useFormNewCompanyBenefit(onSubmit, submitRef, nameInUserFormated, setSubmissionError, setSubmissionSuccess);

    const scrollToTop = () => {
        // importer le hook useRef : import {useRef} from "react";
        // Déclarer la ref :     const scrollContainerRef = useRef(null);
        // ajouter au contenant : className="h-full overflow-auto" ref={scrollContainerRef} 
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0; // Définit la position de défilement à 0 pour remonter en haut
        }
    };

    const handleSubmitForm = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page
        const valid = validateForm("all");
        const vatList = Object.entries(vatValues).map(([vatUuid, vatData]) => ({
            vatUuid: vatUuid,
            vatAmount: vatData
        }));

        if (valid) {
            const formData = {
                file,
                companyBenefitInvoiceFileUuid: "",
                consultantUuid: selectValue || nameInUserFormated,
                period: period + "-01",
                invoiceDate: date,
                currency: currency,
                amountExcludingVat: amount,
                totalAmount: toNumber(totalVat) + toNumber(amount),
                comment: comment,
                vatList: vatList,
                benefitType: benefitType
            };
            setDateSend(date);
            setAmountSend(amount);
            setCurrencySend(currency);
            onSubmit(formData); // Appelle la fonction de soumission passée en props
            scrollToTop();

        } else {
            console.error("Form error detected : ", valid)
            scrollToTop();
        }

    }
    useEffect(() => {
        submissionSuccess && resetForm();
        setSubmissionSuccess(false);
        // console.log("FormNewCompanyBenefit submissionSuccess change", submissionSuccess)
    }, [submissionSuccess]);



    useEffect(() => {
        (activeUser || !selectValue) && setSelectValue(activeUser?.uuid);
    }, [activeUser, selectValue]);

    function ListingConsultantsForSelect() {
        if (global) {
            return (
                <>
                    {usersFormated?.map((user) => (<SelectItem value={user.uuid}>{user.trigram + " " + user.firstname + " " + user.lastname}</SelectItem>))}
                </>
            )
        } else {
            return (
                activeUser && <SelectItem value={activeUser.uuid}>{activeUser.trigram + " " + activeUser.firstname + " " + activeUser.lastname}</SelectItem>
            )
        }

    }
    useEffect(() => {

    }, [global]);
    return (
        <div className="h-full overflow-auto" ref={scrollContainerRef} >
            {submissionError && <PrintErrorMessage error={submissionError} />}
            {submissionSuccess && <FormIsSend message={
                <span>
                    <Label message="success.sendForm.companyBenefits" />
                    <Label>{dateSend}</Label>
                    <Label message="success.sendForm.forAmount" />
                    <Label>{amountSend} {currencySend}</Label>
                    <Label message="success.sendForm.continue" />
                </span>
            } />}
            <form onSubmit={handleSubmitForm} className="flex flex-col gap-thirdDegree p-4" >
                <div className='flex flex-col gap-secondDegree'>
                    <Label fontWeight="bold" className="col-span-1" message="consultants.expense.supportingDocuments" />
                    <DragAndDropZone file={file} setFile={setFile} fileError={fileError} />
                </div>

                <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>

                    <Label fontWeight="bold" className="col-span-1" message="consultants.companyBenefits.consultant" />
                    <Select value={selectValue || (global ? undefined : nameInUserFormated)} onValueChange={(value) => setSelectValue(value)}  >
                        <SelectTrigger className={`col-span-3 p-2 border rounded-md text-body bg-white ${selectValueError === true && `border-error`} ${selectValueError === false && `border-success`} ${selectValueError == null && `border-greyStrong`}`}>
                            <SelectValue placeholder={<Label message="global.actions.select" />} />
                        </SelectTrigger>

                        <SelectContent>
                            <ListingConsultantsForSelect />
                        </SelectContent>
                    </Select>

                </div>
                {/* Benefit Type */}
                <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                    <Label fontWeight="bold" className="col-span-1" message="consultants.companyBenefits.benefitType" />
                    <Select className="" value={benefitType} onValueChange={(e) => { setBenefitType(e) }}                       >
                        <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body ${benefitTypeError === true && `border-error`} ${benefitTypeError === false && `border-success`} ${benefitTypeError == null && `border-greyStrong`} `}>
                            <SelectValue placeholder={<Label text="global.actions.select" />}></SelectValue>
                        </SelectTrigger>
                        <SelectContent >
                            {BENEFITS_TYPE?.map((type, index) => (
                                <SelectItem key={index} value={type.enum}>
                                    <Label message={`consultants.companyBenefits.` + type.label}></Label>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                {/* InvoiceDate */}
                <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                    <Label fontWeight="bold" className="col-span-1" message="consultants.expense.date" />
                    <Input
                        type="date"
                        value={date}
                        onChange={(e) => { setDate(e.target.value) }}
                        className={`col-span-3 p-2 border rounded-md text-body ${dateError === true && `border-error`} ${dateError === false && `border-success`} ${dateError == null && `border-greyStrong`} `}
                    />
                </div>
                {/* Period */}
                <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                    <Label fontWeight="bold" className="col-span-1" message="consultants.companyBenefits.period" />
                    <Input
                        type="month"
                        value={period}
                        onChange={(e) => { setPeriod(e.target.value) }}
                        className={`col-span-3 p-2 border rounded-md text-body ${periodError === true && `border-error`} ${periodError === false && `border-success`} ${periodError == null && `border-greyStrong`} `}
                    />
                </div>
                {/* Comment */}
                <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                    <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.comment" />
                    <div className='col-span-3 flex items-center gap-firstDegree'>
                        <Input
                            type="text"
                            onWheel={(e) => e.target.blur()}
                            value={comment}
                            onChange={(e) => { setComment(e.target.value) }}
                            placeholder={`---`}
                            className={`p-2 border rounded-md grow text-body  ${commentError === true && `border-error`} ${commentError === false && `border-success`} ${commentError == null && `border-greyStrong`} `}
                        // onBlur={amountError ? validateForm("amountErrorTest") : undefined}
                        />

                    </div>
                </div>

                {/* Currencies */}
                <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                    <Label fontWeight="bold" className="col-span-1" message="consultants.expense.currency" />
                    {errorCurrencies ? <PrintErrorMessage error={errorCurrencies} className={'col-span-3'} /> :
                        <Select className="" value={currency} onValueChange={(e) => { setCurrency(e) }}                       >
                            <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body `}>
                                <SelectValue placeholder="Currency" />
                            </SelectTrigger>
                            <SelectContent >
                                {loadingCurrencies ? <span className='loader' /> : (allCurrencies?.map((cur, index) => (
                                    <SelectItem key={index} value={cur.code}>
                                        <Label >{cur.code + " " + cur.symbol}</Label>
                                    </SelectItem>
                                )))}
                            </SelectContent>
                        </Select>}
                </div>

                {/* Amount HT */}
                <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                    <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.amountExclTaxs" />
                    <div className='col-span-3 flex items-center gap-firstDegree'>
                        <Input
                            type="number"
                            step="0.01"  // Permet de saisir des nombres décimaux
                            value={amount}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => { setAmount(e.target.value) }}
                            placeholder={`0,00 ` + currency}
                            className={`p-2 border rounded-md grow text-body text-right ${amountError === true && `border-error`} ${amountError === false && `border-success`} ${amountError == null && `border-greyStrong`} `}
                        />
                        {currency}
                    </div>

                </div>

                {/* Vat Rates */}
                <div>
                    <Label fontWeight="bold" message="consultants.expense.VATBreakdown" />
                    <h3 className="font-bold mb-2"></h3>
                    <div className="flex flex-row gap-secondDegree justify-center items-center grid grid-cols-4">
                        <div className="flex flex-col gap-2 grow col-span-3">

                            {
                                (errorVat ? <PrintErrorMessage error={errorVat} className={'col-span-3'} /> :
                                    loadingVat ? <span className='loader' /> : (vatRates.map((item, index) => (
                                        <div key={index} className='grid grid-cols-3  gap-firstDegree items-center text-right '>
                                            <div className="col-span-1 pr-2">
                                                <Label fontWeight="semibold" >{item.rate} %</Label>

                                            </div>
                                            <Input
                                                type="number"
                                                step="0.01"  // Permet de saisir des nombres décimaux
                                                min="0"
                                                // onWheel={(e) => e.target.blur()}
                                                name={`item${index}`}
                                                onWheel={(e) => e.target.blur()}
                                                value={vatValues[item.uuid]?.value || undefined}
                                                onChange={(e) => { handleVatChange(e, item) }}
                                                placeholder={t("consultants.expense.amountVAT")}
                                                className="p-2 border border-greyStrong rounded-md text-body col-span-2 text-right"

                                            />
                                        </div>
                                    ))))
                            }


                        </div>

                        <div className='grow flex flex-col gap-firstDegree items-center text-right col-span-1'>
                            <span><Label fontWeight="semibold" message="consultants.expense.totalVAT" /> : </span>
                            <Label>{totalVat?.toFixed(2)} {currency}</Label>

                        </div>
                    </div>
                </div>


                <Separator />
                {/* Total */}
                <div className='flex gap-secondDegree items-center'>
                    <div className='grow'>
                        <Label size="h3" message="consultants.expense.amountInclTaxs" />
                    </div>
                    <Label fontWeight="bold" size="h3" color="primary">{toNumber(totalVat) + toNumber(amount)} {currency}</Label>



                </div>
                <Input type='submit' id="submitFormNewCompanyBenefit" className='hidden' ref={submitRef} />

            </form >
        </div >
    );
}
