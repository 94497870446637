import React, { useRef, useState, useEffect } from "react";
import NewCompanyBenefitPanel from '@/components/macro-organisms/panels/panels-admin/newCompanyBenefit/NewCompanyBenefitPanel';
import { Toaster } from '@/components/organisms/feedback/toaster';
import { useCompanyBenefitInvoices } from '@/services/consultant-services/query/useCompanyBenefitInvoices';
import InViewComponent from '@/components/organisms/InViewComponent';
import { useParams } from 'react-router-dom';
import { useConsultantCompanyBenefitInvoices } from '@/services/admin-services/query/useConsultantCompanyBenefitInvoices';
import PrintEmptyDataMessage from "@/components/molecules/PrintEmptyDataMessage";

import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { Button } from "@/components/molecules/buttons/button";
import CompanyBenefits from "@/components/macro-organisms/tabs/consultant-vision-user/companyBenefit/CompanyBenefits";

export default function CompanyBenefitsTab() {
    const [lastSlice, setLastSlice] = useState(1);
    const { id } = useParams();

    const [companyBenefitsCopy, setCompanyBenefitsCopy] = useState([]);
    const { loading, error, data: companyBenefitInvoices, refetch } = useConsultantCompanyBenefitInvoices(id, lastSlice);


    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setCompanyBenefitsCopy([]);
        refetch(n);
        // console.log("refetchFromScratch n+", n)
    };

    const fetchNewSlice = () => {
        // console.log("fetchNewSlice", companyBenefitInvoices?.hasNext, lastSlice, "loading =", loading, error, "companyBenefitInvoices =", companyBenefitInvoices, "setCompanyBenefitsCopy =", companyBenefitsCopy)
        if (!loading && companyBenefitsCopy.length > 0 && companyBenefitInvoices?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };
    useEffect(() => {
        // console.log("useEffect companyBenefitsCopy",companyBenefitsCopy, "companyBenefitInvoices", companyBenefitInvoices, "companyBenefitsCopy?.length", companyBenefitsCopy?.length, companyBenefitInvoices?.companyBenefits?.length)
        companyBenefitsCopy?.length < companyBenefitInvoices?.companyBenefits?.length && setCompanyBenefitsCopy((prevCompanyBenefit) => [
            ...prevCompanyBenefit,
            ...companyBenefitInvoices.companyBenefits
        ]);
    }, [companyBenefitsCopy]);

    // Concaténation des nouvelles données avec les anciennes
    useEffect(() => {
        // console.log("useEffect", "companyBenefitInvoices", companyBenefitInvoices, "loading", loading)
        if (!loading && companyBenefitInvoices) {

            // Mettre à jour la copie des dépenses avec les nouvelles données
            if (lastSlice == 1) {
                setCompanyBenefitsCopy(() => [
                    ...companyBenefitInvoices?.companyBenefits
                ]);
            } else {
                setCompanyBenefitsCopy((prevCompanyBenefit) => [
                    ...prevCompanyBenefit,
                    ...companyBenefitInvoices?.companyBenefits
                ]);
            }
        }

       
    }, [companyBenefitInvoices]);

    // Requête initiale lors du changement d'ID
    useEffect(() => {
        refetchFromScratch();
    }, [id]);

    // TODO clean des taux de tva, clean du form is send, reset du consultant selectionné 
    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-auto pb-secondDegree px-1">
            <div className="flex gap-secondDegree">
                <Toaster />
                <Button style="ghost" disabled={loading} showLeftIcon leftIcon={<ReplayRoundedIcon />} onClick={() => refetchFromScratch()} showText={false} sizePadding={"firstDegree"} />

                <NewCompanyBenefitPanel global={false} refetchFromScratch={refetchFromScratch} />
            </div>
            {loading && <div className="w-full flex justify-center"><span className="loader " /></div>}

            {(!error && !loading && companyBenefitsCopy) && companyBenefitsCopy.map((item, index) => (
                <CompanyBenefits key={index} data={item} dataOwner={true} refetchFromScratch={refetchFromScratch} />
            ))}

            {!error && !loading &&
                companyBenefitsCopy?.length == 0 &&
                <PrintEmptyDataMessage message="error.emptyDataCompanyBenefit" />}
            <InViewComponent inView={fetchNewSlice} />

        </div>
    )
}
