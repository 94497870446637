/* eslint-disable react/prop-types */
/**
 * @component AddCertification
 *
 * The `AddCertification` component provides a form for adding or editing a certification. It includes functionality for form validation, submission, and deletion of certifications. The component supports both adding new certifications and updating existing ones.
 *
 * Key Features:
 * - Form for adding or editing certification details.
 * - Validation of form data before submission.
 * - Handling of form submission, including adding or updating certifications.
 * - Option to delete a certification with confirmation.
 * - Integration with various hooks for API interactions and form state management.
 *
 * Props:
 * - @param {Object} certification - The certification object to be edited (if applicable).
 * - @param {Boolean} showForm - Flag to control the visibility of the form.
 * - @param {Function} setShowForm - Function to toggle the visibility of the form.
 * - @param {Boolean} [editingCertification=false] - Flag indicating if the form is in edit mode.
 * - @param {Function} setEditingCertification - Function to toggle the editing state.
 * - @param {Object} user - User object containing details needed for API interactions.
 * - @param {Function} setIsDoingAction - Function to set the action state (e.g., loading state).
 * - @param {Function} setFormIsValid - Function to set the form validity state.
 * - @param {Function} setServerResponseIserror - Function to handle server response errors.
 * - @param {Function} setErrors - Function to set error messages.
 *
 * Local State:
 * - `confirmDelete`: Boolean to manage the delete confirmation state.
 * - `formData`: Object to store form input values.
 * - `errors`: Object to store error messages for form fields.
 * - `listOfInvalidInput`: Array of invalid input field names.
 *
 * Usage:
 * - This component is used for both adding new certifications and editing existing ones.
 * - It includes functionality to handle form validation, submission, and deletion.
 * - It integrates with hooks for API interactions and state management.
 *
 * Example:
 * ```jsx
 * <AddCertification
 *   certification={currentCertification}
 *   showForm={showForm}
 *   setShowForm={setShowForm}
 *   editingCertification={isEditing}
 *   setEditingCertification={setEditing}
 *   user={currentUser}
 *   setIsDoingAction={setIsDoingAction}
 *   setFormIsValid={setFormIsValid}
 *   setServerResponseIserror={setServerResponseIserror}
 *   setErrors={setErrors}
 * />
 * ```
 *
 * Dependencies:
 * - @component Form: Component that wraps the form elements.
 * - @component TextFieldInput: Component for text input fields.
 * - @component TextArea: Component for multi-line text input.
 * - @component Button: Provides action buttons like "Save" and "Cancel".
 * - @mui/icons-material: Icons used for actions like "Delete" and "Save".
 * - @hook useForm: React Hook Form for form state management.
 * - @hook useUpdateProspect: Hook for adding new certifications.
 * - @hook useUpdateProspectUpdateItems: Hook for updating certifications.
 * - @hook useUpdateProspectDeleteItems: Hook for deleting certifications.
 * - @utils validateFormData: Utility function for validating form data.
 * - @utils getAppLanguage: Utility function for getting the current app language.
 *
 * Functions:
 * - `handleChange(e, name)`: Updates the form data state based on user input.
 * - `handleSubmit(e)`: Handles form submission, performs validation, and calls API methods for adding or updating certifications.
 * - `handleCancel()`: Cancels the form operation and hides the form.
 * - `handleDelete()`: Deletes the certification after confirmation.
 * - `setDate(value)`: Updates the date field in the form data.
 * - `transformToBackendData(formData, lang)`: Transforms form data into the format required by the backend API.
 *
 * @example
 * const certification = {
 *   title: 'Certification Title',
 *   organization: 'Certification Organization',
 *   dateObtained: '2024-01-01',
 *   description: 'Certification Description',
 *   uuid: 'certification-uuid'
 * };
 *
 * <AddCertification
 *   certification={certification}
 *   showForm={showForm}
 *   setShowForm={setShowForm}
 *   editingCertification={true}
 *   setEditingCertification={setEditingCertification}
 *   user={currentUser}
 *   setIsDoingAction={setIsDoingAction}
 *   setFormIsValid={setFormIsValid}
 *   setServerResponseIserror={setServerResponseIserror}
 *   setErrors={setErrors}
 * />
 */

import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { validateFormData } from "./validateForm";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import TextArea from "@/components/molecules/inputs/TextArea";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import { motion } from "framer-motion";
import useUpdateProspect from "@/services/admin-services/mutations/updateProspect/useUpdateProspectAddItems";
import { Label } from "@/components/atoms/texts/label";
import getAppLanguage from "@/utils/getAppLanguage";
import useUpdateProspectUpdateItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectUpdateItems";
import useUpdateProspectDeleteItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectDeleteItems";
export default function AddCertification({
    certification,
    showForm,
    setShowForm,
    editingCertification = false,
    setEditingCertification,
    user,
    setIsDoingAction,
    setFormIsValid,
    setServerResponseIserror,
    setErrors,
    setOpenShowMoreButton,
}) {
    const form = useForm();
    const {
        addNewCertification,
        dataAddCertification,
        loadingAddCertification,
        errorAddingCertification,
    } = useUpdateProspect();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const { updateCertification, errorUpdateCertification } = useUpdateProspectUpdateItems()
    const { deleteCertification, errorDeleteCertification } = useUpdateProspectDeleteItems()
    const [formData, setFormData] = useState({
        title: certification ? certification.title : "",
        organism: certification ? certification.organization : "",
        date: certification ? certification.dateObtained : "",
        description: certification ? certification.description : "",
        certificationUuid: certification ? certification.uuid : "",
    });
    const lang = getAppLanguage();
    const [erros, setErros] = useState({
        title: "",
        organism: "",
        date: "",
        description: "",
    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const handleChange = (e, name) => {
        //setEditingCertification(true)
        const { value } = e.target;

        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setFormIsValid && setFormIsValid(false);

        // console.log(formData);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErros);

        setlistOfInvalidInput(isValid);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            if (editingCertification) {
                await updateCertification(transformToBackendData(formData, lang), user.uuid)
                setIsDoingAction && setIsDoingAction(true);
                if (errorUpdateCertification) {
                    setErrors && setErrors(errorUpdateCertification);
                    setFormIsValid && setFormIsValid(false);
                    setServerResponseIserror && setServerResponseIserror(true);
                } else {
                    setFormIsValid && setFormIsValid(true);
                }

            } else {
                // Sinon, ajoutez simplement la nouvelle description à la liste
                // console.log("id ", id);

                try {
                    await addNewCertification(
                        transformToBackendData(formData, lang),
                        user.uuid
                    );
                    // Optionnel: gérer les actions supplémentaires après le succès
                    // alert("Add successfully ")
                    setIsDoingAction && setIsDoingAction(true);
                    setFormIsValid && setFormIsValid(true);
                    setServerResponseIserror && setServerResponseIserror(false);
                    setEditingCertification && setEditingCertification(false);
                    if (errorAddingCertification) setErrors && setErrors(errorAddingCertification);
                } catch (err) {
                    setFormIsValid && setFormIsValid(false);
                    if (errorAddingCertification) setErrors && setErrors(errorAddingCertification);
                    // alert("error adding certfication", err);
                    console.error("Error adding certification:", err);
                    console.log(errorAddingCertification);
                    setServerResponseIserror && setServerResponseIserror(true);
                }
            }
            setShowForm && setShowForm(false);
            setOpenShowMoreButton && setOpenShowMoreButton(false);
        } else {
            console.log("invalid form");
            console.log("value invalid", isValid);
            console.log("value invalid", erros);
            console.log("form data", formData);
        }
        setConfirmDelete(false);
    };
    const handleCancel = () => {
        setEditingCertification && setEditingCertification(false);
        setShowForm && setShowForm(false);
        setConfirmDelete(false);
        setOpenShowMoreButton && setOpenShowMoreButton(false);
    };
    const handleDelete = async () => {
        setShowForm(false);
        const result = await deleteCertification(user.uuid, certification.uuid);
        if (errorDeleteCertification) {
            console.error("Failed to delete certification:", result.error);
            setErrors && setErrors(result.error);
            setServerResponseIserror && setServerResponseIserror(true);
            // alert("error delete certfication", errorDeleteCertification);
        } else {
            setIsDoingAction && setIsDoingAction(true);
            console.log("Certification deleted successfully:", certification.uuid);

            setFormIsValid && setFormIsValid(true);

            // alert("delete succed");
        }
        setConfirmDelete(false);
    };
    const setDate = (value) => {

        setFormData((prevFormData) => ({
            ...prevFormData,
            ["date"]: value,
        }));

    }

    return (
        <div>
            {showForm && (
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit}
                        className={` flex flex-col gap-secondDegree shadow-md bg-smoke  border-sm border-medium  p-secondDegree `}
                    >
                        <TextFieldInput
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.certificationTitle"
                            type="text"
                            value={formData.title}
                            onChange={(e) => handleChange(e, "title")}
                            required
                            color={listOfInvalidInput.includes("title") ? "error" : ""}
                        // border={
                        //   listOfInvalidInput.includes("certificationTitle")
                        //     ? "border-error"
                        //     : ""
                        // }
                        />
                        <TextFieldInput
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.organism"
                            type="text"
                            value={formData.organism}
                            onChange={(e) => handleChange(e, "organism")}
                            required
                            color={listOfInvalidInput.includes("organism") ? "error" : ""}
                        // border={
                        //   listOfInvalidInput.includes("organism") ? "border-error" : ""
                        // }
                        // className={
                        //   listOfInvalidInput.includes("organism") ? "border-error" : ""
                        // }
                        />
                        {/* <DatePickerValue
                value={formData.date}
                setValue={setDateObtained}
                label="consultants.statement.prospectConsultantPanel.formPlaceHolder.dateObtained"
              /> */}
                        <TextFieldInput
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.date"
                            type={"date"}
                            value={formData.date}
                            onChange={(e) => handleChange(e, "date")}
                            color={listOfInvalidInput.includes("date") ? "error" : ""}
                            required
                        //border={listOfInvalidInput.includes("date") ? "border-error" : ""}
                        />
                        {/* <div className="bloc">
              <DatePickerValue
                  // value={formData.date}
                  //  label="consultants.statement.prospectConsultantPanel.formPlaceHolder.date"
                  // setValue={setDate}
              />
            </div> */}

                        <TextArea
                            value={formData.description}
                            onChange={(e) => handleChange(e, "description")}
                            placeHolder="consultants.statement.prospectConsultantPanel.description"
                            rows={4} // Nombre de lignes par défaut
                            cols={40} // Nombre de colonnes par défaut
                            className="rounded-sm border-disabled border-[1px] p-firstDegree w-full " // Ajoutez d'autres attributs ou styles au besoin
                        />

                        <div className="flex flex-row gap-[12px] items-center w-full">
                            {editingCertification && (
                                <>
                                    {!confirmDelete ? (
                                        <Button
                                            onClick={() => setConfirmDelete(true)}
                                            showLeftIcon={true}
                                            showText={false}
                                            //sizePadding="firstDegree"
                                            leftIcon={
                                                <DeleteIcon className="text-primary cursor-pointer" />
                                            }
                                            style="ghost"
                                        />
                                    ) : (
                                        <motion.div
                                            className="flex flex-row gap-2 w-[70%] items-center"
                                            initial={{ x: -100, opacity: 0 }} // Commence à gauche et invisible
                                            animate={{ x: 0, opacity: 1 }} // Se déplace à droite et devient visible
                                            transition={{ duration: 0.5 }} // Durée de la transition
                                        >
                                            <Label message="consultants.statement.vatSettings.confirmDelete" />
                                            <Button
                                                showLeftIcon
                                                className="text-error border-error rounded-sm bg-white"
                                                leftIcon={
                                                    <DeleteOutlineIcon
                                                        className="text-error"
                                                        fontSize="small"
                                                    />
                                                }
                                                // showText={false}
                                                text={"global.actions.confirmDelete"}
                                                sizePadding="small"
                                                style="outline"
                                                onClick={() => handleDelete(certification.uuid)}
                                            />
                                        </motion.div>
                                    )}
                                </>
                            )}
                            <Button
                                showLeftIcon={true}
                                leftIcon={<ClearIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.cancel"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="outline"
                                className={`${confirmDelete ? "w-[17%]" : "font-bold border-primary w-full"
                                    }`}
                                showToolTip={confirmDelete ? true : false}
                                onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                            <Button
                                showLeftIcon={true}
                                leftIcon={<SaveIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.save"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="fill"
                                showToolTip={confirmDelete ? true : false}
                                className={`${confirmDelete ? "w-[16%]" : "font-bold w-full"}`}
                                type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                    </form>
                </Form>
            )}
        </div>
    );
}
const transformToBackendData = (formdata, lang) => {
    return {
        dateObtained: formdata.date,
        description: formdata.description,
        language: lang,
        organization: formdata.organism,
        title: formdata.title,
        certificationUuid: formdata.certificationUuid
    };
};
