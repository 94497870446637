import { useState } from 'react';
import { useAuth } from '@/context/AuthContext';
import errorToText from '@/utils/errorToText';

const usePasswordReset = () => {
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();

    const passwordReset = async (resetToken, newPassword) => {
        setLoading(true);
        try {
            const response = await fetch(window.ENV.BACKEND_URL+'/api/public/users/passwordReset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    
                },
                body: JSON.stringify({
                    resetPasswordToken: resetToken,
                    newPassword: newPassword
                })
            });
            // console.log("response : ",response);
            if (response.status != 200) {
                const errMessage = errorToText(response);
                setLoading(false);
                throw new Error(errMessage);
            }

            // const data = await response.json();
            setLoading(false);
            return { success: true, error: null };
        } catch (err) {
            setLoading(false);
            return { success: false, error: err.message };
        }
    };

    return { passwordReset };
};

export default usePasswordReset;
