import React, { useRef, useState, useEffect } from 'react';

import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Button } from '@/components/molecules/buttons/button';
import { AddRounded, DirectionsCar } from '@mui/icons-material';

import FormNewCompanyBenefit from '@/components/macro-organisms/panels/panels-admin/newCompanyBenefit/FormNewCompanyBenefit';
import Action from '@/components/molecules/buttons/Action';
import { useStoreCompanyBenefitInvoice } from '@/services/admin-services/api-rest/useStoreCompanyBenefitInvoice';
import { useAddCompanyBenefitInvoice } from '@/services/admin-services/mutations/useAddCompanyBenefitInvoice';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';

export default function NewCompanyBenefitPanel({ global = true, refetchFromScratch = () => { } }) {
    const submitRef = useRef(null);
    const [openNewCompanyBenefit, setOpenNewCompanyBenefit] = useState(false);
    const [dataToSend, setDataToSend] = useState();
    const [submissionError, setSubmissionError] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const { storeCompanyBenefitInvoice, loading: storeLoading, error: storeError, data: storeData } = useStoreCompanyBenefitInvoice();

    const { addCompanyBenefitInvoice, loading, error, data } = useAddCompanyBenefitInvoice()

    const handleSubmit = async (formData) => {
        // console.log("Form data:", formData);
        // setDataToSend(formData);
        if (!formData?.file) return;
        try {
            // Réinitialiser les états
            setSubmissionError(null);
            setSubmissionSuccess(false);

            // Étape 1 : Envoyer le fichier et récupérer l'UUID
            const fileResponse = await storeCompanyBenefitInvoice(formData.file, formData?.consultantUuid);
            // const companyBenefitInvoiceFileUuid = fileResponse?.uuid || storeData?.uuid;
            // console.log("fileResponse :", fileResponse);

            // Étape 2 : Envoyer la mutation avec l'UUID du fichier
            if (fileResponse?.data?.uuid) {
                // Valider et formater les valeurs 
                const newCompanyBenefitInvoice = {
                    companyBenefitInvoiceFileUuid: fileResponse?.data?.uuid, // Ajouter l'UUID du fichier
                    consultantUuid: formData.consultantUuid,
                    period: formData.period,
                    invoiceDate: formData.invoiceDate,
                    currency: formData.currency,
                    amountExcludingVat: parseFloat(formData.amountExcludingVat), // Convertir en Float
                    totalAmount: parseFloat(formData.totalAmount), // Convertir en Float
                    comment: formData.comment,
                    vatList: formData.vatList, 
                    benefitType : formData.benefitType
                };
                // console.log("newCompanyBenefitInvoice:", newCompanyBenefitInvoice, storeData);

                // Étape 2 : Envoyer la mutation avec les variables formatées
                await addCompanyBenefitInvoice({
                    variables: newCompanyBenefitInvoice, // Passer les variables correctement
                });

            }
        } catch (err) {
            // Gestion des erreurs
            setSubmissionError(err.message);
            console.error("Erreur lors de la soumission:", err);
        }
    };
    useEffect(() => {
        // console.log("use effect storeData", storeError, "storeData = ", storeData, "storeLoading ", storeLoading)

        // const sendCompanyBenefit = async (uuidFile) => {
        //     // Valider et formater les valeurs de formData
        //     const newCompanyBenefitInvoice = {
        //         companyBenefitInvoiceFileUuid: uuidFile, // Ajouter l'UUID du fichier
        //         consultantUuid: dataToSend.consultantUuid,
        //         period: dataToSend.period,
        //         invoiceDate: dataToSend.invoiceDate,
        //         currency: dataToSend.currency,
        //         amountExcludingVat: parseFloat(dataToSend.amountExcludingVat), // Convertir en Float
        //         totalAmount: parseFloat(dataToSend.totalAmount), // Convertir en Float
        //         comment: dataToSend.comment,
        //         vatList: dataToSend.vatList
        //     };
        //     console.log("newCompanyBenefitInvoice:", newCompanyBenefitInvoice, storeData);

        //     // Étape 2 : Envoyer la mutation avec les variables formatées
        //     await addCompanyBenefitInvoice({
        //         variables: newCompanyBenefitInvoice, // Passer les variables correctement
        //     });
        // }
        // if (storeData) {
        //     dataToSend && sendCompanyBenefit(storeData.uuid)
        // }

        storeError && setSubmissionError(storeError)
    }, [storeError, storeData, storeLoading]);

    useEffect(() => {
        if (data?.addCompanyBenefitInvoice?.uuid) {
            // Afficher le succès si tout est ok
            setSubmissionSuccess(true);
            refetchFromScratch();
        }
        //  console.log("use effect data", data)
        error && setSubmissionError(error)

    }, [error, data]);


    const handleClose = (close) => {
        setSubmissionError(null);
        setSubmissionSuccess(null);
        setOpenNewCompanyBenefit(close);
    };
    return (
        <Sheet open={openNewCompanyBenefit} onOpenChange={handleClose}>
            <SheetTrigger className={global && `w-full`}>

                {global ? (
                    <Action

                        icon={<DirectionsCar />}
                        text="consultants.dashboard.actions.companyBenefits"
                        initialVariant={openNewCompanyBenefit ? "active" : "default"}


                    />
                ) : (
                    <>
                        <Button leftIcon={<AddRounded />} showLeftIcon text="consultants.companyBenefits.addCompanyBenefit" />
                    </>
                )}
            </SheetTrigger>
            <SheetContent>
                <SheetHeader
                    title={"consultants.companyBenefits.addCompanyBenefit"}
                    subheader={"global.information.allFieldsMandatory"}
                />
                <SheetSlot className=" flex flex-col relative">
                    <FormNewCompanyBenefit onSubmit={handleSubmit} submitRef={submitRef} global={global} submissionError={submissionError} submissionSuccess={submissionSuccess} setSubmissionError={setSubmissionError} setSubmissionSuccess={setSubmissionSuccess} />
                    {(loading || storeLoading) && <div className='flex w-full justify-center min-h-[50px]'><span className='loader'></span></div>}
                </SheetSlot>

                <SheetFooter
                    buttonOutlineText={"global.actions.clear"}
                    buttonDressedText={(loading) ? "loading" : "global.actions.ConfirmNext"}
                    disabledDressed={(loading)}
                    warning={"global.userBeNotified"}
                    onClickOutline={(e) => submitRef.current && submitRef.reset.resetForm()}
                    onClickDressed={() => { submitRef.current && submitRef.current.click(); setSubmissionError(false); setSubmissionSuccess(false) }}

                />
            </SheetContent>
        </Sheet>
    )
}