/* eslint-disable react/prop-types */
/**
 * @component PrintSkill
 *
 * This component displays skill details and provides functionalities for editing or deleting the skill. It integrates with the `AddSkill` component for editing and manages the display of skill information with options for deletion confirmation and editing.
 *
 * Key Features:
 * - Displays skill information, including its name and rating.
 * - Allows for editing of the skill by showing the `AddSkill` component.
 * - Supports deletion of skills with a confirmation prompt.
 * - Uses `framer-motion` for smooth animations and transitions.
 *
 * Props:
 * - @param {Object} skill - The skill object to be displayed. Includes details like `uuid` and `rating`.
 * - @param {String} className - Optional custom class names for styling.
 * - @param {Boolean} canEdit - Indicates if the skill can be edited.
 * - @param {Object} user - The current user object, used for managing skill data.
 * - @param {Function} setIsDoingAction - Function to indicate if an action (like saving or deleting a skill) is in progress.
 * - @param {Function} setFormIsValid - Function to set the form validity state.
 * - @param {Function} setServerResponseIserror - Function to set the server response error state.
 * - @param {Function} setErrors - Function to set form error details.
 * - @param {Boolean} isMappedSkill - Indicates if the skill is mapped or not.
 *
 * Internal State:
 * - @state {Boolean} showForm - Determines if the `AddSkill` form is displayed.
 * - @state {Boolean} confirmDelete - Indicates if the delete confirmation prompt is active.
 *
 * Usage:
 * - This component is used to display skill details with options for editing or deleting the skill.
 * - It integrates with the `AddSkill` component for editing and provides a user interface for confirming deletions.
 * - The `PrintSkill` component uses `framer-motion` for animations and handles user interactions for skill management.
 *
 * Example:
 * <PrintSkill
 *   skill={currentSkill}
 *   className="custom-class"
 *   canEdit={true}
 *   user={currentUser}
 *   setIsDoingAction={setActionState}
 *   setFormIsValid={setFormValidity}
 *   setServerResponseIserror={setServerErrorState}
 *   setErrors={setFormErrors}
 *   isMappedSkill={true}
 * />
 *
 * Dependencies:
 * - `AddSkill` component for editing skill information.
 * - `Stars` component for displaying skill ratings.
 * - Material-UI icons (`Edit`, `DeleteIcon`, `ClearIcon`, `DeleteOutlineIcon`) for user interface interactions.
 * - `framer-motion` for UI animations.
 * - `useUpdateProspectDeleteItems` hook for deleting skills.
 * - `useGetSkillLabel` utility for retrieving skill labels.
 */

import Stars from "./Stars";
import Edit from "@mui/icons-material/Edit";
import { useState } from "react"
import AddSkill from "./AddSkill"
import { Button } from "@/components/molecules/buttons/button";
import { motion } from "framer-motion";
import { useGetSkillLabel } from "@/utils/getSkillLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import useUpdateProspectDeleteItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectDeleteItems";
import { Label } from "@/components/atoms/texts/label";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import ClearIcon from "@mui/icons-material/Clear";
const defaultSkill = "None";
export default function PrintSkill({
    skill = defaultSkill,
    className,
    canEdit = true,
    user,
    setIsDoingAction,
    setFormIsValid,
    setServerResponseIserror,
    setErrors,
    isMappedSkill = true
}) {
    const [showForm, setShowForm] = useState(false);
    const [confirmDelete, setConfirmdelete] = useState(false)
    const label = useGetSkillLabel();
    const { deleteUnmappedSkill, errorDeleteUnmappedSkill, loadingDeleteUnmappedSkill } = useUpdateProspectDeleteItems()

    const handledeleteUnmappedSkill = async () => {

        await deleteUnmappedSkill(skill.uuid)
        if (errorDeleteUnmappedSkill) {
            setErrors(errorDeleteUnmappedSkill)
            setServerResponseIserror(true)
        }
        setIsDoingAction(true)
    }

    return (
        <motion.div
            initial={{
                scale: 0.9,
            }}
            animate={{
                scale: 1,
            }}
            className="w-full"
        >
            {!showForm && (
                <div
                    className={`${confirmDelete ? "flex-col" : "flex-row"
                        } flex  place-content-end items-center gap-1 w-full text-body `}
                >
                    <div className=" basis-1/2 flex place-content-end truncate w-auto">
                        <Label
                            className={`${isMappedSkill ? "p-0" : "px-secondDegree "
                                } truncate w-auto`}
                        >
                            {isMappedSkill && skill.skill[label]}
                        </Label>{" "}
                    </div>
                    {/* <Separator orientation="vertical" className="bg-black" /> */}
                    {isMappedSkill && (
                        <Stars
                            totalStars={5}
                            indexSelected={skill.rating}
                            allowChanges={false}
                        />
                    )}
                    {!isMappedSkill && (
                        <div className=" flex flex-row items-center ">
                            <Label
                                className={`${isMappedSkill ? "p-0" : "px-secondDegree "
                                    } truncate w-auto`}
                            >
                                {!isMappedSkill && skill[label]}
                            </Label>{" "}
                            {!confirmDelete ? (
                                <Button
                                    onClick={() => setConfirmdelete(true)}
                                    showLeftIcon={true}
                                    showText={false}
                                    //sizePadding="firstDegree"
                                    leftIcon={
                                        <DeleteIcon className="text-primary cursor-pointer " />
                                    }
                                    style="ghost"
                                />
                            ) : (
                                <motion.div
                                    className="flex flex-row gap-2 w-[80%] items-center"
                                    initial={{ x: 100, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Label message="consultants.statement.vatSettings.confirmDelete" />
                                    <Button
                                        showLeftIcon
                                        className="text-error border-error rounded-sm bg-white"
                                        leftIcon={
                                            <DeleteOutlineIcon
                                                className="text-error"
                                                fontSize="small"
                                            />
                                        }
                                        // showText={false}
                                        text={"global.actions.confirmDelete"}
                                        sizePadding="small"
                                        style="outline"
                                        onClick={() => handledeleteUnmappedSkill()}
                                    />
                                    <Button
                                        showLeftIcon={true}
                                        leftIcon={<ClearIcon className="cursor-pointer" />}
                                        showText={false}
                                        style="ghost"
                                        onClick={() => setConfirmdelete(false)}
                                    />
                                </motion.div>
                            )}
                        </div>
                    )}
                    {canEdit && (
                        <Button
                            showLeftIcon={true}
                            leftIcon={<Edit className="text-primary" />}
                            padding="small"
                            style="ghost"
                            showText={false}
                            onClick={() => setShowForm(true)}
                        />
                    )}
                </div>
            )}
            <AddSkill
                skill={skill}
                showForm={showForm}
                setShowForm={setShowForm}

                editingSkill={true}
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
                isMappedSkill={isMappedSkill}
            />
        </motion.div>
    );
}
