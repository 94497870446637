import { Label } from '@/components/atoms/texts/label';
import { Button } from '@/components/molecules/buttons/button';
import { useGetSkillLabel } from '@/utils/getSkillLabel';
import React from 'react';

export default function SkillItem({forGenerationUseCase = false, data, className, ...props }) {
    let type = ""
    const forGenerationUseCase_ = forGenerationUseCase || !!data?.skillType;
    const labelInAppLanguage = useGetSkillLabel();
    switch (!forGenerationUseCase_ ? data?.skill?.skillType : data?.skillType) {
        case "HARD":
            type = "H";
            break;
        case "SOFT":
            type = "S";
            break;
        case "POWER":
            type = "P";
            break;
        default:
            console.log("SkillItem : skillType non reconnu",  data?.skillType, data?.skill?.skillType, forGenerationUseCase,forGenerationUseCase || !!data?.skillType);
            type = "";
            break;
    }
    // 21316ca0-a4a8-44b1-aa45-a3fb42a2ba13
    // 21316ca0-a4a8-44b1-aa45-a3fb42a2ba13

    console.log("SkillItem data received =", data);
    return (
        <div className={`flex justify-start items-center rounded-sm border border-medium p-2  bg-white shadow-sm ${className}`}>
            <div className='h-6 w-6 flex justify-center bg-medium  rounded-[50px] mr-2 border border-grey'>{type}</div>
            <Label>{data.skill ? data.skill[labelInAppLanguage] : data.title}</Label>
            {forGenerationUseCase_ && <Label>{data[labelInAppLanguage]}</Label>}

        {/* <Button style="outline" sizePadding="small" className='rounded-chip' {...props}></Button> */}
        </div>
    )
}