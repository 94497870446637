import React from 'react'
import { Label } from '../atoms/texts/label';

export default function PrintFetchingData() {
  return (
    <div className='flex flex-col gap-4'>

      <Label message="error.fetchingData" />
      <span className='loader'></span>
    </div>
  ); 
}
