
import React, { useEffect, useState } from "react";
import DragAndDropZone from "@/components/organisms/forms/DragAndDropZone";
import { Button } from "@/components/molecules/buttons/button";
import useAnalyzeCV from "@/services/admin-services/useAnalyzeCV"; // Assurez-vous que le chemin est correct
import { Label } from "@/components/atoms/texts/label";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";

const MultiFileUploadZone = ({ hideFileComponent, setHideFileComponent, setIsDoingAction }) => {
    const [files, setFiles] = useState([]);
    const [fileError, setFileError] = useState();
    // const [fileErrors, setFileErrors] = useState({});

    const { getAnalyzeCV, loading, error, response } = useAnalyzeCV();
    const handleUpload = async () => {
        let errors = {};
        // console.log("handleUpload");

        if (files.length > 0) {
            const updatedFiles = await Promise.all(
                files.map(async (file) => {
                    const { data, error } = await getAnalyzeCV(file);
                    // console.log("handleUpload, getAnalyzeCV", "data", data, "error", error);

                    // Accumuler les erreurs s'il y en a
                    if (error) {
                        errors[file.name] = error;
                        return file; // Garde le fichier si erreur
                    }
                    return null; // Retire le fichier si pas d'erreur
                })
            );

            // Met à jour la liste des fichiers sans erreur
            const validFiles = updatedFiles.filter(file => file !== null);
            setFiles(validFiles);

            // Optionnel : Mets à jour les erreurs globales
            setFileError(Object.keys(errors).length > 0 ? errors : null);

        } else {
            setFileError("Please select at least one file to upload.");
        }
        // console.log("handleUpload errors =", errors);
    };


    const handleFileChange = (newFiles) => {
        setFiles([...files, ...newFiles]); // Ajouter les nouveaux fichiers à la liste existante
    };
    useEffect(() => {
        if (response) {
            setIsDoingAction(true)
            setHideFileComponent(true);
        }
    }, [response]);
    // useEffect(() => {
    //     setFileErrors(...fileErrors, error);
    // }, [error]);
    return (
        <>
            {!hideFileComponent && (
                <div className="flex flex-col gap-secondDegree p-thirdDegree">
                    <DragAndDropZone
                        file={files} // Passer la liste des fichiers
                        setFile={handleFileChange} // Fonction de mise à jour des fichiers
                        fileError={fileError}
                        isMultifiles={true}
                    />
                    <Button
                        showLeftIcon={true}
                        showRightIcon={true}
                        text="global.actions.generate"
                        sizePadding="default"
                        style="ghost"
                        onClick={handleUpload}
                        disabled={loading}
                    />
                    {loading && <span className="loader"></span>}
                    {fileError && <Label message="error.errorCvNotUploaded" color="error" />}
                    {/* {error && <p className="text-red-500">Error: {error.detail}</p>} */}
                    {(error) && <PrintErrorMessage error={error} />}
                    {/* {(fileErrors) && <PrintErrorMessage error={fileErrors} />} */}
                    {(fileError) && console.log("fileErrors", fileError)}
                    {response && <p className="text-green-500"> {response.status}</p>}
                    {/* {response && response} */}
                </div>
            )}
        </>
    );
};
//TODO tester la gestion des erreurs et le comportement

export default MultiFileUploadZone;
