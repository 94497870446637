import TimesheetCalendar from './TimesheetCalendar';
import transformTimeEntriesToEvents from './transformTimeEntriesToEvents';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import PrintWarningMessage from '@/components/organisms/feedback/PrintWarningMessagee';
import { useTimeEntries } from '@/services/consultant-services/query/useTimeEntries';
import DaysSynthesis from './DaysSynthesis';
import { useConsultantTimeEntries } from '@/services/admin-services/query/useConsultantTimeEntries';


export default function ShowTimesheet({ year, month, timesheetId, totalDays, refetchTimesheets, dataOwner = false }) {
    const [timesheetMonth, setTimesheetMonth] = useState({ year: year, month: month });
    const [uniqueDaysWithEvents, setUniqueDaysWithEvents] = useState();
    const [dataSynthesis, setDataSynthesis] = useState({ daysWorked: 0, daysIllness: 0, daysVacation: 0, daysTraining: 0 });
    const { data: dataTimeEntries, loading: loadingTimeEntries, error: errorTimeEntries, refetch } = dataOwner ? useTimeEntries(timesheetId) : useConsultantTimeEntries(timesheetId);
    console.log("ShowTimesheet debug dataOwner", dataOwner);

    useEffect(() => {
        if (dataTimeEntries) {
            const transformedEvents = transformTimeEntriesToEvents(dataTimeEntries, year, month);
            console.log(" ShowTimesheet timeEntries_", dataTimeEntries, transformedEvents);
            // setTimeEntriesForCalendar(transformedEvents);
            setUniqueDaysWithEvents(getUniqueDaysWithEvents(transformedEvents));

            const daysWorked = dataTimeEntries.filter(entry => entry.type === 'WORK').length;
            const daysIllness = dataTimeEntries.filter(entry => entry.type === 'ILLNESS').length;
            const daysVacation = dataTimeEntries.filter(entry => entry.type === 'VACATION').length;
            const daysTraining = dataTimeEntries.filter(entry => entry.type === 'TRAINING').length;

            setDataSynthesis({
                daysWorked,
                daysIllness,
                daysVacation,
                daysTraining
            });
        }
        if (errorTimeEntries) {
            console.error("Error fetching time entries: ", errorTimeEntries);
        }


    }, [dataTimeEntries, errorTimeEntries, year, month]);

    const getUniqueDaysWithEvents = (events) => {
        console.log("getUniqueDaysWithEvents events = ", events)
        const uniqueDays = new Set(events?.map(event => moment(event.start).format('YYYY-MM-DD')));
        return uniqueDays.size;
    };

    return (
        <>
            <div className="w-1/4 flex flex-col gap-firstDegree">
                <DaysSynthesis data={dataSynthesis.daysWorked} message="daysWorked" />
                <DaysSynthesis data={dataSynthesis.daysIllness} message="daysIllness" />
                <DaysSynthesis data={dataSynthesis.daysVacation} message="daysVacation" />
                <DaysSynthesis data={dataSynthesis.daysTraining} message="daysTraining" />
            </div>
            <div className="w-3/4">
                <div className='flex min-h-6'>
                    {loadingTimeEntries && <span className='loader' />}
                    {errorTimeEntries && <PrintErrorMessage error={errorTimeEntries} />}
                    {dataTimeEntries && (
                        <div className='w-full'>
                            {(totalDays === 0 && (dataSynthesis?.daysWorked + dataSynthesis?.daysIllness + dataSynthesis?.daysVacation + dataSynthesis?.daysTraining) == 0) && <PrintErrorMessage label="error.nbDaysZero" />}
                            {totalDays !== 0 && totalDays !== uniqueDaysWithEvents && <PrintWarningMessage warning="error.nbDaysDifferent" />}
                            {(totalDays !== 0 || (dataSynthesis?.daysWorked + dataSynthesis?.daysIllness + dataSynthesis?.daysVacation + dataSynthesis?.daysTraining) !== 0) &&
                                <TimesheetCalendar
                                    selectedMonthToDisplay={`${year}-${month}`}
                                    timesheetId={timesheetId}
                                    viewOnly
                                    timesheetMonth={timesheetMonth}
                                    refetchTimesheets={refetchTimesheets}
                                    dataOwner={dataOwner}
                                />}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
