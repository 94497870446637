/* eslint-disable react/prop-types */
/**
 * Star Component
 *
 * This component renders a star rating system using the `react-icons` library, allowing users to select a rating
 * or display an existing rating. It is customizable to allow or disallow changes and notifies a parent component
 * of the selected index via a callback function.
 *
 * Key Features:
 * - Renders a specified number of stars (`totalStars`), with the ability to set an initial selected index.
 * - Allows users to select a star rating by clicking (if `allowChanges` is enabled).
 * - Sends the selected rating back to a parent component using the `informSelectedIndex` callback function.
 * - Dynamically updates the selected stars when `indexSelected` prop changes, re-rendering to reflect the new rating.
 * - Customizable appearance via props, including disabling interactivity with `allowChanges`.
 * - Uses `useEffect` to synchronize internal state with the `indexSelected` prop when it changes.
 *
 * Props:
 * - `totalStars`: Number of stars to display (required).
 * - `informSelectedIndex`: Callback function to notify the parent component of the selected star rating (optional).
 * - `indexSelected`: The index of the currently selected star, used to initialize or update the component (default is `0`).
 * - `allowChanges`: Boolean to control whether the user is allowed to change the star rating by clicking (default is `true`).
 * - Additional props are spread onto the root element for further customization (e.g., className).
 *
 * Usage:
 * - This component can be used for both interactive star ratings and static displays of ratings.
 */

import { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";

const Star = ({
  totalStars,
  informSelectedIndex,
  indexSelected = 0,
  allowChanges = true,
  ...props
}) => {
  const [selectedStars, setSelectedStars] = useState(indexSelected);

  const handleStarClick = (index) => {
    if (allowChanges) {
      setSelectedStars(index + 1);
      informSelectedIndex(index + 1);
    }
  };
  useEffect(() => {
    setSelectedStars(indexSelected);
    informSelectedIndex && informSelectedIndex(indexSelected);
  }, [indexSelected]);
  return (
    <div className="flex " {...props}>
      {[...Array(totalStars)].map((_, index) => (
        <FaStar
          key={index}
          value={selectedStars}
          onClick={() => handleStarClick(index)}
          style={{
            cursor: allowChanges && "pointer",
            fontSize: 25,
          }}
          className={`${
            index < selectedStars ? "text-primary" : "text-grey"
          } border-primary `}
        />
      ))}

   
    </div>
  );
};

export default Star;
