import { Label } from "@/components/atoms/texts/label";
import { useConsultantContext } from "@/context/ConsultantContext";
import Action from "@/components/molecules/buttons/Action";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import PersonIcon from "@mui/icons-material/Person";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AddTaskIcon from "@mui/icons-material/AddTask";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useState, useEffect, useMemo } from "react";

import DividerLight from "@/components/atoms/divider/dividerLight";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ResizablePanel } from "@/components/ui/resizable";
import LineArt from "@/assets/images/lineArt.png";

import { getUserById } from "@/services/consultant-services/StatementData";
import useActiveUser from "./useActiveUser";
import useGetUser from "@/services/admin-services/query/useUser";

export default function DetailsAboutConsultant() {
    const { activeUser, setActiveUser } = useConsultantContext();
    const [itemActive, setItemActive] = useState(0);
    const navigate = useNavigate();
    const { id } = useParams();
    const lastElementOfURL = "./" + window.location.pathname.split("/").pop();

    useEffect(() => {
        // console.log("activeUser change activeUser id is ", activeUser)
        
    }, [activeUser]);

    const actions = [
        {
            icon: <ViewQuiltIcon />,
            text: "consultants.dashboard.tabs.statement",
            url: "./statement",
        },
        {
            icon: <PersonIcon />,
            text: "consultants.dashboard.tabs.information",
            url: "./information",
        },
        {
            icon: <ContactPageIcon />,
            text: "consultants.dashboard.tabs.cv",
            url: "./cv",
            variant: "disabled"
        },
        {
            icon: <RequestQuoteIcon />,
            text: "consultants.dashboard.tabs.payslip",
            url: "./payslip",
        },
        {
            icon: <EventAvailableIcon />,
            text: "consultants.dashboard.tabs.timesheet",
            url: "./timesheet",
            //   variant:"disabled"
        },
        {
            icon: <AddTaskIcon />,
            text: "consultants.dashboard.tabs.skills",
            url: "./skills",
            variant: "disabled"

        },
        {
            icon: <ShoppingCartIcon />,
            text: "consultants.dashboard.tabs.expense",
            url: "./expense",
        },
        {
            icon: <DirectionsCarIcon />,
            text: "consultants.dashboard.tabs.companyBenefits",
            url: "./companyBenefit",
        },
    ];

    const handleClick = (index, url) => {
        // console.log("handleClick, url = ", url, index);
        setItemActive(index);
        navigate(url, { replace: true });
    };
    const pathSegments = window.location.pathname.split("/");

    // Vérifie que l'URL est bien formée selon la structure /consultant/:id/(mot à vérifier)/...
    if (
        pathSegments[1] === "consultant" &&
        pathSegments[2] === id &&
        [
            "statement",
            "information",
            "cv",
            "payslip",
            "timesheet",
            "skills",
            "expense",
            "companyBenefit",
        ].includes(pathSegments[3])
    ) {
        // L'URL est bien formée. Insérer ici la logique souhaitée pour ces cas.
    } else {
        navigate(actions[0].url);
    }
    // console.log("DetailsAboutConsultant lastElementOfURL= " , lastElementOfURL)

    return (
        <ResizablePanel
            defaultSize={85}
            className="h-full z-10"
            id="details-panel"
            order={2}
        >
            <div className="flex flex-col p-secondDegree  h-full  ">
                <div className="overflow-auto ">
                    <img src={LineArt} className="bgLineArt" />
                    <div className="flex-row p-paddingTopAndBottom">
                        {activeUser && (
                            <Label size="h3" fontWeight="bold">
                                {activeUser.firstname + " " + activeUser.lastname}
                            </Label>
                        )}
                        <span> - </span>
                        {activeUser && (
                            <Label size="body" fontWeight="">
                                {activeUser.trigram}
                            </Label>
                        )}
                    </div>

                    <div className="grid grid-cols-8 gap-4 %+ml; overflow-auto">
                        {actions.map((action, index) => (
                            <Action
                                key={index}
                                icon={action.icon}
                                text={action.text}
                                // initialVariant={action.variant || (itemActive === index ? "active" : "default")}
                                initialVariant={
                                    action.variant ||
                                    ("./" + pathSegments[3] === action.url ? "active" : "default")
                                }
                                // initialVariant={ "default"}
                                className="gap-0 space-y-3 py-3"
                                onClickAction={() => handleClick(index, action.url)}
                            />
                        ))}
                    </div>
                    <DividerLight className="" />

                    <Outlet />
                </div>
            </div>
        </ResizablePanel>
    );
}
