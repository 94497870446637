/* eslint-disable react/prop-types */
/**
 * @component SkillsPanel
 *
 * This component is responsible for displaying and managing a panel of skills, including both mapped and unmapped skills.
 * It provides an interface for viewing, adding, and editing skills, with support for handling form validation and user feedback.
 *
 * Key Features:
 * - Displays a list of mapped skills that the user has already added, as well as unmapped skills that can be added.
 * - Allows users to add new skills by opening a form when the "Add Skill" button is clicked.
 * - Each skill is rendered using the `PrintSkill` component for display, editing, and deletion.
 * - Handles form validation and displays error messages using `PrintErrorMessage`.
 * - Integrates animations using `framer-motion` for smooth UI transitions.
 * - Provides a back button to hide the panel and navigate away from it.
 *
 * Props:
 * - @param {Function} hideAllOverview - Function to hide the entire panel view.
 * - @param {Function} showComponent - Function to toggle the visibility of the panel.
 * - @param {Array} skills - Array of skills that are currently mapped to the user.
 * - @param {Object} user - The current user object, used for managing skill ownership and permissions.
 * - @param {Array} unmappedSkills - Array of skills that are available for adding to the user’s profile.
 * - @param {Function} setIsDoingAction - Function to indicate if an action (like adding or editing a skill) is in progress.
 *
 * Internal State:
 * - @state {Boolean} showForm - Controls the visibility of the skill addition form.
 * - @state {Boolean} showMappedSkills - Controls the visibility of the mapped skills section.
 * - @state {Boolean} showUnmappedSkills - Controls the visibility of the unmapped skills section.
 *
 * Usage:
 * - This component is used in contexts where skills need to be managed, such as a user profile or skill management system.
 * - It interacts with components like `PrintSkill` for skill management and `PrintErrorMessage` for displaying validation feedback.
 * - The `SkillsPanel` component provides a user-friendly interface for both viewing existing skills and adding new ones.
 *
 * Example:
 * <SkillsPanel
 *   hideAllOverview={handleHideAllOverview}
 *   showComponent={togglePanelVisibility}
 *   skills={userSkills}
 *   user={currentUser}
 *   unmappedSkills={availableSkills}
 *   setIsDoingAction={setActionState}
 * />
 *
 * Dependencies:
 * - `PrintSkill` component for rendering individual skills.
 * - `PrintErrorMessage` component for displaying form validation errors.
 * - `framer-motion` for UI animations.
 */

import AddSkill from "./AddSkill";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import PrintSkill from "./PrintSkill";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";

export default function SkillsInPanel({
    hideAllOverview,
    showComponent,
    skills,
    user,
    unmappedSkills,
    setIsDoingAction,
}) {

    const [showForm, setShowForm] = useState(false);
    const [fomrIsValid, setFormIsValid] = useState(false);
    const [serverresponseIsError, setServerResponseIserror] = useState(false);
    const [errors, setErrors] = useState(null);


    console.log("les skills reçu", skills, "et unmapp", unmappedSkills);

    const hider = () => {
        hideAllOverview(false);
        showComponent(false);
    };
   
    const handleAddClick = () => {
        setShowForm(true);
        setFormIsValid(false);
    };

    return (
        <motion.div className="flex flex-col gap-secondDegree" variants={item}>
            {serverresponseIsError && <PrintErrorMessage error={errors} />}
            <div className="flex items-center gap-fourthDegree w-full">
                <Button
                    showLeftIcon={true}
                    leftIcon={
                        <ArrowBackIcon className="cursor-pointer" onClick={hider} />
                    }
                    showText={false}
                    style="ghost"
                    onClick={hider}
                />
                <div className="basis-3/4">
                    <Label
                        message="consultants.statement.prospectConsultantPanel.skills"
                        // size="h3"
                        fontWeight="bold"
                    />
                </div>

                <Button
                    showLeftIcon={true}
                    leftIcon={<AddIcon fontSize="small" />}
                    showText={true}
                    text="global.actions.add"
                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                    style="ghost"
                    className="font-bold basis-1/4 "
                    onClick={handleAddClick} // Options: "fill", "outline", "ghost", "dressedGhost"
                />
            </div>
            {showForm && (
                <div className="flex flex-col gap-secondDegree">
                    {" "}
                    <AddSkill
                        showForm={showForm}
                        setShowForm={setShowForm}
                    
                        user={user}
                        setIsDoingAction={setIsDoingAction}
                        setFormIsValid={setFormIsValid}
                        setServerResponseIserror={setServerResponseIserror}
                        setErrors={setErrors}
                        editingSkill={false}
                    />
                  
                </div>
            )}
            <div className=" flex flex-col gap-secondDegree">
                {skills &&
                    skills.map((skill) => {
                        return (
                            <PrintSkill
                                key={skill.skill.uuid}
                                skill={skill}
                                
                                user={user}
                                setIsDoingAction={setIsDoingAction}
                                setFormIsValid={setFormIsValid}
                                setServerResponseIserror={setServerResponseIserror}
                                setErrors={setErrors}
                            />
                        );
                    })}
                {unmappedSkills.length > 0 && (
                    <>
                        <Separator orientation="horizontal" className="" />
                        <Label
                            message={"consultants.cv.unmappedSkills"}
                            size="h3"
                            fontWeight="bold"
                        />
                        <Label
                            message={"consultants.statement.prospectConsultantPanel.umappedSkillsToAdd"}
                        />
                    </>
                )}
                {unmappedSkills &&
                    unmappedSkills.map((skill) => {
                        return (
                            <PrintSkill
                                key={skill.uuid}
                                skill={skill}
                                user={user}
                                canEdit={false}
                                setIsDoingAction={setIsDoingAction}
                                setFormIsValid={setFormIsValid}
                                setServerResponseIserror={setServerResponseIserror}
                                setErrors={setErrors}
                                isMappedSkill={false}
                            />
                        );
                    })}
            </div>
        </motion.div>
    );
}
