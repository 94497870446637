import React from 'react';
import { Label } from '@/components/atoms/texts/label';
import { getEmojiFlag } from "countries-list";

import ShowMoreButton from './ShowMoreButton';

export default function dataItem({ data, className, setIsDoingAction, ...props }) {

    return (
        <div
            className={`bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree min-w-[320px] relative grow-0 ${className}`}
            {...props}
        >
            <div className='flex gap-firstDegree items-center'>
            {getEmojiFlag(data.language== "EN" ? "GB" : data.language)}
            <Label fontWeight="bold" size="h4">
                    {data.title}
                </Label>
            </div>

            <Label>
                {data.school} • {data.city} • {data.startDate} - {data.endDate}
            </Label>
            <Label>{data.description}</Label>
            <ShowMoreButton idBlock={data.uuid} type="education" dataItem={data} setIsDoingAction={setIsDoingAction} />
        </div>
    );
}