
import { Button } from '../molecules/buttons/button'
import { useTranslation } from 'react-i18next';
import { getEmojiFlag } from 'countries-list'
import { languages } from 'countries-list'
import { useEffect } from 'react';
import { useState } from 'react';
import { useToast } from './feedback/use-toast';
import { useAuth } from '@/context/AuthContext';
import useUpdateUser from '@/services/mutation/admin-mutations/useUpdateUserl';
import { setCookie } from '@/utils/cookie';
import { useUpdateProfile } from '@/services/consultant-services/useUpdateProfile';

export default function Translate() {
    const [translate, i18n] = useTranslation("global");
    const [currentLanguage, setCurrentLanguage] = useState();
    const { user: authUser, hasPrivileges } = useAuth();
    // const { setLanguage } = useUpdateUser();
    const { setLanguage } = useUpdateProfile();
    const { toast } = useToast();

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang.toLowerCase())
    }
//TODO : initialiser correctement le langage au démarrage de l'application
    const handleUpdateLanguage = async (newLanguage) => {
        const { data, error } = await setLanguage(newLanguage.toUpperCase());
        if (error) {
            console.error('handleUpdateLanguage Error updating language:', error);
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 2500,
                message: error
            })
            return error;
        } else {
            const updatedUser = { ...authUser, language: newLanguage };
            setCookie('user', JSON.stringify(updatedUser), 7);
            // console.log('handleUpdateLanguage Language updated:', data, newLanguage);
            return undefined;
        }
    };

    useEffect(() => {
        setCurrentLanguage(i18n.language.toUpperCase());
        handleUpdateLanguage(i18n.language);
    }, [i18n.language]);

    

    return (

        <div className='flex rounded-sm overflow-hidden w-full'>


            <Button
                showText={true}
                style={currentLanguage == "EN" ? `dressedGhost` : `ghost`}
                className="rounded-none w-full"
                onClick={() => handleChangeLanguage("en")}>
                {getEmojiFlag("GB")} {languages.en.native}
            </Button>

            <Button
                showText={true}
                style={currentLanguage == "FR" ? `dressedGhost` : `ghost`}
                className="rounded-none w-full"
                onClick={() => handleChangeLanguage("fr")}>
                {getEmojiFlag("FR")} {languages.fr.native}
            </Button>

        </div>
    )
}

