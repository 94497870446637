

import { useState } from "react";
import { useAuth } from "@/context/AuthContext";

export const useGetExpenseReportProof_AsUser = (expenseReportFileUuid) => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const getExpenseProof = async (ExpenseProofFileUuid) => {
        setLoading(true);
        setError(null);
// f4bfbb4b-e9ee-4937-bee8-202c455be206
        try {
            const response = await fetch( window.ENV.BACKEND_URL+`/api/secured/users/expense/expenseReportProof/${ExpenseProofFileUuid}`,{
                method: "GET",
                headers: {
                    Authorization: `Bearer ${user.strongToken}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to retrieve expense invoice");
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            // console.log("blob file expense ", blob, url)

            setData({ url, type: blob.type });
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { getExpenseProof, loading, error, data };
};
