import React, { createContext, useContext, useState, useEffect } from 'react';
import { setCookie, getCookie, eraseCookie } from '../utils/cookie';
import { useNavigate } from 'react-router-dom';
import { availableRoutes } from '@/router/availableRoutes';
import { match } from 'path-to-regexp';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);


const USER_COOKIE_NAME = 'user';

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const userCookie = getCookie(USER_COOKIE_NAME);
        return userCookie ? JSON.parse(userCookie) : null;
    });

    const [viewAs, setViewAs] = useState();
    const navigate = useNavigate();

    // Polling interval in milliseconds
    const POLLING_INTERVAL = 2000;
    let lastCookieValue = getCookie(USER_COOKIE_NAME);

    useEffect(() => {
        const handleStorageChange = () => {
            const userCookie = getCookie(USER_COOKIE_NAME);
            setUser(userCookie ? JSON.parse(userCookie) : null);
            if (userCookie !== lastCookieValue) {
                !userCookie && window.location.reload();
                lastCookieValue = userCookie;
            }
        };

        const checkCookieChange = () => {
            const userCookie = getCookie(USER_COOKIE_NAME);
            if (userCookie !== lastCookieValue) {
                !userCookie && window.location.reload();
                lastCookieValue = userCookie;
                setUser(userCookie ? JSON.parse(userCookie) : null);
            }
        };

        // Add event listener for storage changes
        window.addEventListener('storage', handleStorageChange);

        // Polling for cookie changes
        const interval = setInterval(checkCookieChange, POLLING_INTERVAL);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const hasAdminPrivileges = user && user.privileges && user.privileges.some(priv => priv.name.startsWith("ADMIN"));
        const hasUserPrivileges = user && user.privileges && user.privileges.some(priv => priv.name.startsWith("USER"));
        if (hasAdminPrivileges) {
            setViewAs("ADMIN_")
        } else if (hasUserPrivileges) {
            setViewAs("USER_")
        }
    }, [user]);

    const loginWeakToken = (weakToken) => {
        const updatedUser = { ...user, weakToken };
        setCookie(USER_COOKIE_NAME, JSON.stringify(updatedUser), 7); //TODO : le weakToken est valable 7j
        setUser(updatedUser);
    };

    const loginStrongToken = (strongToken, privileges, name, surname, uuid, company, language, rememberMe) => {
        const updatedUser = { privileges, name, surname, uuid, company, language, isAuthenticated: true, weakToken: null, strongToken };

        const hasAdminPrivileges = updatedUser.privileges.some(priv => priv.name.startsWith("ADMIN"));
        const hasUserPrivileges = updatedUser.privileges.some(priv => priv.name.startsWith("ADMIN"));
        if (hasAdminPrivileges) {
            setViewAs("ADMIN_")
        } else if (hasUserPrivileges) {
            setViewAs("USER_")
        }

        setCookie(USER_COOKIE_NAME, JSON.stringify(updatedUser), rememberMe ? 7 : 1);
        setUser(updatedUser);
    };

    const logout = () => {
        eraseCookie(USER_COOKIE_NAME);
        setUser(null);
    };

    const isAuth = () => {
        return user && user.isAuthenticated ? true : false;
    };

    const hasRoles = (roles) => roles.includes(user?.role);

    const hasPrivileges = (prefix) => {
        return user.privileges.some(priv => priv.name.startsWith(prefix));
    };

    const hasAtLeastOnePrivilegesIn = (requiredPrivileges) => {
        if (!user || !user.privileges) return false;
        if (requiredPrivileges.length === 0) return true; 
        return requiredPrivileges.some(priv => user.privileges.some(userPriv => userPriv.name === priv));
    };

    
    const isBrowsingAllowedTo = (url) => {
        const route = availableRoutes.find(route => {
            if (!route?.url) return false;
            const matcher = match(route.url, { decode: decodeURIComponent });
            return matcher(url);
        });
        const isAllowed = route?.available && hasAtLeastOnePrivilegesIn(route?.requiredPrivileges);
        !isAllowed && console.error("Browsing isn't allowed to ", url, "because you must have the privileges ", route?.requiredPrivileges, " or the route isn't yet available: ", route?.available);
        return isAllowed;
    };

    const handleSetViewAs = (userView = false, adminView = false) => {
        if (userView) {
            setViewAs("USER_")
        }
        if (adminView) {
            setViewAs("ADMIN_")
        }
        if (!userView && !adminView) {
            if (viewAs == "USER_") {
                setViewAs("ADMIN_");
                navigate("/consultant");
            } else if (viewAs == "ADMIN_") {
                setViewAs("USER_");
                navigate("/administration");
            }

        }
    }

    return (
        <AuthContext.Provider value={{ user, loginWeakToken, loginStrongToken, logout, isAuth, hasRoles, hasPrivileges, isBrowsingAllowedTo, handleSetViewAs, viewAs }}>
            {children}
        </AuthContext.Provider>
    );
};
