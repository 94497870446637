import React, { useEffect, useState } from 'react';
import { Label } from '../../atoms/texts/label';
import { Progress } from '@/components/molecules/progress';
import { Button } from '@/components/molecules/buttons/button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function PrintErrorMessage({ error, children, label, className, delay, dismissible }, ...props) {
    const [visible, setVisible] = useState(true);
    const [progress, setProgress] = useState(0);

    const clear = () => {
        setVisible(false);
        setProgress(0);
    }

    function ProgressErrorDelay() {
        return (
            delay && typeof delay === 'number' && (
                <Progress value={progress} className="w-1/3 h-1 " color="bg-error" />
            )
        )
    }

    useEffect(() => {
        if (delay && typeof delay === 'number') {
            const intervalTime = 10; // Intervalle de temps pour mettre à jour la progression
            const steps = delay / intervalTime; // Nombre de mises à jour de la progression
            let currentStep = 0;

            const timer = setInterval(() => {
                currentStep++;
                setProgress((currentStep / steps) * 100);

                if (currentStep >= steps) {
                    setVisible(false);
                    clearInterval(timer);
                }
            }, intervalTime);

            return () => clearInterval(timer); // Nettoyage de l'intervalle en cas de démontage du composant
        }
    }, [delay]);

    if (!visible) {
        return null; // Si le composant n'est plus visible ou s'il n'y a pas d'erreur, on ne rend rien
    }

    if (!error && !label && !children) return

    if ((!!error?.graphQLErrors) && Array.isArray(error?.graphQLErrors) && error?.graphQLErrors?.length > 1) {
        // Si error est un tableau, on parcourt chaque élément et on affiche une div avec un Label par élément
        return (
            <div className={`flex w-full flex-col space-y-2 ${className}`} {...props}>
                {error.graphQLErrors.map((err, index) => (
                    <div key={index} className={`flex p-secondDegree border border-error rounded-sm bg-error20`}>
                        {children ? children : <Label message={label} >{error.message}</Label>}
                        {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={clear} className="text-error rounded-sm"></Button>}
                        <ProgressErrorDelay />
                    </div>
                ))}
            </div>
        );
    } else
        if (error?.message) {
            // Si error.message est une string, on affiche ce message dans le Label
            return (
                <div className={`flex w-full p-secondDegree items-center justify-between border border-error rounded-sm bg-error20 ${className}`} {...props}>
                    {children ? children : <Label message={label} >{error.message}</Label>}
                    {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={clear} className="text-error rounded-sm"></Button>}
                    <ProgressErrorDelay />
                </div>
            );
        } else if (error && !error?.message) {
            // Si error mais pas error.message, on affiche ce message dans le Label
            return (
                <div className={`flex w-full p-secondDegree items-center justify-between border border-error rounded-sm bg-error20 ${className}`} {...props}>
                    {children ? children : <Label message={label}>{error}</Label>}
                    {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={clear} className="text-error rounded-sm"></Button>}
                    <ProgressErrorDelay />
                </div>
            );
        }
    if (label) {
        return (
            <div className={`flex w-full p-secondDegree items-center justify-between border border-error rounded-sm bg-error20 ${className}`} {...props}>
                {children ? children : <Label message={label}>{error}</Label>}
                {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={clear} className="text-error rounded-sm"></Button>}
                <ProgressErrorDelay />
            </div>
        );
    } else {
        return (
            <div className={`flex w-full p-secondDegree items-center justify-between border border-error rounded-sm bg-error20 ${className}`} {...props}>
                {children ? children : <Label message={"error.fromServer.unknownError"}>{error}</Label>}
                {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={clear} className="text-error rounded-sm"></Button>}
                <ProgressErrorDelay />
            </div>
        );
    }
    // return (
    //     <div className={`flex  p-secondDegree items-center justify-between border border-error rounded-sm bg-error20 ${className}`} {...props}>
    //         {children ? children : <Label message={"error.fromServer.unknownError"}>{error}</Label>}
    //         {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={clear} className="text-error rounded-sm"></Button>}
    //         <ProgressErrorDelay />
    //     </div>
    // );

    // // return null; // Si error n'est ni un message ni un tableau, on ne rend rien
    
    // return (
    //     <div className={`w-full flex flex-col space-y-2 p-1 ${className}`} {...props}>
    //         {/* {!!error.graphQLErrors && Array.isArray(error.graphQLErrors) ? (
    //             error.graphQLErrors.map((err, index) => (
    //                 <div key={index} className={`flex items-center justify-between p-secondDegree border border-error rounded-sm bg-error20`}>
    //                     <Label message={label} >{err.message}</Label>
    //                     {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={clear} className="text-error rounded-sm"></Button>}
    //                     <ProgressErrorDelay />
    //                 </div>
    //             ))
    //         ) :  */}
    //         {
    //             error?.message ? (
    //                 <div className={`flex  p-secondDegree items-center justify-between border border-error rounded-sm bg-error20 ${className}`} {...props}>
    //                     {children ? children : <Label message={label} >{error.message}</Label>}
    //                     {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={clear} className="text-error rounded-sm"></Button>}

    //                     <ProgressErrorDelay />


    //                 </div>
    //             ) : (
    //                 <div className={`flex  p-secondDegree items-center justify-between border border-error rounded-sm bg-error20 ${className}`} {...props}>
    //                     {children ? children : <Label message={label}>{error}</Label>}
    //                     {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={clear} className="text-error rounded-sm"></Button>}

    //                     <ProgressErrorDelay />


    //                 </div>
    //             )
    //         }



    //     </div>
    // );
}
