/* eslint-disable react/prop-types */
/**
 * @component Project
 * 
 * This component is responsible for displaying and managing projects. It includes functionality for 
 * adding new projects and displaying a list of existing projects. It also handles form visibility and 
 * error states.
 * 
 * Key Features:
 * - Displays a list of projects with the ability to edit them.
 * - Provides a button to add new projects, which toggles a form for project creation.
 * - Includes a back button to hide the current overview and return to the previous view.
 * - Manages internal states for form visibility, error handling, and project editing.
 * - Uses `framer-motion` for animation effects.
 * 
 * Props:
 * - @param {Function} hideAllOverview - Function to hide all overviews.
 * - @param {Function} showComponent - Function to show or hide the component.
 * - @param {Array} projects - List of projects to be displayed.
 * - @param {Object} user - The current user information.
 * - @param {Function} setIsDoingAction - Function to set the action state.
 * 
 * Internal State:
 * - @state {Boolean} showForm - Determines if the add project form is visible.
 * - @state {Boolean} editingProject - Tracks if a project is being edited.
 * - @state {Boolean} fomrIsValid - Indicates if the form is valid.
 * - @state {Boolean} serverresponseIsError - Indicates if there is an error in server response.
 * - @state {Boolean} userTapping - Indicates if the user is interacting with the form.
 * - @state {Object|null} errors - Stores error messages if any occur.
 * 
 * Usage:
 * - This component is used to manage and display a list of projects, allowing users to add new projects and edit existing ones.
 * - It integrates error handling and state management to provide a smooth user experience.
 */

import  { useState} from "react";
import AddProject from "./AddProject";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import PrintProject from "./PrintProject";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';

export default function Project({
  hideAllOverview,
  showComponent,
  projects,

  user,
  setIsDoingAction,
}) {
  const [showForm, setShowForm] = useState(false);

  const [editingProject, setEditingProject] = useState(false);
  const [fomrIsValid, setFormIsValid] = useState(false);
  const [serverresponseIsError, setServerResponseIserror] = useState(false);
  const [userTapping, setUserTapping] = useState(false);
   const [errors, setErrors] = useState(null);
  
  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };
  const handleAdd = () => {
    setShowForm(true);
    setFormIsValid(false)
}
  return (
    <motion.div variants={item}>
      {serverresponseIsError && <PrintErrorMessage error={errors} />}
      <div className="flex items-center gap-fourthDegree w-full">
        <Button
          showLeftIcon={true}
          leftIcon={
            <ArrowBackIcon className="cursor-pointer" onClick={hider} />
          }
          showText={false}
          style="ghost"
          onClick={hider}
        />
        <div className="basis-3/4">
          <Label
            message="consultants.statement.prospectConsultantPanel.projects"
            size="h3"
            fontWeight="bold"
          />
        </div>

        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={handleAdd} // Options: "fill", "outline", "ghost", "dressedGhost"
        />
      </div>
      <div className=" flex flex-col gap-secondDegree">
        {showForm && (
          <AddProject
            showForm={showForm}
            setShowForm={setShowForm}
            editingProject={false}
            setEditingProject={setEditingProject}
            user={user}
            setIsDoingAction={setIsDoingAction}
            setFormIsValid={setFormIsValid}
            setServerResponseIserror={setServerResponseIserror}
            setUserTapping={setUserTapping}
            setErrors={setErrors}
          />
        )}
        <div className=" flex flex-col gap-firstDegree">
          {projects.map((project) => {
            return (
              <PrintProject
                key={project.uuid}
                project={project}
                setEditingProject={setEditingProject}
                editingProject={editingProject}
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setUserTapping={setUserTapping}
                setErrors={setErrors}
              />
            );
          })}
        </div>
      </div>
    </motion.div>
  );
}