import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { useAuth } from "@/context/AuthContext";

 const ADD_SKILL = gql`
  mutation MyMutation($newSkill: NewSkillDto!) {
    addSkill(newSkill: $newSkill) {
      labelDe
      companyUuid
      labelEn
      labelEs
      labelFr
      labelIt
      labelNl
      skillType
    }
  }
`;
export const useAddSkill = () => {
  const { user } = useAuth();
  const [addSkill, { loading, error, data }] = useMutation(ADD_SKILL, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const handleAddSkill = async (newSkill) => {
    try {
      const response = await addSkill({
        variables: { newSkill },
      });
      return response.data.addSkill;
    } catch (err) {
      console.error("Error adding skill:", err);
      throw err;
    }
  };

  return { handleAddSkill, loading, error, data };
};