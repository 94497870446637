import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const DELETE_PAYSLIP = gql`
  mutation DeletePayslip($uuid: ID!) {
    deletePayslip(deletedPayslip: { uuid: $uuid })
  }
`;

export const useDeletePayslip = () => {
  const { user } = useAuth();
  const [deletePayslip, { loading, error, data }] = useMutation(DELETE_PAYSLIP, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });
//   console.log("useDeletePayslip", deletePayslip); 

  return { deletePayslip, loading, error, data };
};
