/**
 * @component PrintSkillAdded
 *
 * The `PrintSkillAdded` component displays a skill that has been added to a list. It allows users to view skill details, update skill types, and delete skills. The component is interactive, providing feedback and updates based on user actions.
 *
 * Key Features:
 * - Displays skill details including its label and the number of assigned consultants.
 * - Allows users to update the skill type using a dropdown menu.
 * - Provides functionality to delete the skill, with conditions based on whether the skill is assigned to any consultants.
 * - Toggles visibility of delete options based on user interactions.
 *
 * Props:
 * - `skill` (Object): The skill object containing details such as `uuid`, labels, skill type, and assigned users.
 * - `setIsdeleting` (Function): Function to update the state indicating whether a skill is being deleted.
 * - `skills` (Array): List of all skills.
 * - `setChangeContent` (Function): Function to update the state for content changes.
 * - `setNumberOfConsultant` (Function): Function to update the number of consultants assigned to the skill.
 * - `setSkillName` (Function): Function to set the name of the skill.
 * - `labelLangSkill` (String): Language label for the skill.
 * - `setSlectedSkill` (Function): Function to set the currently selected skill.
 *
 * State:
 * - @variable {Object} skillPrinted - Holds the details of the skill being displayed, including its labels and skill type.
 * - @variable {Boolean} showButtonDelete - Toggles the visibility of the delete confirmation button.
 *
 * Hooks/Effects:
 * - @hook {useDeleteSkill} - Custom hook to handle skill deletion, including loading and error states.
 * - @hook {useUpdateSkill} - Custom hook to handle skill updates, including loading and error states.
 * - @effect Updates the skill type and deletes the skill based on user interactions.
 *
 * Helper Functions:
 * - @function handleDeleteSkill - Deletes the skill and updates the state.
 * - @function handleChange - Updates the state with details of the skill for further actions.
 * - @function handleUpdate - Updates the skill type and handles the update operation.
 *
 * Conditional Rendering:
 * - Renders the delete button and confirmation message when `showButtonDelete` is `true`.
 * - Displays skill details and the update dropdown by default.
 * - Shows different messages and actions based on whether the skill has assigned consultants.
 *
 * Example Usage:
 * ```jsx
 * <PrintSkillAdded
 *   skill={skill}
 *   setIsdeleting={setIsdeleting}
 *   skills={skills}
 *   setChangeContent={setChangeContent}
 *   setNumberOfConsultant={setNumberOfConsultant}
 *   setSkillName={setSkillName}
 *   labelLangSkill={labelLangSkill}
 *   setSlectedSkill={setSlectedSkill}
 * />
 * ```
 *
 * Dependencies:
 * - React hooks (`useState`) for managing state.
 * - Custom hooks (`useDeleteSkill`, `useUpdateSkill`) for skill operations.
 * - UI components such as `Button`, `Label`, `UniqueSelect2`.
 * - Material UI icons for delete and expand interactions.
 *
 * Where it is used:
 * - Skill management and display within a dashboard or admin panel.
 */

import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
//import { getSkillsType } from '@/services/admin-services/setSkillPanelData';
import UniqueSelect2 from "@/components/molecules/inputs/UniqueSelect2";
import { Button } from "@/components/molecules/buttons/button";
import { Label } from "@/components/atoms/texts/label";
import { getSkillsType } from "@/services/global-services/api-rest/useAITranslate";
import { useDeleteSkill } from "@/services/admin-services/mutations/useDeleteSkill";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useUpdateSkill } from "@/services/admin-services/mutations/useUpdateSkill";
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
export default function PrintSkillAdded({
    skill,
    setIsdeleting,
    skills,
    setChangeContent,
    setNumberOfConsultant,
    setSkillName,
    labelLangSkill,
    setSlectedSkill,
}) {
    const [skillPrinted, setSkillPrinted] = useState({
        uuid: skill.uuid,
        labelDe: skill.labelDe,
        labelEn: skill.labelEn,
        labelEs: skill.labelEs,
        labelFr: skill.labelFr,
        labelIt: skill.labelIt,
        labelNl: skill.labelNl,
        skillType: skill.skillType,
        skillAssignedUsersCount: skill.skillAssignedUsersCount,
        skillAssignedUsers: skill.skillAssignedUsers,
    });
    const { deleteSkill, loading, error } = useDeleteSkill();
    const [showButtonDelete, setShowButton] = useState(false);
    const {
        updateSkill,
        loading: loadingUpdate,
        error: errorUpdate,
    } = useUpdateSkill();
    // const handleDelete = (itemToDelete) => {
    //   //setShowForm(false);
    //   if (skill) {
    //     setSkills((prevData) =>
    //       prevData.filter((item) => item.uuid !== itemToDelete.uuid)
    //     );
    //   }
    // };
    const handleDeleteSkill = async () => {
        setShowButton(false);
        try {
            await deleteSkill(skill.uuid);
            console.log("delete skill", skill);
            //alert("Skill deleted successfully");
            setShowButton(false);
            setIsdeleting(true);
        } catch (err) {
            alert(`Failed to delete skill: ${err.message}`);
        }
    };

    const handleChange = () => {
        setChangeContent(true);
        setNumberOfConsultant(skillPrinted.skillAssignedUsersCount);
        setSkillName(skillPrinted.labelLangSkill);
        setSlectedSkill(skillPrinted);
    };
    const handleUpdate = async (skillT) => {
        const newSkill = {
            uuid: skillPrinted.uuid,
            labelDe: skillPrinted.labelDe,
            labelEn: skillPrinted.labelEn,
            labelEs: skillPrinted.labelEs,
            labelFr: skillPrinted.labelFr,
            labelIt: skillPrinted.labelIt,
            labelNl: skillPrinted.labelNl,
            skillType: skillT,
        };
        const name = "skillType";
        setSkillPrinted((prevSkillPrinted) => ({
            ...prevSkillPrinted,
            [name]: skillT,
        }));

        console.log("updated skill", newSkill);
        try {
            const updatedSkill = await updateSkill(newSkill);
            //alert("Skill updated successfully", updatedSkill);
        } catch (err) {
            alert(`Failed to update skill: ${err.message}`);
        }
    };

    return (
        <div className="w-full ">
            <div className="w-full px-firstDegree flex flex-row gap-secondDegree items-center  justify-between ">
                <div className=" flex flex-row gap-firstDegree  basis-3/2 ">
                    <div className="flex flex-row items-center gap-1 w-full">
                        <Button
                            showLeftIcon={true}
                            leftIcon={
                                showButtonDelete ? (
                                    <ExpandLessIcon />
                                ) : (
                                    <DeleteIcon />
                                )
                            }
                            padding="small"
                            style="ghost"
                            showText={false}
                            onClick={() => setShowButton(!showButtonDelete)}
                        />

                        <Label fontWeight="bold">
                            {skillPrinted && skillPrinted.labelFr}
                        </Label>
                        <div className="text-primary_hover flex items-end cursor-pointer" onClick={() => handleChange(skillPrinted)}
                        >
                            <Person2RoundedIcon fontSize="small" />
                            <Label >{skillPrinted.skillAssignedUsersCount}</Label>
                        </div>
                    </div>
                </div>

                <div className=" basis-1/4">
                    <UniqueSelect2
                        dataToSelect={getSkillsType()}
                        defaultValue={skillPrinted.skillType}
                        filterDataFunction={handleUpdate}
                    //showLabel={false}
                    />
                </div>
            </div>
            {/* PRINTED when user want to delete*/}
            {showButtonDelete && (
                <div className={`w-full flex flex-row justify-between     mt-1 px-secondDegree py-firstDegree shadow-md items-center  border rounded-sm ${skillPrinted.skillAssignedUsersCount > 0 ? `border-error bg-error20` : `border-warning bg-warning20`}`}>
                    <div className=" ">

                        <Label
                            message={
                                skillPrinted.skillAssignedUsersCount > 0
                                    ? "consultants.statement.setSkillsPanel.canNotDelete"
                                    : "global.actions.askConfirmDelete"
                            }
                        />
                        {skillPrinted.skillAssignedUsersCount > 0 && (
                            <span>
                                <span
                                    className={`${skillPrinted.skillAssignedUsersCount > 0
                                        ? "cursor-pointer"
                                        : "cursor-not-allowed"
                                        } font-bold  text-body underline`}
                                    onClick={() => handleChange(skillPrinted)}
                                >
                                    {skillPrinted && skillPrinted.skillAssignedUsersCount}
                                </span>
                                {' '}
                                <Label
                                    message={
                                        skillPrinted.skillAssignedUsersCount > 1
                                            ? "consultants.informations.consulantLowerCase"
                                            : "global.information.consultant"
                                    }
                                    size="body"
                                />
                            </span>
                        )}
                        {skillPrinted.skillAssignedUsersCount > 0 && "."}
                    </div>

                    {skillPrinted.skillAssignedUsersCount < 1 && <Button
                        showLeftIcon={true}
                        leftIcon={
                            skillPrinted.skillAssignedUsersCount == 0 && (
                                <DeleteIcon className="text-primary" />
                            )
                        }
                        padding="small"
                        style="ghost"
                        showText={false}
                        onClick={handleDeleteSkill}
                    />}
                </div>
            )}
        </div>
    );
}
