// src/apolloClient.js
import {
    ApolloClient,
    InMemoryCache,

    HttpLink,
    from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { eraseCookie } from "./utils/cookie";
import { useAuth } from "./context/AuthContext";
import { hasStrongToken } from "./utils/hasStrongToken";


const errorLink = onError(({ graphqlErrors, networkError }) => {
    // console.log("graphql Received errors:", { graphqlErrors, networkError }); // Ajoutez ceci pour voir toutes les erreurs

    // const {  isAuth } = useAuth();


    if (graphqlErrors) {
        graphqlErrors.map(({ message, location, path }) => {
            alert(`Graphql error ${message}`);
            console.error(`GraphQL error: ${message}`);
            console.error(`Error location: ${location.map((loc) => `Line ${loc.line}, Column ${loc.column}`).join(", ")}`);
            console.error(`Error path: ${path.join(" -> ")}`);
        });
    }

    if (networkError) {
        console.log("Graphql networkError", networkError);
        // handleSetErrorNetworkVisible(true);

        // internet deconnected
        if (networkError.statusCode == 500) {
            alert(`You've been deconnected, please login again`);
            // eraseCookie('user');

        }
        // user token is not valid, graphql return forbidden error
        if (networkError.statusCode == 403) {
            hasStrongToken() && alert(`You've been deconnected, please login again`);
            // isAuth() && eraseCookie('user');
            eraseCookie('user');

        }
        if (networkError.message === "Failed to fetch") {
            // console.log("Graphql no internet ");
        }

        console.log(`Graphql no internet ${networkError}`, networkError.message === "Failed to fetch", networkError.statusCode)

    }

    // console.log(`Graphql  ${networkError}`, networkError.message === "Failed to fetch", networkError.statusCode)

});

const link = from([
    errorLink,
    new HttpLink({
        uri: window.ENV.BACKEND_URL + "/api/graphql",
    }),
]);

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: link,
});

export default client