import { useTranslation } from "react-i18next";

function getAppLanguage(){
    const [translate, i18n] = useTranslation("global");
    return i18n.language.toUpperCase()
    switch (i18n.language.toUpperCase()) {
        case "FR":
            return languages.FR
        case "EN":
            return "EN"
        case "DE":
            return "DE"
        case "ES":
            return "ES"
        case "IT":
            return "IT"
        case "NL":
            return "NL"
        default:
            break;
    }
}
export default getAppLanguage;