

import { useState } from "react";
import TableRow from "../../../../organisms/DataTable/TableRow";
import TableHead from "../../../../organisms/DataTable/TableHead";
import { useEffect } from "react";
import InsertInvitationRoundedIcon from '@mui/icons-material/InsertInvitationRounded';
import {
    getBillsForYear,
    getClientsForUser,
    getMonthDataBills,
    getAllBillYears,
} from "@/utils/transformUserData";
import { filterUnwantedPropertiesAdmin } from "@/utils/transformUserData";
import UniqueSelect from "../../../../molecules/inputs/UniqueSelect";
import MultiSelect from "../../../../molecules/inputs/MultiSelect";
import { useConsultantInvoiceYears } from "@/services/admin-services/query/useConsultantInvoiceYears";
import PrintFetchingData from "@/components/molecules/PrintFetchingData";
import { useInvoicesPerYear } from "@/services/admin-services/query/useConsultantInvoices";
import { useAuth } from "@/context/AuthContext";
function getClientNames(invoices) {
    return invoices.map((invoice) => invoice.clientName);
}

const getTableHead = (isAdmin) => {
    let tableHead = [
        { customer: "consultants.statement.billsDetails.table.customers" },
        { bills: "consultants.statement.billsDetails.bills" },
        { nbDays: "consultants.statement.billsDetails.table.nbDays" },
        { amount: "consultants.statement.billsDetails.table.amount" },
    ];
    if (isAdmin) return tableHead;
    else {
        let nouvelleValeurBills = "consultants.statement.overview.dailyRate";

        // Recherche de l'index de l'élément avec la clé "bills" dans le tableau
        let indexBills = tableHead.findIndex((element) => "bills" in element);

        // Vérification si l'index a été trouvé
        if (indexBills !== -1) {
            // Mise à jour de la valeur de la clé "bills"
            tableHead[indexBills].bills = nouvelleValeurBills;
        } else {
            // Gestion si la clé "bills" n'a pas été trouvée
            console.error("Clé 'bills' non trouvée dans le tableau.");
        }
    }
    return tableHead;
};

// Affichage du tableau mis à jour

const sortItems = (a, b) => {
    return b - a;
};

const filterUnwantedPropertiesConsultants = (data) => {
    return Object.values(data).map((dayData) => {
        let {
            month,
            clientName,
            dailyRateConsultant,
            daysWorked,
            amountConsultant,
            uuid,
            ...rest
        } = dayData;
        month = month.month;
        return {
            month,
            clientName,
            dailyRateConsultant,
            daysWorked,
            amountConsultant,
            uuid,
        };
    });
};

function BillsContent({
    setChangeContent,
    setEditBillsData,
    activeUser,
    changeContent,
    itemToDelete,
    changeMonthDataDeleted,
    monthDataAfterDelete,
    informSelectedYear,
}) {
    const {
        loading: yearsLoading,
        error: yearsError,
        data: yearsData,
    } = useConsultantInvoiceYears();
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [yearlydata, setYearlyData] = useState([]);
    const {
        loadingInvoices,
        errors: errorfectInvoices,
        allInvoices,
        fetchInvoicesPerYear,
    } = useInvoicesPerYear();
    const [customers, setCustomers] = useState([]);
    const { hasPrivileges } = useAuth();

    const [monthDataBills, setMonthDataBills] = useState(null);
    const [dataMonth, setDataMonth] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const wantedProperties = hasPrivileges("ADMIN_")
        ? filterUnwantedPropertiesAdmin
        : filterUnwantedPropertiesConsultants;
    const [fetching, setFetching] = useState(true);



    useEffect(() => {
        if (yearsData) {
            //console.log("Fetched invoice years:", yearsData.consultantInvoiceYears);
            setYears(yearsData.consultantInvoiceYears);

            const fetchAllInvoices = async () => {
                setFetching(true);
                const invoices = [];
                if (yearsData.consultantInvoiceYears.length > 0) {
                    setSelectedYear(yearsData.consultantInvoiceYears[0]);
                    informSelectedYear(selectedYear);
                    for (const year of yearsData.consultantInvoiceYears) {
                        const yearInvoices = await fetchInvoicesPerYear(year);
                        // console.log("entrain de fetch les données invoices", yearInvoices);
                        if (yearInvoices) invoices.push(...yearInvoices);
                        // setYearlyData(invoices);
                    }

                    setYearlyData(invoices);
                    setMonthDataBills(invoices);
                    setDataMonth(
                        hasPrivileges("ADMIN_")
                            ? filterUnwantedPropertiesAdmin(invoices)
                            : filterUnwantedPropertiesConsultants(invoices)
                    );
                    setFilteredData(
                        hasPrivileges("ADMIN_")
                            ? filterUnwantedPropertiesAdmin(invoices)
                            : filterUnwantedPropertiesConsultants(invoices)
                    );

                    if (invoices.length > 0) {
                        // console.log("yearlyData invoices", invoices);
                        const uniqueArray = [...new Set(getClientNames(invoices))];
                        const customs = Array.from(uniqueArray);
                        setCustomers(customs);
                    }
                }
                setFetching(false);
            };

            fetchAllInvoices();
            //   console.log("years data in pp", years);

            //console.log("change content", changeContent)
        }
        // console.log("years data", years);
    }, [yearsData, changeContent]);

    const totalDays = (monthData) => {
        return (
            monthData
                // Exclure la dernière ligne
                .reduce((sum, row) => sum + parseFloat(row.daysWorked), 0)
        );
    };
    const totalAmount = (monthData) => {
        return (
            monthData
                // Exclure la dernière ligne
                .reduce((sum, row) => sum + parseFloat(row.amountClient), 0)
        );
    };
    const filterDataYear = (years) => {
        setSelectedYear(years);
        informSelectedYear(years);

        if (selectedCustomers) {
            filterTable(selectedCustomers);
        } else {
            //setSelectedYear(year);
            //const newYearlyData = getBillsForYear(activeUser, year);

            // const newYearlyData = yearlydata.filter((obj) => obj.year === year);
            const newYearlyData = yearlydata.filter((item) =>
                years.includes(item.year)
            );

            const newData = hasPrivileges("ADMIN_")
                ? filterUnwantedPropertiesAdmin(newYearlyData)
                : filterUnwantedPropertiesConsultants(newYearlyData);
            //   console.log("Year", year);
            newData.push({
                rowNameOrMonth: "annuel",
                clients: "",
                bills: "",
                nbDays: `${totalDays(newData)} jours`,
                amount: totalAmount(newData),
            });
            setFilteredData(() => newData);
        } // Utiliser une fonction de rappel pour s'assurer que newData est à jour
    };

    const filterTable = (selectedValuesClients = []) => {
        setSelectedCustomers(selectedValuesClients);

        // Toujours partir des données originales
        let newFilteredData = hasPrivileges("ADMIN_")
            ? filterUnwantedPropertiesAdmin(yearlydata)
            : filterUnwantedPropertiesConsultants(yearlydata);

        if (selectedValuesClients.length > 0) {
            // Filtrer la table en fonction des clients sélectionnés
            newFilteredData = newFilteredData.filter((item) =>
                selectedValuesClients.includes(item.clientName)
            );
        }
        // console.log("newFilteredData", newFilteredData);

        // Filtrer les lignes pour exclure "annuel"
        newFilteredData = newFilteredData.filter(
            (item) => item.rowNameOrMonth !== "annuel"
        );

        // console.log(newFilteredData); 

        // Ajouter la ligne "annuel" après avoir calculé totalDays et totalAmount
        newFilteredData.push({
            rowNameOrMonth: "annuel",
            clients: "",
            bills: "",
            nbDays: `${totalDays(newFilteredData)} jours`,
            amount: totalAmount(newFilteredData),
        });

        // Mettre à jour l'état avec les données filtrées
        setFilteredData(newFilteredData);
    };

    const handleClick = (id) => {
        // console.log("this row has been cliked", rowData);
        const item = yearlydata.find((obj) => obj.uuid === id);
        //setSelectedItem(item);
        // console.log(item);
        setChangeContent(true);
        setEditBillsData(item);
        changeMonthDataDeleted(monthDataBills);
    };

    useEffect(() => {
        const uniqueArray = [...new Set(customers)];
        const customs = Array.from(uniqueArray);
        setCustomers([...new Set(customers)]);
    }, []);


    useEffect(() => {
        const calculateTotal = () => {
            if (filteredData) {
                const newFilteredData = [...filteredData];

                // Supprimer la ligne "annuel" s'il existe déjà
                const filteredWithoutAnnuel = newFilteredData.filter(
                    (item) => item.rowNameOrMonth !== "annuel"
                );

                // Ajouter la ligne "annuel" après avoir calculé totalDays et totalAmount
                filteredWithoutAnnuel.push({
                    rowNameOrMonth: "annuel",
                    clients: "",
                    bills: "",
                    nbDays: `${totalDays(filteredWithoutAnnuel)} jours`,
                    amount: totalAmount(filteredWithoutAnnuel),
                });

                // Mettre à jour l'état avec les données filtrées
                setFilteredData(filteredWithoutAnnuel);
            }
        };

        // Appeler la fonction de calcul lorsque filteredData change
        calculateTotal();
    }, [yearlydata]);

    if (yearsLoading) return <span className="loader m-secondDegree"></span>;
    if (yearsError || errorfectInvoices)
        return (
            <div className="bg-error p-secondDegree m-secondDegree rounded-sm">
                Error loading years {yearsError.message}
            </div>
        );
    if (loadingInvoices) return <PrintFetchingData />;

    return (
        <div>
            <div className="flex flex-row w-full py-paddingTopAndBottom px-paddingLeftAndRight gap-secondDegree">
                <div className="w-full">


                    <MultiSelect
                        dataToSelect={years}
                        //labelClassName={"font-bold text-h6"}
                        icon={<InsertInvitationRoundedIcon />}
                        label="global.information.theYears"
                        filterDataFunction={filterDataYear}
                    />
                    {/* <Selecter dataToSelect={years} handleValueSelected={changeData}/>  */}
                </div>
                <div className="w-full">
                    <MultiSelect
                        dataToSelect={customers}
                        marginBottom={"mb-[7px]"}
                        filterDataFunction={filterTable}
                    />
                    {/*<Selecter dataToSelect={clientsList} handleValueSelected={changeData} /> */}
                </div>
            </div>
            <div className="p-secondDegree w-[95%]">
                <TableHead
                    tableHeadData={getTableHead(hasPrivileges("ADMIN_"))}
                    colorsOfAllcells={"grey"}
                    classNameForCell={"py-4"}
                />
                {filteredData &&
                    filteredData.map((month, index) => {
                        const { uuid, ...monthWithoutId } = month;

                        return (
                            <TableRow
                                rowData={Object.values(monthWithoutId)}
                                key={index}
                                enableHover={false}
                                tableOfIndexOfCellsToTranslate={[0]}
                                indexOfCellWithSpecificBorder={0}
                                borderName={"left"}
                                makeTansparentFirstCell={true}
                                displayHoveredIcon={true}
                                className=""
                                printToolTip={true}
                                handleClick={() => handleClick(uuid)}
                                tableOfIndexCellsWithoutCurrency={[1, 2, 3]}
                                indexOfCellWithSpecificColor={
                                    Object.values(month)[0] == "annuel" && 4
                                }
                                colorOfSpecificCell="primaryLight"
                                rowId={uuid}

                            // onRowDataChange={(newData) => handleMonthDataChange(newData, index)}
                            />
                        );
                    })}
            </div>
        </div>
    );
}

export default BillsContent;
