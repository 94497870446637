

import React from "react";

import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { Avatar } from "@/components/atoms/avatar";

import EducationItem from "./EducationItem";
import CertificationItem from "./CertificationItem";
import ExperienceItem from "./ExperienceItem";
import ProjectItem from "./ProjectItem";
import SkillItem from "./SkillItem";
import DescriptionItem from "./DescriptionItem";
import JobTitleItem from "./JobTitleItem";
import SaveTemplate from "./SaveTemplate";
import EditCVPanel from "@/components/macro-organisms/panels/panels-admin/EditCV/EditCVPanel";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import UnmappedSkillItem from "./UnmappedSkillItem";
import { useParams } from "react-router-dom";
import useUser from "@/services/admin-services/query/useUser";
import { useState } from "react";
import useProspectPicture from "@/services/admin-services/useProspectPicture";
import { isBlobEmpty } from "@/utils/isBloobEmpty";
import { sortItemsByDateWithDateKey } from "@/utils/sortItemsByDateWithDateKey";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import PrintWarningMessage from "@/components/organisms/feedback/PrintWarningMessagee";
export default function RenderCvContent({
    CVData,
    isMain = false,
    isForProspect = false,
    templateData,
    setIsDoingAction,
    isDoingAction
}) {
    const navigate = useNavigate();
    const {
        profilePicture,
        loading: loadingPicture,
        error: errorPicture,
    } = useProspectPicture(CVData.uuid);
    const user = {
        uuid: CVData.uuid,
        firstname: CVData.firstname,
        lastname: CVData.lastname,
    };

    const [imageSrc, setImageSrc] = useState(null);

   
    return !CVData ? (
        <span className="loader"></span>
    ) : (
        <div
            className={`flex flex-col gap-secondDegree rounded-sm  ${!isMain &&
                " h-[55vh] overflow-y-auto border border-white p-3 bg-hoverLight "
                } `}
        >
            {isMain ? (
                <div className=" flex gap-secondDegree">
                    <div className="bg-white shadow-md rounded-sm grow gap-thirdDegree p-thirdDegree flex">
                        {(CVData.firstname || CVData.lastname) && (
                            loadingPicture ? <span className="loader"></span> :
                                <Avatar
                                
                                    fallback={CVData.firstname + CVData.lastname}
                                    // fallback="test"
                                    size="medium"
                                    className="shrink-0"
                                    src={profilePicture && profilePicture}
                                />
                        )}
                        <div className="flex flex-col gap-firstDegree">
                            <div className="flex items-center space-between overflow-auto">
                                {(CVData.firstname || CVData.lastname) && (
                                    <div className="grow">
                                        <Label fontWeight="bold" size="h4">
                                            {CVData.firstname} {CVData.lastname}
                                        </Label>
                                    </div>
                                )}
                                {CVData.titles && (
                                    <>
                                        {CVData.titles.map((jobTitle, index) => (
                                            <JobTitleItem key={jobTitle.uuid} data={jobTitle} />
                                        ))}
                                    </>
                                )}
                            </div>
                            {CVData.descriptions && (
                                <>
                                    {CVData.descriptions.map((description, index) => (
                                        <DescriptionItem
                                            key={description.uuid}
                                            data={description}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col w-[230px] gap-firstDegree">
                        <Button
                            style="outline"
                            className="font-semibold"
                            showLeftIcon
                             disabled={(CVData.titles.length == 0 || CVData.descriptions.length == 0 )}
                            leftIcon={<PictureAsPdfIcon />}
                            text="global.actions.selectForPdf"
                            onClick={() => navigate("./generate")}

                        />
                        {(CVData.titles.length == 0 || CVData.descriptions.length == 0 )&& <PrintWarningMessage warning="error.oneTitleAndOneDescription"/>}
                        <span className="text-center leading-none">
                            <Label size="caption" message="consultants.cv.editCaption" />
                        </span>
                        <EditCVPanel
                            user={user}
                            isDoingAction={isDoingAction}
                            setIsDoingAction={setIsDoingAction}
                        />
                        {isForProspect && templateData?.length > 0 && (
                            <div className="bg-white flex flex-col shadow-md rounded-sm p-firstDegree gap-firstDegree overflow-auto">
                                <Label fontWeight="bold" message="consultants.cv.saved" />
                                {templateData.map((templateItem, index) => (
                                    <SaveTemplate key={templateItem.uuid} data={templateItem} />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex flex-col gap-secondDegree">
                    {CVData.titles && CVData.titles.length > 0 && (
                        <>
                            <Label
                                size="h3"
                                fontWeight="bold"
                                message="consultants.cv.jobTitle"
                            />
                            {CVData.titles.map((jobTitle, index) => (
                                <JobTitleItem
                                    key={jobTitle.uuid}
                                    data={jobTitle}
                                    className={
                                        isMain
                                            ? ""
                                            : "bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree w-full relative grow-0"
                                    }
                                />
                            ))}
                        </>
                    )}

                    {CVData.descriptions && CVData.descriptions.length > 0 && (
                        <>
                            <Label
                                size="h3"
                                fontWeight="bold"
                                message="consultants.cv.description"
                            />

                            {CVData.descriptions.map((description, index) => (
                                <DescriptionItem
                                    key={description.uuid}
                                    data={description}
                                    className={
                                        isMain
                                            ? ""
                                            : "bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree min-w-[320px] relative grow-0"
                                    }
                                />
                            ))}
                        </>
                    )}
                </div>
            )}
            {CVData.mappedSkills &&
                CVData.mappedSkills.some((skill) => skill?.skill?.skillType === "HARD") && (
                    <div className=" gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.hardSkills"
                        />
                        <div className="py-1 flex gap-firstDegree overflow-auto  flex-wrap ">
                            {CVData.mappedSkills.map(
                                (skillItem) =>
                                    skillItem?.skill?.skillType === "HARD" && (
                                        <SkillItem key={skillItem?.uuid} data={skillItem} />
                                    )
                            )}
                        </div>
                    </div>
                )}
            {CVData.mappedSkills &&
                CVData.mappedSkills.some((skill) => skill?.skill?.skillType === "SOFT") && (
                    <div className=" gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.softSkills"
                        />

                        <div className="py-1 flex gap-firstDegree overflow-auto  flex-wrap">
                            {CVData.mappedSkills.map(
                                (skillItem) =>
                                    skillItem?.skill?.skillType === "SOFT" && (
                                        <SkillItem key={skillItem.uuid} data={skillItem} />
                                    )
                            )}
                        </div>
                    </div>
                )}
            {CVData.mappedSkills &&
                CVData.mappedSkills.some((skill) => skill?.skill?.skillType === "POWER") && (
                    <div className=" gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.powerSkills"
                        />
                        <div className="py-1 rounded-sm flex gap-firstDegree overflow-auto flex-wrap">
                            {CVData.mappedSkills.map(
                                (skillItem, index) =>
                                    skillItem?.skill?.skillType === "POWER" && (
                                        <SkillItem key={skillItem.uuid} data={skillItem} />
                                    )
                            )}
                        </div>
                    </div>
                )}
            {
                CVData.unmappedSkills && CVData.unmappedSkills.length > 0 && (
                    // CVData.unmappedSkills.some((skill) => skill.type === "power") && (
                    <div className=" gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.unmappedSkills"
                        />
                        <div className="py-1 rounded-sm flex gap-firstDegree overflow-auto flex-wrap">
                            {CVData.unmappedSkills.map((skillItem, index) => (
                                <UnmappedSkillItem key={skillItem.uuid} data={skillItem} />
                            ))}
                        </div>
                    </div>
                )
                // )
            }
            {isMain && <Separator />}

            {CVData.projects && CVData.projects.length > 0 && (
                <>
                    <div className="gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.project"
                        />

                        <div className="py-1 flex gap-firstDegree overflow-auto">
                            {/* {CVData.projects.map((projectItem, index) => (
                                <ProjectItem
                                    key={projectItem.uuid}
                                    data={projectItem}
                                    className="max-w-[320px]"
                                    setIsDoingAction={setIsDoingAction}
                                />
                            ))} */}
                            {sortItemsByDateWithDateKey(CVData.projects, 'endDate').map((projectItem, index) => (
                                <ProjectItem
                                    key={projectItem.uuid}
                                    data={projectItem}
                                    className="max-w-[320px]"
                                    setIsDoingAction={setIsDoingAction}
                                />
                            ))}
                        </div>
                    </div>
                    {isMain && <Separator />}
                </>
            )}

            {CVData.experiences && CVData.experiences.length > 0 && (
                <>
                    <div className="gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.experience"
                        />

                        <div className="py-1 flex gap-firstDegree overflow-auto ">
                            {/* {CVData.experiences.map((experienceItem, index) => (
                                <ExperienceItem
                                    key={experienceItem.uuid}
                                    data={experienceItem}
                                    className="max-w-[320px]"
                                    setIsDoingAction={setIsDoingAction}
                                />
                            ))} */}
                            {sortItemsByDateWithDateKey(CVData.experiences, 'endDate').map((experienceItem, index) => (
                                <ExperienceItem
                                    key={experienceItem.uuid}
                                    data={experienceItem}
                                    className="max-w-[320px]"
                                    setIsDoingAction={setIsDoingAction}
                                />
                            ))}
                        </div>
                    </div>
                    {isMain && <Separator />}
                </>
            )}

            {CVData.certifications && CVData.certifications.length > 0 && (
                <>
                    <div className="gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.certification"
                        />

                        <div className="py-1 flex gap-firstDegree overflow-auto">
                            {sortItemsByDateWithDateKey(CVData?.certifications, 'dateObtained').map((certificationItem, index) => (
                                <CertificationItem
                                    key={certificationItem?.uuid}
                                    data={certificationItem}
                                    className="max-w-[320px]"
                                    setIsDoingAction={setIsDoingAction}
                                />
                            ))}
                        </div>


                    </div>
                    {isMain && <Separator />}
                </>
            )}

            {CVData.educations && CVData.educations.length > 0 && (
                <>
                    <div className="gap-secondDegree ">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.education"
                        />

                        <div className="py-1 flex gap-firstDegree overflow-auto">
                            {/* {CVData.educations.map((educationItem, index) => (
                                <EducationItem
                                    key={educationItem.uuid}
                                    data={educationItem}
                                    className="max-w-[320px]"
                                    setIsDoingAction={setIsDoingAction}
                                />
                            ))} */}
                            {sortItemsByDateWithDateKey(CVData.educations, 'endDate').map((educationItem, index) => (
                                <EducationItem
                                    key={educationItem.uuid}
                                    data={educationItem}
                                    className="max-w-[320px]"
                                    setIsDoingAction={setIsDoingAction}
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
