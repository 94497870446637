import { useAuth } from "@/context/AuthContext";
import { useState } from "react";
import errorToText from "@/utils/errorToText";
import { useEffect } from "react";
const useStatementYears = () => {
  const [loading, setLoading] = useState(false);
  const [errorYear, setError] = useState(null);
  const [allYears, setAllYears] = useState(null);

  const getYears = async (token) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        window.ENV.BACKEND_URL+"/api/graphql",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: `query 
                        {
                          statementYears
                        }       
                
                    `,
          }),
        }
      );

      if (!response.ok) {
        const errMessage = errorToText(response);
        setLoading(false);
        throw new Error(errMessage);
      }

      const data = await response.json();
      if (data.errors) {
        const errMessage = data.errors[0].message;
        setLoading(false);
        throw new Error(errMessage);
      }

      setAllYears(data.data.statementYears);
      setLoading(false);
      return { data: data.data.statementYears, error: null };
    } catch (err) {
      setError(err.message);
      setLoading(false);
      return { data: null, error: err.message };
    }
  };

  return { getYears, allYears, errorYear };
};

export default useStatementYears;
