import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const ADD_PAYSLIP = gql`
  mutation AddPayslip(
    $payslipFileUuid: ID!,
    $consultantUuid: ID!,
    $period: Date!,
    $currency: String!,
    $grossSalary: Float!,
    $netSalary: Float!,
    $taxes: Float!,
    $employerContributions: Float!,
    $employeeContributions: Float!,
    $comment: String
    $bonus: Boolean!
  ) {
    addPayslip(newPayslip: {
      payslipFileUuid: $payslipFileUuid,
      consultantUuid: $consultantUuid,
      period: $period,
      currency: $currency,
      grossSalary: $grossSalary,
      netSalary: $netSalary,
      taxes: $taxes,
      employerContributions: $employerContributions,
      employeeContributions: $employeeContributions,
      comment: $comment,
      bonus: $bonus
    }) {
      uuid
      payslipFileUuid
      year
      month
      currency
      grossSalary
      netSalary
      taxes
      employerContributions
      employeeContributions
      comment
      bonus
    }
  }
`;


export const useAddPayslip = () => {
  const { user } = useAuth();
  const [addPayslip, { loading, error, data }] = useMutation(ADD_PAYSLIP, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  return { addPayslip, loading, error, data };
};
