/* eslint-disable react/prop-types */
/**
 * @component OverviewInformationProfile
 *
 * This component displays an overview profile section including user information and avatar. It also provides an interface for navigating to additional details and handling cases where certain profile information is missing.
 *
 * Key Features:
 * - Displays user avatar and profile information.
 * - Handles avatar display logic, including fallback images.
 * - Shows language flags based on user’s spoken languages.
 * - Provides a button to continue to additional details.
 * - Uses `framer-motion` for smooth animations and transitions.
 *
 * Props:
 * - @param {Function} hideAllOverview - Function to hide the overview section.
 * - @param {Function} showComponent - Function to show additional details or components.
 * - @param {Array} languages - Array of languages spoken by the user, used to display language flags.
 * - @param {Object} user - The user object containing user details such as firstname and lastname.
 * - @param {String} avatarSrc - URL of the user's avatar image.
 *
 * Internal State:
 * - @state {String} imageSrc - The source URL for the avatar image, including fallback logic.
 *
 * Usage:
 * - This component is used to display an overview of user profile information, including their avatar and languages spoken.
 * - It provides a button to navigate to more detailed information or sections.
 * - The component handles cases where profile information may be missing or incomplete by displaying default values or fallback images.
 *
 * Example:
 * <OverviewInformationProfile
 *   hideAllOverview={hideOverview}
 *   showComponent={showDetails}
 *   languages={userLanguages}
 *   user={currentUser}
 *   avatarSrc={userAvatarSrc}
 * />
 *
 * Dependencies:
 * - `Label` component for displaying text labels.
 * - `Separator` component for visual separation in the UI.
 * - `Button` component for navigation and actions.
 * - `Avatar` component for displaying user profile images.
 * - `framer-motion` for UI animations.
 * - `getEmojiFlag` utility function for displaying country flags.
 * - `isBlobEmpty` utility function for checking if an image blob is empty.
 */

import { Label } from "@/components/atoms/texts/label";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Button } from "@/components/molecules/buttons/button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Avatar } from "@/components/atoms/avatar";
import { useState, useEffect } from "react";
import { getEmojiFlag } from "countries-list";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import { useTranslation } from "react-i18next";
import { isBlobEmpty } from "@/utils/isBloobEmpty";
import personIcon from "@/assets/images/Icon/personIcon.jpg"
import useProspectPicture from "@/services/admin-services/useProspectPicture";


export default function OverviewInformationProfile({
    hideAllOverview,
    showComponent,
    languages,
    user,
    avatarSrc,
}) {


    const [t] = useTranslation("global");

    const notFilled = t("error.unfilled");
    const [imageSrc, setImageSrc] = useState(null);
    const {
        profilePicture,
        
    } = useProspectPicture(user.uuid);

    useEffect(() => {
        const checkImage = async () => {
            const isEmpty = await isBlobEmpty(avatarSrc);
            console.log("check image avatar profile", isEmpty)

            if (isEmpty) {
                if (user.firstname === "XXX") {
                    setImageSrc(personIcon);
                } else {
                    setImageSrc(null); // Use fallback in Avatar
                }
            } else {
                setImageSrc(avatarSrc);
            }
        };

        checkImage();
    }, [avatarSrc, user.firstname]);
    
    const hider = () => {
        hideAllOverview(true);
        showComponent(true);
    };
    //   const printFlags = () => {

    //   return languages.map((country, index) => (
    //     <div key={index} className="text-[25px] ">
    //       {getEmojiFlag(getLanguageCode(country))}
    //     </div>
    //   ));
    // };
    // console.log("avatarSRC", avatarSrc)


    return (
        <motion.div
            variants={item}
            className="py-paddingTopAndBottom px-paddingSmallLeftAndRight flex flex-row gap-firstDegree bg-medium shadow-md rounded-sm border-l-[1px] border-l-solid"
        >
            <div className="flex flex-row place-content-end items-center py-firstDegree basis-1/3 p-betweenText">
                <Label
                    message="consultants.statement.prospectConsultantPanel.informations"
                    size="body"
                    fontWeight="bold"
                />
            </div>

            <div className="flex">
                <Separator orientation="vertical" className="bg-black" />
            </div>
            <div className="flex flex-row basis-1/2 gap-firstDegree text-bodySmall items-center  py-paddingSmallTopAndBottom">
                <Avatar
                    fallback={`${user.firstname} ${user.lastname}`}
                    size="medium"
                    src={profilePicture}
                    className="shrink-0 grow-0"
                />

                <div className="flex flex-col gap-firstDegree">
                    <Label
                        message="consultants.statement.prospectConsultantPanel.profileTitle"
                        fontWeight="bold"
                    />
                    <div className="flex flex-col gap-betweenText"> </div>
                    <Label size="small">
                        {" "}
                        {user.firstname == "XXX" ? notFilled : user.firstname}{" "}
                        {user.lastname == "XXX" ? notFilled : user.lastname}
                    </Label>
                    {/* <Label></Label> */}
                    {/* <div className="flex flex-row gap-1">{printFlags(user.listCodelanguage)}</div>*/}
                    <div className="flex flex-row gap-1">
                        {languages &&
                            languages.map((lang, index) => (
                                <Label key={index} className="text-[25px]">
                                    {getEmojiFlag(
                                        lang.spokenLanguage == "EN" ? "GB" : lang.spokenLanguage
                                    )}
                                </Label>
                            ))}
                    </div>
                </div>
            </div>
            <div className="grid items-center">
                <Button
                    showLeftIcon={true}
                    leftIcon={<ArrowForwardIcon fontSize="small" />}
                    showText={false}
                    text="global.actions.continue"
                    sizePadding="" // Utilisez "small" pour un padding plus petit
                    style="ghost"
                    className="font-bold"
                    onClick={() => hider()} // Options: "fill", "outline", "ghost", "dressedGhost"
                />
            </div>
        </motion.div>
    );
}
