/* eslint-disable react/prop-types */

/* eslint-disable react/prop-types */
/**
 * @component HandleLanguage
 *
 * The `HandleLanguage` component manages the display and editing of the languages spoken by the user. It provides the ability to view the list of languages, edit them, and add new languages to the user's profile. The component leverages utility functions to map language codes to country flags and full language names.
 *
 * Key Features:
 * - Displays the list of languages with corresponding country flags.
 * - Allows the user to edit or add new languages.
 * - Provides form validation and error handling during language updates.
 * - Supports different user interaction states (view, edit, add).
 *
 * Props:
 * - @param {Array} languages - List of languages spoken by the user, where each language is an object containing the `spokenLanguage` code.
 * - @param {Function} setLanguages - Function to update the list of languages.
 * - @param {Function} setIsDoingAction - Function to toggle loading or action states when performing tasks like updating languages.
 * - @param {Function} setFormIsValid - Function to handle form validation for language input.
 * - @param {Function} setServerResponseIserror - Function to set the error state based on server response.
 * - @param {Function} setErrors - Function to update the error messages.
 * - @param {Object} user - Object representing the user, containing attributes like `uuid`, `firstname`, and `lastname`.
 *
 * Usage:
 * - This component is primarily used to manage the languages section in a user's profile, providing editing and adding functionality for languages spoken by the user.
 *
 * Example:
 * <HandleLanguage
 *   languages={userLanguages}
 *   setLanguages={updateLanguages}
 *   setIsDoingAction={setLoadingAction}
 *   setFormIsValid={validateForm}
 *   setServerResponseIserror={setServerError}
 *   setErrors={updateErrors}
 *   user={userDetails}
 * />
 *
 * Dependencies:
 * - `Label` component for displaying text.
 * - `Button` component for actions like editing or adding languages.
 * - `PrintLanguageLine` component for rendering each language line.
 * - `AddLanguage` component to handle the form for adding new languages.
 * - `getEmojiFlag` utility function to display country flags based on the language code.
 * - `getLanguageNameByCode` utility function to retrieve the full name of a language from its code.
 *
 * Sub-Components:
 * - @component PrintLanguageLine: Renders an individual language with options to edit or delete.
 * - @component AddLanguage: Displays a form for adding new languages to the user's profile.
 */

import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { getEmojiFlag } from "countries-list";
import PrintLanguageLine from "./PrintLanguageLine";
import AddLanguage from "./AddLanguage";
import { getLanguageNameByCode } from "@/utils/getLanguesNameAnCode";



export default function HandleLanguage({
    languages,
    setIsDoingAction,
    setFormIsValid,
    setServerResponseIserror,
    setErrors,
    user,
}) {
    const [editLanguage, setEditLanguage] = useState(false);
    const [showForm, setShowForm] = useState(false);
    // const changeEditLanguage = () => {
    //   languages ? setEditLanguage(true) : setEditLanguage(false);
    //   languages == undefined && showForm && setShowForm(true);
    // };
    // const [languages, setLanguages] = useState(getLanguagesSpoken());

    return (
        <div>
            {!editLanguage ? (
                <div className="flex gap-firstDegree items-center flex-wrap">
                    {languages &&
                        languages.map((lang, index) => (
                            <div
                                key={index}
                                className="flex p-betweenText gap-betweenText bg-white h-7 rounded-[8px] items-center"
                            >
                                <Label className="text-[23px]">

                                    {getEmojiFlag(
                                        lang.spokenLanguage == "EN" ? "GB" : lang.spokenLanguage
                                    )}
                                </Label>
                                <Label>{getLanguageNameByCode(lang.spokenLanguage)}</Label>
                            </div>
                        ))}
                    {languages.length > 0 && (
                        <Button
                            showLeftIcon={true}
                            leftIcon={<EditIcon className="text-primary" fontSize="small" />}
                            showText={false}
                            style="ghost"
                            onClick={() => setEditLanguage(true)}
                        />
                    )}
                </div>
            ) : (
                <div className=" flex flex-col gap-secondDegree">
                    <div className="flex flex-col gap-secondDegree">
                        {languages &&
                            languages.map((lang) => (
                                <PrintLanguageLine
                                    key={lang.uuid}
                                    language={lang}

                                    user={user}
                                    setIsDoingAction={setIsDoingAction}
                                    setFormIsValid={setFormIsValid}
                                    setServerResponseIserror={setServerResponseIserror}
                                    setErrors={setErrors}
                                />
                            ))}
                    </div>

                    <AddLanguage
                        showForm={showForm}
                        setShowForm={setShowForm}
                        setEditingLanguage={setEditLanguage}

                        languages={languages}
                        user={user}
                        setIsDoingAction={setIsDoingAction}
                        setFormIsValid={setFormIsValid}
                        setServerResponseIserror={setServerResponseIserror}
                        setErrors={setErrors}
                    />
                    <div className="flex items-center gap-firstDegree">
                        <Button
                            showLeftIcon={true}
                            leftIcon={<AddIcon fontSize="small" />}
                            sizePadding="default" // Utilisez "small" pour un padding plus petit
                            style="dressedGhost"
                            showText={true}
                            text="global.actions.add"
                            className="w-full"
                            onClick={() => setShowForm(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                        />
                        <Button
                            showLeftIcon={true}
                            leftIcon={<ClearIcon fontSize="small" />}
                            showText={false}
                            style="ghost"
                            onClick={() => setEditLanguage(false)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
