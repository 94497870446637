// import { newTemplateData } from '@/services/global-services/templateData';
// import { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import useTemplateData from './useTemplateData';

// function useManageTemplate() {
//   const { templateData, error } = useTemplateData();
//   const templates = !error ? templateData : [];
//   const { templateID } = useParams();
//   const [templateIDState, setTemplateIDState] = useState();
//   const [template, setTemplate] = useState(); // Nouvel état pour stocker l'objet template

//   useEffect(() => {
//     const initializeTemplate = async () => {
//       let foundTemplate;
//       if (templateID === undefined) {
//         try {
//           // Créez un nouveau template et mettez à jour l'état avec son ID et l'objet
//           const newTemplateId = await newTemplateData(); // Supposons que cette fonction retourne un nouvel ID de template
//           foundTemplate = { templateId: newTemplateId, blockSelected: [] }; // Structure d'un nouveau template
//           setTemplateIDState(newTemplateId);
//           setTemplate(foundTemplate);
//         } catch (error) {
//           console.error("Error creating new template:", error);
//         }
//       } else {
//         // Trouvez le template existant par son ID
//         foundTemplate = templates.find(t => t.templateId === templateID);
//         setTemplateIDState(templateID);
//         // Si le template existe, utilisez-le; sinon, créez une structure par défaut
//         setTemplate(foundTemplate || { templateId: templateID, blockSelected: [] });
//       }
//     };

//     initializeTemplate();
//   }, [ templates]);

//   return { template }; // Retournez à la fois l'ID du template et l'objet template
// }

// export default useManageTemplate;

import useAddProspectCV from '@/services/admin-services/useAddProspectCV';
import { useProspectCv } from '@/services/admin-services/useProspectCv';
// import { newTemplateData } from '@/services/global-services/templateData';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function useManageTemplate(personUuid) {
    const { data: templateData, error, loading } = useProspectCv(); // Utilisation du hook pour obtenir les templates
    const { templateID } = useParams();
    const [templateIDState, setTemplateIDState] = useState();
    const [template, setTemplate] = useState(); // Nouvel état pour stocker l'objet template
    const { addNewProspectCV, } = useAddProspectCV();

    console.log("useManageTemplate, passage", templateID, templateData, template, personUuid);

    useEffect(() => {
        const initializeTemplate = async (personUuid) => {
            if (loading || (templateID && error) || (templateID && !templateData)) console.log("loading and error", loading, error);

            let foundTemplate;
            console.log("useManageTemplate, templateID", templateID, personUuid);

            if (templateID === undefined) {
                try {
                    // Créez un nouveau template et mettez à jour l'état avec son ID et l'objet
                    //   const newTemplateId = await newTemplateData(); // Supposons que cette fonction retourne un nouvel ID de template
                    // const newTemplateId = await addNewProspectCV();
                    // if (newTemplateId) {
                    //     console.log("New CV added with UUID:", newCvUuid);
                    // }

                    foundTemplate = { templateId: 0, blockSelected: [], personUuid: personUuid }; // Structure d'un nouveau template
                    setTemplateIDState(0);
                    setTemplate(foundTemplate, personUuid);
                    console.log("useManageTemplate, template created", foundTemplate);
                } catch (error) {
                    console.error("useManageTemplate, Error creating new template:", error, foundTemplate);
                }
            } else {
                // Transformez templateData en une structure compatible avec blockSelected
                const blockSelected = [];
                Object.keys(templateData).forEach(category => {
                    if (Array.isArray(templateData[category])) {
                        blockSelected.push(...templateData[category].map(item => ({ uuid: item.uuid })));
                    } else if (templateData[category] && templateData[category].uuid) {
                        blockSelected.push({ uuid: templateData[category].uuid });
                    }
                });

                foundTemplate = { templateId: templateID, blockSelected , personUuid: personUuid};
                setTemplateIDState(templateID);
                setTemplate(foundTemplate);
            }
        };

        initializeTemplate(personUuid);
    }, [templateData, loading, error, templateID]);

    return { template, templateID }; // Retournez à la fois l'ID du template et l'objet template
}

export default useManageTemplate;
