import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useParams } from "react-router-dom";

const UPDATE_PROSPECT_CV = gql`
  mutation UpdateTemplate(
    $uuid: ID!, 
    $language: CvLanguage!, 
    $titleUuid: ID!, 
    $descriptionUuid: ID!, 
    $educationUuids: [ID!], 
    $experienceUuids: [ID!], 
    $mappedSkillUuids: [ID!], 
    $projectUuids: [ID!], 
    $certificationUuids: [ID!], 
    $templateTitle:String!
  ) {
    updateProspectCv(
      updatedProspectCv: {
        uuid: $uuid,
        language: $language,
        titleUuid: $titleUuid,
        descriptionUuid: $descriptionUuid,
        educationUuids: $educationUuids,
        experienceUuids: $experienceUuids,
        mappedSkillUuids: $mappedSkillUuids,
        projectUuids: $projectUuids,
        certificationUuids: $certificationUuids
              templateTitle: $templateTitle

      }
    ) {
      uuid
      language
      templateTitle
    }
  }
`;

export const useUpdateProspectCV = () => {
    const { user } = useAuth();
    const { templateID } = useParams();
    const [updateProspectCv, { data, loading, error }] = useMutation(UPDATE_PROSPECT_CV, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
    });

    const updateExistingProspectCV = async (templateData, language, templateTitle) => {
        if (!templateID) {
            console.error("templateID by params is empty");
            return;
        }

        try {
            const {
                certifications,
                descriptions,
                educations,
                experiences,
                mappedSkills,
                titles,
                projects
            } = templateData;

            // Validation des données
            if (!descriptions || descriptions.length !== 1) {
                throw new Error("Description must contain exactly one item");
            }
            if (!titles || titles.length !== 1) {
                throw new Error("Title must contain exactly one item");
            }
            if (!templateID || !language || !templateTitle || !titles[0]?.uuid || !descriptions[0]?.uuid) {
                throw new Error("Missing required fields: uuid, title, description, or language");
            }

            const result = await updateProspectCv({
                variables: {
                    uuid: templateID,
                    language: language,
                    titleUuid: titles[0].uuid,
                    descriptionUuid: descriptions[0].uuid,
                    educationUuids: educations.map(edu => edu.uuid),
                    experienceUuids: experiences.map(exp => exp.uuid),
                    mappedSkillUuids: mappedSkills.map(skill => skill.uuid),
                    projectUuids: projects.map(proj => proj.uuid),
                    certificationUuids: certifications.map(cert => cert.uuid),
                    templateTitle
                }
            });

            return result.data.updateProspectCv.uuid;
        } catch (e) {
            console.error("useUpdateProspectCV Error updating prospect CV:", e.message);
            return { error: e.message };
        }
    };

    return { updateExistingProspectCV, data, loading, error };
};

export default useUpdateProspectCV;
