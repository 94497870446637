import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { useAuth } from "@/context/AuthContext";

export const UPDATE_SKILL = gql`
  mutation UpdateSkill($updatedSkill: UpdateSkillDto!) {
    updateSkill(updatedSkill: $updatedSkill) {
     
      companyUuid
      labelDe
      labelEn
      labelEs
      labelFr
      labelIt
      labelNl
      skillType
      uuid
    }
  }
`;
export const useUpdateSkill = () => {
  const { user } = useAuth();
  const [updateSkillMutation, { loading, error, data }] = useMutation(
    UPDATE_SKILL,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  const updateSkill = async (updatedSkill) => {
    try {
      const response = await updateSkillMutation({
        variables: { updatedSkill },
      });
      return response.data.updateSkill;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  return { updateSkill, loading, error, data };
};