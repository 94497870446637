import React, { useEffect, useRef, useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Expense from "./Expense";
import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
    SheetFooter,
} from "@/components/templates/sheet";
import FormNewExpense from "./formNewExpense";
import useExpenseData from "./useExpenseDataUser";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import FormIsSend from "@/components/organisms/feedback/formIsSend";
import { Label } from "@/components/atoms/texts/label";
import useAddExpenseReportProof from "@/services/consultant-services/api-rest/useAddExpenseReportProof";
import { useAddExpenseReport } from "@/services/consultant-services/mutation/useAddExpenseReport";
import { useExpenseReports } from "@/services/consultant-services/query/useExpenseReports";
import PrintEmptyDataMessage from "@/components/molecules/PrintEmptyDataMessage";
import InViewComponent from "@/components/organisms/InViewComponent";
import { Toaster } from "@/components/organisms/feedback/toaster";

export default function ExpensePanel({refetchFromScratch}) {
    const submitRef = useRef(null);

    const [openExpensePanel, setOpenExpensePanel] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    const { addExpenseReportProof } = useAddExpenseReportProof();
    const { addExpenseReport, loading: loadingAddExpense, error: errorAddExpense, data: dataAddExpense } = useAddExpenseReport();


    const handleSubmit = async (formData) => {
        console.log("Form data:", formData);
        try {
            // Réinitialiser les états
            setSubmissionError(null);
            setSubmissionSuccess(false);

            // Étape 1 : Envoyer le fichier et récupérer l'UUID
            const fileResponse = await addExpenseReportProof(formData.file);
            const expenseReportFileUuid = fileResponse.data.uuid;

            // Étape 2 : Envoyer la note de frais avec l'UUID du fichier
            const newExpenseReport = {
                ...formData,
                expenseReportFileUuid, // Ajouter l'UUID du fichier
            };
            await addExpenseReport(newExpenseReport);

            // Afficher le succès si tout est ok
            setSubmissionSuccess(true);
            refetchFromScratch();
        } catch (err) {
            // Gestion des erreurs
            setSubmissionError(err.message);
            console.error("Erreur lors de la soumission:", err);
        }
        return { submissionSuccess, submissionError }
    };

    const handleClose = (close) => {
        console.log("handleClose", close);
        setOpenExpensePanel(close);
        setSubmissionError(null);
            setSubmissionSuccess(false);
    }


    useEffect(() => {
        if (dataAddExpense?.addExpenseReport?.uuid) {
            // Afficher le succès si tout est ok
            setSubmissionSuccess(true);
            refetchFromScratch();
        }
        console.log("use effect data", dataAddExpense)
        errorAddExpense && setSubmissionError(errorAddExpense)

    }, [errorAddExpense, dataAddExpense]);

    return (
        <Sheet open={openExpensePanel} onOpenChange={handleClose}>
            <SheetTrigger>
                <Button leftIcon={<AddRoundedIcon />} showLeftIcon text="consultants.expense.newExpense" />
            </SheetTrigger>
            <SheetContent>
                <SheetHeader
                    title={"consultants.expense.newExpense"}
                    subheader={"global.information.allFieldsMandatory"}
                />
                <SheetSlot className="flex">
                    {submissionError && (
                        <PrintErrorMessage error={submissionError} />
                    )}
                    {submissionSuccess && (
                        <FormIsSend message={<Label>Expense submitted successfully!</Label>} />
                    )}
                    <FormNewExpense onSubmit={handleSubmit} submitRef={submitRef} submissionError={submissionError} submissionSuccess={submissionSuccess} setSubmissionSuccess={setSubmissionSuccess} setSubmissionError={setSubmissionError}/>
                    {loadingAddExpense && <span className="loader" />}
                </SheetSlot>
                <SheetFooter
                    buttonOutlineText={"global.actions.clear"}
                    buttonDressedText={"global.actions.ConfirmNext"}
                    warning={"consultants.expense.fileAdded"}
                    onClickOutline={(e) =>
                        submitRef.reset && submitRef.reset.resetForm()
                    }
                    onClickDressed={() =>
                        submitRef.current && submitRef.current.click()
                    }
                />
            </SheetContent>
        </Sheet>
    )
}