import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Mutation pour envoyer un email de support
const SEND_SUPPORT_EMAIL = gql`
  mutation SendSupportEmail($subject: String!, $body: String!) {
    sendSupportEmail(newEmail: { subject: $subject, body: $body })
  }
`;

// Hook personnalisé pour envoyer un email de support
export const useSendSupportEmail = (onSuccess) => {
  const { user } = useAuth();

  // Mutation pour envoyer un email de support
  const [sendSupportEmail, { data, loading, error }] = useMutation(SEND_SUPPORT_EMAIL, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    onCompleted: onSuccess, // Action à exécuter une fois l'email envoyé avec succès
  });

  return {
    sendSupportEmail,
    data,
    loading,
    error,
  };
};
