/* eslint-disable react/prop-types */

/**
 * @component Experience
 *
 * The `Experience` component displays a list of user experiences and provides functionality to add new experiences. It includes options for showing an experience form, handling form validation, and displaying error messages.
 *
 * Key Features:
 * - Displays a list of experiences using the `PrintExperience` component.
 * - Allows users to add new experiences through the `AddExperience` component.
 * - Handles form visibility and error messages.
 * - Provides a button to go back to the previous view.
 *
 * Props:
 * - @param {Function} hideAllOverview - Function to hide all overview sections.
 * - @param {Function} showComponent - Function to control the visibility of the component.
 * - @param {Array} experiences - Array of experience objects to be displayed.
 * - @param {Object} user - The user object containing user details.
 * - @param {Function} setIsDoingAction - Function to indicate that an action (like adding an experience) is in progress.
 *
 * Usage:
 * - This component is used to manage and display user experiences in a profile or admin interface. It allows users to view, add, and manage their experiences.
 *
 * Example:
 * ```jsx
 * <Experience
 *   hideAllOverview={hideAllOverview}
 *   showComponent={showComponent}
 *   experiences={userExperiences}
 *   user={currentUser}
 *   setIsDoingAction={setLoadingState}
 * />
 * ```
 *
 * Dependencies:
 * - `Button` component for action buttons (e.g., Add, Back).
 * - `Label` component for displaying section titles and labels.
 * - `AddExperience` component for adding new experiences.
 * - `PrintExperience` component for displaying individual experiences.
 * - `PrintErrorMessage` component for showing error messages.
 * - `motion` from `framer-motion` for animations.
 * - `useCVTab` hook for managing CV-related tabs (if needed).
 * 
 * State:
 * - `showForm`: Boolean state to control the visibility of the `AddExperience` form.
 * - `editingExperience`: Boolean state to indicate if an experience is being edited.
 * - `formIsValid`: Boolean state to indicate the validity of the form.
 * - `serverresponseIsError`: Boolean state to indicate if there was an error in server response.
 * - `errors`: State to store error messages.
 *
 * Sub-Components:
 * - @component AddExperience: Form for adding new experiences.
 * - @component PrintExperience: Displays individual experience details.
 * - @component PrintErrorMessage: Displays error messages if any.
 */

import AddExperience from "./AddExperience";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import PrintExperience from "./PrintExperience";
import { useState } from "react";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import useCVTab from "@/hooks/global-hooks/useCVTab";
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';



export default function Experience({
  hideAllOverview,
  showComponent,
  experiences,
 
  user,
  setIsDoingAction,
}) {
  const [showForm, setShowForm] = useState(false);
  // const [experiences, setExperiences] = useState([]);
  const [editingExperience, setEditingExperience] = useState(false);
    const [fomrIsValid, setFormIsValid] = useState(false);
    const [serverresponseIsError, setServerResponseIserror] = useState(false);
    const [errors, setErrors] = useState(null);
 

  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };
  return (
    <motion.div variants={item}>
      {serverresponseIsError && <PrintErrorMessage error={errors} />}
      <div className="flex items-center gap-fourthDegree w-full">
        <Button
          showLeftIcon={true}
          leftIcon={
            <ArrowBackIcon className="cursor-pointer" onClick={hider} />
          }
          showText={false}
          style="ghost"
          onClick={hider}
        />
        <div className="basis-3/4">
          <Label
            message="consultants.statement.prospectConsultantPanel.experience"
            size="h3"
            fontWeight="bold"
          />
        </div>

        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={() => setShowForm(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
        />
      </div>
      <div className=" flex flex-col gap-secondDegree">
        {showForm && (
          <AddExperience
            showForm={showForm}
            setShowForm={setShowForm}
            editingExperience={false}
            setEditingExperience={setEditingExperience}
            user={user}
            setIsDoingAction={setIsDoingAction}
            setFormIsValid={setFormIsValid}
            setServerResponseIserror={setServerResponseIserror}
            setErrors={setErrors}
          />
        )}
        <div className=" gap-firstDegree">
          {experiences.map((experience) => {
            return (
              <PrintExperience
                key={experience.uuid}
                experience={experience}
                setEditingExperience={setEditingExperience}
                editingExperience={editingExperience}
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
              />
            );
          })}
        </div>
      </div>
    </motion.div>
  );
}
