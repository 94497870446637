

import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const ADD_INVOICE = gql`
  mutation AddConsultantInvoice($newInvoice: NewConsultantInvoiceRequestDto!) {
    addConsultantInvoice(newInvoice: $newInvoice) {
      uuid
      
      commission
      invoiceDate
      periodStart
      periodEnd
      clientName
      invoiceNumber
      currency
      dailyRateConsultant
      dailyRateClient
      daysWorked
    }
  }
`;

export const useAddInvoice = () => {
  const { user } = useAuth();

  const [addInvoice, { loading, error, data }] = useMutation(ADD_INVOICE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  return { addInvoice, loading, error, data };
};
