/* eslint-disable react/prop-types */

import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/atoms/texts/label';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { Button } from "@/components/molecules/buttons/button";
import PrintWarningMessage from '@/components/organisms/feedback/PrintWarningMessagee';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export default function HandleVats({ vatData, onCheckboxChange, onDeleteVatRate }) {
    const sortedVatData = [...vatData].sort((a, b) => a.rate - b.rate);
    const [t] = useTranslation("global");
    const [deleteConfirmation, setDeleteConfirmation] = useState(undefined);


    return (
        <div className="w-full ">
            <Label
                message="consultants.statement.vatSettings.vatRate"
                size="body"
                fontWeight="bold"
            />
            {sortedVatData.map((item, index) => (
                <div
                    key={item.uuid}
                    className={`flex flex-col items-end w-full  px-firstDegree py-betweenText rounded-sm  `}
                >
                    <div
                        className={`${item.status == "ACTIVE" && "bg-medium"} flex items-center gap-2  w-full  p-firstDegree rounded-sm text-body`}
                    >
                        <Checkbox
                            checked={item.status == "ACTIVE"}
                            onClick={() => onCheckboxChange(item.uuid, item.status)}
                            htmlFor={item.uuid}
                            className="mx-[2px] h-4 w-4 shrink-0  border border-black ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
                        />
                        <span id={item.uuid} className='grow'>{item.rate} %</span>
                        {deleteConfirmation !== item.uuid ? <Button
                            showLeftIcon
                            leftIcon={
                                <DeleteOutlineIcon className="text-error" fontSize="small" />
                            }
                            showText={false}
                            sizePadding="small"
                            style="ghost"
                            onClick={(e) => setDeleteConfirmation(item.uuid)}
                        /> :
                            <>

                                <Label message="consultants.statement.vatSettings.confirmDelete" />
                                <Button
                                    showLeftIcon
                                    className="text-error border-error rounded-sm bg-white"
                                    leftIcon={
                                        <DeleteOutlineIcon className="text-error" fontSize="small" />
                                    }
                                    // showText={false}
                                    text={"global.actions.confirmDelete"}
                                    sizePadding="small"
                                    style="outline"
                                    onClick={(e) => onDeleteVatRate(item.uuid)}
                                />
                                <Button

                                    className="text-primary border-primary py-2 rounded-sm"

                                    text={"global.actions.cancel"}
                                    sizePadding="small"
                                    style="ghost"
                                    onClick={(e) => setDeleteConfirmation(undefined)}
                                /></>}
                    </div>


                </div>
            ))}
        </div>
    );
}
