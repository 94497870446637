import {Resource} from '@opentelemetry/resources';
import {WebTracerProvider, SimpleSpanProcessor, ConsoleSpanExporter} from '@opentelemetry/sdk-trace-web';
import {OTLPTraceExporter} from '@opentelemetry/exporter-trace-otlp-http';
import {registerInstrumentations} from '@opentelemetry/instrumentation';
import {FetchInstrumentation} from '@opentelemetry/instrumentation-fetch';
import {SEMRESATTRS_SERVICE_INSTANCE_ID, SEMRESATTRS_SERVICE_NAME} from '@opentelemetry/semantic-conventions';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';

const consoleExporter = new ConsoleSpanExporter();

const collectorExporter = new OTLPTraceExporter({
    url: window.ENV.OTLP_URL,
    headers: {
        'X-Scope-OrgID': 'synapse42'
    }
});

const provider = new WebTracerProvider({
    resource: new Resource({
        [SEMRESATTRS_SERVICE_NAME]: window.ENV.SERVICE_NAME,
        [SEMRESATTRS_SERVICE_INSTANCE_ID]: window.ENV.SERVICE_INSTANCE_ID,
        'environment': window.ENV.ENVIRONMENT,
    })
});

const fetchInstrumentation = new FetchInstrumentation({
    propagateTraceHeaderCorsUrls: [
        new RegExp(window.ENV.BACKEND_URL)
    ],
    clearTimingResources: true
});
fetchInstrumentation.setTracerProvider(provider);

provider.addSpanProcessor(new SimpleSpanProcessor(consoleExporter));
provider.addSpanProcessor(new SimpleSpanProcessor(collectorExporter));
provider.register({
    // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
    contextManager: new ZoneContextManager(),
});

registerInstrumentations({
    instrumentations: [
        new DocumentLoadInstrumentation(),
        new UserInteractionInstrumentation(),
        fetchInstrumentation
    ],
    tracerProvider: provider
});

export default function TraceProvider({children}) {
    return (
        <>
            {children}
        </>
    );
}
