/* eslint-disable react/prop-types */
/* eslint-disable react/prop-types */
/**
 * @component NavBar
 *
 * The `NavBar` component serves as the navigation bar for the application. It provides a responsive interface for navigation and user interactions, including displaying the application logo, navigation items, notifications, and user profile options.
 *
 * Key Features:
 * - Displays the application logo and a responsive menu toggle button.
 * - Shows navigation items based on the user's authentication state.
 * - Includes a notification button and a user profile button.
 * - Adapts to different screen sizes with responsive styling.
 *
 * Props:
 * - None
 *
 * Local State:
 * - None (Relies on external hooks and context for state management)
 *
 * Usage:
 * - This component is used to render the navigation bar at the top of the application. It adjusts its layout based on the screen size and the user's authentication state.
 *
 * Example:
 * ```jsx
 * <NavBar />
 * ```
 *
 * Dependencies:
 * - @context/AuthContext: Provides authentication state and user information.
 * - @components/molecules/navigation/NavItem: Component for rendering individual navigation items.
 * - @components/atoms/NotificationIcon: Component for displaying a notification icon.
 * - @components/molecules/buttons/button: Provides the Button component used for actions.
 * - @mui/icons-material: Provides icons for the menu and user profile.
 * - @components/organisms/PopUpProfile: Component for displaying a user profile pop-up.
 * - @components/atoms/avatar: Component for displaying user avatars.
 * - @hook useNavbar: Custom hook managing navigation bar state and behavior.
 * - @hook useTranslation: Hook from `react-i18next` for language translation.
 * - @hook useEffect: React hook for managing side effects.
 *
 * Functions:
 * - `handleClick()`: Toggles the visibility of the menu.
 * - `handleNavItemClick(path)`: Handles navigation item clicks, changing the selected item and routing.
 * - `showNotification()`: Displays the notification pop-up.
 * - `showProfile()`: Displays the user profile pop-up.
 *
 * @example
 * <NavBar />
 * ```
 */

import LogoSynapse from '../../../../assets/logos/Logo_Synapse_42_Typo_Blanc.png';
import { useAuth } from '@/context/AuthContext';
import NavItem from '@/components/molecules/navigation/NavItem';
import NotificationIcon from '@/components/atoms/NotificationIcon';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/molecules/buttons/button';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavbar } from './useNavBar';
import PopUpProfile from '@/components/organisms/PopUpProfile';
import { Avatar } from '@/components/atoms/avatar';
import { useEffect } from 'react';
import DialogSupport from '../../DialogSupport';


export default function NavBar() {

    const { user, isAuth, viewAs } = useAuth();
    // console.log("user",user, "isAuth", isAuth());
    const { showMenu, handleClick, handleNavItemClick, variantItems, isSelected, selectedItem, showProfile, showSupport, showNotification } = useNavbar();
    //   const user = { surname: "John", name: "Doe", role: "admin" };
    const { i18n } = useTranslation("global");

    useEffect(() => {
        user && i18n.changeLanguage(user?.language?.toLowerCase())
        // console.log("user in app = ", user)
    }, []);

    return (<>

        <header className={`${showMenu ? 'object-contain   min-h-[70px]' : ' min-h-[70px] '}   object-contain  w-full sticky top-0 left-0 right-0 z-50 bg-horizontalPrimaryLinear h-[70px] `} style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            <nav className={`${showMenu ? 'flex-row items-start justify-end py-[20px] xl:py-0 ' : ''}  xl:items-center xl:flex   xl:gap-[30px] w-full h-full  `}>

                <div className={`${showMenu ? '' : ''} object-contain flex items-center xl:h-full  xl:w-auto w-full shrink-0 px-secondDegree`}>
                    <img src={LogoSynapse} alt='Logo Synapse' className={`${showMenu ? '' : 'xl:mt-0 mt-[20px]'} h-[20.3px] w-auto object-contain shrink-0 `} id="logo" />
                    {isAuth() && (
                        <button type="button"
                            onClick={handleClick}
                            className=" xl:hidden   top-[16px] px-secondDegree absolute right-0 text-sm text-white  justify-end w-1/5 float-right"
                        >
                            {showMenu ? <MenuIcon className="justify-end float-right" /> : <MenuOpenIcon className="justify-end float-right" />}
                        </button>)}
                </div>
                {isAuth() && <div className='flex w-[100%] xl:h-full '>
                    <div className='  xl:w-4/5 items-center justify-items-center flex text-white  xl:flex-row  text-bodySmall justify-center w-[100vw] m-0 bg-horizontalPrimaryLinear  w-full grow' >
                        <div className={`${showMenu ? " hidden " : "  flex mt-secondDegree pb-secondDegree xl:pb-0  w-full xl:mt-0 relative"}  h-full flex-col xl:flex-row xl:w-auto`}>
                            {variantItems.map((itemName, index) => (
                                <NavItem
                                    key={index}
                                    name={itemName.name}
                                    selected={isSelected(itemName.path)}
                                    path={itemName.path}
                                    disabled={itemName.disabled}
                                    className="text-white cursor-pointer "
                                    handleNavItemClick={(path) => handleNavItemClick(path)}

                                />
                            ))

                            }
                            <div className='w-full'>
                                <DialogSupport
                                    isForMobile
                                    componentForMobile={<Button
                                        // showLeftIcon={false}
                                        // showRightIcon={false}
                                        // showText
                                        text={"support.title"}
                                        sizePadding="default" // Utilisez "small" pour un padding plus petit
                                        style="ghost"
                                        onClick={() => { showSupport() }}
                                        className={`${showMenu ? ' hidden' : 'sm:block w-full text-white'}  xl:hidden w-full h-[57px] text-white text-body hover:bg-hover cursor-pointer rounded-md text-bodySmall  ${selectedItem === 'Support' ? 'text-secondary bg-hover font-bold  font-[700]' : ''} `}
                                    />} />
                            </div>
                            <div className='w-full'>

                                {/* <Button
                                    showLeftIcon={false}
                                    leftIcon=""
                                    showRightIcon={false}
                                    rightIcon=""
                                    showText={true}

                                    text="navbarGlobal.notification"// @todo
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="ghost"
                                    onClick={() => showNotification()}// Options: "fill", "outline", "ghost", "dressedGhost"
                                    className={`${showMenu ? ' hidden' : 'sm:block text-white'}  xl:hidden text-white w-full h-[57px] text-body hover:bg-hover cursor-pointer rounded-md  ${selectedItem === 'Notification' ? 'text-secondary bg-hover font-bold font-[700] ' : ''} `}
                                /> */}
                                <NotificationIcon isForMobile
                                    componentForMobile={
                                        <Button
                                            showLeftIcon={false}
                                            leftIcon=""
                                            showRightIcon={false}
                                            rightIcon=""
                                            showText={true}

                                            text="navbarGlobal.notification"// @todo
                                            sizePadding="default" // Utilisez "small" pour un padding plus petit
                                            style="ghost"
                                            onClick={() => showNotification()}// Options: "fill", "outline", "ghost", "dressedGhost"
                                            className={`${showMenu ? ' hidden' : 'sm:block  text-white'}  xl:hidden text-white w-full h-[57px] text-body hover:bg-hover cursor-pointer rounded-md  ${selectedItem === 'Notification' ? 'text-secondary bg-hover font-bold font-[700] ' : ''} `}
                                        />}

                                />


                            </div>
                            <div className=''>
                                <PopUpProfile
                                    isForMobile
                                    component={<Button
                                        showLeftIcon={false}
                                        leftIcon=""
                                        showRightIcon={false}
                                        rightIcon=""
                                        showText={true}
                                        text={"navbarGlobal.profile"}

                                        sizePadding="default" // Utilisez "small" pour un padding plus petit
                                        style="ghost"
                                        onClick={() => showProfile()}// Options: "fill", "outline", "ghost", "dressedGhost"
                                        className={`${showMenu ? ' hidden' : 'sm:block w-full text-white'}  xl:hidden  h-[57px] text-white text-body hover:bg-hover cursor-pointer rounded-md text-bodySmall  ${selectedItem === user.name ? 'text-secondary bg-hover font-bold  font-[700]' : ''} `}
                                    />} />
                            </div>



                        </div>
                    </div>

                    {/* {console.log(user)} */}
                    <div className={`${user ? 'xl:block' : 'xl:hidden'} h-full  gap-x-secondDegree relative py-firstDegree justify-end items-center pr-thirdDegree  hidden xl:flex`} >
                        <DialogSupport />
                        <NotificationIcon className='' />
                        <PopUpProfile
                            user={user}
                            component={<Avatar src={user.avatarSrc} fallback={`${user.surname} ${user.name}`} size="small" className="shrink-0 grow-0 mx-1" />} />


                    </div>
                </div>}
                {!showMenu && <div className='  h-[50vh] w-full xl:hidden' onClick={handleClick}></div>}

            </nav>
        </header>
    </>



    )



}