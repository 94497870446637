import { useAuth } from "@/context/AuthContext";
import { gql, useQuery } from "@apollo/client";
import client from "@/apolloClient";
import { useState } from "react";

const GET_INVOICES = gql`
  query GetInvoices($year: Int!) {
    invoices(year: $year) {
      invoices {
        year
        uuid
        periodStart
        periodEnd
        invoiceDate
        month

        invoiceNumber
        daysWorked
        dailyRateConsultant
        dailyRateClient
        currency
        clientName
        amountConsultant
        amountClient
      }
      year
    }
  }
`;

export const useInvoices = () => {
  const { user } = useAuth();
  const [allInvoices, setAllInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const fetchInvoices = async (year) => {
    setLoading(true);
    try {
      const { data, error } = await client.query({
        query: GET_INVOICES,
        variables: { year },
        context: {
          headers: {
            Authorization: `Bearer ${user.strongToken}`,
          },
        },
        fetchPolicy: "network-only",
      });

      if (data) {
        setAllInvoices((prevInvoices) => [
          ...prevInvoices,
          ...data.invoices.invoices,
        ]);
        return data.invoices.invoices;
      }

      if (error) {
        setErrors(error);
      }
    } catch (err) {
      setErrors(err);
    } finally {
      setLoading(false);
    }
  };

  return { loading, errors, allInvoices, fetchInvoices };
};
