import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';
import errorToText from '@/utils/errorToText';

const useAddRetributionModel = () => {

    const [response, setResponse] = useState(null);
    const { user: authUser } = useAuth();

    const addRetributionModel = async (upToFiveConsultants, upToTenConsultants, moreThanTenConsultants) => {
        if (!authUser.strongToken) {
            setError('No strong token available');
            return { data: null, error: 'No strong token available' };
        }

        try {
            const response = await fetch(window.ENV.BACKEND_URL+'/api/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authUser.strongToken}`
                },
                body: JSON.stringify({
                    query: `
                    mutation addRetributionModel {
                        addRetributionModel(
                            newRetributionModel: {
                              upToFiveConsultants: ${upToFiveConsultants}, 
                              upToTenConsultants: ${upToTenConsultants}, 
                              moreThanTenConsultants: ${moreThanTenConsultants}, 
                              companyUuid: "${authUser.company.uuid}"
                            }
                          ) {
                            uuid
                            upToTenConsultants
                            upToFiveConsultants
                            moreThanTenConsultants
                        }
                    }
                    `
                })
            });


            if (!response.ok) {
                const errMessage = errorToText(response);
                throw new Error(errMessage);
            }

            const data = await response.json();
            if (data.errors) {
                const errMessage = data.errors[0].message;
                throw new Error(errMessage);
            }

            setResponse(data.data.addRetributionModel);

            return { data: data.data.addRetributionModel, error: null };
        } catch (err) {
            return { data: null, error: err.message };
        }
    };

    return { addRetributionModel, uuidResponnse: response };
};

export default useAddRetributionModel;


