/* eslint-disable react/prop-types */
import { cn } from "@/lib/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
export default function TextArea({ placeHolder, className, ...props }) {
  const [t] = useTranslation("global");
  const placeHolderTranslated = t(placeHolder);
  const [value, setValue] = useState(props.value || ""); 
  const handleChange = (e) => {
    setValue(e.target.value); // Mettre à jour la valeur d'état interne
    if (props.onChange) {
      props.onChange(e); // Appeler la fonction de gestionnaire de changement parent si elle est définie
    }
  };

  return (
    <textarea
      placeholder={placeHolderTranslated}
      value={value}
      className={cn(
        "rounded-[4px] border-grey border-[1px] p-firstDegree w-full text-body ",
        className
      )}
      onChange={handleChange}
      {...props}
    />
  );
}
