import { gql } from "@apollo/client";

import { useAuth } from "@/context/AuthContext";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

export const GET_ALL_TEMPLATES = gql`
  query templateCV($prospectUuid : ID!) {
    prospectCvs(prospectUuid: $prospectUuid) {
        uuid
        language
        title {
        title
        }
        templateTitle
    }
    }   
`;


export const useProspectCvs = () => {
    const { user } = useAuth();
    const { prospectID } = useParams();
    if (!prospectID) {
        const error = "prospectID by params is empty"
        return { loading: false, error, data: null, refetch: null }
    } else {
        const { loading, error, data, refetch } = useQuery(GET_ALL_TEMPLATES, {
            variables: { prospectUuid: prospectID },
            context: {
                headers: {
                    Authorization: `Bearer ${user.strongToken}`,
                },
            },
            fetchPolicy: "network-only", // S'assure que les données sont toujours récupérées du serveur
        });

        return { loading, error, data: (data && data.prospectCvs), refetch };
    }

};
