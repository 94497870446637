"use client";
import * as React from "react"
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"

import { cn } from "@/lib/utils"
import { toggleVariants } from "@/components/ui/toggle"

const ToggleGroupContext = React.createContext({
    size: "default",
    variant: "default",

})

const ToggleGroup = React.forwardRef(({ className, variant, size, children, ...props }, ref) => (
    <ToggleGroupPrimitive.Root
        ref={ref}
        className={cn("flex items-center justify-center  border-1 border border-medium rounded-sm bg-white ", className)}
        {...props}>
        <ToggleGroupContext.Provider value={{ variant, size }}>
            {children}
        </ToggleGroupContext.Provider>
    </ToggleGroupPrimitive.Root>
))

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName

const ToggleGroupItem = React.forwardRef(({ className, children, variant, size, ...props }, ref) => {
    const context = React.useContext(ToggleGroupContext)

    return (
        (<ToggleGroupPrimitive.Item
            ref={ref}
            className={cn(toggleVariants({
                variant: context.variant || variant,
                size: context.size || size,
            }), className,
            "data-[state=on]:bg-medium data-[state=on]:font-bold data-[state=on]:text-primary hover:text-primary ", // Ajout de la classe bg-primary lorsque l'élément est sélectionné
            "data-[state=off]:"  // Ajout de la couleur blanche pour le texte lorsque sélectionné
                      )}
            {...props}>
            {children}
        </ToggleGroupPrimitive.Item>)
    );
})

ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName

export { ToggleGroup, ToggleGroupItem }
