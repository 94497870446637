import React from 'react'
import { Label } from '@/components/atoms/texts/label';
export default function PrintTableCellContent({cellContent}) {
  return (
    <span>
      {cellContent == "XXX" || cellContent==null ? (
        <Label message="error.unfilled" color="disabled" size="small" />
      ) : (
        cellContent
      )}
    </span>
  );
}
