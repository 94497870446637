/* eslint-disable react/prop-types */
/* eslint-disable react/prop-types */

/**
 * @component PrintExperience
 *
 * The `PrintExperience` component displays a summary of a professional experience. It allows users to expand or collapse the details of the experience and provides an option to edit the experience.
 *
 * Props:
 * - @param {Object} experience - The experience object containing details to be displayed and edited.
 * - @param {Function} setEditingExperience - Function to set the state of whether an experience is being edited.
 * - @param {boolean} editingExperience - Boolean indicating if the experience is being edited.
 * - @param {Object} user - User object containing user details.
 * - @param {Function} setIsDoingAction - Function to indicate that an action (such as editing an experience) is in progress.
 * - @param {Function} setFormIsValid - Function to set the validity of the form.
 * - @param {Function} setServerResponseIserror - Function to handle server response errors.
 * - @param {Function} setErrors - Function to set error messages.
 *
 * Usage:
 * - This component is used to display a professional experience and allow the user to edit it.
 * - It shows a collapsible view of the experience details and includes an edit button to modify the experience.
 *
 * Example:
 * ```jsx
 * <PrintExperience
 *   experience={currentExperience}
 *   setEditingExperience={setEditingExperience}
 *   editingExperience={isEditingExperience}
 *   user={currentUser}
 *   setIsDoingAction={setLoadingState}
 *   setFormIsValid={setFormValidity}
 *   setServerResponseIserror={setServerErrorState}
 *   setErrors={setErrorMessages}
 * />
 * ```
 *
 * Dependencies:
 * - `AddExperience` for displaying and managing the experience form.
 * - `Label` for displaying labels and messages.
 * - `EditIcon`, `ExpandMoreIcon`, `ExpandLessIcon` from `@mui/icons-material` for icons.
 *
 * Local State:
 * - `showDetails` : Boolean to control the visibility of experience details.
 * - `showForm` : Boolean to control the visibility of the experience editing form.
 *
 * Functions:
 * - `handleEdit` : Sets the component to editing mode and displays the editing form.
 */

import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddExperience from "./AddExperience";
import { Label } from "@/components/atoms/texts/label";

export default function PrintExperience({
  experience,
  setEditingExperience,
  editingExperience,

  user,
  setIsDoingAction,
  setFormIsValid,
  setServerResponseIserror,
  setErrors
}) {
  const [showDetails, setShowDetails] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const handleEdit = () => {
    setEditingExperience(true);
    setShowForm(true);
    setFormIsValid(false)
  };
  return (
    <div className="flex flex-col gap-firstDegree">
      {!showForm && (
        <div className="flex gap-[12px] ">
          {!showDetails && (
            <ExpandMoreIcon onClick={() => setShowDetails(true)} />
          )}
          {showDetails && (
            <ExpandLessIcon onClick={() => setShowDetails(false)} />
          )}
          <span className="font-bold flex items-center">
            {experience.title}
          </span>
          <EditIcon
            className="text-primary cursor-pointer"
            onClick={handleEdit}
          />
        </div>
      )}
      {!showForm && showDetails && (
        <div className="flex flex-col gap-firstDegree">
          <div className="flex flex-row gap-firstDegree py-firstDegree text-bodySmall items-center">
            <Label fontWeight="bold">{experience.company}</Label>
            <Label>{experience.city}</Label>
            <Label>{experience.country}</Label>
            <Label>{experience.startDate}</Label>
            <Label
              message="global.information.fromAtoB"
              size="small"
              className=""
            />
            <Label>{experience.endDate}</Label>
          </div>
          <div className="text-bodySmall">
            <Label>{experience.description}</Label>
          </div>
        </div>
      )}

      <AddExperience
        experience={experience}
        showForm={showForm}
        setShowForm={setShowForm}
        editingExperience={editingExperience}
        setEditingExperience={setEditingExperience}
        user={user}
        setIsDoingAction={setIsDoingAction}
        setFormIsValid={setFormIsValid}
        setServerResponseIserror={setServerResponseIserror}
        setErrors={setErrors}
      />
    </div>
  );
}
