/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/molecules/table";
import { Label } from "@/components/atoms/texts/label";
import { monthPath } from "@/utils/translationPath";



export default function TableFinance({ financeData, onContentUpdate }) {
    const [oldData, setOldData] = useState();

    const months = [
        "january", "february", "march", "april", "may", "june",
        "july", "august", "september", "october", "november", "december",
    ];
    function isNumeric(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }
    const handleFocus = (e) => {
        // console.log("onFocus ********************************", e)
        setOldData(e.target.innerText);  // Sauvegardez l'ancienne valeur avant l'édition
    };
    const handleBlur = (event, month, category, subCategory) => {
        // Lorsque l'utilisateur termine l'édition, vous capturez la valeur
        const newValue = event.target.innerText;
        console.log(` ********************************") Updated value for ${month}: ${newValue}`, category, subCategory);
        if (!isNumeric(newValue)) {
            event.target.innerText = oldData;  // Réinitialisez à l'ancienne valeur si non numérique
        } else {
            onContentUpdate(month, newValue);  // Mettez à jour avec la nouvelle valeur si elle est numérique
        }
    };
    return (
        <Table className="w-full ">
            <TableHeader>
                <TableRow className="h-auto">
                    <TableHead className="bg-transparent  "></TableHead>
                    {Object.values(monthPath).map((month, index) => (
                        <TableHead key={index} className="min-w-[70px] h-auto">
                            <Label message={month} size="small" className="" />
                        </TableHead>
                    ))}

                    <TableHead className="bg-horizontalPrimaryLinear  text-white ">
                        <Label message="global.total" size="small" />
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {financeData && Object.entries(financeData).map(([category, details], categoryIndex) => {
                    // Calculer le total général pour chaque catégorie avant de détailler
                    const totalByCategory = Object.values(details).reduce((acc, subCategory) => {
                        months.forEach(month => {
                            acc[month] = (acc[month] || 0) + subCategory[month];
                        });
                        return acc;
                    }, {});

                    const totalGeneral = Object.values(totalByCategory).reduce((acc, current) => acc + current, 0);

                    return (
                        <>
                            <TableRow key={`total-${categoryIndex}`} className="font-bold ">
                                <TableCell className="text-end bg-transparent " >
                                    <Label
                                        message={`finance.${category}`}

                                        size="small"
                                    >{category}</Label>
                                </TableCell>
                                {months.map((month, monthIndex) => (
                                    <TableCell key={monthIndex} className="tdHover">
                                        {totalByCategory[month]}
                                    </TableCell>
                                ))}
                                <TableCell className="bg-bgLight tdHoverDark">
                                    {totalGeneral}
                                </TableCell>
                            </TableRow>
                            {Object.entries(details).map(([subCategory, data], subCategoryIndex) => (
                                <TableRow key={`${categoryIndex}-${subCategoryIndex}`}>
                                    <TableCell className="text-end bg-transparent">
                                        <Label
                                            size="small"

                                        >{subCategory}</Label>
                                    </TableCell>
                                    {months.map((month, monthIndex) => (
                                        <TableCell
                                            key={monthIndex}
                                            className="tdHover"
                                            contentEditable='true'
                                            onFocus={handleFocus}
                                            onBlur={(e) => handleBlur(e, month, category, subCategory)}>
                                            {data[month]}
                                        </TableCell>
                                    ))}
                                    <TableCell className="bg-bgLight tdHoverDark">
                                        {data.total}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    );
                })}
            </TableBody>

        </Table>
    );
}
