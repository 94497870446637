/* eslint-disable react/prop-types */
/**
 * @component StatementTab
 *
 * The `StatementTab` component is responsible for displaying financial statements or performance overviews for consultants. It fetches and displays data related to consultants' yearly statements and provides tools for filtering, viewing charts, and interacting with bill management functionalities.
 *
 * Key Features:
 * - Fetches and displays yearly statement data for consultants.
 * - Provides a filter to select different years of data.
 * - Displays data in a table format with additional visualization through charts.
 * - Integrates with bill management functionality via the `BillsMain` component.
 * - Provides skeleton loaders and error handling for data fetching.
 * - Handles user-specific permissions and roles to adjust the display accordingly.
 *
 * Props:
 * - None. The component relies on data from hooks (authentication, URL parameters, etc.).
 *
 * State:
 * - @variable {String} selectedYear - Stores the year selected by the user for filtering statement data.
 * - @variable {Array} allYearlyData - Stores all yearly data fetched from the backend for the consultant.
 * - @variable {Object} yearData - Stores data for the currently selected year.
 *
 * Hooks/Effects:
 * - @hook {useParams} - Retrieves the consultant's ID from the URL parameters.
 * - @hook {useAuth} - Retrieves the authenticated user's information (including role and token).
 * - @hook {useConsultantStatementYears} - Fetches the list of years available for the consultant's statements.
 * - @hook {useConsultantStatement} - Fetches the detailed statement data for the selected year.
 * - @effect Fetches available years when the component mounts or when the user's token or consultant ID changes.
 * - @effect Fetches data for each available year and stores it in `allYearlyData`.
 * - @effect Updates the displayed data when a year is selected or when yearly data is updated.
 *
 * Conditional Rendering:
 * - Displays a skeleton loader while data is being fetched.
 * - Displays an error message if the data fetch fails.
 * - Shows a message if no years or data are available.
 * - Displays the `BillsMain` component for managing bills when no data is present.
 * - Displays an overview table and charts when data is successfully fetched.
 *
 * Functions:
 * - @function fetchYears - Fetches the available years for the consultant's statements.
 * - @function fetchData - Fetches the statement data for all available years.
 * - @function setYearData - Filters the data to display the selected year's statement.
 *
 * Usage:
 * This component is primarily used in the administrative panel to display consultants' financial or performance data. It is integrated with various sub-components such as `BillsMain`, `Filter`, `ListOfEditableOverview`, and chart components for data visualization.
 *
 * Example:
 * <StatementTab />
 *
 * Dependencies:
 * - React hooks (`useState`, `useEffect`) for managing state and side effects.
 * - Router hooks (`useParams`) for retrieving consultant-specific data from the URL.
 * - Custom hooks (`useAuth`, `useConsultantStatementYears`, `useConsultantStatement`) for fetching data and managing authentication.
 * - UI components for rendering tables, filters, and charts (`Filter`, `ListOfEditableOverview`, `LineChartComp`, `TableOverflow`).
 * - Error handling components (`PrintErrorMessage`, `PrintEmptyDataMessage`).
 *
 * Where it is used:
 * - Statement
 * 
 */

import { Separator } from "@/components/atoms/divider/separator";
import ListOfEditableOverview from "@/components/organisms/ListOfEditableOverview";
import { useState, useEffect } from "react";
import TableOverflow from "@/components/organisms/TableOverflow";
import Filter from "@/components/organisms/Filter";
import { useAuth } from "@/context/AuthContext";
import useConsultantStatementYears from "@/services/admin-services/query/useConsultantStatementYears";
import useConsultantStatement from "@/services/admin-services/query/useConsultantStatement";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import LineChartComp from "@/components/organisms/charts/LineChartForStatementTab";
import SkeletonHandler from "@/components/templates/skeletons/SkeletonHandler";
import { Toaster } from "@/components/organisms/feedback/toaster";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { Label } from "@/components/atoms/texts/label";
import { useParams } from "react-router-dom";
import PrintEmptyDataMessage from "@/components/molecules/PrintEmptyDataMessage";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import EditBillsPanel from "@/components/macro-organisms/panels/panels-admin/Bills/EditBillsPanel";

export default function StatementTab() {
    const { id } = useParams();
    const { user } = useAuth();
    const [selectedYear, setSelectedYear] = useState("");

    const { getYears, allYears, errorYear } = useConsultantStatementYears();
    const { getAllStatamentData, error, loading } = useConsultantStatement();
    const [allYearlyData, setAllYearlyData] = useState([]);
    const [yearData, setYearData] = useState({});

    useEffect(() => {
        const fetchYears = async () => {
            if (user.strongToken && id) {
                await getYears(user.strongToken, id);
                // console.log("fetching data", allYears);
            }
        };
        fetchYears();
    }, [user.strongToken, id]);

    //faire une requête pour chaque années disponible dans allYears
    useEffect(() => {
        if (id && allYears) {
            const fetchData = async () => {
                const data = [];
                for (const year of allYears) {
                    const result = await getAllStatamentData(user.strongToken, year, id);
                    if (result.data) {
                        data.push(result.data);
                    }
                }
                setAllYearlyData(data);
            };
            fetchData();
        }
    }, [id, allYears]);

    useEffect(() => {
        // Sélectionner les données de l'année cliquée
        if (allYearlyData) {
            const selectedData = allYearlyData.find(
                (item) => item.year === parseInt(selectedYear)
            );
            // console.log("selected data", selectedData);
            setYearData(selectedData);
        }
    }, [selectedYear, allYearlyData]);

    if (error) {
        console.log("il y'a une erreur", error);
        <PrintErrorMessage message={error} />;
    }

    if (id == "undefined")
        return (
            <PrintEmptyDataMessage
                message={"error.selectUser"}
                icon={<AdsClickIcon />}
            />
        );

    if (!allYears) {
        return (
            <div className="grid grid-cols-2">
                <SkeletonHandler name={"overview"} />
                <SkeletonHandler name={"overflow"} />
            </div>
        );
    }

    return (
        <div className="h-[100%] w-[100%] flex flex-col gap-1 statementTab ">

            {allYears && allYears.length == 0 ? (
                <div className=" flex flex-row w-full justify-between h-[47px]">
                    <div className="w-14 lg:w-44"></div>
                    <div className="w-14 lg:w-44"></div>
                    <div className="w-14 lg:w-44"></div>
                    <EditBillsPanel />

                </div>
            ) : (

                <div className="flex flex-row w-full justify-end h-[47px]">
                    <div className="ml-auto flex items-center space-x-4">
                        <Filter
                            items={allYears && allYears}
                            informSelectedItem={setSelectedYear}
                            className={"mr-[23vw]"}
                        />
                        <EditBillsPanel />
                    </div>
                </div>
            )}

            {allYears?.length == 0 ? (
                <PrintEmptyDataMessage
                    message={"error.emptyData"}
                    icon={<WarningAmberRoundedIcon />}
                />
            ) : error ? (
                <PrintErrorMessage error={error} />
            ) : (
                <div className=" flex  gap-1 ">
                    <div className="flex flex-col  ">
                        <div className=" flex flex-col mb-firstDegree  ">
                            <div className="flex items-center justify-start gap-firstDegree">
                                <Label
                                    size="h2"
                                    fontWeight="bold"

                                    message="consultants.statement.overview.overview"
                                    //color="black"
                                    className="font-700"
                                />{loading && <span className="loader" />}
                                </div>
                            {/* {user.role == "admin" && (
                                <Label
                                    size="small"
                                    message="consultants.statement.overview.doubleTapToEdit"
                                    //color="black"
                                    
                                />
                            )} */}
                        </div>
                        <ListOfEditableOverview
                            year={selectedYear}
                            yearData={yearData}
                            userActive={user}
                            className="w-[300px]"
                        />
                    </div>
                    {/* <div> */}
                    <Separator
                        className={`${user?.role == "admin"
                            ? "2xl:h-[118%] 2xl:mx-8 mx-4"
                            : "2xl:h-[100%] mx-8 "
                            } relative  h-[730px] w-[1px] `}
                        orientation="vertical"
                        color="secondaryBlue"
                        blur=""
                    />
                    {/* </div> */}
                    <div className=" w-[100%] flex flex-col ">
                        <div className="flex items-center justify-start gap-firstDegree">
                            <Label
                                size="h2"
                                fontWeight="bold"

                                message="consultants.statement.overflow.overflowFluctuation"
                            //color="black"

                            />{loading && <span className="loader" />}</div>
                        <TableOverflow yearData={yearData} />

                        <div className="w-[100%]  max-h-[250px] h-full  bg-lighter">
                            <LineChartComp yearData={yearData} />
                        </div>
                    </div>
                </div>
            )}

            <Toaster />
        </div>
    );
}
