/* eslint-disable react/prop-types */
/**
 * @component PrintProfileTitle
 *
 * The `PrintProfileTitle` component displays a profile title and provides an option to edit it. When the edit button is clicked, it shows a form to add or update the profile title.
 *
 * Key Features:
 * - Displays the profile title.
 * - Provides an edit button to show the form for editing the title.
 * - Integrates with the `AddProfileTitle` component to handle form actions.
 *
 * Props:
 * - @param {Object} profile - The profile object containing the `title` and other profile details.
 * - @param {Function} setEditingProfile - Function to toggle the editing mode for the profile.
 * - @param {boolean} editingProfile - Flag indicating whether the profile is being edited.
 * - @param {Function} setProfiles - Function to update the list of profiles after changes.
 * - @param {Function} setIsDoingAction - Function to trigger loading or actions during profile updates.
 * - @param {Function} setFormIsValid - Function to indicate if the form submission was valid.
 * - @param {Function} setServerResponseIserror - Function to indicate if there was an error from the server response.
 * - @param {Function} setErrors - Function to update the error messages in case of submission failure.
 * - @param {Object} user - The user object containing at least the `uuid` of the current user.
 *
 * Usage:
 * - This component is used to display a profile title and provide an interface to edit the title. It integrates with the `AddProfileTitle` component to handle the form submission for adding or updating the profile title.
 *
 * Example:
 * ```jsx
 * <PrintProfileTitle
 *   profile={profileData}
 *   setEditingProfile={toggleEdit}
 *   editingProfile={isEditing}
 *   setProfiles={updateProfilesList}
 *   setIsDoingAction={triggerAction}
 *   setFormIsValid={checkFormValidity}
 *   setServerResponseIserror={handleServerError}
 *   setErrors={displayErrors}
 *   user={currentUser}
 * />
 * ```
 *
 * Dependencies:
 * - `Label` component for displaying the profile title.
 * - `EditIcon` from `@mui/icons-material` for the edit button.
 * - `AddProfileTitle` component for handling the form actions to add or update the profile title.
 *
 * Sub-Components:
 * - @component Label: Displays the profile title.
 * - @component EditIcon: Provides an icon button to trigger the edit form.
 * - @component AddProfileTitle: Form component for adding or editing the profile title.
 */

import { Label } from "@/components/atoms/texts/label";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import AddProfileTitle from "./AddProfileTitle";

export default function PrintProfileTitle({
  profile,
  setEditingProfile,
  editingProfile,
  setIsDoingAction,
  setFormIsValid,
  setServerResponseIserror,
  setErrors,
  user
}) {
  const [showForm, setShowForm] = useState(false);
  const handleEdit = () => {
    setShowForm(true);
    setEditingProfile(true);
  };
  return (
    <div className="grow">
      {!showForm && (
        <div className="flex grow flex-row items-center gap-firstDegree flex-wrap ">
          <Label className="flex grow">{profile.title}</Label>
          <EditIcon
              className="text-primary cursor-pointer"
              onClick={handleEdit}
            />
        </div>
      )}
      <AddProfileTitle
        profile={profile}
        showForm={showForm}
        setShowForm={setShowForm}
        editingProfile={editingProfile}
        setEditingProfile={setEditingProfile}
        user={user}
        setIsDoingAction={setIsDoingAction}
        setFormIsValid={setFormIsValid}
        setServerResponseIserror={setServerResponseIserror}
        setErrors={setErrors}
      />
    </div>
  );
}
