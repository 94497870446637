import React, { useRef, useState, useEffect } from 'react';

import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Button } from '@/components/molecules/buttons/button';
import { AddRounded, DirectionsCar } from '@mui/icons-material';

import Action from '@/components/molecules/buttons/Action';
import TimesheetCalendar from './TimesheetCalendar';
import { useTimeEntriesData } from './useTimesheetData';
import transformTimeEntriesToEvents from './transformTimeEntriesToEvents';
import EditIcon from "@mui/icons-material/Edit";
import { useTimeEntries } from '@/services/consultant-services/query/useTimeEntries';

export default function NewTimesheetPanel({ global = true, isEditing, year, month, timesheetId, client, refetchTimesheets, newTimesheetButton }) {
    const submitRef = useRef(null);
    const [openNewTimesheet, setOpenNewTimesheet] = useState(undefined);
    // const { timeEntries, error } = useTimeEntriesData(year, month, timesheetId);


    // const [timeEntriesForCalendar, setTimeEntriesForCalendar] = useState();
    // const [formCalendarIsValid, setFormCalendarIsValid] = useState(false);
    const [timesheetUuid, setTimesheetUuid] = useState(timesheetId);
    const [isEditingMode, setIsEditingMode] = useState(isEditing);
    const [timesheetMonth, setTimesheetMonth] = useState({ year: year, month: month });

    // const { data: dataTimeEntries, loading: loadingTimeEntries, error: errorTimeEntries, refetch } = useTimeEntries(timesheetUuid);

    useEffect(() => {
        if (!timesheetUuid) return
        if (!openNewTimesheet) return;

        console.log("timesheetUuid change = ", timesheetUuid);
        setIsEditingMode(true);
        refetch;
    }, [timesheetUuid]);

    useEffect(() => {
        if (openNewTimesheet === false) {
            console.log("fermeture du panel, timesheetUuid=", timesheetUuid);

            // setTimeEntriesForCalendar();
            // setFormCalendarIsValid(false);
            setTimesheetUuid();
            // refetchTimesheets();
            // setIsEditingMode();
            // setTimesheetMonth({ year: undefined, month: undefined });
        }

    }, [openNewTimesheet]);

    // useEffect(() => {
    //     if (!openNewTimesheet) return;
    //     console.log("test NewTimesheetPanel", dataTimeEntries, errorTimeEntries, timesheetMonth.year, timesheetMonth.month)
    //     if (dataTimeEntries) {
    //         const transformedEvents = transformTimeEntriesToEvents(dataTimeEntries, timesheetMonth.year, timesheetMonth.month);
    //         console.log(" useEffect dataTimeEntries timeEntries_", dataTimeEntries, transformedEvents);
    //         setTimeEntriesForCalendar(transformedEvents);
    //     }
    //     if (errorTimeEntries) {
    //         console.error("Error fetching time entries: ", errorTimeEntries);
    //     }
    // }, [dataTimeEntries, errorTimeEntries, timesheetMonth]);


    return (
        <Sheet open={openNewTimesheet} onOpenChange={setOpenNewTimesheet}>
            <SheetTrigger className={global && `w-full`}>

                {global ? (
                    <Action
                        icon={<DirectionsCar />}
                        text="consultants.dashboard.actions.timesheet"
                        initialVariant={openNewTimesheet ? "active" : "default"}
                    />
                ) : (
                    timesheetId ? <Button style="pill" leftIcon={< EditIcon fontSize='small' />} showLeftIcon showText={false} sizePadding="pill" text="global.actions.edit" /> :
                        < Button leftIcon={< AddRounded />} showLeftIcon text="consultants.timesheet.addTimesheet" />
                )}
            </SheetTrigger>
            <SheetContent>
                <SheetHeader
                    title={isEditingMode ? "global.actions.edit" : "consultants.timesheet.addTimesheet"}
                />
                <SheetSlot className=" flex  relative">

                    <TimesheetCalendar
                        // formCalendarisValid={formCalendarIsValid}
                        // setFormCalendarisValid={setFormCalendarIsValid}
                        submitRef={submitRef}
                        selectedMonthToDisplay={isEditingMode && `${timesheetMonth.year}-${timesheetMonth.month < 10 ? `0` : ''}${timesheetMonth.month}`}
                        needAbsolutePopUp
                        // timeEntries={isEditingMode && timeEntriesForCalendar}
                        // refetch={refetch}
                        setTimesheetMonth={setTimesheetMonth}
                        timesheetMonth={timesheetMonth}
                        // setTimesheetUuid={setTimesheetUuid}
                        timesheetId={timesheetId}
                        client={client}
                        // seul le dataOwner a accès à ce panel
                        dataOwner 
                        refetchTimesheets={refetchTimesheets}
                    />
                </SheetSlot>
                {/* <SheetFooter
                    // buttonOutlineText={"global.actions.clear"}
                    buttonDressedText={"global.actions.save"}
                    // warning={"global.userBeNotified"}
                    // onClickOutline={(e) => submitRef.current && submitRef.reset.resetForm()}
                    onClickDressed={() => submitRef?.current.click()}
                    disabledDressed={!formCalendarisValid}

                /> */}
            </SheetContent>
        </Sheet>
    )
}