import * as React from "react";

import { cn } from "@/lib/utils";
import { Label } from "../atoms/texts/label";

const Input = React.forwardRef(
  ({ className, type, value, name,error, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex justify-beetween w-full rounded-md border bg-lighter px-3 py-2 text-body ring-offset-primary file:border-0 file:bg-transparent file:text-body file:font-medium  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-medium focus-visible:ring-offset-2 disabled:cursor-not-allowed bg-white disabled:bg-medium disabled:border-grey disabled:text-disabled",
          className
          
        )}
        ref={ref}
        name={name}
        value={value}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
