/* eslint-disable react/prop-types */
/**
 * @component Education
 *
 * The `Education` component displays a list of educational experiences and provides functionality to add or edit education details. It allows users to toggle between views, display educational entries, and handle form submissions along with any server response errors.
 *
 * Key Features:
 * - Toggle visibility of the education overview and add form.
 * - Add new education entries or edit existing ones using the `AddEducation` component.
 * - Display educational experiences using the `PrintEducation` component.
 * - Handle form submission, validation, and server response errors.
 *
 * Props:
 * - @param {Function} hideAllOverview - Function to hide all overview sections.
 * - @param {Function} showComponent - Function to control the visibility of the component.
 * - @param {Array} educations - Array of education objects to be displayed.
 * - @param {Object} user - User object containing user details necessary for API interactions.
 * - @param {Function} setIsDoingAction - Function to toggle the state indicating if an action (such as adding or editing) is in progress.
 *
 * Local State:
 * - `showForm`: Boolean to control the visibility of the add education form.
 * - `editingEducation`: Boolean to indicate if an education entry is being edited.
 * - `fomrIsValid`: Boolean to indicate if the form is valid.
 * - `serverresponseIsError`: Boolean to indicate if there was an error in server response.
 * - `errors`: Error object to store and display error messages.
 *
 * Usage:
 * - This component is used in user profile settings to manage educational experiences, including adding new entries and editing existing ones.
 * - Integrates with the `AddEducation` component for form handling and the `PrintEducation` component for displaying individual education entries.
 *
 * Example:
 * ```jsx
 * <Education
 *   hideAllOverview={hideOverview}
 *   showComponent={toggleComponent}
 *   educations={userEducations}
 *   user={currentUser}
 *   setIsDoingAction={toggleLoading}
 * />
 * ```
 *
 * Dependencies:
 * - @component AddEducation: Provides the form for adding or editing education details.
 * - @component PrintEducation: Displays individual education entries.
 * - @component ArrowBackIcon from `@mui/icons-material`: Used for the back navigation icon.
 * - @component Label: Displays section headers and labels.
 * - @component Button: Used for action buttons like "Add".
 * - @component motion from `framer-motion`: Used for animations.
 * - @hook useCVTab: Custom hook for fetching CV data and handling errors.
 * - @component PrintErrorMessage: Displays error messages related to server responses or form submission issues.
 *
 * Functions:
 * - `hider`: Function to hide all overview sections and the current component view.
 */


import  { useState } from "react";
import AddEducation from "./AddEducation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import PrintEducation from "./PrintEducation";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import useCVTab from "@/hooks/global-hooks/useCVTab";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
export default function Education({
  hideAllOverview,
  showComponent,
  educations,

  user,
  setIsDoingAction 
}) {
  const { CVData, error } = useCVTab();

  const [showForm, setShowForm] = useState(false);
  // const [educations, setEducations] = useState([]);
  const [editingEducation, setEditingEducation] = useState(false);
    const [fomrIsValid, setFormIsValid] = useState(false);
  const [serverresponseIsError, setServerResponseIserror] = useState(false);
   const [errors, setErrors] = useState(null);

  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };
 

  return (
    <motion.div variants={item}>
    
      {serverresponseIsError && <PrintErrorMessage error={errors} />}
      <div className="flex items-center gap-fourthDegree w-full">
        <ArrowBackIcon className="cursor-pointer" onClick={hider} />
        <div className="basis-3/4">
          <Label
            message="consultants.statement.prospectConsultantPanel.education"
            size="h3"
            fontWeight="bold"
          />
        </div>

        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={() => setShowForm(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
        />
      </div>
      <div className=" flex flex-col gap-secondDegree">
        {showForm && (
          <AddEducation
            showForm={showForm}
            setShowForm={setShowForm}
           
            editingEducation={false}
            setEditingEducation={setEditingEducation}
            user={user}
            setIsDoingAction={setIsDoingAction}
            setFormIsValid={setFormIsValid}
            setServerResponseIserror={setServerResponseIserror}
            fomrIsValid={fomrIsValid}
            setErrors={setErrors}
          />
        )}
        {educations &&
          educations.map((education, index) => {
            return (
              <PrintEducation
                key={index}
                education={education}
                setEditingEducation={setEditingEducation}
                editingEducation={editingEducation}
              
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                fomrIsValid={fomrIsValid}
                setErrors={setErrors}
              />
            );
          })}
      </div>
    </motion.div>
  );
}
