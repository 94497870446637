import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const APPROVE_EXPENSE_REPORT = gql`
  mutation ApproveExpenseReport($uuid: ID!, $approvalStatus: ExpenseReportApprovalStatus!, $impactsOverflow : Boolean!) {
    approveExpenseReport(approvedExpenseReport: { 
    uuid: $uuid, 
    approvalStatus: $approvalStatus 
    impactsOverflow: $impactsOverflow
    })
  }
`;

export const useApproveExpenseReport = () => {
  const { user } = useAuth();
  const [approveExpenseReportMutation, { loading, error, data }] = useMutation(APPROVE_EXPENSE_REPORT, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const approveExpenseReport = async (uuid, approvalStatus, impactsOverflow) => {
    const response = await approveExpenseReportMutation({
      variables: { uuid, approvalStatus , impactsOverflow},
    });
    return response.data.approveExpenseReport;
  };

  return { approveExpenseReport, loading, error, data };
};
