// Rôle requis : USER_TIMESHEET
// Ce hook permet à un utilisateur de soumettre une timesheet signée par le client pour validation.
// Seuls les utilisateurs ayant le rôle USER_TIMESHEET peuvent accéder à cette requête.

import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';

function useTimesheetSubmission() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();

    const submitSignedTimesheet = async (timesheetUuid, signedTimesheetFile) => {
        const apiUrl = `${window.ENV.BACKEND_URL}/api/secured/users/timesheet/submit`;
        const formData = new FormData();
        formData.append('uuid', timesheetUuid);
        formData.append('signedTimesheet', signedTimesheetFile);

        setLoading(true);
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user?.strongToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const dataError = await response.json();
                throw new Error(`HTTP error! Status: ${response.status}. Details: ${dataError?.detail}`);
            }

        } catch (err) {
            setError(err);
            // throw new Error(`HTTP error! ${err}`);
        } finally {
            setLoading(false);
            
        }
    };

    return { submitSignedTimesheet, loading, error };
}

export default useTimesheetSubmission;
