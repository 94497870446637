import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Button } from './buttons/button';

export default function ControlsDownload({dataOwner, onClickNewTab, onClickDownload, onClickDelete, hideDownload=false, hideOpenNewTab=false, hideDelete=false, disabledDownload=false, disabledOpenNewTab=false, disabledDelete=false}) {
    return (
        <div className='flex justify-center items-center h-full'>
            {!hideOpenNewTab && <Button showLeftIcon leftIcon={<OpenInNewRoundedIcon />} disabled={disabledOpenNewTab} showText={false} style="ghost" sizePadding="small" className="text-night" onClick={()=>onClickNewTab()}/>}
            {!hideDownload && <Button showLeftIcon leftIcon={<DownloadRoundedIcon />} disabled={disabledDownload}  showText={false} style="ghost" sizePadding="small" className="text-night" onClick={()=>onClickDownload()}/>}
            {!hideDelete && dataOwner && <Button showLeftIcon leftIcon={<DeleteRoundedIcon />} disabled={disabledDelete}  showText={false} style="ghost" sizePadding="small" className="text-error" onClick={()=>onClickDelete()}/>}
        </div>
    )
}