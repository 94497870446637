
/* eslint-disable react/prop-types */
import { useState } from "react";
import TableRow from "../../../../organisms/DataTable/TableRow";
import TableHead from "../../../../organisms/DataTable/TableHead";
//import Select from 'react-select'
import { useEffect } from "react";
//import useConsultantData from '../navigation/MenuConsultant/useConsultantData';

import { filterUnwantedPropertiesAdmin } from "@/utils/transformUserData";
import UniqueSelect from "../../../../molecules/inputs/UniqueSelect";
import MultiSelect from "../../../../molecules/inputs/MultiSelect";

import PrintFetchingData from "@/components/molecules/PrintFetchingData";
import { monthMap } from "@/utils/transformUserData";
import { useAuth } from "@/context/AuthContext";
import { useInvoiceYears } from "@/services/consultant-services/useInvoiceYears";
import { useInvoices } from "@/services/consultant-services/useInvoices";
import PrintErrorMessage from "@/components/molecules/PrintErrorMessage";

// Assigner la somme totale à la dernière ligne
// Ajoutez la ligne "annuel" après avoir calculé totalDays
function getClientNames(invoices) {
    return invoices.map((invoice) => invoice.clientName);
}

const getTableHead = (isAdmin) => {
    let tableHead = [
        { customer: "consultants.statement.billsDetails.table.customers" },
        { bills: "consultants.statement.billsDetails.bills" },
        { nbDays: "consultants.statement.billsDetails.table.nbDays" },
        { amount: "consultants.statement.billsDetails.table.amount" },
    ];
    if (isAdmin) return tableHead;
    else {
        let nouvelleValeurBills = "consultants.statement.overview.dailyRate";

        // Recherche de l'index de l'élément avec la clé "bills" dans le tableau
        let indexBills = tableHead.findIndex((element) => "bills" in element);

        // Vérification si l'index a été trouvé
        if (indexBills !== -1) {
            // Mise à jour de la valeur de la clé "bills"
            tableHead[indexBills].bills = nouvelleValeurBills;
        } else {
            // Gestion si la clé "bills" n'a pas été trouvée
            console.error("Clé 'bills' non trouvée dans le tableau.");
        }
    }
    return tableHead;
};

// Affichage du tableau mis à jour

const sortItems = (a, b) => {
    return b - a;
};

// };
const filterUnwantedPropertiesConsultants = (data) => {
    return Object.values(data).map((dayData) => {
        let {
            month,
            clientName,
            dailyRateConsultant,
            daysWorked,
            amountConsultant,
            uuid,
            ...rest
        } = dayData;
        month = monthMap[month];
        return {
            month,
            clientName,
            dailyRateConsultant,
            daysWorked,
            amountConsultant,

        };
    });
};

function BillsContentConsultant({
    setChangeContent,
    setEditBillsData,
    activeUser,
    changeContent,
    itemToDelete,
    changeMonthDataDeleted,
    monthDataAfterDelete,
    informSelectedYear,
}) {
    const {
        loading: yearsLoading,
        error: yearsError,
        data: yearsData,
        refetch
    } = useInvoiceYears();
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [yearlydata, setYearlyData] = useState([]);
    const {
        loadingInvoices,
        errors: errorfectInvoices,
        allInvoices,
        fetchInvoices,
    } = useInvoices();
    const [customers, setCustomers] = useState([]);
    const { hasPrivileges } = useAuth();

    const [monthDataBills, setMonthDataBills] = useState(null);
    const [dataMonth, setDataMonth] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const wantedProperties = filterUnwantedPropertiesConsultants;
    const [fetching, setFetching] = useState(true);



    useEffect(() => {

        if (yearsData) {
            //console.log("Fetched invoice years:", yearsData.invoiceYears);
            setYears(yearsData.invoiceYears);

            const fetchAllInvoices = async () => {
                setFetching(true);
                const invoices = [];
                if (yearsData.invoiceYears.length > 0) {
                    setSelectedYear(yearsData.invoiceYears[0]);
                    informSelectedYear(selectedYear);
                    for (const year of yearsData.invoiceYears) {
                        const yearInvoices = await fetchInvoices(year);
                        console.log("entrain de fetch les données invoices", allInvoices);
                        if (yearInvoices) invoices.push(...yearInvoices);
                        // setYearlyData(invoices);
                    }

                    setYearlyData(allInvoices);
                    setMonthDataBills(invoices);
                    // setDataMonth(
                    //   hasPrivileges("ADMIN_")
                    //     ? filterUnwantedPropertiesAdmin(invoices)
                    //     : filterUnwantedPropertiesConsultants(invoices)
                    // );
                    setFilteredData(

                        filterUnwantedPropertiesConsultants(invoices)
                    );


                    // console.log("yearlyData invoices", invoices);

                    const uniqueArray = [...new Set(getClientNames(invoices))];
                    const customs = Array.from(uniqueArray);
                    setCustomers(customs);

                }
                setFetching(false);
            };

            fetchAllInvoices();
            console.log("years data in pp", years);

            //console.log("change content", changeContent)
        }
        console.log("years data", years);
    }, [yearsData]);



    const totalDays = (monthData) => {
        return (
            monthData
                // Exclure la dernière ligne
                .reduce((sum, row) => sum + parseFloat(row.daysWorked), 0)
        );
    };

    const totalAmount = (monthData) => {
        return (
            monthData
                // Exclure la dernière ligne
                .reduce((sum, row) => sum + parseFloat(row.amountConsultant), 0)
        );
    };

    const filterDataYear = (year) => {
        setSelectedYear(year);
        informSelectedYear(year);

        if (selectedCustomers) {
            filterTable(selectedCustomers);
        } else {
            //setSelectedYear(year);
            //const newYearlyData = getBillsForYear(activeUser, year);
            const newYearlyData = yearlydata.filter((obj) => obj.year === year);

            const newData = hasPrivileges("ADMIN_")
                ? filterUnwantedPropertiesAdmin(newYearlyData)
                : filterUnwantedPropertiesConsultants(newYearlyData);
            //   console.log("Year", year);
            newData.push({
                rowNameOrMonth: "annuel",
                clients: "",
                bills: "",
                nbDays: `${totalDays(newData)} jours`,
                amount: totalAmount(newData),
            });
            setFilteredData(() => newData);
        } // Utiliser une fonction de rappel pour s'assurer que newData est à jour
    };

    const filterTable = (selectedValuesClients = []) => {
        setSelectedCustomers(selectedValuesClients);

        let newFilteredData = filteredData;
        if (selectedValuesClients.length > 0) {
            // Filtrer la table en fonction des clients sélectionnés
            newFilteredData = filteredData.filter((item) =>
                selectedValuesClients.includes(item.clientName)
            );
        } else {
            newFilteredData = hasPrivileges("ADMIN_")
                ? filterUnwantedPropertiesAdmin(yearlydata)
                : filterUnwantedPropertiesConsultants(yearlydata);
        }
        console.log("new filtered DATA", newFilteredData);
        newFilteredData = newFilteredData.filter(
            (item) => item.rowNameOrMonth !== "annuel"
        );
        // Ajouter la ligne "annuel" après avoir calculé totalDays et totalAmount
        newFilteredData.push({
            rowNameOrMonth: "annuel",
            clients: "",
            bills: "",
            nbDays: `${totalDays(newFilteredData)} jours`,
            amount: totalAmount(newFilteredData),
        });
        // Mettre à jour l'état avec les données filtrées
        setFilteredData(newFilteredData);
    };

    const handleClick = (id) => {
        // console.log("this row has been cliked", rowData);
        const item = yearlydata.find((obj) => obj.uuid === id);
        //setSelectedItem(item);
        console.log(item);
        setChangeContent(true);
        setEditBillsData(item);
        changeMonthDataDeleted(monthDataBills);
    };

    useEffect(() => {
        const uniqueArray = [...new Set(customers)];
        const customs = Array.from(uniqueArray);
        setCustomers([...new Set(customers)]);
    }, []);

    // const changeData = (value) => {
    //     console.log('Bills selected value', value)
    // }

    useEffect(() => {
        const calculateTotal = () => {
            if (filteredData) {
                const newFilteredData = [...filteredData];

                // Supprimer la ligne "annuel" s'il existe déjà
                const filteredWithoutAnnuel = newFilteredData.filter(
                    (item) => item.rowNameOrMonth !== "annuel"
                );

                // Ajouter la ligne "annuel" après avoir calculé totalDays et totalAmount
                filteredWithoutAnnuel.push({
                    rowNameOrMonth: "annuel",
                    clients: "",
                    bills: "",
                    nbDays: `${totalDays(filteredWithoutAnnuel)} jours`,
                    amount: totalAmount(filteredWithoutAnnuel),
                });

                // Mettre à jour l'état avec les données filtrées
                setFilteredData(filteredWithoutAnnuel);
            }
        };

        // Appeler la fonction de calcul lorsque filteredData change
        calculateTotal();
    }, [yearlydata]);

    if (yearsLoading) return <PrintFetchingData />;
    if (yearsError || errorfectInvoices)
        return <PrintErrorMessage error={yearsError || errorfectInvoices} />
    if (loadingInvoices) return <PrintFetchingData />;


    return (
        <div>
            <div className="flex flex-row w-full py-paddingTopAndBottom px-paddingLeftAndRight gap-secondDegree">
                <div className="w-full">
                    <UniqueSelect
                        dataToSelect={years}
                        labelClassName={"font-bold text-h6"}
                        filterDataFunction={filterDataYear}
                    />
                    {/* <Selecter dataToSelect={years} handleValueSelected={changeData}/>  */}
                </div>
                <div className="w-full">
                    <MultiSelect
                        dataToSelect={customers}
                        marginBottom={"mb-[7px]"}
                        labelClassName={"font-bold text-h6"}
                        filterDataFunction={filterTable}
                    />
                    {/*<Selecter dataToSelect={clientsList} handleValueSelected={changeData} /> */}
                </div>
            </div>
            <div className="p-secondDegree w-[95%]">
                <TableHead
                    tableHeadData={getTableHead(false)}
                    colorsOfAllcells={"grey"}
                    classNameForCell={"py-4"}
                />
                {filteredData &&
                    filteredData.map((month, index) => {
                        const { uuid, ...monthWithoutId } = month;

                        return (
                            <TableRow
                                rowData={Object.values(monthWithoutId)}
                                key={index}
                                enableHover={false}
                                tableOfIndexOfCellsToTranslate={[0]}
                                indexOfCellWithSpecificBorder={0}
                                borderName={"left"}
                                makeTansparentFirstCell={true}
                                displayHoveredIcon={true}
                                className=""
                                printToolTip={true}
                                //handleClick={() => handleClick(uuid)}
                                tableOfIndexCellsWithoutCurrency={[1, 2, 3]}
                                indexOfCellWithSpecificColor={
                                    Object.values(month)[0] == "annuel" && 4
                                }
                                colorOfSpecificCell="primaryLight"
                                rowId={uuid}

                            // onRowDataChange={(newData) => handleMonthDataChange(newData, index)}
                            />
                        );
                    })}
            </div>
        </div>
    );
}

export default BillsContentConsultant;
