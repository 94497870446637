import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
// Assurez-vous que le chemin d'importation est correct
import { useAuth } from "@/context/AuthContext";

export const GET_ALL_SKILLS = gql`
  query GetAllSkills($companyUuid: ID!) {
    allSkills(companyUuid: $companyUuid) {
      skillAssignedUsersCount
      companyUuid
      labelEn
      labelDe
      labelEs
      labelFr
      labelIt
      labelNl
      skillType
      uuid
      skillAssignedUsers {
        uuid
        firstname
        lastname
      }
    }
  }
`;
export const useAllSkills = () => {
  const { user } = useAuth();

  const { loading, error, data, refetch } = useQuery(GET_ALL_SKILLS, {
    variables: { companyUuid: user.company.uuid },
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    fetchPolicy: "network-only", // S'assure que les données sont toujours récupérées depuis le serveur
  });

  return { loading, error, data, refetch };
};