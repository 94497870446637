import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Mutation pour ajouter une nouvelle TVA
const ADD_VAT = gql`
  mutation AddVat($companyUuid: ID!, $rate: Float!) {
    addVat(newVatDto: {companyUuid: $companyUuid, rate: $rate}) {
      uuid
    }
  }
`;

// Hook personnalisé pour ajouter une TVA
export const useAddVat = (refetch) => {
  const { user } = useAuth();
  const companyUuid = user.company.uuid;

  // Mutation pour ajouter une nouvelle TVA
  const [addVat, { loading: addLoading, error: addError }] = useMutation(ADD_VAT, {
    variables: { companyUuid },
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    onCompleted: refetch // Rafraîchir les données après la mutation
  });

  return { 
    addVat,
    addLoading,
    addError 
  };
};
