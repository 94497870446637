import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useEffect } from "react";

const GET_COMPANY_BENEFITS_INVOICES = gql`
  query CompanyBenefitInvoices($sliceNumber: Int!) {
    companyBenefitInvoices(companyBenefitInvoicesRequest: { sliceNumber: $sliceNumber }) {
       companyBenefits {
        uuid
        companyBenefitInvoiceFileUuid
        year
        month
        invoiceDate
        amountExcludingVat
        totalAmount
        benefitType
        comment
        vatList {
          vatRate
          vatAmount
        }
      }
      hasNext
    }
  }
`;

export const useCompanyBenefitInvoices = (sliceNumber) => {
  const { user } = useAuth();

  const { loading, error, data , refetch} = useQuery(GET_COMPANY_BENEFITS_INVOICES, {
    variables: { sliceNumber },
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch(sliceNumber)
  }, [sliceNumber]);

  return { loading, error, data: data?.companyBenefitInvoices, refetch };
};
