/* eslint-disable react/prop-types */
import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useState } from "react";
import { Label } from "@/components/atoms/texts/label";
import { cn } from "@/lib/utils";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";

const customers = "consultants.statement.billsDetails.customer";
export default function MultiSelect({
    dataToSelect,
    filterDataFunction,
    label = customers,
    icon = <PersonIcon />,
    className,
    labelClassName,
    marginBottom,
}) {
    // const animatedComponents = makeAnimated();
    let options;
    dataToSelect && (options = dataToSelect.map((value) => ({ value, label: value })));
    const [selectedValue, setSelectedValue] = useState(null);
    const [t] = useTranslation('global');
    const handleSelectChange = (selectedOption) => {
        // `selectedOption` will contain the selected value and label
        if (selectedOption) {
            const selectedValues = selectedOption.map((option) => option.value);

            setSelectedValue(selectedValues);
            // console.log("Selected value:", selectedOption);

            //filterDataFunction
            filterDataFunction && filterDataFunction(selectedValues);
        }
    };

    return (
        <div className={cn(className, " w-full flex flex-col gap-firstDegree")}>
            {/* <div className={cn("flex flex-row  gap-firstDegree", marginBottom)}> */}
            <div className="flex items-center gap-firstDegree">
                {icon}
                <Label message={label} className={labelClassName} />
            </div>

            {/* </div> */}
            {/* <div className="w-auto "> */}
            <Select
                closeMenuOnSelect={false}
                // components={animatedComponents}
                className="text-body"
                placeholder={t("global.actions.select")}
                //defaultValue={[colourOptions[4], colourOptions[5]]}
                isMulti
                options={options}
                onChange={handleSelectChange}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? "black" : "black",
                        borderRadius: "8px",
                        
                    }),
                }}
            />
            {/* </div> */}
        </div>
    );
}
