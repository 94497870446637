// Rôle requis : USER_TIMESHEET
// Ce hook permet à un utilisateur de supprimer une timesheet.
// Seuls les utilisateurs ayant le rôle USER_TIMESHEET peuvent accéder à cette mutation.

import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const DELETE_TIMESHEET = gql`
  mutation DeleteTimesheet($uuid: ID!) {
    deleteTimesheet(deletedTimesheet: { uuid: $uuid })
  }
`;

export const useDeleteTimesheet = () => {
  const { user } = useAuth();
  const [deleteTimesheetMutation, { loading, error, data }] = useMutation(DELETE_TIMESHEET, {
    context: {
      headers: {
        Authorization: `Bearer ${user?.strongToken}`,
      },
    },
  });

  const deleteTimesheet = async (uuid) => {
    try {
      const response = await deleteTimesheetMutation({
        variables: { uuid },
      });
      return { data: response.data?.deleteTimesheet, error: null };
    } catch (err) {
      return { data: null, error: err.message };
    }
  };

  return { deleteTimesheet, loading, error, data };
};
