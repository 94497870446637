import React from 'react';
import { ResizablePanelGroup, ResizableHandle, ResizablePanel } from "@/components/ui/resizable";

import { Outlet } from "react-router-dom"
import ListingConsultant from '@/components/organisms/navigation/MenuConsultant/ListingConsultant';

export default function Consultant() {

    return (
        <div className="bg-lighter flex grow overflow-hidden relative">
            <ResizablePanelGroup direction="horizontal" className={"divide-x "} id="panel1" order={1}>
                <ResizablePanel defaultSize={15} className=' h-full px-firstDegree bg-medium max-w-[260px] w-[260px] min-w-[260px] z-20' id="panelListing" order={1} >

                    <ListingConsultant />
                </ResizablePanel>

                {/* <ResizableHandle withHandle /> */}
                <Outlet />
            </ResizablePanelGroup>
        </div>

    )
}
