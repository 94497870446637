import * as React from "react";
import ErrorUnderConstruction from "@/errorUnderConstruction";
import Overflow from "@/pages/consultant/overflow/overflow";

export default function OverflowOptimization() {
    return (
        <Overflow />
        // <ErrorUnderConstruction/>

    )
}