import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
 // Assurez-vous que le chemin d'importation est correct
import { useAuth } from "@/context/AuthContext"
    
    ;
export const DELETE_SKILL = gql`
  mutation DeleteSkill($uuid: ID!) {
    deleteSkill(uuid: $uuid)
  }
`;


export const useDeleteSkill = () => {
  const { user } = useAuth();
  const [deleteSkillMutation, { loading, error, data }] = useMutation(
    DELETE_SKILL,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      },
    }
  );

  const deleteSkill = async (uuid) => {
    try {
      const response = await deleteSkillMutation({
        variables: { uuid },
      });
      return response.data.deleteSkill;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  return { deleteSkill, loading, error, data };
};