/* eslint-disable react/prop-types */
/**
 * @component PrintLanguageLine
 *
 * The `PrintLanguageLine` component displays a language spoken by the user along with their proficiency level. It allows editing the proficiency and deleting the language. The user can confirm deletion or edit the language level using a star rating system.
 *
 * Key Features:
 * - Displays a language and its associated proficiency level (in stars).
 * - Allows editing the language proficiency with immediate updates.
 * - Allows users to confirm deletion of the language.
 * - Provides user feedback based on server responses for update and delete actions.
 *
 * Props:
 * - @param {Object} language - The language object containing `uuid`, `spokenLanguage`, and `rating` properties.
 * - @param {Object} user - The user object containing at least the `uuid`.
 * - @param {Function} setEditingLanguage - Function to toggle the editing mode.
 * - @param {boolean} editingLanguage - Flag indicating if the language is being edited.
 * - @param {Function} setLanguages - Function to update the list of languages.
 * - @param {Function} setIsDoingAction - Function to trigger loading or actions when updating or deleting languages.
 * - @param {Function} setFormIsValid - Function to indicate if the form is valid after an action.
 * - @param {Function} setServerResponseIserror - Function to indicate if there was an error in the server response.
 * - @param {Function} setErrors - Function to update the error messages in case of an action failure.
 *
 * Usage:
 * - This component is used in user profile settings to display, edit, and delete spoken languages with a star rating system.
 *
 * Example:
 * ```jsx
 * <PrintLanguageLine
 *   language={language}
 *   user={user}
 *   setEditingLanguage={toggleEdit}
 *   setLanguages={updateLanguages}
 *   setIsDoingAction={toggleAction}
 *   setFormIsValid={validateForm}
 *   setServerResponseIserror={handleServerError}
 *   setErrors={updateErrors}
 * />
 * ```
 *
 * Dependencies:
 * - `Button` component for form actions (Save, Cancel, Delete).
 * - `Label` component for displaying text labels (language name).
 * - `Stars` component for rating the proficiency of the language.
 * - `useTranslation` hook from `react-i18next` for multi-language support.
 * - `useUpdateProspectDeleteItems` and `useUpdateProspectUpdateItems` hooks for managing API calls to update or delete languages.
 * - Utility functions `getLanguageNameByCode` and `getEmojiFlag` for displaying language names and flags.
 *
 * Sub-Components:
 * - @component Button: The button component used for various actions like Save, Cancel, Delete.
 * - @component Label: A component to display the language name with a flag.
 * - @component Stars: A star rating system to capture and display the user's proficiency level.
 * - @component Separator: A visual separator used when editing or confirming deletion.
 */
import { Button } from "@/components/molecules/buttons/button";
import { Label } from "@/components/atoms/texts/label";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stars from "../skills/Stars";
import { getLanguageNameByCode } from "@/utils/getLanguesNameAnCode";
import { getEmojiFlag } from "countries-list";
import useUpdateProspectDeleteItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectDeleteItems";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import useUpdateProspectUpdateItems from "@/services/admin-services/mutations/updateProspect/useUpdateProspectUpdateItems";


//const languageNames = Object.values(languages).map((lang) => lang.name);
const languageLevels = ["A1", "A2", "B1", "B2", "C1", "C2"];

export default function PrintLanguageLine({
    language,
    user,
    setEditingLanguage,
    editingLanguage,

    setIsDoingAction,
    setFormIsValid,
    setServerResponseIserror,
    setErrors,
}) {
    const [t] = useTranslation("global");
    const { deleteSpokenLanguage, errorDeleteSpokenLanguage, loadingDeleteSpokenLanguage } = useUpdateProspectDeleteItems()
    const { updateSpokenLanguage, errorUpdateSpokenLanguage, loadingUpdateSpokenLanguage } = useUpdateProspectUpdateItems()
    const [languageName, setLanguageName] = useState("");
    const [languageCode, setLanguageCode] = useState("");
    const [level, setLevel] = useState(language.rating);
    const [levelOld, setlevelOld] = useState(language.rating);
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [isEditing, setIsEditing] = useState(false)



    useEffect(() => {
        const mother = t("global.information.motherTongue");
        if (languageLevels[6]) {
            languageLevels[6] != mother && languageLevels.push(mother);
        } else {
            languageLevels.push(mother);
        }
    }, [t]);

    // const [showForm, setShowForm] = useState(false);
    // const handleEdit = () => {
    //   setShowForm(true);
    //   setEditingLanguage(true);
    // };

    const handleUpdate = async () => {

        await updateSpokenLanguage(
            user.uuid,
            language.uuid,
            language.spokenLanguage,
            level
        );
        setIsDoingAction(true)
        if (errorUpdateSpokenLanguage) {
            setErrors(errorUpdateSpokenLanguage)
            setServerResponseIserror(true)

        }
        setIsEditing(false)
    }

    const handleChange = (val) => {
        levelOld != level && setIsEditing(true)
        setLevel(val)

    }


    const handleCancel = () => {
        setIsEditing(false)
        !errorUpdateSpokenLanguage && setLevel(levelOld)
    }

    const handleDelete = async (uuid) => {
        //setShowForm(false);
        try {
            // Replace with actual project UUID to delete
            await deleteSpokenLanguage(user.uuid, uuid);
            //alert("Updated Projects:", updatedProjects);
            setIsDoingAction && setIsDoingAction(true);

            if (errorDeleteSpokenLanguage) {
                setErrors && setErrors(errorDeleteSpokenLanguage);
                setFormIsValid && setFormIsValid(false);
                setServerResponseIserror && setServerResponseIserror(true);
            }
            else {
                setFormIsValid && setFormIsValid(true);
            }
        } catch (err) {
            // alert("Failed to delete project:", err);
            console.error("Failed to delete project:", err);
        }
    };
    //const [hideInput, setHideInput] = useState(false);
    return (
        <div className={`flex flex-col  ${confirmDelete && `gap-secondDegree rounded-sm p-firstDegree border border-error bg-white`}`}>
            <div className={` flex gap-secondDegree items-center text-body `}>
                {!confirmDelete && (
                    <Button
                        onClick={() => setConfirmDelete(true)}
                        showLeftIcon={true}
                        sizePadding="firstDegree"
                        showText={false}
                        leftIcon={<DeleteIcon className="text-primary cursor-pointer" />}
                        style="ghost"
                    />
                )}

                <Label>
                    {" "}
                    {getEmojiFlag(
                        language.spokenLanguage == "EN" ? "GB" : language.spokenLanguage
                    ) +
                        " " +
                        getLanguageNameByCode(language.spokenLanguage)}
                </Label>

                <Stars
                    totalStars={5}
                    informSelectedIndex={handleChange}
                    indexSelected={level}
                    allowChange={true}
                />
            </div>

            {isEditing && (
                <>
                    <div className="flex flex-row gap-[12px] items-center w-full">
                        {/* <DeleteIcon
                  className="text-primary cursor-pointer"
                  onClick={handleDelete}
                /> */}
                        <Button
                            showLeftIcon={true}
                            leftIcon={<ClearIcon fontSize="small" />}
                            showText={true}
                            text="global.actions.cancel"
                            sizePadding="default" // Utilisez "small" pour un padding plus petit
                            style="outline"
                            className="font-bold border-primary w-full"
                            onClick={() => handleCancel()} // Options: "fill", "outline", "ghost", "dressedGhost"
                        />
                        <Button
                            showLeftIcon={true}
                            leftIcon={<SaveIcon fontSize="small" />}
                            showText={true}
                            text="global.actions.save"
                            sizePadding="default" // Utilisez "small" pour un padding plus petit
                            style="fill"
                            className="font-bold w-full"
                            onClick={() => handleUpdate()}
                        // Options: "fill", "outline", "ghost", "dressedGhost"
                        />
                    </div>
                    <Separator />
                </>
            )}
            {confirmDelete && (
                <>
                    <div className="flex gap-2 items-center">
                        <Label message="consultants.statement.vatSettings.confirmDelete" />
                        <Button
                            showLeftIcon
                            className="text-error border-error rounded-sm bg-white"
                            leftIcon={
                                <DeleteOutlineIcon className="text-error" fontSize="small" />
                            }
                            // showText={false}
                            text={"global.actions.confirmDelete"}
                            sizePadding="small"
                            style="outline"
                            onClick={() => handleDelete(language.uuid)}
                        />
                        <Button
                            className="text-primary border-primary py-2 rounded-sm"
                            text={"global.actions.cancel"}
                            sizePadding="small"
                            style="ghost"
                            onClick={() => setConfirmDelete(false)}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
