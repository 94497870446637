import React from 'react'
import BlueHeader from '@/components/organisms/blueHeader'
import LineArt from "@/assets/images/lineArt.png";
import AddProspectsPanel from '@/components/macro-organisms/panels/panels-admin/AddProspects/AddProspectsPanel';
import TableProspects from './TableProspects';
import Table from '@/components/ui/Table_';
import { Outlet } from 'react-router-dom';

export default function Prospects() {
    return (
        <div className="bg-lighter flex grow overflow-hidden relative">
            <div className="w-full h-[93vh] overflow-y-scroll overflow-x-hidden z-10  ">
                <img src={LineArt} className="bgLineArt " />
                {/* <BlueHeader title="prospects.prospectsConsultants" />
                <div className="px-[10vw] py-thirdDegree  gap-thirdDegree flex flex-col w-full  ">

                    <AddProspectsPanel />
                    <TableProspects />
                </div> */}
                <Outlet />
            </div>
        </div>
    );
}
