/* eslint-disable react/prop-types */
import React from "react";
import Select from "react-select";
import { useState } from "react";
import { cn } from "@/lib/utils";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Label } from "@/components/atoms/texts/label";
import { useTranslation } from "react-i18next";


export default function UniqueSelect({
  dataToSelect,
  filterDataFunction,
  label = "consultants.statement.billsDetails.year",
  icon = <CalendarTodayIcon />,
  className,
  labelClassName,
  isSearchable = false,
  showLabel = true,
  defaultValue,
  placeholderSelect,
  adaptSelectForInvoices=false,

    ...props
}) {
  const [isClearable, setIsClearable] = useState(true);
  
   //const [isSearchable, setIsSearchable] = useState(true);
  //   const [isDisabled, setIsDisabled] = useState(false);
  //   const [isLoading, setIsLoading] = useState(false);
  //   const [isRtl, setIsRtl] = useState(false);
  let placeHolder = placeholderSelect ? placeholderSelect: "global.actions.select";
  const [t] = useTranslation("global");
     placeHolder = t(placeHolder);
  const [selectedValue, setSelectedValue] = useState(dataToSelect ? dataToSelect[0]: "");
  const handleSelectChange = (selectedOption) => {
    // `selectedOption` will contain the selected value and label
    if (selectedOption) {
      setSelectedValue(selectedOption);
      console.log("Selected value:", selectedOption.value);
      //filterDataFunction
      filterDataFunction && filterDataFunction(selectedOption.value);
    }
  };
  let options;

  dataToSelect &&
    (options = dataToSelect.map((value) => ({ value, label: value })));
  return (
    <div className={cn(className, "w-full ")}>
      {showLabel && (
        <div className="flex flex-row mb-firstDegree gap-firstDegree ">
          {icon}
          <Label message={label} className={labelClassName} />
        </div>
      )}
      <div className="w-full text-body ">
        <Select
          classNames=""
          classNamePrefix="select"
          //defaultValue={dataToSelect[0]}
          // isDisabled={isDisabled}
          //isLoading={isLoading}
          // openMenuOnFocus={true}
          placeholder={placeHolder}
          value={selectedValue && selectedValue}
          // value={defaultSelected  ? defaultSelected : selectedValue}
          isSearchable={true}
          isClearable={isClearable}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "black" : "black",
              borderRadius: "8px",
              height: adaptSelectForInvoices && "33px",
              marginLeft: adaptSelectForInvoices && "26px",
              width:"100%"
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: "black", // Place color customization here
            }),
          }}
          name={label}
          options={options}
          onChange={handleSelectChange}
          {...props}
        />
      </div>
    </div>
  );
}
