import React, { useState } from 'react'
import { Label } from '../../atoms/texts/label';


export default function PrintSuccessMessage({ success, isComponent = false, className, dismissible }) {
    const [visible, setVisible] = useState(true);

    if (visible) {
        return (
            <div className={`flex flex-col p-secondDegree border border-success rounded-sm bg-success20 ${className}`} >
                {isComponent && success}
                {!isComponent && <Label message={success}>{success}</Label>}
                {dismissible && <Button style="ghost" sizePadding="medium" showLeftIcon leftIcon={<CloseRoundedIcon />} showText={false} onClick={() => setVisible(false)} className="text-error rounded-sm"></Button>}
            </div>
        );
    } else {
        return <></>
    }
}
