/* eslint-disable react/prop-types */
/**
 * @component PopupOverDelete
 *
 * The `PopupOverDelete` component provides a user interface for confirming the deletion of a prospect. It displays a dialog with details about the prospect and prompts the user to confirm or cancel the deletion action.
 *
 * Key Features:
 * - Displays a button to trigger the deletion confirmation dialog.
 * - Shows details about the prospect to be deleted.
 * - Provides options to confirm or cancel the deletion.
 *
 * Props:
 * - @param {Function} handleClickDelete - Callback function to handle the delete action. Receives the prospect's UUID as an argument.
 * - @param {Object} prospect - Object representing the prospect to be deleted. Should contain `firstname` and `lastname` properties for display purposes.
 *
 * Local State:
 * - No local state is managed within this component. It relies on props and dialog interactions.
 *
 * Usage:
 * - This component is used to prompt the user to confirm the deletion of a prospect. It is typically used in administrative interfaces where deletion actions are needed.
 *
 * Example:
 * ```jsx
 * <PopupOverDelete
 *   handleClickDelete={handleDelete}
 *   prospect={{ firstname: 'John', lastname: 'Doe', uuid: '123-abc' }}
 * />
 * ```
 *
 * Dependencies:
 * - @component Button: Component for rendering buttons with different styles and text.
 * - @component Dialog: Components for displaying modal dialogs.
 * - @component Label: Component for displaying text labels.
 * - @mui/icons-material: Icons used in buttons.
 * - @hook React: React hooks and components used to manage dialog state and interactions.
 *
 * Functions:
 * - `handleClickDelete(prospect.uuid)`: Function to handle the delete action. This function is called when the user confirms the deletion.
 *
 * @example
 * const handleDelete = (uuid) => {
 *   console.log(`Deleting prospect with UUID: ${uuid}`);
 * };
 *
 * <PopupOverDelete
 *   handleClickDelete={handleDelete}
 *   prospect={{ firstname: 'John', lastname: 'Doe', uuid: '123-abc' }}
 * />
 */


import React from 'react'
import { Button } from '@/components/molecules/buttons/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import { Label } from '@/components/atoms/texts/label';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
export default function PopupOverDelete({handleClickDelete, prospect}) {
  return (
    <aside className="flex gap-secondDegree items-center">
      <Dialog>
        <DialogTrigger>
          <Button
            style="ghost"
            showLeftIcon={true}
            sizePadding={"pill"}
            className={"w-min corner-md"}
            showText={false}
            leftIcon={<DeleteIcon className="text-error" />}
          />
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <Label message="prospects.deleteProspect" size="h4" />
            </DialogTitle>
            <DialogDescription className="flex flex-col gap-4 py-4">
              <Label message={"prospects.noteDelete"} fontWeight="bold" />
              <span className="flex flex-row gap-4 items-center">
                <Label message="prospects.confirmDelete" />
                {prospect.firstname} {prospect.lastname} ?
              </span>

              {/*  */}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className=" flex flex-row gap-2">
            <DialogClose className='w-full' >
              <Button
                style="outline"
                text="global.actions.cancel"
                className="w-full"
                // onClick={() => {
                //   callUpdateConsultantProfile(selectedProfile);
                //   onclose();
                // }}
              />
            </DialogClose>
            <DialogClose className="w-full">
              <Button
                style="outline"
                text="global.actions.delete"
                className="w-full text-error border-error"
                onClick={() => handleClickDelete(prospect.uuid)}
              />
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </aside>
  );
}
