export const BENEFITS_TYPE = [
    { enum: 'PHONE', label: 'phoneEnum' },
    { enum: 'LEASING', label: 'companyBenefitEnum' },
    { enum: 'OTHER', label: 'otherEnum' }
];

export const GENDERS = [
    { enum: 'MALE', label: "consultants.statement.prospectConsultantPanel.male" },
    { enum: 'FEMALE', label: "consultants.statement.prospectConsultantPanel.female" },
    // { enum: 'OTHER', label: "consultants.statement.prospectConsultantPanel.other" }
]
// export const IMPACT_OVERFLOW = [
//     { false: { enum: 'false', label: "consultants.expense.impactsOverflow.false" } },
//     { true: { enum: 'true', label: "consultants.expense.impactsOverflow.true" } }
// ]

export const IMPACT_OVERFLOW = {
    true: { enum: true, label: "consultants.expense.impactsOverflow.true" },
    false: { enum: false, label: "consultants.expense.impactsOverflow.false" }
  };
  