
import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

// Définition de la requête GraphQL pour obtenir tous les rôles
const GET_ALL_ROLES = gql`
  query ALL_ROLES($companyUuid: ID!) {
    allRoles(companyUuid: $companyUuid) {
      eligibleToProspectMigration
      name
      uuid
    }
  }
`;

const useAllRoles = () => {
  const { user: authUser } = useAuth();
  const companyUuid = authUser.company.uuid;

  const { loading, error, data, refetch } = useQuery(GET_ALL_ROLES, {
    variables: { companyUuid },
    context: {
      headers: {
        Authorization: `Bearer ${authUser.strongToken}`,
      },
    },
    fetchPolicy: "network-only",
  });

  return { 
    loading, 
    error, 
    allRoles: data?.allRoles, 
    refetch 
  };
};

export default useAllRoles;
